import {Component, Input} from '@angular/core';
import {IDocument, IFlowData} from "@modules/private/pushing/data/interfaces/ipushing.interface";
import {IWeetrustDocument} from "@modules/private/loan/data/interfaces/weetrusth.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiWeetrusthService} from "@modules/private/loan/data/services/api/api-weetrusth.service";

@Component({
  selector: 'app-pushing-record-external-url',
  templateUrl: './pushing-record-external-url.component.html',
  styleUrls: ['./pushing-record-external-url.component.scss']
})
export class PushingRecordExternalUrlComponent {
  @Input() data!: IDocument
  @Input() values!: IFlowData | null
  isUpdating = false
  agreement!: IWeetrustDocument
  entity!: FormGroup
  constructor(
    private fb: FormBuilder,
    private apiWeetrustService: ApiWeetrusthService
  ) {
    this.entity = this.fb.group({
      url: ['', [Validators.required]],
      requireUserAction: [false, [Validators.required]]
    });
  }

  getEntity() {
    console.log(this.data, "haber")
    const data = new FormData()
    data.append('value', this.entity.value.url)
    data.append('requireUserAction', this.entity.value.requireUserAction)
    data.append('documentId', this.data._id)
    return data
  }
}
