import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {FlowService} from "@modules/private/pushing/data/services/flow.service";

@Component({
  selector: 'app-update-flow-pushing',
  templateUrl: './update-flow-pushing.component.html',
  styleUrls: ['./update-flow-pushing.component.scss']
})
export class UpdateFlowPushingComponent implements OnInit {
  @Input() entity!: FormGroup
  @Input() submitted!: boolean
  flows:{label: string; value: string;}[]= []

  constructor(
    private flowService: FlowService
  ) {
  }

  ngOnInit() {
    this.getFlows()
  }

  getFlows() {
    this.flowService.getAll().subscribe((r) => {
      console.log("flows", r)
      if (!r.error) {
        this.flows = r.data.map(f => {
          return {
            label: f.name,
            value: f._id
          }
        })
      }
    })
  }
}
