import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {ILoanOwnership, ILoanRecordsPickList} from "@modules/private/loan/data/interfaces/iloan.interface";
import {LoaderService} from "@data/services/page/loader.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiAdminService} from "@modules/private/admin/data/services/api-admin.service";
import {Message, MessageService} from "primeng/api";

@Component({
  selector: 'app-loan-assignment',
  templateUrl: './loan-assignment.component.html',
  styleUrls: ['./loan-assignment.component.scss'],
  providers: [MessageService]
})
export class LoanAssignmentComponent implements OnInit {
  sourceProducts!: ILoanRecordsPickList[];
  targetProducts!: ILoanRecordsPickList[];
  admins: {label: string; value: string;}[] = []
  entity: FormGroup
  responseMessage: Message[] = []
  showMessage = false
  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private apiLoanService: ApiLoanService,
    private loaderService: LoaderService,
    private apiAdminService: ApiAdminService,
    private messageService: MessageService,
  ) {
    this.entity = this.fb.group({
      adminId: ['', [Validators.required]],
    })
  }

  ngOnInit() {
    this.getAdmins()
  }

  getAssignments() {
    console.log(this.entity)
    if (this.entity.valid) {
      this.loaderService.showSpinner()
      this.apiLoanService.getByOwnership({adminId: this.entity.value.adminId}).subscribe((r) => {
        this.loaderService.hideSpinner()
        this.sourceProducts = r.data.loans
        const ownership = r.data.loansOwnership.map((l: ILoanOwnership) => l.itemId)
        this.targetProducts = r.data.loans.filter((l: ILoanRecordsPickList) => ownership.includes(l._id))
        this.sourceProducts = this.sourceProducts.filter((l) => !ownership.includes(l._id))
        this.cdr.markForCheck();
      })
    }
  }

  getAdmins() {
    this.apiAdminService.getAll().subscribe(r => {
      const admins: {label: string; value: string;}[] = []
      r.data.forEach(a => {
        admins.push({
          label: `${a.names.firstName} ${a.names.lastName} | ${a.emailAddress}`,
          value: a._id
        })
      })
      this.admins = admins
    })
  }


  get getSelectedLoans() {
    return this.targetProducts
  }

  assign(){
    console.log(this.targetProducts)
    this.loaderService.showSpinner()
    this.apiLoanService.assignLoans({ids: this.targetProducts.map(l => l.folio), module: 'LOAN', adminId: this.entity.value.adminId}).subscribe(r => {
      this.loaderService.hideSpinner()
      console.log(r)
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 8000 });
    })
  }
}
