<p-card>
  <div class="row">
    <div class="col-12 mb-3">
      <h1 class="mb-3">Convenios</h1>
      <p class="text-color-secondary">Los convenios permiten vincular una pagadora y la razón social de una empresa.</p>
      <p-toast></p-toast>
    </div>
  </div>
  <p-table
    #dtAgreements
    [scrollable]="true"
    scrollHeight="600px"
    [value]="agreements"
    [columns]="cols"
    dataKey="_id"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [loading]="loading"
    [paginator]="true"
    [tableStyle]="{'width': '100%'}"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} convenios"
    [globalFilterFields]="['entity.name', 'entity.rfc', 'remuneratorId.name', 'creationDate']"
    (onFilter)="onFilter($event, dtAgreements)"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-row justify-content-between">
        <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
        <div>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dtAgreements.filterGlobal(getValue($event), 'contains')" placeholder="Buscar..." />
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="min-width:15rem">
          <div class="flex align-items-center" pSortableColumn="entity.name">
            Nombre
            <p-columnFilter type="text" field="entity.name" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="entity.name"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:15rem">
          <div class="flex align-items-center" pSortableColumn="entity.rfc">
            RFC
            <p-columnFilter type="text" field="entity.rfc" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="entity.rfc"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:15rem">
          <div class="flex align-items-center" pSortableColumn="remuneratorId.name">
            Nombre de pagadora
            <p-columnFilter type="text" field="remuneratorId.name" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="remuneratorId.name"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:15rem">
          <div class="flex align-items-center" pSortableColumn="creationDate">
            Fecha de creación
            <p-columnFilter type="date" field="creationDate" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="creationDate"></p-sortIcon>
          </div>
        </th>
        <ng-container *ngIf="authService.userHasAuthorization('AGREEMENT','UPDATE')">
          <th style="min-width:10rem">
            Acciones
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-agreement>
      <tr>
        <td>{{ agreement.entity.name }}</td>
        <td>{{ agreement.entity.rfc }}</td>
        <td>{{ agreement.remuneratorId.name }}</td>
        <td>
          <span class="ml-1 vertical-align-middle">{{ agreement.creationDate | date: 'MM/dd/yyyy' }}</span>
        </td>
        <ng-container *ngIf="authService.userHasAuthorization('AGREEMENT','UPDATE')">
          <td>
            <button pButton pRipple type="button" icon="pi pi-pencil" (click)="newEntity('UPDATE', agreement)" class="p-button-rounded p-button-text"></button>
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">No se encontraron convenios</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>

<!------------------------CREATE AGREEMENT ---------------------------->
<p-dialog [(visible)]="createEntity" [style]="{ width: '450px' }" [header]="formAction === 'CREATE' ? 'Nuevo convenio' : 'Actualizar convenio'" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <app-create-agreement [entity]="entity" [type]="formAction" [submitted]="submitted"></app-create-agreement>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="saveProduct()" [disabled]="!entity.valid"></button>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

