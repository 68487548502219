<ng-container *ngIf="authService.userHasAuthorization('LOAN','CREATE_AGREEMENT');">
  <div class="grid">
    <div class="col-12">
      <div class="main-title">
        Generar contrato
      </div>
      <div class="col-12">
        <button pButton pRipple label="Generar" icon="fa-solid fa-file-circle-check" class="p-button-success" (click)="generateAgreement()"></button>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="data && agreement && !updateAgreement;else elseAgreement">
  <div class="grid background-secondary">
    <div class="col-6">
      <h3 class="mb-2">Estatus de la firma:<span class="font-normal h6 ml-2">{{(agreement.responseData.status || '-') | selectEnum:$_loanWeetrustStatus}}</span></h3>
    </div>
    <div class="col-6">
      <h3 class="mb-2">Liga para la firma:<span class="ml-2"><a [href]="agreement.responseData.documentFileObj.url" target="_blank"><i class="fa-solid fa-link"></i></a></span></h3>
    </div>
  </div>
  <ng-container *ngIf="authService.userHasAuthorization('LOAN','UPDATE');">
    <div class="grid">
      <div class="col-12 text-right mt-3">
        <button pButton pRipple label="Actualizar contracto" icon="fa-solid fa-eye" class="p-button-success" (click)="updateAgreement = true"></button>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #elseAgreement>
  <div class="col-12">
    <form [formGroup]="entity">
      <div class="grid">
        <div class="col-12">
          <div class="main-title">
            Alta de contrato
          </div>
        </div>
        <div class="col-12 md:col-6 xl:col-4">
          <div class="field field-flex">
            <label for="contractId">Id de contrato (weetrust):*</label>
            <input type="text" pInputText id="contractId" formControlName="contractId" placeholder="973cd2e1279517787c2b8c8c" required />
            <ng-container *ngIf="entity.get('contractId')?.invalid && (entity.get('contractId')?.dirty || entity.get('contractId')?.touched)">
              <small class="p-error" *ngIf="entity.get('contractId')?.errors?.['required']">El campo es obligatorio.</small>
              <small class="p-error" *ngIf="entity.get('contractId')?.errors?.['minlength']">El campo debe tener {{entity.get('contractId')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
            </ng-container>
          </div>
        </div>
        <div class="col-12 text-right">
          <ng-container *ngIf="authService.userHasAuthorization('LOAN','UPDATE');">
            <button pButton pRipple label="Cancelar" icon="fa-solid fa-times" class="p-button-outlined p-button-danger mr-3"  *ngIf="agreement" (click)="updateAgreement = false"></button>
            <button pButton pRipple [label]="agreement ? 'Actualizar contrato': 'Agregar contrato'" icon="pi pi-check" class="p-button-success" (click)="addContractToLoan()" [disabled]="entity.invalid || isUpdating"></button>
          </ng-container>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<p-toast></p-toast>
