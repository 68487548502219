import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-pushing-record-cancel',
  templateUrl: './pushing-record-cancel.component.html',
  styleUrls: ['./pushing-record-cancel.component.scss']
})
export class PushingRecordCancelComponent {
  @Input() entity!: FormGroup
  @Input() submitted!: boolean
}
