<ng-container *ngIf="data">
  <div class="grid">
    <ng-container *ngIf="urlIncodeSession !== '' && !updateValidation; else elseValidation">
      <div class="col-12">
        <div class="main-title">
          Verificación biometrica
        </div>
      </div>
      <div class="col-12 md:col-6">
        <h3 class="mb-2">Link a la verificación de identidad: <span><a [href]="urlIncodeSession" target="_blank"><i class="fa-solid fa-link"></i></a></span></h3>
      </div>
      <div class="col-4 md:col-6">
        <h3 class="mb-2">Estatus de validación: <span class="font-normal">{{incodeSessionStatus | selectEnum:$_loanOnboardingStatus}}</span></h3>
      </div>
      <div class="col-12 text-right mt-3">
        <button pButton pRipple label="Actualizar validación" icon="fa-solid fa-eye" class="p-button-success" (click)="updateValidation = true"></button>
      </div>
      <ng-container *ngIf="documents.fullFrameFrontID && documents.fullFrameFrontID !== ''">
        <div class="col-12">
          <div class="main-title">
            Documentos
          </div>
        </div>
        <div class="col-12">
          <ng-container  *ngFor="let i of (documents | keyvalue)">
            <img class="img-validation" [src]="'data:image/jpg;base64,'+i.value">
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #elseValidation>
      <div class="col-12">
        <form [formGroup]="entity">
          <div class="grid">
            <div class="col-12">
              <div class="main-title">
                Actualizar id de verificación
              </div>
            </div>
            <div class="col-12 md:col-6 xl:col-4">
              <div class="field field-flex">
                <label for="sessionId">Id de validación de identidad (incode):*</label>
                <input type="text" pInputText id="sessionId" formControlName="sessionId" placeholder="973cd2e1279517787c2b8c8c" required />
                <ng-container *ngIf="entity.get('sessionId')?.invalid && (entity.get('sessionId')?.dirty || entity.get('sessionId')?.touched)">
                  <small class="p-error" *ngIf="entity.get('sessionId')?.errors?.['required']">El campo es obligatorio.</small>
                  <small class="p-error" *ngIf="entity.get('sessionId')?.errors?.['minlength']">El campo debe tener {{entity.get('sessionId')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
                </ng-container>
              </div>
            </div>
            <ng-container *ngIf="authService.userHasAuthorization('LOAN','UPDATE');">
              <div class="col-12 text-right">
                <button pButton pRipple label="Cancelar" icon="fa-solid fa-times" class="p-button-outlined p-button-danger mr-3" (click)="updateValidation = false"></button>
                <button pButton pRipple label="Actualizar validación de identidad" icon="pi pi-check" class="p-button-success" (click)="addValidationToLoan()" [disabled]="entity.invalid || isUpdating"></button>
              </div>
            </ng-container>
          </div>
        </form>
      </div>
    </ng-template>
  </div>
</ng-container>
<p-toast></p-toast>
