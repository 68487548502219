<ng-container *ngIf="entity">
  <form [formGroup]="entity">
    <div class="grid">
      <div class="col-12">
        <div class="main-title">
          Datos generales
        </div>
      </div>
      <ng-container *ngIf="formAction === 'CREATE'">
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="rfc">RFC*</label>
            <input type="text" pInputText id="rfc" formControlName="rfc" placeholder="XXXX900909XX1" required />
            <ng-container *ngIf="entity.get('rfc')?.invalid && (entity.get('rfc')?.dirty || entity.get('rfc')?.touched)">
              <small class="p-error" *ngIf="entity.get('rfc')?.errors?.['required']">El RFC es obligatorio.</small>
              <small class="p-error" *ngIf="entity.get('rfc')?.errors?.['minlength']">El RFC debe tener al menos {{entity.get('rfc')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
              <small class="p-error" *ngIf="entity.get('rfc')?.errors?.['pattern']">El RFC no cumple con el formato de un RFC.</small>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="firstName">Nombre(s)*</label>
          <input type="text" pInputText id="firstName" formControlName="firstName" placeholder="John" required />
          <ng-container *ngIf="entity.get('firstName')?.invalid && (entity.get('firstName')?.dirty || entity.get('firstName')?.touched)">
            <small class="p-error" *ngIf="entity.get('firstName')?.errors?.['required']">El nombre es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('firstName')?.errors?.['minlength']">El nombre debe tener al menos {{entity.get('firstName')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="lastName">Apellidos*</label>
          <input type="text" pInputText id="lastName" formControlName="lastName" placeholder="Doe" required />
          <ng-container *ngIf="entity.get('lastName')?.invalid && (entity.get('lastName')?.dirty || entity.get('lastName')?.touched)">
            <small class="p-error" *ngIf="entity.get('lastName')?.errors?.['required']">Los apellidos son obligatorios.</small>
            <small class="p-error" *ngIf="entity.get('lastName')?.errors?.['minlength']">El apellidos deben tener al menos {{entity.get('lastName')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="curp">CURP*</label>
          <input type="text" pInputText id="curp" formControlName="curp" placeholder="XXXX900210HDFRRV01" required />
          <ng-container *ngIf="entity.get('curp')?.invalid && (entity.get('curp')?.dirty || entity.get('curp')?.touched)">
            <small class="p-error" *ngIf="entity.get('curp')?.errors?.['required']">El curp es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('curp')?.errors?.['minlength']">El curp debe tener al menos {{entity.get('curp')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
            <small class="p-error" *ngIf="entity.get('curp')?.errors?.['pattern']">El CURP no cumple con el formato válido XXXX900909HDFRRV01</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="main-title">
          Datos de contacto
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="emailAddress">Email*</label>
          <input type="text" pInputText id="emailAddress" formControlName="emailAddress" placeholder="correo@pulpi.mx" required />
          <ng-container *ngIf="entity.get('emailAddress')?.invalid && (entity.get('emailAddress')?.dirty || entity.get('emailAddress')?.touched)">
            <small class="p-error" *ngIf="entity.get('emailAddress')?.errors?.['required']">El email es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('emailAddress')?.errors?.['minlength']">El email debe tener al menos {{entity.get('emailAddress')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
            <small class="p-error" *ngIf="entity.get('emailAddress')?.errors?.['pattern']">El email no cumple con el formato válido de un email.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex mb-3">
          <label for="phoneNumber">Teléfono*</label>
          <p-inputMask mask="+999999999999" id="phoneNumber" formControlName="phoneNumber" placeholder="+52 55 3424 3312"></p-inputMask>
          <ng-container *ngIf="entity.get('phoneNumber')?.invalid && (entity.get('phoneNumber')?.dirty || entity.get('phoneNumber')?.touched)">
            <small class="p-error" *ngIf="entity.get('phoneNumber')?.errors?.['required']">El campo es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('phoneNumber')?.errors?.['pattern']">El teléfono no cumple con el formato +52 55 4323 4233.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="main-title">
          Datos del puesto
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="status">Estatus de registro*</label>
          <div class="flex justify-content-center flex-column">
            <p-dropdown formControlName="status" [panelStyle]="{'width': '100%' }" [style]="{'height': '45px'}" scrollHeight="150px" [options]="statuses" optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true" placeholder="Selecciona un estatus">
              <ng-template pTemplate="selectedItem">

                <div class="flex align-items-center" *ngIf="entity.value.status">
                  <div>{{getNameStatuses(entity.value.status)}}</div>
                </div>
              </ng-template>
              <ng-template let-status pTemplate="item">
                <div class="flex align-items-center">
                  <div>{{ status.label }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <ng-container *ngIf="entity.get('status')?.invalid && (entity.get('status')?.dirty || entity.get('status')?.touched)">
              <small class="p-error" *ngIf="entity.get('status')?.errors?.['required']">El estatus es obligatorio.</small>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-container *ngIf="formAction === 'UPDATE'">
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="statusVerification">Estatus de verificación*</label>
            <div class="flex justify-content-center flex-column">
              <p-dropdown formControlName="statusVerification" [panelStyle]="{'width': '100%' }" [style]="{'height': '45px'}" scrollHeight="150px" [options]="statusesVerification" optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true" placeholder="Selecciona un estatus">
                <ng-template pTemplate="selectedItem">

                  <div class="flex align-items-center" *ngIf="entity.value.statusVerification">
                    <div>{{getNameStatusesVerification(entity.value.statusVerification)}}</div>
                  </div>
                </ng-template>
                <ng-template let-status pTemplate="item">
                  <div class="flex align-items-center">
                    <div>{{ status.label }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
              <ng-container *ngIf="entity.get('statusVerification')?.invalid && (entity.get('statusVerification')?.dirty || entity.get('statusVerification')?.touched)">
                <small class="p-error" *ngIf="entity.get('statusVerification')?.errors?.['required']">El estatus es obligatorio.</small>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="payrollId">Nómina*</label>
          <div class="flex justify-content-center flex-column">
            <p-dropdown formControlName="payrollId" [panelStyle]="{'width': '100%' }" [style]="{'height': '45px'}" scrollHeight="150px" [options]="payrolls" optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true" placeholder="Selecciona una nómina">
              <ng-template pTemplate="selectedItem">

                <div class="flex align-items-center" *ngIf="entity.value.payrollId">
                  <div>{{getNamePayroll(entity.value.payrollId)}}</div>
                </div>
              </ng-template>
              <ng-template let-payroll pTemplate="item">
                <div class="flex align-items-center">
                  <div>{{ payroll.label }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <ng-container *ngIf="entity.get('payrollId')?.invalid && (entity.get('payrollId')?.dirty || entity.get('payrollId')?.touched)">
              <small class="p-error" *ngIf="entity.get('payrollId')?.errors?.['required']">La nómina es obligatorio.</small>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="position">Nombre de la posición*</label>
          <input type="text" pInputText id="position" formControlName="position" placeholder="Gerente" required />
          <ng-container *ngIf="entity.get('position')?.invalid && (entity.get('position')?.dirty || entity.get('position')?.touched)">
            <small class="p-error" *ngIf="entity.get('position')?.errors?.['required']">La posicion es obligatoria.</small>
            <small class="p-error" *ngIf="entity.get('position')?.errors?.['minlength']">El posición debe tener al menos {{entity.get('position')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="salary">Salario anualizado*</label>
          <input type="number" pInputText id="salary" formControlName="salary" placeholder="Gerente" required />
          <ng-container *ngIf="entity.get('salary')?.invalid && (entity.get('salary')?.dirty || entity.get('salary')?.touched)">
            <small class="p-error" *ngIf="entity.get('salary')?.errors?.['required']">El salario es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('salary')?.errors?.['minlength']">El salario debe tener al menos {{entity.get('salary')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="startDate">Fecha de ingreso*</label>
          <p-calendar formControlName="startDate" [showIcon]="true"></p-calendar>
          <ng-container *ngIf="entity.get('startDate')?.invalid && (entity.get('startDate')?.dirty || entity.get('startDate')?.touched)">
            <small class="p-error" *ngIf="entity.get('startDate')?.errors?.['required']">La fecha de ingreso es obligatoria.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="endDate">Fecha de egreso</label>
          <p-calendar formControlName="endDate" [showIcon]="true"></p-calendar>
          <ng-container *ngIf="entity.get('endDate')?.invalid && (entity.get('endDate')?.dirty || entity.get('endDate')?.touched)">
            <small class="p-error" *ngIf="entity.get('endDate')?.errors?.['required']">La fecha de egreso es obligatoria.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="main-title">
          Datos adicionales
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="civilStatus">Estado civil</label>
          <div class="flex justify-content-center flex-column">
            <p-dropdown formControlName="civilStatus" [panelStyle]="{'width': '100%' }" [style]="{'height': '45px'}" scrollHeight="150px" [options]="civilstatuses" optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true" placeholder="Selecciona un estado civil">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center" *ngIf="entity.value.civilStatus">
                  <div>{{getNameCivilStatus(entity.value.civilStatus)}}</div>
                </div>
              </ng-template>
              <ng-template let-civilStatus pTemplate="item">
                <div class="flex align-items-center">
                  <div>{{ civilStatus.label }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <ng-container *ngIf="entity.get('civilStatus')?.invalid && (entity.get('civilStatus')?.dirty || entity.get('civilStatus')?.touched)">
              <small class="p-error" *ngIf="entity.get('civilStatus')?.errors?.['required']">El estatus civil es obligatorio.</small>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="nationality">Nacionalidad</label>
          <div class="flex justify-content-center flex-column">
            <p-dropdown formControlName="nationality" [panelStyle]="{'width': '100%' }" [style]="{'height': '45px'}" scrollHeight="150px" [options]="nationalities" optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true" placeholder="Selecciona un nacionalidad">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center" *ngIf="entity.value.nationality">
                  <div>{{getNameNationality(entity.value.nationality)}}</div>
                </div>
              </ng-template>
              <ng-template let-nationality pTemplate="item">
                <div class="flex align-items-center">
                  <div>{{ nationality.label }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <ng-container *ngIf="entity.get('nationality')?.invalid && (entity.get('nationality')?.dirty || entity.get('nationality')?.touched)">
              <small class="p-error" *ngIf="entity.get('nationality')?.errors?.['required']">La nacionalidad es obligatorio.</small>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="birthDate">Fecha de nacimiento*</label>
          <p-calendar formControlName="birthDate" [showIcon]="true"></p-calendar>
          <ng-container *ngIf="entity.get('birthDate')?.invalid && (entity.get('birthDate')?.dirty || entity.get('birthDate')?.touched)">
            <small class="p-error" *ngIf="entity.get('birthDate')?.errors?.['required']">La fecha de nacimiento es obligatoria.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="sex">Genero*</label>
          <div class="flex justify-content-center flex-column">
            <p-dropdown formControlName="sex" [panelStyle]="{'width': '100%' }" [style]="{'height': '45px'}" scrollHeight="150px" [options]="genders" optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true" placeholder="Selecciona un genero">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center" *ngIf="entity.value.sex">
                  <div>{{getNameSex(entity.value.sex)}}</div>
                </div>
              </ng-template>
              <ng-template let-sex pTemplate="item">
                <div class="flex align-items-center">
                  <div>{{ sex.label }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <ng-container *ngIf="entity.get('sex')?.invalid && (entity.get('civilStatus')?.dirty || entity.get('sex')?.touched)">
              <small class="p-error" *ngIf="entity.get('sex')?.errors?.['required']">El genero es obligatorio.</small>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="main-title">
          Dirección
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="state">Estado</label>
          <div class="flex justify-content-center flex-column">
            <p-dropdown formControlName="state" [panelStyle]="{'width': '100%' }" [style]="{'height': '45px'}" scrollHeight="150px" [options]="states" optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true" placeholder="Selecciona un estado">
              <ng-template pTemplate="selectedItem">

                <div class="flex align-items-center" *ngIf="entity.value.state">
                  <div>{{getNameStates(entity.value.state)}}</div>
                </div>
              </ng-template>
              <ng-template let-state pTemplate="item">
                <div class="flex align-items-center">
                  <div>{{ state.label }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <ng-container *ngIf="entity.get('state')?.invalid && (entity.get('state')?.dirty || entity.get('state')?.touched)">
              <small class="p-error" *ngIf="entity.get('state')?.errors?.['required']">El estado es obligatorio.</small>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="city">Ciudad</label>
          <input type="text" pInputText id="city" formControlName="city" placeholder="México" />
          <ng-container *ngIf="entity.get('city')?.invalid && (entity.get('city')?.dirty || entity.get('city')?.touched)">
            <small class="p-error" *ngIf="entity.get('city')?.errors?.['required']">La ciudad es obligatoria.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="neighborhood">Delegacion</label>
          <input type="text" pInputText id="neighborhood" formControlName="neighborhood" placeholder="Montes urales" />
          <ng-container *ngIf="entity.get('neighborhood')?.invalid && (entity.get('neighborhood')?.dirty || entity.get('neighborhood')?.touched)">
            <small class="p-error" *ngIf="entity.get('neighborhood')?.errors?.['required']">La colonia es obligatoria.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="postalCode">Código Postal</label>
          <input type="text" pInputText id="postalCode" formControlName="postalCode" placeholder="11000" />
          <ng-container *ngIf="entity.get('postalCode')?.invalid && (entity.get('postalCode')?.dirty || entity.get('neighborhood')?.touched)">
            <small class="p-error" *ngIf="entity.get('postalCode')?.errors?.['required']">El codigo postal es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="streetNameAndNumber">Calle y número</label>
          <input type="text" pInputText id="streetNameAndNumber" formControlName="streetNameAndNumber" placeholder="Las palmas 23" />
          <ng-container *ngIf="entity.get('streetNameAndNumber')?.invalid && (entity.get('streetNameAndNumber')?.dirty || entity.get('neighborhood')?.touched)">
            <small class="p-error" *ngIf="entity.get('streetNameAndNumber')?.errors?.['required']">La calle y número es obligatoria.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-3">
        <div class="field flex flex-column">
          <label for="notifyUser">Notificar a usuario</label>
          <p-inputSwitch id="notifyUser" formControlName="notifyUser"></p-inputSwitch>
        </div>
      </div>
    </div>
  </form>
</ng-container>

