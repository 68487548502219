<p-card>
  <div class="grid">
    <div class="col-12 mb-3">
      <h1 class="mb-3">Reporteria</h1>
      <p class="text-color-secondary">
        En este apartado podras exportar diferentes reportes ya establecidos por el sistema, y podras acceder a un historico de reportes generados.
      </p>
      <p-toast></p-toast>
    </div>
    <div class="col-12 md:col-6">
      <div class="field field-flex">
        <label for="kindReport">Selecciona el reporte:</label>
        <p-dropdown [(ngModel)]="kindReport" [panelStyle]="{'width': '100%' }" [options]="reports" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
      </div>
    </div>
    <div class="col-12">
      <ng-container [ngSwitch]="kindReport">
        <ng-container *ngSwitchCase="'SOD_REPORT'">
          <app-sod-report (onGenerated)="tableReport.getReports()"></app-sod-report>
        </ng-container>
        <ng-container *ngSwitchCase="'CIB_REPORT'">
          <app-reports-pdl-to-cib (onGenerated)="tableReport.getReports()"></app-reports-pdl-to-cib>
        </ng-container>
        <ng-container *ngSwitchCase="'ATR_REPORT'">
          <app-atr-report (onGenerated)="tableReport.getReports()"></app-atr-report>
        </ng-container>
        <ng-container *ngSwitchCase="'INCIDENTS_SOD'">
          <app-sod-incidents-report (onGenerated)="tableReport.getReports()" ></app-sod-incidents-report>
        </ng-container>
        <ng-container *ngSwitchCase="'WORKERS_CAMPAIGNS'">
          <app-workers-report (onGenerated)="tableReport.getReports()" ></app-workers-report>
        </ng-container>
        <ng-container *ngSwitchCase="'ATR_PERCENT_REPORT'">
          <app-percent-report (onGenerated)="tableReport.getReports()" ></app-percent-report>
        </ng-container>
        <ng-container *ngSwitchCase="'CIRCLE_LOAN_REPORT'">
          <app-circle-report (onGenerated)="tableReport.getReports()" ></app-circle-report>
        </ng-container>
        <ng-container *ngSwitchCase="'ENFORCEABILITY_REPORT'">
          <app-enforceability-report (onGenerated)="tableReport.getReports()" ></app-enforceability-report>
        </ng-container>
        <ng-container *ngSwitchCase="'APPLICATION_HISTORY_REPORT'">
          <app-application-report (onGenerated)="tableReport.getReports()" ></app-application-report>
        </ng-container>
      </ng-container>
    </div>
    <div class="col-12">
      <div class="main-title">
        Historico de reportes
      </div>
    </div>
    <div class="col-12">
      <app-reports-table #tableReport></app-reports-table>
    </div>
  </div>
</p-card>
