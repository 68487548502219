<section id="htmlData">
<div class="grid">
  <ng-container *ngFor="let form of getFormDocuments">
    <ng-container *ngFor="let item of form">
      <ng-container [ngSwitch]="item.typeField">
        <ng-container *ngSwitchCase="'SEPARATOR'">
          <div class="col-12">
            <div class="main-title">
              {{item.title}}
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div class="col-12 md:col-6 xl:col-4">
            <h5 class="h5-resume">{{item.nameField}}:</h5>
            <p class="p-resume">
              <ng-container [ngSwitch]="item.typeField">
                <ng-container *ngSwitchCase="'INPUT_DATE'">
                  {{getFormValueForm(item.id) | date:'dd-MM-YYYY'}}
                </ng-container>
                <ng-container *ngSwitchCase="'SELECT'">
                   {{getCorrectValue(item.options,getFormValueForm(item.id))}}
                </ng-container>
                <ng-container *ngSwitchDefault>{{getFormValueForm(item.id)}}</ng-container>
              </ng-container>
            </p>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <div class="col-12">
    <div class="main-title">
      Documentos del usuario
    </div>
  </div>
  <div class="col-12 flex flex-column">
    <ng-container *ngFor="let doc of getDocuments">
      <div class="field-checkbox">
        <p-radioButton [id]="doc.documentId._id" name="category" [(ngModel)]="selectedDocument" [value]="doc.documentId.title" (click)="generateDocument(getDocumentValue(doc.documentId._id))" [disabled]="getDocumentValue(doc.documentId._id) === ''"></p-radioButton>
        <label [for]="doc.documentId._id" class="ml-2 {{getDocumentValue(doc.documentId._id) === '' ? 'radio-disabled': ''}}">{{ doc.documentId.title }} <small class="font-bold" *ngIf="getDocumentValue(doc.documentId._id) === ''">(No cargado)</small></label>
      </div>
    </ng-container>
  </div>
</div>
<div class="col-12">
    <div id="adobe-dc-view" class="{{selectedDocument && typeDocument === 'pdf' ? '' : 'display-none'}}"></div>
  <div class="img-container {{selectedDocument && typeDocument === 'image' ? '': 'display-none'}}">
    <img src="" class="image-selector" id="img-element">
  </div>
</div>
</section>
