import { Component } from '@angular/core';

@Component({
  selector: 'app-update-payer',
  templateUrl: './update-payer.component.html',
  styleUrls: ['./update-payer.component.scss']
})
export class UpdatePayerComponent {

}
