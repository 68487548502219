<ng-container *ngIf="withdrawals">
  <div class="grid">
    <div class="col-12">
      <p-table
        [scrollable]="true"
        scrollHeight="600px"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 20, 50]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} retiros"
        [value]="withdrawals" [tableStyle]="{ 'min-width': '100%' }">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="folio">Folio <p-sortIcon field="folio"></p-sortIcon></th>
            <th pSortableColumn="accountNumber">Cuenta CLABE <p-sortIcon field="accountNumber"></p-sortIcon></th>
            <th pSortableColumn="date">Fecha de retiro <p-sortIcon field="date"></p-sortIcon></th>
            <th pSortableColumn="installment.dueDate">Fecha de exigibilidad <p-sortIcon field="installment.dueDate "></p-sortIcon></th>
            <th pSortableColumn="amount">Monto <p-sortIcon field="amount"></p-sortIcon></th>
            <th pSortableColumn="fee">Comision <p-sortIcon field="fee"></p-sortIcon></th>
            <th pSortableColumn="collectedAmount">Monto cobrado <p-sortIcon field="collectedAmount"></p-sortIcon></th>
            <th>Estatus de pago</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-withdrawal>
          <tr>
            <td>{{ withdrawal.folio}}</td>
            <td>{{ withdrawal.accountNumber}}</td>
            <td>{{ withdrawal.date | date:'dd-MM-YYYY' }}</td>
            <td>{{ withdrawal.installment.dueDate | date: 'dd-MM-YYYY'}}</td>
            <td>{{ withdrawal.amount | currency }}</td>
            <td>{{ withdrawal.fee | currency}}</td>
            <td>{{ withdrawal.collectedAmount | currency}}</td>
            <td>
              <p-tag [value]="getStatusPayment(withdrawal)" [severity]="getSeverity(getStatusPayment(withdrawal))"></p-tag>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</ng-container>
