import {Component, Input} from '@angular/core';
import {ILoanPopulated} from "@modules/private/loan/data/interfaces/iloan.interface";
import {FileUploadEvent} from "primeng/fileupload";
import {AuthService} from "@data/services/modules/auth/auth.service";

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: 'app-loan-documents',
  templateUrl: './loan-documents.component.html',
  styleUrls: ['./loan-documents.component.scss']
})
export class LoanDocumentsComponent {
  @Input() data!: ILoanPopulated
  demo = []

  constructor(
    public authService: AuthService
  ) {
  }

  onUpload($e: any) {

  }
}
