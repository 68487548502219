import {ITableCol} from "@data/interfaces/itable.interface";
import {TYPE_PERIOD_ENUM, TYPE_PERIOD_ENUM_COLOR} from "@modules/private/company/data/enums/typePeriods.enum";
import {
  TYPE_PRODUCT_INSTALLMENTS_ENUM,
  TYPE_PRODUCT_INSTALLMENTS_ENUM_COLOR
} from "@modules/private/installment/data/enum/typeProducts.enum";

export const APPLICATION_COLUMNS: ITableCol[] = [
  {type: 'numeric', field: 'installment', header: 'Número de la letra', showFilter: true},
  {type: 'numeric', field: 'amountApplied', header: 'Monto Aplicado', showFilter: true, dataType: 'currency'},
  {type: 'numeric', field: 'installmentCapitalAmount', header: 'Capital de la letra', showFilter: true, dataType: 'currency'},
  {type: 'numeric', field: 'installmentInterestAmount', header: 'Intéres de la letra', showFilter: true, dataType: 'currency'},
  {type: 'numeric', field: 'installmentCollectedAmount', header: 'Monto cobrado de la letra', showFilter: true, dataType: 'currency'},
  {type: 'numeric', field: 'percentInstallmentPaid', header: 'Porcentaje pagado de la letra', showFilter: true},
  {type: 'numeric', field: 'percentApplicationAmountApplied', header: 'Porcentaje de la aplicación aplicado', showFilter: true},
  {type: 'date', field: 'applicationDate', header: 'Fecha de la aplicación', dataType: 'date', showFilter: true},
  {type: 'date', field: 'dueDate', header: 'Fecha de exigibilidad de la letra', dataType: 'date', showFilter: true},
  {type: 'date', field: 'requestProductDate', header: 'Fecha de solicitud del producto', dataType: 'date', showFilter: true},
  {type: 'date', field: 'requestProductDispersion', header: 'Fecha de dispersión del producto', dataType: 'date', showFilter: true},
  {type: 'text', field: 'accountNumber', header: 'Cuenta receptora', showFilter: true},
  {type: 'date', field: 'paymentDate', header: 'Fecha de pago', dataType: 'date', showFilter: true},
  {type: 'text', field: 'trackingId', header: 'Clave de rastreo', showFilter: true},
  {type: 'text', field: 'status', header: 'Estatus de aplicación', showFilter: true},
  {type: 'enum', field: 'typeProduct', header: 'Tipo de producto', showFilter: true, dataType: 'enum', enum: TYPE_PRODUCT_INSTALLMENTS_ENUM, enumColor: TYPE_PRODUCT_INSTALLMENTS_ENUM_COLOR, options: [
      {value: 'WITHDRAWAL', label: 'Retiro'},
      {value: 'LOAN', label: 'Crédito'},
    ]},
  {type: 'text', field: 'applier', header: 'Nombre del aplicador', showFilter: true},
  {type: 'text', field: 'emailApplier', header: 'Correo del aplicador', showFilter: true},
  {type: 'text', field: 'firstName', header: 'Nombre(s)', showFilter: true},
  {type: 'text', field: 'lastName', header: 'Apellidos', showFilter: true},
  {type: 'text', field: 'owner', header: 'Nombre completo', showFilter: true},
  {type: 'text', field: 'emailOwner', header: 'Correo del usuario', showFilter: true},
  {type: 'text', field: 'phoneOwner', header: 'Teléfono del usuario', showFilter: true},
  {type: 'text', field: 'company', header: 'Corporativo', showFilter: true},
  {type: 'text', field: 'contract', header: 'Razón social', showFilter: true},
  {type: 'text', field: 'remunerator', header: 'Pagadora', showFilter: true},
  {type: 'text', field: 'payroll', header: 'Nómina', showFilter: true},
  {type: 'enum', field: 'period', header: 'Periodo',showFilter: true, dataType: 'enum', enum: TYPE_PERIOD_ENUM, enumColor: TYPE_PERIOD_ENUM_COLOR, options: [
      {value: 'WEEKLY_PERIOD', label: 'Semanal'},
      {value: 'SEMIMONTHLY_PERIOD', label: 'Quincenal'},
      {value: 'BIWEEKLY_PERIOD', label: 'Catorcenal'},
      {value: 'MONTHLY_PERIOD', label: 'Mensual'},
    ]},
]
