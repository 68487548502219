import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {IResponseArray} from "@data/interfaces/iresponseArray.interface";
import {environment} from "@env/environment";
import {catchError, map, of} from "rxjs";
import {
  IApiWithdrawal,
  IApiWithdrawalList,
  IApiWithdrawalWithInstallment
} from "@modules/private/withdrawal/data/interfaces/iwithdrawal.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiWithdrawalService extends ApiClass {
  constructor() {
    super();
    this.path = 'withdrawal'
  }

  getAll(page = 1, limit = 10, filters = {}) {
    const response: IApiWithdrawalList = {error: true, msg: '', data: [], totalItems: 0, status: 200, page: 1, itemsByPage: 10}
    return this.http.post<IResponseArray>(environment.apiUrl + this.path + '/all', {page, limit, filters}).pipe(
      map((r) => r as IApiWithdrawalList),
      catchError((e) => of(response))
    )
  }

  getByUserId(id: string) {
    return this.getCollection(id, environment.apiUrl, 'withdrawal/user').pipe(map((r) => r as {error: boolean; msg: string; data: IApiWithdrawalWithInstallment[]}))
  }

  cancel(id: string) {
    return this.patchCollection({status: 'FAILED', id}, environment.apiUrl, 'withdrawal/status').pipe(map((r) => r as { error: boolean, msg: string; data: IApiWithdrawal } ))
  }
}
