<ng-container *ngIf="entity && allOptionsAvailable">
  <form [formGroup]="entity">
    <ng-container *ngIf="type === 'CREATE'">
      <div class="field">
        <label for="payerId">Pagadora</label>
        <div class="flex justify-content-center flex-column">
          <p-dropdown formControlName="payerId" [disabled]="type !== 'CREATE'" [panelStyle]="{'width': '80%' }" scrollHeight="150px" [options]="payers" optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true" placeholder="Selecciona una pagadora">
            <ng-template pTemplate="selectedItem">

              <div class="flex align-items-center" *ngIf="entity.value.payerId">
                <div>{{getName(entity.value.payerId)}}</div>
              </div>
            </ng-template>
            <ng-template let-payer pTemplate="item">
              <div class="flex align-items-center">
                <div>{{ payer.label }}</div>
              </div>
            </ng-template>
          </p-dropdown>
          <ng-container *ngIf="entity.get('payerId')?.invalid && (entity.get('payerId')?.dirty || entity.get('payerId')?.touched)">
            <small class="p-error" *ngIf="entity.get('payerId')?.errors?.['required']">La pagadora es obligatoria.</small>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div class="field">
      <label for="name">Nombre</label>
      <input type="text" pInputText id="name" formControlName="name" placeholder="Convenio ABC" required autofocus />
      <ng-container *ngIf="entity.get('name')?.invalid && (entity.get('name')?.dirty || entity.get('name')?.touched)">
        <small class="p-error" *ngIf="entity.get('name')?.errors?.['required']">El nombre es obligatorio.</small>
        <small class="p-error" *ngIf="entity.get('name')?.errors?.['minlength']">El nombre debe tener al menos {{entity.get('name')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
      </ng-container>
    </div>
    <div class="field">
      <label for="rfc">RFC</label>
      <input type="text" pInputText id="rfc" formControlName="rfc" placeholder="XXXX000000XX0" style="text-transform: uppercase" required autofocus />
      <ng-container *ngIf="entity.get('rfc')?.invalid && (entity.get('rfc')?.dirty || entity.get('rfc')?.touched)">
        <small class="p-error" *ngIf="entity.get('rfc')?.errors?.['required']">El RFC es obligatorio.</small>
        <small class="p-error" *ngIf="entity.get('rfc')?.errors?.['pattern']">El RFC no cumple con el formato de un RFC.</small>
      </ng-container>
    </div>
  </form>
</ng-container>
