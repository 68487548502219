import {Component, Input, OnInit} from '@angular/core';
import {OfferService} from "@modules/private/pushing/data/services/offer.service";
import {IOffer} from "@modules/private/pushing/data/interfaces/ioffer.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "@data/services/page/loader.service";

@Component({
  selector: 'app-pushing-provider-calculator',
  templateUrl: './pushing-provider-calculator.component.html',
  styleUrls: ['./pushing-provider-calculator.component.scss']
})
export class PushingProviderCalculatorComponent implements OnInit{
  @Input() workerId! : string
  data!: IOffer | null
  showTotals = false
  checkBestOffers = true
  @Input() calculateOffer = false
  errorMsg = ''
  entity!: FormGroup
  @Input() typeForm = 'BASE_FORM'
  desireProducts = [
    {label: 'Selecciona un periodo', value: ''},
    {label: 'Nomipay CDMX', value: 'MAS_NOMINA_LOAN'},
    {label: 'Nomipay GEM', value: 'MAS_NOMINA_GEM_LOAN'},
  ]
  constructor(
    private offerService: OfferService,
    private fb: FormBuilder,
    private loaderService: LoaderService
  ) {
  }

  ngOnInit() {
    this.constructForm()
    console.log(this.typeForm)
    if (this.typeForm === 'BASE_FORM') this.getOffers({workerId: this.workerId})
  }


  constructForm(amount = 0, minValue = 5000, maxValue = 5000, desireProduct: string = '') {
    if (this.typeForm === 'RFC_FORM') {
      if (this.data) {
        this.entity = this.fb.group({
          chosenAmount: [amount, [Validators.required, Validators.min(minValue), Validators.max(maxValue)]],
          rfc: [this.entity.value.rfc ?? ''],
          desireProduct: [this.entity.value.desireProduct],
          typeSearch: ['rfc']
        })
      } else {
        this.entity = this.fb.group({
          rfc: [''],
          desireProduct: [desireProduct],
          typeSearch: ['rfc']
        })
      }
    } else {
      this.entity = this.fb.group({
        chosenAmount: [amount, [Validators.required, Validators.min(minValue), Validators.max(maxValue)]],
        desireProduct: [desireProduct,  [Validators.required]],
        typeSearch: ['workerId']
      })
    }
  }
  getOffers(data: any) {
    this.errorMsg = ''
    this.offerService.getBestOffers(data).subscribe(r => {
      if (r.error) {
        this.errorMsg = r.msg
        this.data = null
      } else {
        this.data = r.data
        this.data.offers.sort((a, b) => a.amount - b.amount)
        this.constructForm(this.entity.value.chosenAmount, r.data.minCapacity, r.data.maxCapacity, this.entity.value.desireProduct)
      }
      this.loaderService.hideSpinner()
    })
  }

  restoreOffers() {
    this.loaderService.showSpinner()
    if (this.typeForm === 'BASE_FORM') this.getOffers({workerId: this.workerId, typeSearch: this.entity.value.typeSearch, desireProduct: this.entity.value.desireProduct})
    else this.getOffers({rfc: this.entity.value.rfc, typeSearch: this.entity.value.typeSearch, desireProduct: this.entity.value.desireProduct})
  }

  getSelectedOffer() {
    this.loaderService.showSpinner()
    if (this.typeForm === 'BASE_FORM') this.getOffers({...this.entity.value, workerId: this.workerId, desireProduct: this.entity.value.desireProduct})
    else this.getOffers(this.entity.value)
  }
}
