import { Component } from '@angular/core';
import {AuthService} from "@data/services/modules/auth/auth.service";

@Component({
  selector: 'app-home-dashboard',
  templateUrl: './home-dashboard.component.html',
  styleUrls: ['./home-dashboard.component.scss']
})
export class HomeDashboardComponent {

  constructor(
    public authService: AuthService
  ) {
  }
}
