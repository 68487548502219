import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiPayrollService} from "@modules/private/payroll/data/services/api/api-payroll.service";
import {ADDRESS_STATES} from "@data/constants/address-states.const";

@Component({
  selector: 'app-worker-update',
  templateUrl: './worker-update.component.html',
  styleUrls: ['./worker-update.component.scss']
})
export class WorkerUpdateComponent implements OnInit{
  @Input() entity!: FormGroup
  @Input() formAction = 'CREATE'
  payrolls: {label: string; value: string;}[] = []
  genders: {label: string; value: string;}[] = [
    { label: "Selecciona una opción", value: "" },
    { label: "Femenino", value: "FEMALE_SEX" },
    { label: "Masculino", value: "MALE_SEX" },
  ]
  statuses: {label: string; value: string;}[] = [
    { label: "Selecciona una opción", value: "" },
    { label: "Activo", value: "ACTIVE" },
    { label: "Desactivado", value: "DEACTIVATED" },
    { label: "Pendiente de activación", value: "PENDING_ACTIVATION" },
  ]
  statusesVerification :{label: string; value: string;}[] = [
  { label: "Selecciona una opción", value: "" },
  { label: "Pendiente de verificación", value: "PENDING_VERIFICATION" },
  { label: "No requiere verificación", value: "NO_REQUIRED_VERIFICATION" },
  { label: "Completado", value: "COMPLETED" },
]
  civilstatuses: {label: string; value: string;}[] = [
    { label: "Selecciona una opción", value: "" },
    { label: "Soltero(a)", value: "SINGLE" },
    { label: "Casado(a)", value: "MARRIED" },
    { label: "Divorciado(a)", value: "DIVORCED" },
    { label: "Viudo(a)", value: "WIDOWER" },
    { label: "Otro", value: "OTHER" }
  ]
  nationalities: {label: string; value: string;}[] = [
    { label: "Selecciona una opción", value: "" },
    { label: "Mexicana", value: "MEXICAN" },
    { label: "Extranjera", value: "FOREIGN" },
  ]
  states: {label: string; value: string;}[] = ADDRESS_STATES

  constructor(
    private payrollService: ApiPayrollService
  ) {
  }
  ngOnInit() {
    this.getPayrolls()
  }

  getNamePayroll(id: string) {
    return this.payrolls.find(p => p.value === id)?.label
  }

  getNameCivilStatus(id: string) {
    return this.civilstatuses.find(p => p.value === id)?.label
  }

  getNameNationality(id: string) {
    return this.nationalities.find(p => p.value === id)?.label
  }

  getNameStates(id: string) {
    return this.states.find(p => p.value === id)?.label
  }

  getNameStatuses(id: string) {
    return this.statuses.find(p => p.value === id)?.label
  }

  getNameStatusesVerification(id: string) {
    return this.statusesVerification.find(p => p.value === id)?.label
  }

  getNameSex(id: string) {
    return this.genders.find(p => p.value === id)?.label
  }

  getPayrolls() {
    this.payrollService.getNames().subscribe(r => {
      if(!r.error) {
        this.payrolls = [
          {label: 'Selecciona una opción', value: ''},
          ...r.data.map(p => {
            return {
              label: p.name,
              value: p._id
            }
          })
        ]
      }
    })
  }
}
