import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {WriteOffService} from "@modules/private/write-off/data/services/write-off.service";
import {ILoanPopulated} from "@modules/private/loan/data/interfaces/iloan.interface";
import {MessageService} from "primeng/api";
import {WRITE_OFF_STATUS_ENUM} from "@modules/private/write-off/data/enums/writeOff.enum";



@Component({
  selector: 'app-create-write-off',
  templateUrl: './create-write-off.component.html',
  styleUrls: ['./create-write-off.component.scss']
})
export class CreateWriteOffComponent implements OnInit {
  @Input() entity!: FormGroup
  @Input() writeOffData!: {loanId: string, loanFolio: string, writeOffTypes: string[], installments: any }
  @Input() isPayrollAction: boolean = false
  @Input() loan!: ILoanPopulated
  @Output() confirmation = new EventEmitter<{writeOffs:any[], payrolls:any[]}>()
  writeOffTypes: {esp:any, eng:any} = {esp: {}, eng: {}}
  writeOffToApply: {writeOffs:any[], payrolls:any[]} = {writeOffs:[], payrolls:[]}
  writeOffCalculationToShow: {writeOffs:any[]} = {writeOffs:[]}
  installmentPayroll: any[] =[]
  clonedInstallmentPayroll: { [s: string]: any } = {}

  constructor(
    public writeOffService: WriteOffService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.writeOffTypes.esp =this.writeOffService.getTypes('ESP')
    this.writeOffTypes.eng =this.writeOffService.getTypes('ENG')
    this.prePayrollDetails()
    this.confirmation.emit(this.writeOffToApply)
  }

  writeOffCalculation(entity: FormGroup) {
    this.writeOffToApply.writeOffs = []
    this.writeOffCalculationToShow.writeOffs = []
    const reason = entity.value.reason
    delete entity.value.reason
    const availableAmounts:{[key:string]:any} = Object.fromEntries(Object.entries(entity.value).filter(val => val[1]!=0))
    const installments = this.writeOffData.installments
      .filter((installment: any) => (installment.paidAmount + 0.1) <= (installment.capital + installment.revenue))
      .sort((a: any, b: any) => {
        return b.date.localeCompare(a.date)
      })
    if (installments.length == 0){
      this.messageService.add({
        severity: 'success',
        summary: 'Letras Pagadas',
        detail: 'No hay letras por condonar',
        life: 5000
      })
      return
    }
    for (const installment of installments ) {
      if (Object.entries(availableAmounts).filter(val => val[1]!=0).length != 0) {
        const writeOffSimulation = this.writeOffSimulation(installment, availableAmounts)
        const preWriteOffToApply = writeOffSimulation.preRelief.filter((concept: {}) => Object.keys(concept).length !== 0)
        if (preWriteOffToApply.length !== 0) {
          const result = this.writeOffFormatted(preWriteOffToApply,installment)
          result.reason=reason
          this.writeOffToApply.writeOffs.push(result)
          this.writeOffCalculationToShow.writeOffs.push(result)
        }
      }
    }
    this.writeOffCalculationToShow.writeOffs.forEach(writeOff => {
      const result = this.addDebtReliefNotApplied(writeOff.debtRelief)
      writeOff.debtRelief.push(...result)
    })
    this.writeOffService.order(this.writeOffCalculationToShow.writeOffs)
    if ( Object.values(availableAmounts).every(amount => amount === 0 || amount <= 0.004) ) {
      if (!this.isPayrollAction) {
        this.confirmation.emit(this.writeOffToApply)
      } else {
        if (this.writeOffToApply.payrolls.length>=1)  this.confirmation.emit(this.writeOffToApply)
      }
    } else {
      let response=""
      Object.entries(availableAmounts).forEach(([key,value]) => {
        if (value!==0 && response === "") {
          response = this.writeOffService.traduceType(key, "ESP");
        }else if (value!==0 && response !== "") {
          response += ', ' + this.writeOffService.traduceType(key, "ESP")
        }
      })
      this.writeOffToApply.writeOffs = []
      this.confirmation.emit(this.writeOffToApply)
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `El monto a condonar no puede ser mayor a los montos totales de ${response}.`,
        life: 5000
      })
    }
  }
  private addDebtReliefNotApplied(resultToShow: any): any {
    const debtsTypes = this.writeOffService.getTypes('ENG')
    const resultAdded: { concept: string; availableAmounts: string; write: string; debtRelief: string; }[] = []
    const currentConcept:string[]=[]
    resultToShow.forEach((debt: any) => {
      const cleanConcept= debt.concept.slice(0,debt.concept.indexOf("Amount"))
      currentConcept.push(cleanConcept)
    })
    const conceptMissing = debtsTypes.filter(debt=> !currentConcept.includes(debt))
    conceptMissing.forEach(concept => {
      const writeOffNotApplied = {
        concept: concept,
        availableAmounts: 'N/A',
        write: 'N/A',
        debtRelief: 'N/A',
      }
      resultAdded.push(writeOffNotApplied)
    })
    return resultAdded;
  }

  private writeOffSimulation(installment: {[key: string]: any}, availableAmounts:{[key: string]: any}) : {preRelief: any} {
    const result:{preRelief: any} = {preRelief:{}}
    result.preRelief = Object.entries(availableAmounts).map(([concept,availableAmount]) => {
      const cleanConcept= concept.startsWith('accessory') ? concept : concept.slice(0,concept.indexOf("Amount"))
      if (installment[`${cleanConcept}`] >= 0.005 && availableAmounts[concept] >= 0.005) {
        let write = installment[`${cleanConcept}`] - availableAmount
        write = write <= 0.004 ? 0 : write
        let letterRelief = (installment[`${cleanConcept}`] - write) <= 0.004 ? 0 : installment[`${cleanConcept}`] - write
        availableAmounts[concept] -= Math.min(installment[cleanConcept], availableAmount)
        availableAmounts[concept] = availableAmount[concept] <= 0.004 ? 0 : availableAmounts[concept]
        return {
          concept:concept,
          originAmount: installment[cleanConcept],
          write: write,
          debtRelief: letterRelief
        }
      } else {
        return {}
      }
    })
    return result
  }

  writeOffFormatted(preRelief:any, installment:any) {
    return {
      index: installment.index,
      installmentId: installment.id,
      debtRelief:preRelief,
      reason: "",
      productType: 'LOAN',
      loanId: this.writeOffData.loanId,
      loanFolio: this.writeOffData.loanFolio,
      dueDate: installment.date
    }
  }

  prePayrollDetails() {
    const payrollDetail = {
      folio: this.loan.folio,
      date: new Date().toISOString(),
      skipOverpaid: false,
      skipPaymentApplied: false
    }
    this.installmentPayroll.push(payrollDetail)
  }

  onRowEditInit(installment: any) {
    this.clonedInstallmentPayroll[installment._id] = { ...installment }
  }

  onRowEditSave(installment: any) {
    this.installmentPayroll.forEach(payroll => {
      if (payroll.id === installment.id) {
        delete this.clonedInstallmentPayroll[installment.id]
        payroll.clabe = installment.clabe
        payroll.trackingId = installment.trackingId
        payroll.skipOverpaid = installment.skipOverpaid
        payroll.skipPaymentApplied = installment.skipPaymentApplied
        payroll.date = this.transformDate(installment.date)
      }
    })
    this.writeOffToApply.payrolls= this.installmentPayroll
    this.confirmation.emit(this.writeOffToApply)
  }

  onRowEditCancel(installment: any, ri: any) {
    this.installmentPayroll[ri] = this.clonedInstallmentPayroll[installment._id]
    delete this.clonedInstallmentPayroll[installment.id]
  }

  private transformDate(payrollDate: any){
    const date = new Date(payrollDate)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`
  }

  validateTrackingInput(event: any): void {
    const input = event.target.value;
    const regex = /^[a-zA-Z0-9]*$/;
    if (!regex.test(input)) {
      event.target.value = input.replace(/[^a-zA-Z0-9]/g, '');
    }
  }
}

