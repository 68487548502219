<ng-container *ngIf="data">
  <ng-container *ngFor="let document of data.pushing.flowId.documents">
    <ng-container [ngSwitch]="document.documentId.type">
      <p-fieldset [legend]="document.documentId.title" [toggleable]="true" [collapsed]="true" [style]="{margin: '0 0 16px 0'}">
        <ng-template pTemplate="header">
          <div class="flex align-items-center gap-2 px-2">
            <p-tag [value]=" ((['MANUAL', 'EXTERNAL'].includes(document.documentId.type) && getStageStatus(document.documentId._id) !== 'VALIDATED') ? 'NEED_AGENT_INTERACTION' : getStageStatus(document.documentId._id)) | selectEnum: $_statusPushingEnum" [severity]="((['MANUAL', 'EXTERNAL'].includes(document.documentId.type) && getStageStatus(document.documentId._id) !== 'VALIDATED') ? 'NEED_AGENT_INTERACTION' : getStageStatus(document.documentId._id)) | selectEnum: $_statusPushingColorEnum"></p-tag>
          </div>
        </ng-template>
        <ng-container *ngSwitchCase="'OFFER'">
          <app-pushing-record-offer #offer [data]="document.documentId" [values]="getValue(document.documentId._id)"></app-pushing-record-offer>
          <div class="grid">
            <div class="col-12 flex justify-content-end mt-3">
              <button  pButton pRipple label="Actualizar"  class="p-button-success mr-2" (click)="confirmUpdate($event, getStageId(document.documentId._id), offer.getEntity())" [disabled]="offer.entity.invalid || offer.isLoading"></button>
              <ng-container *ngIf="getStageId(document.documentId._id) !== '' && ['VALIDATED', 'MANUAL_VALIDATION', 'IN_PROGRESS'].includes(getStageStatus(document.documentId._id)) ">
                <button  pButton pRipple label="Aprobar"  class="p-button-primary mr-2" (click)="confirmApprobation($event, getStageId(document.documentId._id), document.documentId._id)"></button>
                <button  pButton pRipple label="Rechazar" class="p-button-danger" (click)="reject(getStageId(document.documentId._id))"></button>
              </ng-container>
            </div>
          </div>
        </ng-container>
       <ng-container *ngSwitchCase="'FORM'">
          <app-pushing-record-form #form [data]="document.documentId" [values]="getValue(document.documentId._id)"></app-pushing-record-form>
           <div class="grid">
             <div class="col-12 flex justify-content-end mt-3">
               <button  pButton pRipple label="Actualizar"  class="p-button-success mr-2" (click)="confirmUpdate($event, getStageId(document.documentId._id), form.getEntity())" [disabled]="form.entity.invalid || form.isLoading"></button>
               <ng-container *ngIf="getStageId(document.documentId._id) !== '' && ['VALIDATED', 'MANUAL_VALIDATION', 'IN_PROGRESS'].includes(getStageStatus(document.documentId._id)) ">
                 <button  pButton pRipple label="Aprobar"  class="p-button-primary mr-2" (click)="confirmApprobation($event, getStageId(document.documentId._id), document.documentId._id)"></button>
                <button  pButton pRipple label="Rechazar" class="p-button-danger" (click)="reject(getStageId(document.documentId._id))"></button>
               </ng-container>
             </div>
           </div>
       </ng-container>
        <ng-container *ngSwitchCase="'DOCUMENT'">
          <app-pushing-record-document #documentUpload [data]="document.documentId" [values]="getValue(document.documentId._id)"></app-pushing-record-document>
          <div class="grid">
            <div class="col-12 flex justify-content-end mt-3">
              <button  pButton pRipple label="Actualizar"  class="p-button-success mr-2" (click)="confirmUpdate($event, getStageId(document.documentId._id), documentUpload.getEntity())" [disabled]="!documentUpload.hasFile"></button>
              <button  pButton pRipple label="Aprobar"  class="p-button-primary mr-2" (click)="confirmApprobation($event, getStageId(document.documentId._id), document.documentId._id)"></button>
              <ng-container *ngIf="getStageId(document.documentId._id) !== '' &&  ['VALIDATED', 'MANUAL_VALIDATION', 'IN_PROGRESS'].includes(getStageStatus(document.documentId._id))">
                <button  pButton pRipple label="Rechazar" class="p-button-danger" (click)="reject(getStageId(document.documentId._id))"></button>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'INCODE'">
          <app-pushing-record-idetity #identity [data]="document.documentId" [values]="getValue(document.documentId._id)"></app-pushing-record-idetity>
          <div class="grid">
            <div class="col-12 flex justify-content-end mt-3">
              <button  pButton pRipple label="Actualizar"  class="p-button-success mr-2" (click)="confirmUpdate($event, getStageId(document.documentId._id), identity.getEntity())" [disabled]="identity.entity.invalid"></button>
              <button  pButton pRipple label="Aprobar"  class="p-button-primary mr-2" (click)="confirmApprobation($event, getStageId(document.documentId._id), document.documentId._id)"></button>
              <ng-container *ngIf="getStageId(document.documentId._id) !== '' &&  ['VALIDATED', 'MANUAL_VALIDATION', 'IN_PROGRESS'].includes(getStageStatus(document.documentId._id))">
                <button  pButton pRipple label="Rechazar" class="p-button-danger" (click)="reject(getStageId(document.documentId._id))"></button>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'WEETRUST'">
          <app-pushing-record-sign-documents #signDocument [data]="document.documentId" [values]="getValue(document.documentId._id)"></app-pushing-record-sign-documents>
          <div class="grid">
            <div class="col-12 flex justify-content-end mt-3">
              <button  pButton pRipple label="Actualizar"  class="p-button-success mr-2" (click)="confirmUpdate($event, getStageId(document.documentId._id), signDocument.getEntity())" [disabled]="signDocument.entity.invalid"></button>
              <button  pButton pRipple label="Aprobar"  class="p-button-primary mr-2" (click)="confirmApprobation($event, getStageId(document.documentId._id), document.documentId._id)"></button>
              <ng-container *ngIf="getStageId(document.documentId._id) !== '' &&  ['VALIDATED', 'MANUAL_VALIDATION', 'IN_PROGRESS'].includes(getStageStatus(document.documentId._id))">
                <button  pButton pRipple label="Rechazar" class="p-button-danger" (click)="reject(getStageId(document.documentId._id))"></button>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'EXTERNAL'">
          <app-pushing-record-external-url #externalUrl [data]="document.documentId" [values]="getValue(document.documentId._id)"></app-pushing-record-external-url>
          <div class="grid">
            <div class="col-12 flex justify-content-end mt-3">
              <button  pButton pRipple label="Actualizar"  class="p-button-success mr-2" (click)="confirmUpdate($event, getStageId(document.documentId._id), externalUrl.getEntity())" [disabled]="externalUrl.entity.invalid"></button>
              <button  pButton pRipple label="Aprobar"  class="p-button-primary mr-2" (click)="confirmApprobation($event, getStageId(document.documentId._id), document.documentId._id)"></button>
              <ng-container *ngIf="getStageId(document.documentId._id) !== '' &&  ['VALIDATED', 'MANUAL_VALIDATION', 'IN_PROGRESS'].includes(getStageStatus(document.documentId._id))">
                <button  pButton pRipple label="Rechazar" class="p-button-danger" (click)="reject(getStageId(document.documentId._id))"></button>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'MANUAL'">
          <app-pushing-record-manual-validation #manualValidation [data]="document.documentId" [values]="getValue(document.documentId._id)"></app-pushing-record-manual-validation>
          <div class="grid">
            <div class="col-12 flex justify-content-end mt-3">
              <button  pButton pRipple label="Aprobar"  class="p-button-primary mr-2" (click)="confirmApprobation($event, getStageId(document.documentId._id), document.documentId._id)"></button>
              <ng-container *ngIf="getStageId(document.documentId._id) !== '' &&  ['VALIDATED', 'MANUAL_VALIDATION', 'IN_PROGRESS'].includes(getStageStatus(document.documentId._id))">
                <button  pButton pRipple label="Rechazar" class="p-button-danger" (click)="reject(getStageId(document.documentId._id))"></button>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </p-fieldset>
    </ng-container>
  </ng-container>
</ng-container>
<p-dialog [(visible)]="createEntity" [style]="{ width: '450px' }" [header]="'Rechazar'" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <app-pushing-record-cancel [entity]="entity" [submitted]="submitted"></app-pushing-record-cancel>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="continueWithReject()" [disabled]="!entity.valid"></button>
  </ng-template>
</p-dialog>
<p-confirmPopup></p-confirmPopup>
<p-toast></p-toast>
