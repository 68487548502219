<ng-container *ngIf="entity">
  <form [formGroup]="entity">
    <div class="field field-flex mb-5">
      <label for="workerId">Selecciona un usuario:*</label>
      <div class="flex justify-content-center flex-column">
        <p-dropdown formControlName="workerId" [panelStyle]="{'width': '100%' }" scrollHeight="150px" [options]="workers" optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true" placeholder="Selecciona un usuario">
          <ng-template pTemplate="selectedItem">

            <div class="flex align-items-center" *ngIf="entity.value.workerId">
              <div>{{getUserName(entity.value.workerId)}}</div>
            </div>
          </ng-template>
          <ng-template let-user pTemplate="item">
            <div class="flex align-items-center">
              <div>{{ user.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
        <ng-container *ngIf="entity.get('workerId')?.invalid && (entity.get('workerId')?.dirty || entity.get('workerId')?.touched)">
          <small class="p-error" *ngIf="entity.get('workerId')?.errors?.['required']">El usuario es obligatorio.</small>
        </ng-container>
      </div>
    </div>
    <div class="field field-flex">
      <label for="flowId">Selecciona un flujo:*</label>
      <p-dropdown formControlName="flowId" [panelStyle]="{'width': '100%' }" [options]="flows" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
      <ng-container *ngIf="entity.get('flowId')?.invalid && (entity.get('flowId')?.dirty || entity.get('flowId')?.touched)">
        <small class="p-error" *ngIf="entity.get('flowId')?.errors?.['required']">Debes seleccionar una opción.</small>
      </ng-container>
    </div>
    <div class="mb-8">
      <small>Este proceso se permite generar un flujo a un usuario, esto le permitira subir su documentacion, es importante mencionar que ningún usuario puede tener mas de un proceso en curso.</small>
    </div>
  </form>
</ng-container>
