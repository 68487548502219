<div class="grid">
  <ng-container *ngIf="data || typeForm === 'RFC_FORM';">
    <div class="col-12">
      <small>En este apartado se muestran las mejores ofertas que se le pueden ofrecer a el usuario, tomarse en cuenta que en cuanto el monto se acerque al monto mayor permitido, los periodos serán mayores y también los intereses.</small>
    </div>
    <ng-container *ngIf="data">
      <div class="col-12">
        <small>El usuario puede acceder a un crédito de <strong>{{data.minCapacity | currency}}</strong> a <strong>{{data.maxCapacity | currency}}</strong></small>
      </div>
    </ng-container>
    <div class="col-12">
      <div class="flex">
        <div class="field field-flex mr-5">
          <label for="">Mostrar totales:</label>
          <p-inputSwitch [(ngModel)]="showTotals"></p-inputSwitch>
        </div>
        <div class="field field-flex mr-5">
          <label for="">Mostrar mejores ofertas:</label>
          <p-inputSwitch [(ngModel)]="checkBestOffers"></p-inputSwitch>
        </div>
        <div class="field field-flex">
          <label for="">Calcular oferta:</label>
          <p-inputSwitch [(ngModel)]="calculateOffer"></p-inputSwitch>
        </div>
      </div>
    </div>
    <ng-container *ngIf="calculateOffer">
      <div class="col-12 calculate-offer">
        <form [formGroup]="entity">
          <div class="grid">
            <ng-container *ngIf="data">
              <div class="col-12">
                <div class="field field-flex">
                  <label for="chosenAmount">Monto de la oferta</label>
                  <p-inputNumber formControlName="chosenAmount" inputId="chosenAmount" mode="currency" currency="MXN" locale="es-MX"> </p-inputNumber>
                  <ng-container *ngIf="entity.get('chosenAmount')?.invalid && (entity.get('chosenAmount')?.dirty || entity.get('chosenAmount')?.touched)">
                    <small class="p-error" *ngIf="entity.get('chosenAmount')?.errors?.['required']">El monto del crédito es obligatorio.</small>
                    <small class="p-error" *ngIf="entity.get('chosenAmount')?.errors?.['min']">El valor minimo es {{entity.get('chosenAmount')?.errors?.['min']['min']}}.</small>
                    <small class="p-error" *ngIf="entity.get('chosenAmount')?.errors?.['max']">El valor máximo es {{entity.get('chosenAmount')?.errors?.['max']['max']}}.</small>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="typeForm === 'RFC_FORM'">
              <div class="col-12">
                <div class="field">
                  <label for="desireProduct">Tipo de busqueda:*</label>
                  <p-dropdown formControlName="desireProduct" [panelStyle]="{'width': '100%' }" [options]="desireProducts" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
                  <ng-container *ngIf="entity.get('desireProduct')?.invalid && (entity.get('desireProduct')?.dirty || entity.get('desireProduct')?.touched)">
                    <small class="p-error" *ngIf="entity.get('desireProduct')?.errors?.['required']">El tipo de producto es obligatorio.</small>
                  </ng-container>
                </div>
              </div>
              <div class="col-12">
                <div class="field">
                  <label for="rfc">RFC:*</label>
                  <input type="text" pInputText id="rfc" formControlName="rfc" placeholder="XXXX900101XX1" required />
                  <ng-container *ngIf="entity.get('rfc')?.invalid && (entity.get('rfc')?.dirty || entity.get('rfc')?.touched)">
                    <small class="p-error" *ngIf="entity.get('rfc')?.errors?.['required']">El RFC es obligatorio.</small>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <div class="col-12 text-right flex">
              <button pButton pRipple label="Calcular oferta" icon="pi pi-check" class="p-button p-button-sm" (click)="getSelectedOffer()" [disabled]="!entity.valid"></button>
              <button pButton pRipple label="Restablecer ofertas" icon="fa-solid fa-times" class="p-button p-button-sm p-button-danger ml-1" (click)="restoreOffers()" [disabled]="typeForm === 'RFC_FORM' && entity.value.rfc === '' && data === undefined"></button>
            </div>
          </div>
        </form>
      </div>
    </ng-container>
    <ng-container *ngIf="data">
    <div class="col-12 flex flex-wrap {{typeForm === 'RFC_FORM' ? 'justify-content-center': 'justify-content-between'}}">
      <ng-container *ngFor="let loan of data.offers">
        <div class="card-offer">
          <div class="card-offer_amount">Monto del crédito: <strong>{{loan.amount | currency}}</strong></div>
          <div class="card-offer_container">
            <h5>Plazos</h5>
            <ng-container *ngFor="let offer of loan.offer">
              <div class="single-offer {{(loan.amount * 2) >= (offer.installmentAmount * offer.installmentCount) && checkBestOffers ? 'best-offer': ''}}">
                <p>Monto por quincena: <strong>{{offer.installmentAmount | currency}}</strong></p>
                <p>Número de quincenas: <strong>{{offer.installmentCount}}</strong></p>
                <ng-container *ngIf="showTotals">
                  <p>Total a pagar: <strong>{{(offer.installmentAmount * offer.installmentCount) | currency}}</strong></p>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="errorMsg !== ''">
    <p-tag icon="pi pi-times" severity="danger" [value]="errorMsg"></p-tag>
  </ng-container>
</div>
