<div class="grid">
  <div class="col-12 pb-8 form-input-select mb-8">
    <p-multiSelect
      [options]="workers"
      [(ngModel)]="selectedWorkers"
      optionLabel="name"
      placeholder="Colaboradores seleccionados"
      (onChange)="onSelectedWorkerChange.emit(selectedWorkers)"
    />
  </div>
</div>
