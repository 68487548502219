import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ILoanPopulated} from "@modules/private/loan/data/interfaces/iloan.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {IInstallmentCalculated} from "@modules/private/loan/data/interfaces/installment.interface";
import {PERIOD_TYPE_ENUM} from "@modules/private/payroll/data/enums/payroll.enum";
import {LOAN_STATUS_ENUM} from "@modules/private/loan/data/enums/loan.enum";
import {MessageService, SelectItemGroup} from "primeng/api";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {ApiAccessoryService} from "@modules/private/accesory/data/services/api-accessory.service";
import {IAccessory, IApiAccessoryList} from "@modules/private/accesory/data/interfaces/iaccessory.interface";
import {CurrencyPipe} from "@angular/common";

@Component({
  selector: 'app-loan-offers',
  templateUrl: './loan-offers.component.html',
  styleUrls: ['./loan-offers.component.scss'],
  providers: [MessageService, CurrencyPipe]
})
export class LoanOffersComponent implements OnInit{
  isUpdating = false
  @Input() data!: ILoanPopulated
  entity!: FormGroup
  installmentsCalculated: IInstallmentCalculated[] = []
  $_periodTypeEnum = PERIOD_TYPE_ENUM
  $_statusLoanEnum = LOAN_STATUS_ENUM
  @Output() updateData = new EventEmitter()
  accessories: SelectItemGroup[] = []
  requiredAccessories: IAccessory[] = []
  constructor(
    private fb: FormBuilder,
    private apiLoanService: ApiLoanService,
    private messageService: MessageService,
    public authService: AuthService,
    private accessoryService: ApiAccessoryService,
    private currencyPipe: CurrencyPipe
  ) {
  }

  ngOnInit() {
    this.entity = this.fb.group({
      amount: [this.data.amount, [Validators.required, Validators.min(500)]],
      installmentCount: [this.data.installmentCount, [Validators.required, Validators.min(1)]],
      rate: [(this.data.rate * 100).toFixed(3), [Validators.required, Validators.min(0)]],
      creationFee:  [this.data.creationFee, [Validators.required, Validators.min(0)]],
      accountNumber: [this.data.accountNumber, [Validators.required, Validators.pattern(/^\d{18}$/)]],
      startDate: [this.data.startDate ? new Date(this.data.startDate):  '', [Validators.required]],
      date: [this.data.date ? new Date(this.data.date) : ''],
      optionalAccessories: this.data.accessories ? [this.data.accessories.filter(a => a._id && a.type === 'OPTIONAL').map(a => a._id)]: []
    })
    this.getAmortizationTable()
    this.getAllAccessories()
  }

  getAllAccessories() {
    this.accessoryService.getAll().subscribe(r => {
      console.log(r)
      this.requiredAccessories = r.data.filter(a => a.type === 'REQUIRED')
      this.accessories = [
        {
          label: 'Accesorios opcionales',
          value: 'Accesorios opcionales',
          items: r.data.filter(a => a.type !== 'REQUIRED').map(a => {
            const prices =  a.isFinanced ? `${ a.calculation === 'FIXED' ? this.currencyPipe.transform(a.price.weekly) : a.price.weekly + '%'} semanal | ${ a.calculation === 'FIXED' ? this.currencyPipe.transform(a.price.biweekly) : a.price.biweekly + '%'} catorcenal | ${a.calculation === 'FIXED' ? this.currencyPipe.transform(a.price.semimonthly) : a.price.semimonthly + '%'} quincenal | ${a.calculation === 'FIXED' ? this.currencyPipe.transform(a.price.monthly) : a.price.monthly + '%'} mensual` : `${a.calculation === 'FIXED' ? this.currencyPipe.transform(a.price.creation) : a.price.creation + '%' } una sola exibicion`
            return {
              label: `${a.name} | Costo: ${prices} | ${a.isFinanced ? 'Es financiado' : 'No financiado'}`,
              value: a._id,
              checked: true
            }
          })
        }
      ]
    })
  }


  getAmortizationTable() {
    this.apiLoanService.getCalculatedInstallments(this.data._id).subscribe(r => {
      console.log(r)
      this.installmentsCalculated = r.data
    })
  }

  saveAndRecalculate() {
    this.isUpdating = true
    console.log(this.entity.value)
    this.apiLoanService.update({...this.entity.value, id: this.data._id, requiredAccessories: this.requiredAccessories.map(a => a._id)}).subscribe((r) => {
      console.log(r)
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 5000 });
      this.isUpdating = false
      if(!r.error) {
        this.getAmortizationTable()
        this.updateData.emit()
      }
    })
  }

  transformDate(date: string) {
    return date.substring(0,10)
  }

  get getTotalAmounts() {
    const totals = {interests: 0, capital: 0, total: 0}
    this.installmentsCalculated.forEach(i => {
      totals.interests += i.revenueAmount
      totals.capital += i.principalAmount
      totals.total = totals.total + i.revenueAmount + i.principalAmount
    })
    return totals
  }

  get getAccessoryAmounts() {
    const totals = {interests: 0, capital: 0, total: 0}
    this.installmentsCalculated.forEach(i => {
      totals.interests += i.accessoryRevenueAmount
      totals.capital += i.accessoryPrincipalAmount
      totals.total = totals.total + i.accessoryRevenueAmount + i.accessoryPrincipalAmount
    })
    return totals
  }

  get getResumeTotals() {
    const totals = {interests: 0, capital: 0, total: 0}
    this.installmentsCalculated.forEach(i => {
      totals.interests += i.accessoryRevenueAmount + i.revenueAmount
      totals.capital += i.accessoryPrincipalAmount + i.principalAmount
      totals.total = totals.total + i.accessoryRevenueAmount + i.accessoryPrincipalAmount + i.principalAmount + i.revenueAmount
    })
    return totals
  }
}
