import {Component, OnInit, ViewChild} from '@angular/core';
import {ICompany} from "../data/interfaces/icompany.interface";
import {Table} from "primeng/table";
import {ApiCompanyService} from "../data/services/api/api-company.service";
import {DataTableService} from "@data/services/utils/data-table.service";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "@data/services/modules/auth/auth.service";

@Component({
  selector: 'app-list-company',
  templateUrl: './list-company.component.html',
  styleUrls: ['./list-company.component.scss'],
  providers: [ MessageService, ConfirmationService ]
})
export class ListCompanyComponent  implements  OnInit {
  companies!: ICompany[];
  companiesFiltered!: ICompany[]
  clonedCompanies: {[s: string]: ICompany} = {};
  items: MenuItem[] = [];
  cols: any[] = [
    {field: 'name', header: 'Nombre'},
    {field: 'creationDate', header: 'Fecha de creacion'}
  ]
  colsPdf: any[] = [
    {dataKey: 'name', title: 'Nombre'},
    {dataKey: 'creationDate', title: 'Fecha de creación'}
  ]
  loading: boolean = true;
  createEntity = false;
  submitted: boolean = false;
  company!: FormGroup
  formAction : 'CREATE' | 'UPDATE' = 'CREATE'
  @ViewChild('dt1') dt1!: Table;
  constructor(
    private apiCompanyService: ApiCompanyService,
    public dataTableService: DataTableService,
    private messageService: MessageService,
    private fb: FormBuilder,
    public authService: AuthService
  ) {
    this.createMenu()
  }

  ngOnInit() {
    this.find()
  }

  find() {
    this.loading = true
    this.apiCompanyService.getAll().subscribe(r => {
      if(!r.error) {
        this.companies = r.data.map(r => {
          r.creationDate = new Date(r.creationDate)
          return r
        })
        this.companiesFiltered = this.companies
      }
      this.loading = false
    })
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value
  }

  onRowEditInit(company: ICompany) {
    this.clonedCompanies[company._id as string] = { ...company };
  }

  onRowEditSave(company: ICompany) {
    /*if (company.price > 0) {
      delete this.clonedCompanies[company._id as string];
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Product is updated' });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Price' });
    }*/
  }
  hideDialog() {
    this.createEntity = false;
    this.submitted = false;
  }

  newEntity(formAction: 'CREATE' | 'UPDATE', data: ICompany | null = null) {
    this.formAction = formAction
    if (formAction === 'CREATE') {
      this.company = this.fb.group({
        name: ['', [Validators.required, Validators.minLength(3)]]
      })
    } else {
      this.company = this.fb.group({
        name: [data?.name, [Validators.required, Validators.minLength(3)]],
        id: [data?._id]
      })
    }

    this.company.clearValidators()
    this.submitted = false;
    this.createEntity = true;
  }
  saveProduct() {
    this.submitted = true
    const api = this.formAction === 'CREATE' ? this.apiCompanyService.create(this.company.value) : this.apiCompanyService.patch(this.company.value)

    api.subscribe((r) => {
      console.log(r)
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Exitoso', detail: r.error ? r.msg: r.msg, life: 3000 });
      if (!r.error) {
        this.hideDialog()
      }
    })
  }

  onRowEditCancel(company: ICompany, index: number) {
    this.companies[index] = this.clonedCompanies[company._id as string];
    delete this.clonedCompanies[company._id as string];
  }

  onFilter(event: any, dt:any) {
    this.companiesFiltered = event.filteredValue
  }

  createMenu() {
    if (this.authService.userHasAuthorization('COMPANY','EXPORT')) {
      this.items = [
        {
          label: 'Descargar',
          icon: 'fa-solid fa-cloud-arrow-down',
          items: [
            {
              label: 'CSV',
              command: () => {
                this.dt1.exportCSV()
                this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa' });
              }
            },
            {
              label: 'Excel',
              command: () => {

                this.dataTableService.exportExcel(this.companiesFiltered.map((c) => {
                  return {'Nombre': c.name, 'Fecha de creación': c.creationDate}
                }))
                this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa' });
              }
            },
            {
              label: 'PDF',
              command: () => {
                this.dataTableService.exportPdf(this.colsPdf, this.companiesFiltered)
                this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa' });
              }
            }
          ]
        }
      ]
    }
    this.items = [
      ...this.items,
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find()
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dt1.clear()
          this.find()
        }
      }
    ]
    if (this.authService.userHasAuthorization('COMPANY','CREATE')) {
      this.items = [
        ...this.items,
        { separator: true },
        {
          label: 'Nuevo',
          icon: 'fa-solid fa-square-plus',
          command: () => {
            this.newEntity('CREATE')
          }
        }
      ]
    }
  }
}
