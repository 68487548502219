import {Component, ViewChild} from '@angular/core';
import {DataTableService} from "@data/services/utils/data-table.service";
import {Table} from "primeng/table";
import {IRecordsRequestLoan} from "@modules/private/loan/data/interfaces/iloan.interface";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {PERIOD_TYPE_ENUM, PERIOD_TYPE_ENUM_COLOR} from "@modules/private/payroll/data/enums/payroll.enum";
import {
  BUCKET_ENUM,
  LOAN_STATUS_ENUM,
  LOAN_STATUS_ENUM_COLOR,
  TYPE_PRODUCT_ENUM, TYPE_SERVICE_ENUM
} from "@modules/private/loan/data/enums/loan.enum";
import {IsoDateEnumPipe} from "../../../../data/pipes/enum/iso-date-enum.pipe";
import {SelectEnumPipe} from "@data/pipes/enum/select-enum.pipe";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {LOAN_COLUMNS} from "@modules/private/loan/data/columns/loanColumns.constant";
import {WORKER_COLUMNS} from "../../workers/data/constants/workerColumns.constant";
import {AFILIATION_ENUM, WORKER_STATUS_ENUM} from "@modules/private/workers/data/enums/workerStatus.enum";
import {LoaderService} from "@data/services/page/loader.service";
import {AuthService} from "@data/services/modules/auth/auth.service";

interface Column {
  field: string;
  header: string;
  type: string;
}

@Component({
  selector: 'app-list-loan',
  templateUrl: './list-loan.component.html',
  styleUrls: ['./list-loan.component.scss'],
  providers: [MessageService, ConfirmationService, SelectEnumPipe, CurrencyPipe, DatePipe]
})
export class ListLoanComponent {
  createEntity = false
  assignLoans = false
  loans!: IRecordsRequestLoan[];
  totalRecords = 0
  itemsByPage = 10
  selectedWorkers!: IRecordsRequestLoan[] | null;
  loading: boolean = true;
  items: MenuItem[] = []
  status = [
    {label: 'Aprobado', value: 'APPROVED', color: 'success'},
    {label: 'Pendiente de aprobación', value: 'PENDING_APPROVAL', color: 'warning'},
    {label: 'Denegado', value: 'DENIED', color: 'danger'}
  ]
  _selectedColumns!: Column[]
  selectedLoans!: IRecordsRequestLoan[]
  columns = LOAN_COLUMNS
  @ViewChild('dtLoans') dtLoans!: Table;
  constructor(
    private apiLoanService: ApiLoanService,
    public dataTableService: DataTableService,
    private messageService: MessageService,
    private selectEnumPipe: SelectEnumPipe,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private loaderService: LoaderService,
    public authService: AuthService
  ) {
    this.createItems()
  }

  createItems() {
    this.createMenu()
    this.selectedColumns = this.columns
  }

  ngOnInit() {
    this.find()
  }

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    this._selectedColumns = this.columns.filter((col) => val.includes(col));
  }

  find(page = 0, limit = this.itemsByPage, filters = {}, sort = {} ) {
    this.loading = true;
    let downFilters = {}
    this.apiLoanService.getAll(page, limit, {...filters, ...downFilters}, sort).subscribe(r => {
      console.log(r)
      this.totalRecords = r.totalItems
      this.loans = r.data
      this.loading = false
    })
  }

  getValueObject(obj: any, keys: string) {
    const keysArray = keys.split('.')
    let value: any
    for (const key in keysArray) {
      if(key === '0') {
        value = obj[keysArray[key]]
      } else {
        value = value[keysArray[key]]
      }
    }
    return value
  }

  clear(table: Table) {
    table.clear();
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value
  }

  loadLoans(e: any) {
    const currentPage = e.first / this.itemsByPage
    this.itemsByPage = e.rows
    const finalFilters = e.filters
    if (e.filters.global) finalFilters['folio'] = e.filters.global
    let sort :any= {}
    if (e.sortField) {
      sort[e.sortField] = e.sortOrder
    }
    this.find(currentPage, this.itemsByPage, finalFilters, sort)
  }

  convertData(d: IRecordsRequestLoan[]) {
    return d.map(i => {
      return {
         ...i,
        period: this.selectEnumPipe.transform(i.period, PERIOD_TYPE_ENUM),
        product: this.selectEnumPipe.transform(i.product, TYPE_PRODUCT_ENUM),
        status: this.selectEnumPipe.transform(i.status, LOAN_STATUS_ENUM),
        typeService: this.selectEnumPipe.transform(i.typeService, TYPE_SERVICE_ENUM),
        userStatus: this.selectEnumPipe.transform(i.userStatus, AFILIATION_ENUM),
        amountFounded: this.currencyPipe.transform(i.amountFounded),
        capitalBorrowed: this.currencyPipe.transform(i.capitalBorrowed),
        creationFee: this.currencyPipe.transform(i.creationFee),
        depositDate: this.datePipe.transform(i.depositDate, 'dd-MM-YYYY'),
        firstInstallment: this.datePipe.transform(i.firstInstallment, 'dd-MM-YYYY'),
        lastInstallment: this.datePipe.transform(i.lastInstallment, 'dd-MM-YYYY'),
        nextInstallment: this.datePipe.transform(i.nextInstallment, 'dd-MM-YYYY'),
        firstInstallmentDefeated: this.datePipe.transform(i.firstInstallmentDefeated, 'dd-MM-YYYY'),
        interestInstallment: this.currencyPipe.transform(i.interestInstallment),
        capitalInstallment: this.currencyPipe.transform(i.capitalInstallment),
        installmentAmount: this.currencyPipe.transform(i.installmentAmount),
        defeatedCreationFee: this.currencyPipe.transform(i.defeatedCreationFee),
        totalDefeated: this.currencyPipe.transform(i.totalDefeated),
        totalPaid: this.currencyPipe.transform(i.totalPaid),
        totalToPaid: this.currencyPipe.transform(i.totalToPaid),
        totalToLiquidate: this.currencyPipe.transform(i.totalToLiquidate),
        capitalPaid: this.currencyPipe.transform(i.capitalPaid),
        capitalPending: this.currencyPipe.transform(i.capitalPending),
        capitalDefeated: this.currencyPipe.transform(i.capitalDefeated),
        interestToPay: this.currencyPipe.transform(i.interestToPay),
        interestPaid: this.currencyPipe.transform(i.interestPaid),
        interestPending: this.currencyPipe.transform(i.interestPending),
        interestDefeated: this.currencyPipe.transform(i.interestDefeated),
        delayedDate: this.datePipe.transform(i.delayedDate, 'dd-MM-YYYY'),
        downDate: this.datePipe.transform(i.downDate, 'dd-MM-YYYY'),
        requestDate: this.datePipe.transform(i.requestDate, 'dd-MM-YYYY')
      }
    })
  }

  exportPdf() {
    this.loaderService.showSpinner()
    this.apiLoanService.getAll(0, 100000000, this.dtLoans.filters).subscribe(r => {
      this.dataTableService.exportPdf([
        {dataKey: 'rfc', title: 'RFC'},
        {dataKey: 'folio', title: 'Folio'},
        ...this.selectedColumns.map(sc => {
          return {
            dataKey: sc.field,
            title: sc.header
          }
        })
      ], this.convertData(r.data))
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa', life: 5000 });
    })
  }

  exportExcel() {
    this.loaderService.showSpinner()
    this.apiLoanService.getAll(0, 100000000, this.dtLoans.filters).subscribe(r => {
      const dataConverted = this.convertData(r.data)
      this.dataTableService.exportExcel(dataConverted.map(i => {
        const response: any = {RFC: i.rfc, folio: i.folio}
        for (const column of this.selectedColumns) {
          const ii = i as any
          response[column.header] = ii[column.field]
        }
        return response
      }))
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa', life: 5000 });
    })
  }

  hideDialog() {
    this.createEntity = false;
  }

  createMenu() {
    if (this.authService.userHasAuthorization('LOAN','EXPORT')) {
      this.items.push({
        label: 'Descargar',
        icon: 'fa-solid fa-cloud-arrow-down',
        items: [
          {
            label: 'Excel',
            command: () => {
              this.exportExcel()
            }
          },
          {
            label: 'PDF',
            command: () => {
              this.exportPdf()
            }
          }
        ]
      })
    }
    this.items = [
      ...this.items,
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find()
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtLoans.clear()
          this.find()
        }
      }
    ]
    if (this.authService.userHasAuthorization('LOAN', 'CREATE')) {
      this.items = [
        ...this.items,
        { separator: true },
        {
          label: 'Nuevo',
          icon: 'fa-solid fa-square-plus',
          command: () => {
            this.createEntity = true
          }
        }
      ]
    }
    // TODO: ACTION TO ASSIGN
    if (this.authService.userHasAuthorization('LOAN', 'ASSIGNMENT')) {
      this.items = [
        ...this.items,
        {separator: true},
        {
          label: 'Asignar créditos',
          icon: 'fa-solid fa-arrow-right-arrow-left',
          command: () => {
            this.assignLoans = true
          }
        }
      ]
    }
  }
  protected readonly IsoDateEnumPipe = IsoDateEnumPipe;
  protected readonly WORKER_COLUMNS = WORKER_COLUMNS;
}
