<p-table
  #dtMainTable
  [scrollable]="true"
  scrollHeight="600px"
  [value]="entities"
  [lazy]="searchInServer"
  (onLazyLoad)="loadEntities($event)"
  [columns]="selectedColumns"
  dataKey="_id"
  [rows]="itemsByPage"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50, 100]"
  [loading]="loadingTable"
  [paginator]="true"
  [tableStyle]="{'width': '100%'}"
  [(selection)]="selectedEntities"
  [rowHover]="true"
  [totalRecords]="totalRecords"
  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} {{pluralEntityName}}"
  [styleClass]="'p-datatable-sm'"
>
  <ng-template pTemplate="caption">
    <div class="flex flex-row justify-content-between">
      <p-splitButton label="Acciones" [model]="menu" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
      <div>
        <p-multiSelect [options]="columns" [(ngModel)]="selectedColumns" optionLabel="header" selectedItemsLabel="{0} columnas seleccionadas" [style]="{'min-width': '200px'}" placeholder="Escoge las columnas"></p-multiSelect>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th pFrozenColumn></th>
      <ng-container *ngFor="let col of columns">
        <th  style="min-width: 15rem">
          <ng-container *ngIf="col.showFilter">
            <ng-container [ngSwitch]="col.type">
              <ng-container *ngSwitchCase="'numeric'">
                <p-columnFilter type="numeric" [field]="col.field" [currency]="'MXN'"></p-columnFilter>
              </ng-container>
              <ng-container *ngSwitchCase="'number'">
                <p-columnFilter type="numeric" [field]="col.field"></p-columnFilter>
              </ng-container>
              <ng-container *ngSwitchCase="'enum'">
                <div class="flex align-items-center" pSortableColumn="periodType">
                  <p-columnFilter [field]="col.field" matchMode="equals" [showMenu]="false" >
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-dropdown [ngModel]="value" [options]="col.options" (onChange)="filter($event.value)" placeholder="Cualquiera">
                        <ng-template let-option pTemplate="item">
                          <p-tag [value]="option.label" [severity]="option.value | selectEnum: col.enumColor"></p-tag>
                        </ng-template>
                      </p-dropdown>
                    </ng-template>
                  </p-columnFilter>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'boolean'">
                <p-columnFilter type="boolean" [field]="col.field"></p-columnFilter>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <p-columnFilter [type]="col.type" [field]="col.field"></p-columnFilter>
              </ng-container>
            </ng-container>
          </ng-container>
        </th>
      </ng-container>
      <th style="min-width: 45rem"></th>
    </tr>
    <tr>
      <th style="padding-left: 15px;" pFrozenColumn>
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <ng-container *ngFor="let col of columns">
        <th  style="min-width: 15rem">
          {{col.header}}
        </th>
      </ng-container>
      <th style="min-width: 45rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-columns="columns">
    <tr>
      <td pFrozenColumn>
        <p-tableCheckbox [value]="item"></p-tableCheckbox>
      </td>
      <td *ngFor="let col of columns">
        <ng-container [ngSwitch]="col.dataType">
          <ng-container *ngSwitchCase="'currency'">
            {{getValueObject(item, col.field) | currency}}
          </ng-container>
          <ng-container *ngSwitchCase="'date'">
            {{getValueObject(item, col.field) | date: 'dd-MM-YYYY'}}
          </ng-container>
          <ng-container *ngSwitchCase="'timeElapsed'">
            {{getValueObject(item, col.field) | timeElapsed}}
          </ng-container>
          <ng-container *ngSwitchCase="'link'">
            <a class="link-subsection" routerLink="{{'/'+col.rootLink+'/'+getValueObject(item, col.linkField)}}">{{getValueObject(item, col.field)}}</a>
          </ng-container>
          <ng-container *ngSwitchCase="'progress'">
            <p-progressBar [style]="{ height: '12px', fontSize: '12px' }" [value]="roundNumber(getValueObject(item, col.field))"></p-progressBar>
          </ng-container>
          <ng-container *ngSwitchCase="'enum'">
            <ng-container *ngIf="getValueObject(item, col.field) && getValueObject(item, col.field) !== ''; else elseEnum ">
              <p-tag [value]="getValueObject(item, col.field) | selectEnum:col.enum" [severity]="getValueObject(item, col.field) | selectEnum:col.enumColor"></p-tag>
            </ng-container>
            <ng-template #elseEnum>
              --
            </ng-template>
          </ng-container>
          <ng-container *ngSwitchCase="'boolean'">
            <i class="pi" [ngClass]="{ 'text-green-500 pi-check-circle': getValueObject(item, col.field), 'text-red-500 pi-times-circle': !getValueObject(item, col.field) }"></i>
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{getValueObject(item, col.field)}}
          </ng-container>
        </ng-container>
      </td>
      <td>
        <ng-container *ngFor="let action of actions">
          <ng-container *ngIf="showAction(item, action.rule)">
            <button pButton class="p-button p-button-sm mr-2 p-button-{{action.color}}" [label]="action.title" [icon]="action.icon" (click)="action.showConfirm ? confirmAction($event, action.confirmMessage,{event: $event, action: action.action, data: item}): onAction.emit({event: $event, action: action.action, data: item})"> </button>
          </ng-container>
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No se encontraron {{pluralEntityName}}</td>
    </tr>
  </ng-template>
</p-table>
<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
