<p-card>
  <div class="row">
    <div class="col-12">
      <h1 class="mb-3">Condonaciones</h1>
      <p class="mb-3 text-color-secondary">Las condonaciones se refieren a la cancelación o perdón de una deuda, ya sea total o parcial. </p>
      <p-toast></p-toast>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p-tabView styleClass="tabview-custom">
        <ng-container>
          <p-tabPanel>
            <ng-template pTemplate="header">
              <i class="fa-solid fa-flag"></i>
              <span class="ml-2">Pendientes</span>
            </ng-template>
            <p-table
              #dtWriteOffPending
              [scrollable]="true"
              scrollHeight="600px"
              [value]="pendingWriteOffs"
              [lazy]="true"
              (onLazyLoad)="loadWriteOffs($event, 'pending')"
              [columns]="selectedColumns"
              dataKey="_id"
              [rows]="itemsByPage"
              [showCurrentPageReport]="true"
              [rowsPerPageOptions]="[10, 25, 50, 100]"
              [loading]="loading"
              [paginator]="true"
              [tableStyle]="{'width': '100%'}"
              [(selection)]="selectedWriteOffs"
              [rowHover]="true"
              [totalRecords]="totalPendingRecords"
              currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} condonaciones"
            >
              <ng-template pTemplate="caption">
                <div class="flex flex-row justify-content-between">
                  <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
                  <div>
                    <p-multiSelect [options]="columns" [(ngModel)]="selectedColumns" optionLabel="header" selectedItemsLabel="{0} columnas seleccionadas" [style]="{'min-width': '200px'}" placeholder="Escoge las columnas"></p-multiSelect>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th pFrozenColumn></th>
                  <th style="width: 5rem;" pFrozenColumn></th>
                  <ng-container *ngFor="let col of columns">
                    <th  style="min-width: 15rem">
                      <ng-container *ngIf="col.showFilter">
                        <ng-container [ngSwitch]="col.type">
                          <ng-container *ngSwitchCase="'enum'">
                            <div class="flex align-items-center" pSortableColumn="statusType">
                              <p-columnFilter [field]="col.field" matchMode="equals" [showMenu]="false">
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                  <p-dropdown [ngModel]="value" [options]="col.options" (onChange)="filter($event.value)" placeholder="Cualquiera">
                                    <ng-template let-option pTemplate="item">
                                      <p-tag [value]="option.label" [severity]="option.value | selectEnum: col.enumColor"></p-tag>
                                    </ng-template>
                                  </p-dropdown>
                                </ng-template>
                              </p-columnFilter>
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchCase="'numeric'">
                            <p-columnFilter [field]="col.field" matchMode="equals" pKeyFilter="hex">
                            </p-columnFilter>
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                            <p-columnFilter [type]="col.type" [field]="col.field"></p-columnFilter>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </th>
                  </ng-container>
                </tr>
                <tr>
                  <th style="padding-left: 15px;" pFrozenColumn>
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th style="min-width: 15rem" pFrozenColumn>
                    Montos a condonar
                  </th>
                  <ng-container *ngFor="let col of columns">
                    <th  style="min-width: 15rem">
                      {{col.header}}
                    </th>
                  </ng-container>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item let-columns="columns" let-expanded="expanded">
                <tr>
                  <td pFrozenColumn>
                    <p-tableCheckbox [value]="item"></p-tableCheckbox>
                  </td>
                  <td pFrozenColumn>
                    <button type="button" pButton pRipple [pRowToggler]="item" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                  </td>
                  <td *ngFor="let col of columns">
                    <ng-container [ngSwitch]="col.dataType">
                      <ng-container *ngSwitchCase="'date'">
                        {{getValueObject(item, col.field).split('T')[0] }}
                      </ng-container>
                      <ng-container *ngSwitchCase="'enum'">
                        <ng-container *ngIf="getValueObject(item, col.field) && getValueObject(item, col.field) !== ''; else elseEnum ">
                          <p-tag [value]="getValueObject(item, col.field) | selectEnum:col.enum" [severity]="getValueObject(item, col.field) | selectEnum:col.enumColor"></p-tag>
                        </ng-container>
                        <ng-template #elseEnum>
                          --
                        </ng-template>
                      </ng-container>
                      <ng-container *ngSwitchCase="'link'">
                        <a class="link-subsection" routerLink="{{'/loan/'+getValueObject(item, col.linkField)}}">{{getValueObject(item, col.field)}}</a>
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        {{getValueObject(item, col.field)}}
                      </ng-container>
                    </ng-container>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="rowexpansion" let-item>
                <tr>
                  <td colspan="7">
                    <div class="p-3">
                      <p-table [value]="item.debtRelief" dataKey="concept">
                        <ng-template pTemplate="header">
                          <tr>
                            <th pSortableColumn="concept">Tipo de monto <p-sortIcon field="concept"></p-sortIcon></th>
                            <th pSortableColumn="originAmount">Monto Origina <p-sortIcon field="originAmount"></p-sortIcon></th>
                            <th pSortableColumn="write">Monto despues de condonar <p-sortIcon field="write"></p-sortIcon></th>
                            <th pSortableColumn="debtRelief">Monto condonado <p-sortIcon field="debtRelief"></p-sortIcon></th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-debt>
                          <tr>
                            <td>{{ debt.concept | selectEnum:$_writeOffConcept }}</td>
                            <td>{{ debt.originAmount | currency}}</td>
                            <td>{{ debt.write | currency}}</td>
                            <td>{{ debt.debtRelief | currency}}</td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="7">No se encontraron condonaciones</td>
                </tr>
              </ng-template>
            </p-table>
          </p-tabPanel>
        </ng-container>
        <ng-container>
          <p-tabPanel>
            <ng-template pTemplate="header">
              <i class="fa-solid fa-receipt"></i>
              <span class="ml-2">Historico</span>
            </ng-template>
            <p-table
              #dtWriteOffHistory
              [scrollable]="true"
              scrollHeight="600px"
              [value]="writeOffs"
              [lazy]="true"
              (onLazyLoad)="loadWriteOffs($event, 'history')"
              [columns]="selectedColumns"
              dataKey="_id"
              [rows]="itemsByPage"
              [showCurrentPageReport]="true"
              [rowsPerPageOptions]="[10, 25, 50, 100]"
              [loading]="loading"
              [paginator]="true"
              [tableStyle]="{'width': '100%'}"
              [(selection)]="selectedWriteOffs"
              [rowHover]="true"
              [totalRecords]="totalRecords"
              currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} condonaciones"
            >
              <ng-template pTemplate="caption">
                <div class="flex flex-row justify-content-between">
                  <div>
                    <p-multiSelect [options]="columns" [(ngModel)]="selectedColumns" optionLabel="header" selectedItemsLabel="{0} columnas seleccionadas" [style]="{'min-width': '200px'}" placeholder="Escoge las columnas"></p-multiSelect>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th pFrozenColumn></th>
                  <th style="width: 5rem;" pFrozenColumn></th>
                  <ng-container *ngFor="let col of columns">
                    <th  style="min-width: 15rem">
                      <ng-container *ngIf="col.showFilter">
                        <ng-container [ngSwitch]="col.type">
                          <ng-container *ngSwitchCase="'enum'">
                            <div class="flex align-items-center" pSortableColumn="statusType">
                              <p-columnFilter [field]="col.field" matchMode="equals" [showMenu]="false">
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                  <p-dropdown [ngModel]="value" [options]="col.options" (onChange)="filter($event.value)" placeholder="Cualquiera">
                                    <ng-template let-option pTemplate="item">
                                      <p-tag [value]="option.label" [severity]="option.value | selectEnum: col.enumColor"></p-tag>
                                    </ng-template>
                                  </p-dropdown>
                                </ng-template>
                              </p-columnFilter>
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchCase="'numeric'">
                              <p-columnFilter [field]="col.field" matchMode="equals" pKeyFilter="hex">
                              </p-columnFilter>
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                            <p-columnFilter [type]="col.type" [field]="col.field"></p-columnFilter>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </th>
                  </ng-container>
                </tr>
                <tr>
                  <th style="padding-left: 15px;" pFrozenColumn>
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th style="min-width: 15rem" pFrozenColumn>
                    Montos a condonar
                  </th>
                  <ng-container *ngFor="let col of columns">
                    <th  style="min-width: 15rem">
                      {{col.header}}
                    </th>
                  </ng-container>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item let-columns="columns" let-expanded="expanded">
                <tr>
                  <td pFrozenColumn>
                    <p-tableCheckbox [value]="item"></p-tableCheckbox>
                  </td>
                  <td pFrozenColumn>
                    <button type="button" pButton pRipple [pRowToggler]="item" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                  </td>
                  <td *ngFor="let col of columns">
                    <ng-container [ngSwitch]="col.dataType">
                      <ng-container *ngSwitchCase="'date'">
                        {{getValueObject(item, col.field).split('T')[0]}}
                      </ng-container>
                      <ng-container *ngSwitchCase="'enum'">
                        <ng-container *ngIf="getValueObject(item, col.field) && getValueObject(item, col.field) !== ''; else elseEnum ">
                          <p-tag [value]="getValueObject(item, col.field) | selectEnum:col.enum" [severity]="getValueObject(item, col.field) | selectEnum:col.enumColor"></p-tag>
                        </ng-container>
                        <ng-template #elseEnum>
                          --
                        </ng-template>
                      </ng-container>
                      <ng-container *ngSwitchCase="'link'">
                        <a class="link-subsection" routerLink="{{'/loan/'+getValueObject(item, col.linkField)}}">{{getValueObject(item, col.field)}}</a>
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        {{getValueObject(item, col.field)}}
                      </ng-container>
                    </ng-container>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="rowexpansion" let-item>
                <tr>
                  <td colspan="7">
                    <div class="p-3">
                      <p-table [value]="item.debtRelief" dataKey="concept">
                        <ng-template pTemplate="header">
                          <tr>
                            <th pSortableColumn="concept">Tipo de monto <p-sortIcon field="concept"></p-sortIcon></th>
                            <th pSortableColumn="originAmount">Monto Origina <p-sortIcon field="originAmount"></p-sortIcon></th>
                            <th pSortableColumn="write">Monto despues de condonar <p-sortIcon field="write"></p-sortIcon></th>
                            <th pSortableColumn="debtRelief">Monto condonado <p-sortIcon field="debtRelief"></p-sortIcon></th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-debt>
                          <tr>
                            <td>{{ debt.concept | selectEnum:$_writeOffConcept }}</td>
                            <td>{{ debt.originAmount | currency}}</td>
                            <td>{{ debt.write | currency}}</td>
                            <td>{{ debt.debtRelief | currency}}</td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="7">No se encontraron condonaciones</td>
                </tr>
              </ng-template>
            </p-table>
          </p-tabPanel>
        </ng-container>
      </p-tabView>
    </div>
  </div>
</p-card>
