import {Component, Input, ViewChild} from '@angular/core';
import {IApiWithdrawal} from "@modules/private/withdrawal/data/interfaces/iwithdrawal.interface";
import {IPosition} from "@modules/private/workers/data/interfaces/iposition.interface";
import {IWorker} from "@modules/private/workers/data/interfaces/iworker.interface";
import {IPayroll} from "@modules/private/payroll/data/interfaces/ipayroll.interface";
import {ICompany} from "@modules/private/company/data/interfaces/icompany.interface";
import {IAgreement} from "@modules/private/agreement/data/interfaces/iapi-agreement.interface";
import {IPayer} from "@modules/private/payer/data/interfaces/iapi-payer.interface";
import {ApiWithdrawalService} from "@modules/private/withdrawal/data/services/api/api-withdrawal.service";
import {DataTableService} from "@data/services/utils/data-table.service";
import {Table} from "primeng/table";
import {ILoan} from "@modules/private/loan/data/interfaces/iloan.interface";
import {
  IInstallment,
  IRecordsRequestInstallment
} from "@modules/private/installment/data/interfaces/iinstallment.interface";
import {ConfirmationService, MenuItem, MessageService, SelectItem} from "primeng/api";
import {ApiInstallmentService} from "@modules/private/installment/data/services/api/api-installment.service";
import {PERIOD_TYPE_ENUM, PERIOD_TYPE_ENUM_COLOR} from "@modules/private/payroll/data/enums/payroll.enum";
import {WORKER_COLUMNS} from "../../workers/data/constants/workerColumns.constant";
import {ITableCol} from "@data/interfaces/itable.interface";
import {INSTALLMENT_COLUMNS} from "@modules/private/installment/data/constants/installmentColumns.constant";
import {SelectEnumPipe} from "@data/pipes/enum/select-enum.pipe";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {LoaderService} from "@data/services/page/loader.service";
import {LOAN_COLUMNS} from "../../loan/data/columns/loanColumns.constant";
import {AuthService} from "@data/services/modules/auth/auth.service";

interface Column {
  field: string;
  header: string;
  type: string;
}

@Component({
  selector: 'app-list-installment',
  templateUrl: './list-installment.component.html',
  styleUrls: ['./list-installment.component.scss'],
  providers: [MessageService, ConfirmationService, SelectEnumPipe, CurrencyPipe, DatePipe]
})
export class ListInstallmentComponent {
  items: MenuItem[] = []
  installments!: IRecordsRequestInstallment[];
  totalRecords = 0
  itemsByPage = 10
  first = 0
  selectedInstallments!: IRecordsRequestInstallment[] | null;
  _selectedColumns!: ITableCol[]
  loading: boolean = true;
  columns = INSTALLMENT_COLUMNS
  @ViewChild('dtInstallments') dtInstallments!: Table;
  constructor(
    private apiInstallmentService: ApiInstallmentService,
    public dataTableService: DataTableService,
    private messageService: MessageService,
    private selectEnumPipe: SelectEnumPipe,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private loaderService: LoaderService,
    private authService: AuthService
  ) {
    this.createMenu()
    this.selectedColumns = this.columns
  }

  ngOnInit() {
    this.find()
  }

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    this._selectedColumns = this.columns.filter((col) => val.includes(col));
  }


  refreshTable() {
    this.refresh()
    this.find()
  }

  find(page = 0, limit = this.itemsByPage, filters = {}) {
    this.loading = true;
    this.apiInstallmentService.getAll(page, limit, filters).subscribe(r => {
      console.log(r)
      this.totalRecords = r.totalItems
      this.installments = r.data
      this.loading = false
    })
  }

  clear(table: Table) {
    table.clear();
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value
  }

  loadInstallments(e: any) {
    console.log(e)
    const currentPage = e.first / this.itemsByPage
    this.itemsByPage = e.rows
    const finalFilters = {...e.filters}
    if (e.filters.global) finalFilters['product.folio'] = e.filters.global
    this.find(currentPage, this.itemsByPage, finalFilters)
  }


  exportPdf() {
    this.loaderService.showSpinner()
    this.apiInstallmentService.getAll(0, 100000000, this.dtInstallments.filters).subscribe(r => {
      this.dataTableService.exportPdf([
        {dataKey: 'rfc', title: 'RFC'},
        ...this.selectedColumns.map(sc => {
          return {
            dataKey: sc.field,
            title: sc.header
          }
        })
      ], this.convertData(r.data))
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa', life: 5000 });
    })
  }

  exportExcel() {
    this.loaderService.showSpinner()
    this.apiInstallmentService.getAll(0, 100000000, this.dtInstallments.filters).subscribe(r => {
      const dataConverted = this.convertData(r.data)

      this.dataTableService.exportExcel(dataConverted.map(i => {
        const response: any = {RFC: i.rfc}
        for (const column of this.selectedColumns) {
          const ii = i as any
          response[column.header] = ii[column.field]
        }
        return response
      }))
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa', life: 5000});
    })
  }

  convertData(d: IRecordsRequestInstallment[]) {
    return d.map(i => {
      return {
        ...i
      }
    })
  }
  refresh() {
    this.first = 0;
  }

  getValueObject(obj: any, keys: string) {
    const keysArray = keys.split('.')
    let value: any
    for (const key in keysArray) {
      if(key === '0') {
        value = obj[keysArray[key]]
      } else {
        value = value[keysArray[key]]
      }
    }
    return value
  }

    protected readonly WORKER_COLUMNS = WORKER_COLUMNS;
  protected readonly LOAN_COLUMNS = LOAN_COLUMNS;

  createMenu() {
    if (this.authService.userHasAuthorization('INSTALLMENT','EXPORT')) {
      this.items.push({
        label: 'Descargar',
        icon: 'fa-solid fa-cloud-arrow-down',
        items: [
          {
            label: 'CSV',
            command: () => {
              this.dtInstallments.exportCSV()
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa' });
            }
          },
          {
            label: 'Excel',
            command: () => {
              // this.dataTableService.exportExcel(this.transformData())
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa' });
            }
          },
          {
            label: 'PDF',
            command: () => {
              // this.dataTableService.exportPdf(this.colsPdf, this.transformData())
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa' });
            }
          }
        ]
      })
    }
    this.items = [
      ...this.items,
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find()
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtInstallments.clear()
          this.find()
        }
      }
    ]
  }
}
