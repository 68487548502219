<ng-container *ngIf="company">
  <form [formGroup]="company">
    <div class="field">
      <label for="name">Nombre</label>
      <input type="text" pInputText id="name" formControlName="name" placeholder="Corporativo ABC" required autofocus />
      <ng-container *ngIf="company.get('name')?.invalid && (company.get('name')?.dirty || company.get('name')?.touched)">
        <small class="p-error" *ngIf="company.get('name')?.errors?.['required']">El nombre es obligatorio.</small>
        <small class="p-error" *ngIf="company.get('name')?.errors?.['minlength']">El nombre debe tener al menos {{company.get('name')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
      </ng-container>
    </div>
  </form>
</ng-container>
