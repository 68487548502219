import {ITableCol} from "@data/interfaces/itable.interface";
import {WRITE_OFF_STATUS_ENUM, WRITE_OFF_STATUS_ENUM_COLOR} from "@modules/private/write-off/data/enums/writeOff.enum";

export const WRITE_OFF_COLUMNS: ITableCol[] = [
  {type: 'numeric', field: 'index', header: 'Letra', showFilter: true},
  {type: 'text', field: 'loanFolio', header: 'Folio', dataType: 'link', linkField: 'loanId', rootLink: 'loan', showFilter: true},
  {type: 'text', field: 'reason', header: 'Razón de Condonación', showFilter: true},
  {type: 'enum', field: 'status', header: 'Estatus de Condonación', showFilter: true, dataType: 'enum', enum: WRITE_OFF_STATUS_ENUM, enumColor: WRITE_OFF_STATUS_ENUM_COLOR, options: [
      {value: 'PENDING', label: 'Pendiente'},
      {value: 'APPLIED', label: 'Aplicado'},
      {value: 'CANCELED', label: 'Cancelado'},
      {value: 'NOT_APPLIED', label: 'No Aplicado'},
    ]},
  {type: 'date', field: 'date', header: 'Fecha de Aplicación', dataType: 'date', showFilter: true},
  {type: 'text', field: 'adminFullName', header: 'Asesor', showFilter: true},
]
