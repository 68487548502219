import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ApiWorkerService} from "@modules/private/workers/data/services/api/api-worker.service";
import {LoaderService} from "@data/services/page/loader.service";

@Component({
  selector: 'app-worker-integrate-accounts',
  templateUrl: './worker-integrate-accounts.component.html',
  styleUrls: ['./worker-integrate-accounts.component.scss']
})
export class WorkerIntegrateAccountsComponent {
  workers: {id: string; name: string;}[] = [];
  @Input() selectedWorkers: {id: string; name: string}[] = [];
  @Input() workerId = '';
  @Output() onSelectedWorkerChange = new EventEmitter();


  constructor(
    private apiWorkerService: ApiWorkerService,
    private loaderService: LoaderService,
  ) {
    this.getAllWorkers()
  }

  getAllWorkers() {
    this.loaderService.showSpinner()
    this.apiWorkerService.getAllNamesAndRfcs().subscribe(r => {
      this.loaderService.hideSpinner()
      console.log(r)
      if (r.data.length > 0) {
        r.data.forEach(w => {
          if (w._id !== this.workerId) {
            this.workers.push({
              id: w._id,
              name: `${w.rfc} ${w.firstName} ${w.lastName}`
            })
          }
        })
      }
    })
  }
}
