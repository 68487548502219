import {Component, Input, OnInit} from '@angular/core';
import {ILoanPopulated} from "@modules/private/loan/data/interfaces/iloan.interface";
import {ApiWeetrusthService} from "@modules/private/loan/data/services/api/api-weetrusth.service";
import {IWeetrustDocument} from "@modules/private/loan/data/interfaces/weetrusth.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {MessageService} from "primeng/api";
import {LOAN_WEETRUST_STATUS} from "@modules/private/loan/data/enums/loan.enum";
import {PdfAgreementService} from "@data/services/utils/pdf-agreement.service";
import {IInstallmentCalculated} from "@modules/private/loan/data/interfaces/installment.interface";
import {AuthService} from "@data/services/modules/auth/auth.service";

@Component({
  selector: 'app-loan-sing-aggrement',
  templateUrl: './loan-sing-aggrement.component.html',
  styleUrls: ['./loan-sing-aggrement.component.scss'],
  providers: [MessageService]
})
export class LoanSingAggrementComponent implements OnInit{
  @Input() data!: ILoanPopulated
  @Input() installments: IInstallmentCalculated[] = []
  agreement!: IWeetrustDocument
  entity!: FormGroup
  isUpdating = false
  updateAgreement = false
  $_loanWeetrustStatus = LOAN_WEETRUST_STATUS
  constructor(
    private fb: FormBuilder,
    private apiWeetrustService: ApiWeetrusthService,
    private apiLoanService: ApiLoanService,
    private messageService: MessageService,
    private pdfAgreementService: PdfAgreementService,
    public authService: AuthService
  ) {
    this.entity = this.fb.group({
      contractId: ['', [Validators.required, Validators.minLength(24), Validators.maxLength(24)]]
    });
  }

  ngOnInit() {
    this.getDocumentInfo()
  }

  getDocumentInfo(contractId = this.data.agreementId) {
    this.apiWeetrustService.getDocumentById(contractId).subscribe(r => {
      this.agreement = r.data
    })
  }

  addContractToLoan() {
    this.isUpdating = true
    this.apiLoanService.updateContractId({...this.entity.value, id: this.data._id}).subscribe((r) => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 5000 });
      this.isUpdating = false
      this.getDocumentInfo(this.entity.value.contractId)
      this.updateAgreement = false
    })
  }

  generateAgreement() {
    this.apiLoanService.getCalculatedInstallments(this.data._id).subscribe(r => {
      this.pdfAgreementService.fillAgreement(this.data, r.data).then(r => {
        console.log(r)
      })
    })
  }
}
