import {Component, Input, OnInit} from '@angular/core';
import {IPushingFlowDetail, StageItem} from "@modules/private/pushing/data/interfaces/ipushing.interface";
import {PdfServiceService} from "@data/services/utils/pdf-service.service";
import {PdfAgreementService} from "@data/services/utils/pdf-agreement.service";
declare global { interface Window { AdobeDC: any; } }
@Component({
  selector: 'app-pushing-resume',
  templateUrl: './pushing-resume.component.html',
  styleUrls: ['./pushing-resume.component.scss']
})
export class PushingResumeComponent implements OnInit {
  @Input() data!: IPushingFlowDetail
  @Input() stages: StageItem[] = []
  dataItems:any = {}
  selectedDocument = null
  typeDocument = 'pdf'

  constructor(
    private pdfServiceService: PdfServiceService,
    private pdfAgreementService: PdfAgreementService
  ) {
  }
  ngOnInit() {
    this.constructDataFlow()
  }

  generateDocument(url: string) {
    console.log(url)
    if (url) {
      this.typeDocument = (url.includes('.pdf')) ? 'pdf' : 'image'
      if (this.typeDocument === 'pdf') {
        this.pdfServiceService.ready().then(async () => {
          const canvas: any = document.querySelector('#adobe-dc-view');
          canvas.style.display = "block";
          let defConfg = {
            // defaultViewMode: "FIT_WIDTH"
          }
          this.pdfAgreementService.downloadPDF(url).subscribe((r: any) => {
            const fileUrl = URL.createObjectURL(r)
            this.pdfServiceService.previewFile('adobe-dc-view', defConfg, fileUrl,);
          })
        })
      } else {
        const canvas: any = document.querySelector('#img-element');
        canvas.src  = url
      }
    } else this.selectedDocument = null
  }

  get getFormDocuments() {
    const formRequiredFields = this.data.pushing.flowId.documents.filter(d => d.documentId.type === 'FORM')
    return formRequiredFields.map(fr => JSON.parse(fr.documentId.settings))
  }

  get getDocuments() {
    return this.data.pushing.flowId.documents.filter(d => d.documentId.type === 'DOCUMENT')
  }

  constructDataFlow() {
    const formDataFlow = this.data.pushing.flowId.documents.filter(d => d.documentId.type === 'FORM')
    const formDataIds = formDataFlow.map(fdf => fdf.documentId._id)
    const formDataItems = this.data.flowData.filter(d => formDataIds.includes( d.documentId)).map(v => JSON.parse(v.value))
    for (const formDataItem of formDataItems) {
      this.dataItems = {...formDataItem}
    }
  }

  getFormValueForm(index: string) {
    return this.dataItems[index]
  }

  getCorrectValue(options: {value: string; label: string}[], selectedValue: string) {
    return options.find(o => o.value === selectedValue )?.label ?? ''
  }

  getDocumentValue(id: string) {
    return this.data.flowData.find(d => d.documentId === id)?.value ?? ''
  }

}
