import { Component } from '@angular/core';

@Component({
  selector: 'app-worker-pulpi-advance',
  templateUrl: './worker-pulpi-advance.component.html',
  styleUrls: ['./worker-pulpi-advance.component.scss']
})
export class WorkerPulpiAdvanceComponent {

}
