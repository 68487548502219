export const ADDRESS_STATES = [
  { label: "Selecciona una opción", value: "" },
  { value: "AGUASCALIENTES", label: "AGUASCALIENTES" },
  { value: "BAJA CALIFORNIA", label: "BAJA CALIFORNIA" },
  { value: "BAJA CALIFORNIA SUR", label: "BAJA CALIFORNIA SUR" },
  { value: "CHIHUAHUA", label: "CHIHUAHUA" },
  { value: "CHIAPAS", label: "CHIAPAS" },
  { value: "CAMPECHE", label: "CAMPECHE" },
  { value: "CIUDAD DE MEXICO", label: "CIUDAD DE MEXICO" },
  { value: "COAHUILA", label: "COAHUILA" },
  { value: "COLIMA", label: "COLIMA" },
  { value: "DURANGO", label: "DURANGO" },
  { value: "GUERRERO", label: "GUERRERO" },
  { value: "GUANAJUATO", label: "GUANAJUATO" },
  { value: "HIDALGO", label: "HIDALGO" },
  { value: "JALISCO", label: "JALISCO" },
  { value: "MICHOACAN", label: "MICHOACAN" },
  { value: "ESTADO DE MEXICO", label: "ESTADO DE MEXICO" },
  { value: "MORELOS", label: "MORELOS" },
  { value: "NAYARIT", label: "NAYARIT" },
  { value: "NUEVO LEON", label: "NUEVO LEON" },
  { value: "OAXACA", label: "OAXACA" },
  { value: "PUEBLA", label: "PUEBLA" },
  { value: "QUINTANA ROO", label: "QUINTANA ROO" },
  { value: "QUERETARO", label: "QUERETARO" },
  { value: "SINALOA", label: "SINALOA" },
  { value: "SAN LUIS POTOSI", label: "SAN LUIS POTOSI" },
  { value: "SONORA", label: "SONORA" },
  { value: "TABASCO", label: "TABASCO" },
  { value: "TLAXCALA", label: "TLAXCALA" },
  { value: "TAMAULIPAS", label: "TAMAULIPAS" },
  { value: "VERACRUZ", label: "VERACRUZ" },
  { value: "YUCATAN", label: "YUCATAN" },
  { value: "ZACATECAS", label: "ZACATECAS" }
]
