import {Component, OnInit, ViewChild} from '@angular/core';
import {IApiAdmin} from "@modules/private/admin/data/interfaces/iapi-admin.interface";
import {MenuItem, MessageService, SelectItemGroup} from "primeng/api";
import {Table} from "primeng/table";
import {ApiRoleService} from "@modules/private/admin/data/services/api-role.service";
import {IApiRole} from "@modules/private/admin/data/interfaces/iapi-role.interface";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {ApiPermissionService} from "@modules/private/admin/data/services/api-permission.service";
import {IApiPermission} from "@modules/private/admin/data/interfaces/iapi-permission.interface";
import {LoaderService} from "@data/services/page/loader.service";

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit{
  roles!: IApiRole[]
  loading: boolean = true;
  cols: any[] = [
    {field: 'identifier'},
    {field: 'description'}
  ]
  items: MenuItem[] = [];
  createEntity = false
  formAction = 'CREATE'
  submitted = false
  entity!: FormGroup
  @ViewChild('dtRoles') dtAdmins!: Table;
  permissions: SelectItemGroup[] = []
  constructor(
    private apiRoleService: ApiRoleService,
    private messageService: MessageService,
    private fb: FormBuilder,
    public authService: AuthService,
    private apiPermissionService: ApiPermissionService,
    private loaderService: LoaderService
  ) {
    this.createMenu()
  }
  ngOnInit() {
    this.find()
  }

  createMenu() {
    this.items = [
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find()
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtAdmins.clear()
          this.find()
        }
      },
    ]
    if (this.authService.userHasAuthorization('ROLE', 'CREATE')) {
      this.items = [
        ...this.items,
        { separator: true },
        {
          label: 'Nuevo',
          icon: 'fa-solid fa-square-plus',
          command: () => {
            this.newEntity('CREATE')
          }
        }
      ]
    }
  }

  find() {
    this.loading = true
    this.apiRoleService.getAll().subscribe(r => {
      console.log(r)
      if (!r.error) {
        this.roles = r.data
      }
      this.loading = false
    })
  }

  newEntity(formAction: 'CREATE' | 'UPDATE', data: IApiRole | null = null) {
    this.formAction = formAction
    this.getPermissions()
    if (formAction === 'CREATE') {
      this.entity = this.fb.group({
        identifier: ['', [Validators.required]],
        description: ['', [Validators.required]],
        permissions: []
      })
    } else {
      this.entity = this.fb.group({
        id: [data?._id],
        identifier: [data?.identifier, [Validators.required]],
        description: [data?.description, [Validators.required]],
        permissions: [data?.permissions.map(p => p._id)]
      })
    }
    this.entity.clearValidators()
    this.submitted = false
    this.createEntity = true
  }

  saveRole() {
    this.loaderService.showSpinner()
    if (this.formAction === 'CREATE') {
      this.apiRoleService.create(this.entity.value).subscribe(r => {
        this.loaderService.hideSpinner()
        this.messageService.add({
          severity: r.error ? 'error' : 'success',
          summary: r.error ? 'Error' : 'Success',
          detail: r.error ? r.msg : r.msg,
          life: 5000
        });
        if (!r.error) {
          this.hideDialog()
          this.find()
        }
      })
    } else {
      this.apiRoleService.update(this.entity.value).subscribe(r => {
        this.loaderService.hideSpinner()
        this.messageService.add({
          severity: r.error ? 'error' : 'success',
          summary: r.error ? 'Error' : 'Success',
          detail: r.error ? r.msg : r.msg,
          life: 5000
        });
        if (!r.error) {
          this.hideDialog()
          this.find()
        }
      })
    }
  }

  getPermissions() {
    this.apiPermissionService.getAll().subscribe(r => {
      for (const permission of r.data) {
        const currentPermissionIndex = r.data.findIndex(p => p.identifier === permission.identifier)
        if (currentPermissionIndex && this.permissions[currentPermissionIndex]) {
          this.permissions[currentPermissionIndex].items.push({
            label: permission.description,
            value: permission._id
          })
        } else {
          this.permissions.push({
            label: permission.module,
            value: permission.module,
            items: [{
              label: permission.description,
              value: permission._id
            }]
          })
        }
      }
      console.log("aqui", this.permissions)
    })
  }

  hideDialog() {
    this.createEntity = false;
    this.submitted = false;
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value
  }
}
