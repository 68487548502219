<ng-container *ngIf="data">
    <div class="grid">
      <div class="col-12">
        <div class="main-title">
          Estatus de solicitud
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="statusValidationStatus">Estatus de validación:</label>
          <p-dropdown [(ngModel)]="statusValidationStatus" [panelStyle]="{'width': '100%' }" [options]="statusValidation" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
        </div>
      </div>
    </div>
    <ng-container *ngIf="statusValidationStatus === 'APPROVED'">
      <form [formGroup]="acceptLoan">
        <div class="grid">
          <div class="col-12">
            <div class="main-title">
              Expediente validado
            </div>
          </div>
          <div class="col-12 flex align-items-center">
            <p-inputSwitch formControlName="confirmRecord" ></p-inputSwitch>
            <span class="ml-2">He validado el expediente</span>
          </div>
          <div class="col-12">
            <div class="main-title">
              Documentos validados
            </div>
          </div>
          <div class="col-12 flex align-items-center">
            <p-inputSwitch formControlName="confirmDocuments" ></p-inputSwitch>
            <span class="ml-2">He validado los documentos</span>
          </div>
          <div class="col-12">
            <div class="main-title">
              Verificación biometrica
            </div>
          </div>
          <div class="col-12 flex align-items-center">
            <p-inputSwitch formControlName="confirmValidation" ></p-inputSwitch>
            <span class="ml-2">He validado la verificación biometrica</span>
          </div>
          <div class="col-12">
            <div class="main-title">
              Contrato
            </div>
          </div>
          <div class="col-12 flex align-items-center">
            <p-inputSwitch formControlName="confirmContract" ></p-inputSwitch>
            <span class="ml-2">He validado el contrato</span>
          </div>
          <div class="col-12">
            <div class="field field-flex">
              <label for="comment">Comentario:</label>
              <textarea rows="5" cols="30" pInputTextarea formControlName="comment" id="comment"></textarea>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="statusValidationStatus === 'REJECTED'">
      <form [formGroup]="cancelLoan">
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="field field-flex">
              <label for="rejectReason">Motivo de rechazo:</label>
              <p-dropdown formControlName="rejectReason" [panelStyle]="{'width': '100%' }" [options]="reasons" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
              <ng-container *ngIf="cancelLoan.get('rejectReason')?.invalid && (cancelLoan.get('rejectReason')?.dirty || cancelLoan.get('rejectReason')?.touched)">
                <small class="p-error" *ngIf="cancelLoan.get('rejectReason')?.errors?.['required']">Debes seleccionar una opción.</small>
              </ng-container>
            </div>
          </div>
          <div class="col-12">
            <div class="field field-flex">
              <label for="comment">Comentario:</label>
              <textarea rows="5" cols="30" pInputTextarea formControlName="comment" id="comment"></textarea>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="authService.userHasAuthorization('LOAN','UPDATE');">
      <div class="grid">
        <div class="col-12 text-right">
          <ng-container *ngIf="statusValidationStatus === 'APPROVED';">
            <button pButton pRipple label="Aprobar crédito" icon="pi pi-check" class="p-button-success" (click)="updateStatus('APPROVED')" [disabled]="acceptLoan.invalid || isUpdating || !acceptLoan.value.confirmRecord || !acceptLoan.value.confirmDocuments || !acceptLoan.value.confirmValidation || !acceptLoan.value.confirmContract"></button>
          </ng-container>
          <ng-container *ngIf="statusValidationStatus === 'REJECTED';">
            <button pButton pRipple label="Rechazar crédito" icon="fa-solid fa-times" class="p-button-danger" (click)="updateStatus('ACCEPT')" [disabled]="cancelLoan.invalid || isUpdating"></button>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
<p-toast></p-toast>
