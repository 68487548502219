<ng-container *ngIf="entity">
  <form [formGroup]="entity">
    <div class="grid px-4">
      <div class="col-12">
        <p>Asegurate de llenar todos los campos con * ya que son campos obligatorios, adicional nos ayudas llenando los campos que no son obligatorios en caso de contar con ellos.</p>
      </div>
      <div class="col-12 comment-rejected" *ngIf="values && values.comment !== ''">
        Último motivo de rechazo: <strong>{{values.comment}}</strong>
      </div>
      <div class="col-12">
        <div class="message">
          <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
        </div>
      </div>
      <ng-container *ngFor="let input of inputs">
        <ng-container [ngSwitch]="input.typeField">
          <ng-container *ngSwitchCase="'SEPARATOR'">
            <div class="col-12">
              <div class="main-title">
                {{input.title ?? ''}}
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'INPUT_TEXT'">
            <div class="col-12 md:col-6 xl:col-4">
              <div class="field field-flex">
                <label [for]="input.id">{{input.nameField}}:{{input.validations && input.validations.length > 0 ? '*' : ''}}</label>
                <input type="text" pInputText [id]="input.id" [formControlName]="input.id" [placeholder]="input.placeholder" />
                <ng-container *ngIf="entity.get(input.id)?.invalid && (entity.get(input.id)?.dirty || entity.get(input.id)?.touched)">
                  <ng-container *ngFor="let validation of input.validations">
                    <ng-container [ngSwitch]="validation.type">
                      <ng-container *ngSwitchCase="'required'">
                        <small class="p-error" *ngIf="entity.get(input.id)?.errors?.['required']">{{validation.errorMsg}}</small>
                      </ng-container>
                      <ng-container *ngSwitchCase="'pattern'">
                        <small class="p-error" *ngIf="entity.get(input.id)?.errors?.['pattern']">{{validation.errorMsg}}</small>
                      </ng-container>
                      <ng-container *ngSwitchCase="'minlength'">
                        <small class="p-error" *ngIf="entity.get(input.id)?.errors?.['minlength']">{{validation.errorMsg}}</small>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'SELECT'">
            <div class="col-12 md:col-6 xl:col-4">
              <div class="field field-flex">
                <label [for]="input.id">{{input.nameField}}:{{input.validations && input.validations.length > 0 ? '*' : ''}}</label>
                <p-dropdown [formControlName]="input.id" [panelStyle]="{'width': '100%' }" [options]="input.options" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
                <ng-container *ngIf="entity.get(input.id)?.invalid && (entity.get(input.id)?.dirty || entity.get(input.id)?.touched)">
                  <ng-container *ngFor="let validation of input.validations">
                    <ng-container [ngSwitch]="validation.type">
                      <ng-container *ngSwitchCase="'required'">
                        <small class="p-error" *ngIf="entity.get(input.id)?.errors?.['required']">{{validation.errorMsg}}</small>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'INPUT_DATE'">
            <div class="col-12 md:col-6 xl:col-4">
              <div class="field field-flex">
                <label [for]="input.id">{{input.nameField}}:{{input.validations && input.validations.length > 0 ? '*' : ''}}</label>
                <p-calendar [formControlName]="input.id" [showIcon]="true"></p-calendar>
                <ng-container *ngIf="entity.get(input.id)?.invalid && (entity.get(input.id)?.dirty || entity.get(input.id)?.touched)">
                  <ng-container *ngFor="let validation of input.validations">
                    <ng-container [ngSwitch]="validation.type">
                      <ng-container *ngSwitchCase="'required'">
                        <small class="p-error" *ngIf="entity.get(input.id)?.errors?.['required']">{{validation.errorMsg}}</small>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </form>
</ng-container>
