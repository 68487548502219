export enum LOAN_STATUS_ENUM {
  APPROVED = 'Aprobado',
  PENDING_APPROVAL = 'Pendiente de aprobación',
  DENIED = 'Denegado'
}

export enum LOAN_STATUS_ENUM_COLOR {
  APPROVED = 'success',
  PENDING_APPROVAL = 'warning',
  DENIED = 'danger'
}

export enum LOAN_INCODE_ONBOARDING_STATUS {
  ID_VALIDATION_FINISHED = 'INE Capturada',
  POST_PROCESSING_FINISHED = 'Selfi Capturada',
  FACE_VALIDATION_FINISHED = 'Validación de identidad terminada',
  ONBOARDING_FINISHED = 'Aprobado por el sistema',
  MANUAL_REVIEW_APPROVED = 'Aprobada por validación manual',
  MANUAL_REVIEW_REJECTED = 'Rechazada manualmente',
  UNKNOWN = 'Desconocido',
  EXPIRED = 'Expirada'
}

export enum LOAN_WEETRUST_STATUS {
  COMPLETED = 'Documento Firmado',
  PENDING = 'Pendiente de firma'
}

export enum BUCKET_ENUM {
  BETWEEN_0_30 = 'De 0 a 30 días',
  BETWEEN_31_60 = 'De 31 a 60 días',
  BETWEEN_61_90 = 'De 61 a 90 días',
  BETWEEN_91_120 = 'De 91 A 120 días',
  BETWEEN_121_150 = 'De 121 a 150 días',
  BETWEEN_151_180 = 'De 151 a 180 días'
}

export enum BUCKET_ENUM_COLOR {
  BETWEEN_0_30 = 'success',
  BETWEEN_31_60 = 'warning',
  BETWEEN_61_90 = 'warning',
  BETWEEN_91_120 = 'danger',
  BETWEEN_121_150 = 'danger',
  BETWEEN_151_180 = 'danger'
}

export enum TYPE_SERVICE_ENUM {
  NEW = 'Nuevo',
  REPEAT = 'Repetido'
}

export enum TYPE_SERVICE_ENUM_COLOR {
  NEW = 'primary',
  REPEAT = 'success'
}

export enum TYPE_PRODUCT_ENUM {
  "Crédito" = 'Crédito',
  "Adelanto Pulpi" = 'Adelanto Pulpi'
}

export enum TYPE_PRODUCT_ENUM_COLOR {
  "Crédito" = 'success',
  "Adelanto Pulpi" = 'primary'
}

export enum LOAN_PAYMENT_STATUS_ENUM {
  APPLIED = 'Aplicado',
  CANCELLED = 'Cancelado'
}
