<ng-container *ngIf="data && entity">
  <div class="grid background-secondary">
    <div class="col-4">
      <h3 class="mb-2">Monto de la letra (sin accesorios):</h3>
      <p>{{data.installmentAmount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto del accesorio por letra:</h3>
      <p>{{(data.installmentAccessoryAmount) | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto de la letra (con accesorios):</h3>
      <p>{{(data.installmentAmount + data.installmentAccessoryAmount) | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto del capital:</h3>
      <p>{{data.outstandingPrincipalAmount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto de intéres:</h3>
      <p>{{data.outstandingInterestAmount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Tipo de periodo:</h3>
      <p>{{data.positionId.payrollId.periodType | selectEnum:$_periodTypeEnum}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Fecha de solicitud:</h3>
      <p>{{data.creationDate | date:'dd-MM-YYYY'}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Estatus:</h3>
      <p>{{data.status | selectEnum:$_statusLoanEnum}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto de intéres total a pagar:</h3>
      <p>{{getTotalAmounts.interests | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto de capital total a pagar:</h3>
      <p>{{getTotalAmounts.capital | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto total a pagar:</h3>
      <p>{{getTotalAmounts.total | currency}}</p>
    </div>
  </div>
  <div class="col-12">
    <div class="main-title">
      Accesorios
    </div>
  </div>
  <div class="grid background-secondary mt-3">
    <div class="col-4">
      <h3 class="mb-2">Monto de capital de accesorios a financiar:</h3>
      <p>{{getAccessoryAmounts.capital | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto de interes total de accesorios a financiar:</h3>
      <p>{{getAccessoryAmounts.interests | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto total de accesorios a financiar:</h3>
      <p>{{getAccessoryAmounts.total | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto total de accesorios sin financiar:</h3>
      <p>{{data.accessoryCreation | currency}}</p>
    </div>
  </div>
  <div class="col-12">
    <div class="main-title">
      Totales
    </div>
  </div>
  <div class="grid background-secondary mt-3">
    <div class="col-4">
      <h3 class="mb-2">Monto de capital total a pagar incluyendo accesorios:</h3>
      <p>{{getResumeTotals.capital | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto de interes total a pagar incluyendo accesorios:</h3>
      <p>{{getResumeTotals.interests | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto total a pagar incluyendo accesorios:</h3>
      <p>{{getResumeTotals.total | currency}}</p>
    </div>
  </div>
  <form [formGroup]="entity">
    <div class="grid">
      <div class="col-12">
        <div class="main-title">
          Configuración del crédito
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="amount">Monto del crédito:</label>
          <p-inputNumber formControlName="amount" inputId="amount" mode="currency" currency="MXN" locale="es-MX"> </p-inputNumber>
          <ng-container *ngIf="entity.get('amount')?.invalid && (entity.get('amount')?.dirty || entity.get('amount')?.touched)">
            <small class="p-error" *ngIf="entity.get('amount')?.errors?.['required']">El monto del crédito es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('amount')?.errors?.['min']">El valor minimo es {{entity.get('amount')?.errors?.['min']['min']}}.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="installmentCount">Número de letras:</label>
          <p-inputNumber formControlName="installmentCount" inputId="installmentCount"> </p-inputNumber>
          <ng-container *ngIf="entity.get('installmentCount')?.invalid && (entity.get('installmentCount')?.dirty || entity.get('installmentCount')?.touched)">
            <small class="p-error" *ngIf="entity.get('installmentCount')?.errors?.['required']">El número de letras es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('installmentCount')?.errors?.['min']">El valor minimo de letras es {{entity.get('installmentCount')?.errors?.['min']['min']}}.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="rate">Tasa de intéres:</label>
          <input type="number" pInputText min="0" step="0.01" id="rate" formControlName="rate" placeholder="0.00" required />
          <ng-container *ngIf="entity.get('rate')?.invalid && (entity.get('rate')?.dirty || entity.get('rate')?.touched)">
            <small class="p-error" *ngIf="entity.get('rate')?.errors?.['required']">La tasa de intéres es obligatoria.</small>
            <small class="p-error" *ngIf="entity.get('rate')?.errors?.['min']">El valor minimo es {{entity.get('rate')?.errors?.['min']['min']}}.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="creationFee">Comisión por apertura:</label>
          <p-inputNumber formControlName="creationFee" inputId="creationFee" mode="currency" currency="MXN" locale="es-MX"> </p-inputNumber>
          <ng-container *ngIf="entity.get('creationFee')?.invalid && (entity.get('creationFee')?.dirty || entity.get('creationFee')?.touched)">
            <small class="p-error" *ngIf="entity.get('creationFee')?.errors?.['required']">La comisión por apertura es obligatoria.</small>
            <small class="p-error" *ngIf="entity.get('creationFee')?.errors?.['min']">El valor minimo es {{entity.get('rate')?.errors?.['min']['min']}}.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="accountNumber">Cuenta CLABE:</label>
          <input type="text" pInputText id="accountNumber" formControlName="accountNumber" placeholder="424242424242424242" required />
          <ng-container *ngIf="entity.get('accountNumber')?.invalid && (entity.get('accountNumber')?.dirty || entity.get('accountNumber')?.touched)">
            <small class="p-error" *ngIf="entity.get('accountNumber')?.errors?.['required']">El campo es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('accountNumber')?.errors?.['pattern']">La cuenta clabe debe tener 18 digitos.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="date">Fecha de dispersión:</label>
          <p-calendar formControlName="date" [showIcon]="true"></p-calendar>
          <ng-container *ngIf="entity.get('date')?.invalid && (entity.get('date')?.dirty || entity.get('date')?.touched)">
            <small class="p-error" *ngIf="entity.get('date')?.errors?.['required']">La fecha de dispersión es obligatoria.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="startDate">Fecha de exigibilidad de la primera letra:</label>
          <p-calendar formControlName="startDate" [showIcon]="true"></p-calendar>
          <ng-container *ngIf="entity.get('startDate')?.invalid && (entity.get('startDate')?.dirty || entity.get('startDate')?.touched)">
            <small class="p-error" *ngIf="entity.get('startDate')?.errors?.['required']">La fecha de dispersión es obligatoria.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="main-title">
          Accesorios Obligatorios
        </div>
      </div>
      <div class="col-12">
        <div class="grid">
          <ng-container *ngFor="let accessory of requiredAccessories">
            <div class="col-12 md:col-6 lg:col-4">
              <div class="accessory">
                <h3>{{accessory.name}}</h3>
                <ng-container *ngIf="accessory.isFinanced; else elsePrices;">
                  <p class="accessory-property">Costo: <strong>{{accessory.calculation === 'FIXED' ? (accessory.price.weekly | currency) : (accessory.price.weekly + '% sobre el monto solicitado')}} / semanal</strong></p><br>
                  <p class="accessory-property">Costo: <strong>{{accessory.calculation === 'FIXED' ? (accessory.price.biweekly | currency) : (accessory.price.biweekly + '% sobre el monto solicitado')}} / catorcenal</strong></p><br>
                  <p class="accessory-property">Costo: <strong>{{accessory.calculation === 'FIXED' ? (accessory.price.semimonthly | currency) : (accessory.price.semimonthly + '% sobre el monto solicitado')}} / quincenal</strong></p><br>
                  <p class="accessory-property">Costo: <strong>{{accessory.calculation === 'FIXED' ? (accessory.price.monthly | currency) : (accessory.price.monthly + '% sobre el monto solicitado')}} / mensual</strong></p><br>
                </ng-container>
                <ng-template #elsePrices>
                  <p class="accessory-property">Costo: <strong>{{accessory.calculation === 'FIXED' ? (accessory.price.creation | currency) : (accessory.price.creation + '% sobre el monto solicitado')}} </strong></p><br>
                </ng-template>
                <p class="accessory-property">Términos y condiciones: <strong>{{accessory.termsAndCondition === '' ? '---':accessory.termsAndCondition}}</strong></p><br>
                <p class="accessory-property-description">{{accessory.description}}</p><br>
                <p-tag [value]="accessory.isFinanced ? 'Financiado': 'No financiado'" [severity]="accessory.isFinanced ? 'danger':'success'"></p-tag>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="main-title">
          Accesorios opcionales
        </div>
      </div>
      <div class="col-12">
        <div class="field field-flex">
          <label for="optionalAccessories">Accesorios opcionales:</label>
          <p-multiSelect [options]="accessories" [group]="true" formControlName="optionalAccessories" placeholder="Selecciona los accesorios" scrollHeight="200px" display="chip">
            <ng-template let-group pTemplate="group">
              <div class="flex align-items-center">
                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'mr-2 flag flag-' + group.value" style="width: 20px" />
                <span>{{ group.label }}</span>
              </div>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
      <div class="col-12 text-right">
        <ng-container *ngIf="authService.userHasAuthorization('LOAN','UPDATE');">
          <button pButton pRipple label="Guardar y recalcular" icon="fa-solid fa-calculator" class="p-button-success" (click)="saveAndRecalculate()" [disabled]="entity.invalid || isUpdating"></button>
        </ng-container>
      </div>
      <div class="col-12">
        <div class="main-title">
          Tabla de amortización (temporal)
        </div>
      </div>
      <div class="col-12">
        <p-table [value]="installmentsCalculated" [tableStyle]="{ 'min-width': '50rem' }">
          <ng-template pTemplate="header">
            <tr>
              <th>Número de letra</th>
              <th>Monto de capital</th>
              <th>Monto del intéres</th>
              <th>Monto de accesorios</th>
              <th>Monto de intéres de accesorios</th>
              <th>Monto total</th>
              <th>Fecha de exigibilidad</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-installment>
            <tr>
              <td>{{ +installment.index + 1}}</td>
              <td>{{ installment.principalAmount | currency }}</td>
              <td>{{ installment.revenueAmount | currency }}</td>
              <td>{{ installment.accessoryPrincipalAmount | currency }}</td>
              <td>{{ installment.accessoryRevenueAmount | currency }}</td>
              <td>{{ (installment.revenueAmount + installment.principalAmount + installment.accessoryPrincipalAmount + installment.accessoryRevenueAmount) | currency }}</td>
              <td>{{ transformDate(installment.dueDate) | date: 'dd-MM-YYYY' }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </form>
</ng-container>
<p-toast></p-toast>
