<ng-container>
  <div class="col-12">
    <div class="main-title">
      Detalle de Condonación
    </div>
  </div>
  <form [formGroup]="entity">
    <div class="flex flex-column gap-2 pt-4">
      <label> Por favor, indique los montos totales por tipo de cargo que se van a condonar.</label>
      <input placeholder="Razon de condonación" pInputText id="reason" formControlName="reason" aria-describedby="username-help"/>
    </div>
    <div *ngFor="let type of writeOffTypes.eng" class="card flex flex-column md:flex-row gap-0">
      <div class="p-inputgroup md:col-3">
        <span class="p-inputgroup-addon">
          <i class="fa-solid fa-table-list"></i>
        </span>
        <input pInputText placeholder="{{this.writeOffService.traduceType(type,'ESP').toUpperCase()}}" [readonly]="true"/>
      </div>
      <div class="p-inputgroup md:col-3">
        <span class="p-inputgroup-addon">$</span>
        <p-inputNumber [min]="0" formControlName="{{type}}Amount" inputId="{{type}}Amount" mode="currency" currency="MXN" locale="es-MX" [showClear]="true"></p-inputNumber>
      </div>
    </div>
    <div class="card flex justify-content-center">
      <p-button label="Calcular condonación" (click)="writeOffCalculation(entity)" [disabled]=entity.invalid></p-button>
    </div>
  </form>
  <div *ngIf="confirmation" class="card pt-5">
    <p-table [value]="writeOffCalculationToShow.writeOffs" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template  pTemplate="header">
        <tr>
          <th>Letra</th>
          <th *ngFor="let type of writeOffTypes.esp" >Monto de {{type}}</th>
          <th *ngFor="let type of writeOffTypes.esp">{{type.charAt(0).toUpperCase() + type.slice(1)}} despues de Condonación</th>
          <th>Fecha de exigibilidad</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-writeOff>
        <tr>
          <td>{{ writeOff.index }}</td>
          <td *ngFor="let type of writeOff.debtRelief" >{{type.write=== 'N/A'? 'N/A' :(type.write + type.debtRelief | currency)}}</td>
          <td *ngFor="let type of writeOff.debtRelief">{{ type.write=== 'N/A'? 'N/A' : (type.write | currency)}}</td>
          <td>{{ writeOff.dueDate | isoDateEnum}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="grid mt-5" *ngIf="isPayrollAction">
    <div #calendarWrapper></div>
    <div class="col-12">
      <div class="main-title">
        Detalle de Pago
      </div>
      <div *ngIf="confirmation" class="card pt-5">
        <p-table [value]="installmentPayroll" dataKey="_id" editMode="row" [tableStyle]="{'min-width': '50rem'}">
          <ng-template  pTemplate="header">
            <tr>
              <th>Total a Pagar</th>
              <th>Folio del Producto</th>
              <th style="width:20%" >CLABE receptora</th>
              <th style="width:20%" >CLABE de rastreo</th>
              <th>Fecha de Cobro</th>
              <th>Desactivar validación de pago aplicado</th>
              <th>Sobre pagar</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-installment let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="installment">
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-inputNumber [(ngModel)]="installment.amount" [pKeyFilter]="'money'" [maxFractionDigits]="2"/>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{installment.amount}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>{{installment.folio}}</td>
              <td>
              <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-inputMask
                      mask="9999 9999 9999 9999 99"
                      [minlength]="18"
                      [(ngModel)]="installment.clabe"
                      [required]="true"/>
                    <small>El campo debe tener 18 caracteres.</small>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{installment.clabe}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText [(ngModel)]="installment.trackingId" (input)="validateTrackingInput($event)" [required]="true"/>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{installment.trackingId}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <div class="card flex justify-content-center">
                      <p-calendar [(ngModel)]="installment.date" [appendTo]="calendarWrapper" dateFormat="yy-mm-dd"></p-calendar>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{installment.date | isoDateEnum}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-inputSwitch [(ngModel)]="installment.skipPaymentApplied"></p-inputSwitch>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{installment.skipPaymentApplied ? 'Si' : 'No'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-inputSwitch [(ngModel)]="installment.skipOverpaid"></p-inputSwitch>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{installment.skipOverpaid ? 'Si' : 'No'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <div class="flex align-items-center justify-content-center gap-2">
                  <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(installment)" class="p-button-rounded p-button-text"></button>
                  <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(installment)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                  <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(installment, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

  </div>
</ng-container>

