import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {environment} from "@env/environment";
import {map} from "rxjs";
import {IReport} from "@modules/private/reports/data/interfaces/ireport.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiReportsService extends ApiClass{

  constructor(
  ) {
    super()
    this.path = 'report'
  }

  getReport() {
    return this.getCollection('',environment.apiUrl, 'report/sod/main' ).pipe(map((r) => r as {data: {url: string}; msg: string; error: boolean;}))
  }

  getPDLtoCIBReport(data: any) {
    return this.postCollection(data,'loan/cib' ).pipe(map((r) => r as {data: {url: string}; msg: string; error: boolean;}))
  }

  getAll() {
    return this.getCollection('' ).pipe(map((r) => r as {data: IReport[]; msg: string; error: boolean;}))
  }

  getAtrReport() {
    return this.getCollection('', environment.apiUrl, 'report/loan/atr').pipe(map((r) => r as {data: {url: string}; msg: string; error: boolean;}))
  }

  getPercentAtrReport() {
    return this.getCollection('', environment.apiUrl, 'report/loan/atr_percent').pipe(map((r) => r as {data: {url: string}; msg: string; error: boolean;}))
  }

  getCircleReport() {
    return this.getCollection('', environment.apiUrl, 'report/loan/circle').pipe(map((r) => r as {data: {url: string}; msg: string; error: boolean;}))
  }

  getEnforceabilityReport() {
    return this.getCollection('', environment.apiUrl, 'report/installment/enforceability').pipe(map((r) => r as {data: {url: string}; msg: string; error: boolean;}))
  }

  getApplicationReport() {
    return this.postCollection('','application/history').pipe(map((r) => r as {data: {url: string}; msg: string; error: boolean;}))
  }

  getIncidentsSodReport(data: any) {
    return this.postCollection(data, 'sod/incidents').pipe(map((r) => r as {data: {url: string}; msg: string; error: boolean;}))
  }

  getWorkersReport() {
    return this.getCollection('',environment.apiUrl, 'report/workers' ).pipe(map((r) => r as {data: {url: string}; msg: string; error: boolean;}))
  }
}
