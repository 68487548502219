import { Injectable } from '@angular/core';
declare global { interface Window { AdobeDC: any; } }

@Injectable({
  providedIn: 'root'
})
export class PdfServiceService {
  readyPromise: Promise<void> = new Promise((resolve) => {
    if (window.AdobeDC) {
      resolve();
    } else {
      /* Wait for Adobe Document Services PDF Embed API to be ready */
      document.addEventListener('adobe_dc_view_sdk.ready', () => {
        resolve();
      });
    }
  });
  adobeDCView: any;
  ready() {
    return this.readyPromise;
  }
  previewFile(divId: string, viewerConfig: any, fileName: string){
    const config: any = {
      /* Pass your registered client id */
      clientId: "525e16b297644a66947e2e16dac6e9f3" // we can import from environment file
    };
    if (divId) { /* Optional only for Light Box embed mode */
      /* Pass the div id in which PDF should be rendered */
      config.divId = divId;
    }
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new window.AdobeDC.View(config);
    /* Invoke the file preview API on Adobe DC View object */
    const previewFilePromise = this.adobeDCView.previewFile({
      /* Pass information on how to access the file */
      content: {
        /* Location of file where it is hosted */
        location: {
          url: fileName, // pdf file,
          /*
          If the file URL requires some additional headers, then it can be passed as follows:-
          headers: [
              {
                  key: '<HEADER_KEY>',
                  value: '6f0231b964a44bf895fa7829ff69ab67',
              }
          ]
          */
        },
      },
      /* Pass meta data of file */
      metaData: {
        /* file name */
        fileName: 'Document.pdf',
        /* file ID */
        id: 'Documento'
      }
    }, viewerConfig);
  }
}
