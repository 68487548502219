import {Component, EventEmitter, Output} from '@angular/core';
import {MenuItem} from "primeng/api";
import {AuthService} from "@data/services/modules/auth/auth.service";

@Component({
  selector: 'app-skeleton-header',
  templateUrl: './skeleton-header.component.html',
  styleUrls: ['./skeleton-header.component.scss']
})
export class SkeletonHeaderComponent {
  avatar = 'assets/media/images/avatars/ia-avatar.jpg'
  items: MenuItem[] | undefined;
  @Output() onChangeMenu = new EventEmitter()
  constructor(
    public authService: AuthService
  ) {
  }
  ngOnInit() {
    this.items = [
      /*{
        label: 'Mi perfil',
        icon: 'pi pi-fw pi-cog'
      },
      {
        separator: true
      },*/
      {
        label: 'Cerrar sesión',
        icon: 'pi pi-fw pi-power-off',
        routerLink:'logout'
      }
    ];
  }
}
