<ng-container *ngIf="data && entity">
  <form [formGroup]="entity">
    <div class="grid">
      <div class="col-12">
        <div class="main-title">
          Datos Generales
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="firstName">Nombre(s) del usuario:*</label>
          <input type="text" pInputText id="firstName" formControlName="firstName" placeholder="John" required />
          <ng-container *ngIf="entity.get('firstName')?.invalid && (entity.get('firstName')?.dirty || entity.get('firstName')?.touched)">
            <small class="p-error" *ngIf="entity.get('firstName')?.errors?.['required']">El campo es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('firstName')?.errors?.['minlength']">El campo debe tener al menos {{entity.get('firstName')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="lastName">Apellido(s) del usuario:*</label>
          <input type="text" pInputText id="lastName" formControlName="lastName" placeholder="Doe" required />
          <ng-container *ngIf="entity.get('lastName')?.invalid && (entity.get('lastName')?.dirty || entity.get('lastName')?.touched)">
            <small class="p-error" *ngIf="entity.get('lastName')?.errors?.['required']">El campo es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('lastName')?.errors?.['minlength']">El campo debe tener al menos {{entity.get('lastName')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="curp">CURP:*</label>
          <input type="text" pInputText id="curp" formControlName="curp" placeholder="XXXX900909HDFRRV01" required />
          <ng-container *ngIf="entity.get('curp')?.invalid && (entity.get('curp')?.dirty || entity.get('curp')?.touched)">
            <small class="p-error" *ngIf="entity.get('curp')?.errors?.['required']">El campo es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('curp')?.errors?.['pattern']">El valor no es un CURP válido.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="gender">Genero:*</label>
          <p-dropdown formControlName="gender" [panelStyle]="{'width': '100%' }" [options]="genders" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
          <ng-container *ngIf="entity.get('gender')?.invalid && (entity.get('gender')?.dirty || entity.get('gender')?.touched)">
            <small class="p-error" *ngIf="entity.get('gender')?.errors?.['required']">Debes seleccionar una opción.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="maritalStatus">Estado Civil:</label>
          <p-dropdown formControlName="maritalStatus" [panelStyle]="{'width': '100%' }" [options]="maritalStatus" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
          <ng-container *ngIf="entity.get('maritalStatus')?.invalid && (entity.get('maritalStatus')?.dirty || entity.get('maritalStatus')?.touched)">
            <small class="p-error" *ngIf="entity.get('maritalStatus')?.errors?.['required']">Debes seleccionar una opción.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="nationality">Nacionalidad:</label>
          <p-dropdown formControlName="nationality" [panelStyle]="{'width': '100%' }" [options]="nationalities" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
          <ng-container *ngIf="entity.get('nationality')?.invalid && (entity.get('nationality')?.dirty || entity.get('nationality')?.touched)">
            <small class="p-error" *ngIf="entity.get('nationality')?.errors?.['required']">Debes seleccionar una opción.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="main-title">
          Vivienda
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="kindHouse">Tipo de vivienda:</label>
          <p-dropdown formControlName="kindHouse" [panelStyle]="{'width': '100%' }" [options]="kindHouses" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
          <ng-container *ngIf="entity.get('kindHouse')?.invalid && (entity.get('kindHouse')?.dirty || entity.get('kindHouse')?.touched)">
            <small class="p-error" *ngIf="entity.get('kindHouse')?.errors?.['required']">Debes seleccionar una opción.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="timeHouse">Tiempo en la residencia:</label>
          <input type="text" pInputText id="timeHouse" formControlName="timeHouse" placeholder="3 años" />
          <ng-container *ngIf="entity.get('timeHouse')?.invalid && (entity.get('timeHouse')?.dirty || entity.get('timeHouse')?.touched)">
            <small class="p-error" *ngIf="entity.get('timeHouse')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="main-title">
          Datos de contacto
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="emailAddress">Correo:*</label>
          <input type="text" pInputText id="emailAddress" formControlName="emailAddress" placeholder="correo@gmail.com" required />
          <ng-container *ngIf="entity.get('emailAddress')?.invalid && (entity.get('emailAddress')?.dirty || entity.get('emailAddress')?.touched)">
            <small class="p-error" *ngIf="entity.get('emailAddress')?.errors?.['required']">El campo es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('emailAddress')?.errors?.['pattern']">El valor no es un email válido.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="phoneNumber">Número teléfonico:*</label>
          <p-inputMask mask="+999999999999" id="phoneNumber" formControlName="phoneNumber" placeholder="+52 55 3424 3312"></p-inputMask>
          <ng-container *ngIf="entity.get('phoneNumber')?.invalid && (entity.get('phoneNumber')?.dirty || entity.get('phoneNumber')?.touched)">
            <small class="p-error" *ngIf="entity.get('phoneNumber')?.errors?.['required']">El campo es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('phoneNumber')?.errors?.['pattern']">El teléfono no cumple con el formato +52 55 4323 4233.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="main-title">
          Identificación Oficial
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="typeIdentityDocument">Tipo de identificacion oficial:*</label>
          <p-dropdown formControlName="typeIdentityDocument" [panelStyle]="{'width': '100%' }" [options]="typeIdentityDocuments" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
          <ng-container *ngIf="entity.get('typeIdentityDocument')?.invalid && (entity.get('typeIdentityDocument')?.dirty || entity.get('typeIdentityDocument')?.touched)">
            <small class="p-error" *ngIf="entity.get('typeIdentityDocument')?.errors?.['required']">Debes seleccionar una opción.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="main-title">
          Tipo de empleado
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="namePosition">Nombre del puesto:*</label>
          <input type="text" pInputText id="namePosition" formControlName="namePosition" placeholder="Gerente" required />
          <ng-container *ngIf="entity.get('namePosition')?.invalid && (entity.get('namePosition')?.dirty || entity.get('namePosition')?.touched)">
            <small class="p-error" *ngIf="entity.get('namePosition')?.errors?.['required']">El campo es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('namePosition')?.errors?.['minlength']">El campo debe tener al menos {{entity.get('namePosition')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="ingressDate">Fecha de ingreso:*</label>
          <p-calendar formControlName="ingressDate" [showIcon]="true"></p-calendar>
          <ng-container *ngIf="entity.get('ingressDate')?.invalid && (entity.get('ingressDate')?.dirty || entity.get('ingressDate')?.touched)">
            <small class="p-error" *ngIf="entity.get('ingressDate')?.errors?.['required']">Debes seleccionar una opción.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="employeeNumber">Número de empleado:</label>
          <input type="text" pInputText id="employeeNumber" formControlName="employeeNumber" placeholder="1234-ABC" />
          <ng-container *ngIf="entity.get('employeeNumber')?.invalid && (entity.get('employeeNumber')?.dirty || entity.get('employeeNumber')?.touched)">
            <small class="p-error" *ngIf="entity.get('employeeNumber')?.errors?.['required']">Debes seleccionar una opción.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="main-title">
          Dirección
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="address_country">País:*</label>
          <input type="text" pInputText id="address_country" formControlName="address_country" placeholder="México" required />
          <ng-container *ngIf="entity.get('address_country')?.invalid && (entity.get('address_country')?.dirty || entity.get('address_country')?.touched)">
            <small class="p-error" *ngIf="entity.get('address_country')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="address_cp">Código postal:*</label>
          <input type="text" pInputText id="address_cp" formControlName="address_cp" placeholder="11000" required />
          <ng-container *ngIf="entity.get('address_cp')?.invalid && (entity.get('address_cp')?.dirty || entity.get('address_cp')?.touched)">
            <small class="p-error" *ngIf="entity.get('address_cp')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="address_state">Estado</label>
          <div class="flex justify-content-center flex-column">
            <p-dropdown formControlName="address_state" [panelStyle]="{'width': '100%' }" scrollHeight="250px" [options]="states" optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true" placeholder="Selecciona un usuario">
              <ng-template pTemplate="selectedItem">

                <div class="flex align-items-center" *ngIf="entity.value.address_state">
                  <div>{{getStateName(entity.value.address_state)}}</div>
                </div>
              </ng-template>
              <ng-template let-state pTemplate="item">
                <div class="flex align-items-center">
                  <div>{{ state.label }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <ng-container *ngIf="entity.get('address_state')?.invalid && (entity.get('address_state')?.dirty || entity.get('address_state')?.touched)">
              <small class="p-error" *ngIf="entity.get('address_state')?.errors?.['required']">El estado es obligatorio.</small>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
            <label for="address_city">Ciudad:*</label>
          <input type="text" pInputText id="address_city" formControlName="address_city" placeholder="Nuevo León" required />
          <ng-container *ngIf="entity.get('address_city')?.invalid && (entity.get('address_city')?.dirty || entity.get('address_city')?.touched)">
            <small class="p-error" *ngIf="entity.get('address_city')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="address_delegation">Delegación:*</label>
          <input type="text" pInputText id="address_delegation" formControlName="address_delegation" placeholder="Miguel Hidalgo" required />
          <ng-container *ngIf="entity.get('address_delegation')?.invalid && (entity.get('address_delegation')?.dirty || entity.get('address_delegation')?.touched)">
            <small class="p-error" *ngIf="entity.get('address_delegation')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="address_neighborhood">Colonia:*</label>
          <input type="text" pInputText id="address_neighborhood" formControlName="address_neighborhood" placeholder="Lomas de chapultepec" required />
          <ng-container *ngIf="entity.get('address_neighborhood')?.invalid && (entity.get('address_neighborhood')?.dirty || entity.get('address_neighborhood')?.touched)">
            <small class="p-error" *ngIf="entity.get('firstName')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="address_street">Calle:*</label>
          <input type="text" pInputText id="address_street" formControlName="address_street" placeholder="Las palmas 23" required />
          <ng-container *ngIf="entity.get('address_street')?.invalid && (entity.get('address_street')?.dirty || entity.get('address_street')?.touched)">
            <small class="p-error" *ngIf="entity.get('address_street')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="address_externalNumber">Número exterior:*</label>
          <input type="text" pInputText id="address_externalNumber" formControlName="address_externalNumber" placeholder="23" required />
          <ng-container *ngIf="entity.get('address_externalNumber')?.invalid && (entity.get('address_externalNumber')?.dirty || entity.get('address_externalNumber')?.touched)">
            <small class="p-error" *ngIf="entity.get('address_externalNumber')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="address_internalNumber">Número interior:</label>
          <input type="text" pInputText id="address_internalNumber" formControlName="address_internalNumber" placeholder="interior 12" />
          <ng-container *ngIf="entity.get('address_internalNumber')?.invalid && (entity.get('address_internalNumber')?.dirty || entity.get('address_internalNumber')?.touched)">
            <small class="p-error" *ngIf="entity.get('address_internalNumber')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="address_betweenStreets">Entre calles:</label>
          <input type="text" pInputText id="address_betweenStreets" formControlName="address_betweenStreets" placeholder="Madero y Victoria" />
          <ng-container *ngIf="entity.get('address_betweenStreets')?.invalid && (entity.get('address_betweenStreets')?.dirty || entity.get('address_betweenStreets')?.touched)">
            <small class="p-error" *ngIf="entity.get('address_betweenStreets')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="main-title">
          Información del crédito
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="loan_usage">Destino del crédito:</label>
          <p-dropdown formControlName="loan_usage" [panelStyle]="{'width': '100%' }" [options]="destinationLoanOptions" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
          <ng-container *ngIf="entity.get('loan_usage')?.invalid && (entity.get('loan_usage')?.dirty || entity.get('loan_usage')?.touched)">
            <small class="p-error" *ngIf="entity.get('loan_usage')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="loan_disposition_method">Método de disposición:*</label>
          <p-dropdown formControlName="loan_disposition_method" [panelStyle]="{'width': '100%' }" [options]="arrangementMethods" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
          <ng-container *ngIf="entity.get('loan_disposition_method')?.invalid && (entity.get('loan_disposition_method')?.dirty || entity.get('loan_disposition_method')?.touched)">
            <small class="p-error" *ngIf="entity.get('loan_disposition_method')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="loan_clabe">Cuenta CLABE:*</label>
          <input type="text" pInputText id="loan_clabe" formControlName="loan_clabe" placeholder="424242424242424242" required />
          <ng-container *ngIf="entity.get('loan_clabe')?.invalid && (entity.get('loan_clabe')?.dirty || entity.get('loan_clabe')?.touched)">
            <small class="p-error" *ngIf="entity.get('loan_clabe')?.errors?.['required']">El campo es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('loan_clabe')?.errors?.['pattern']">La cuenta clabe debe tener 18 digitos.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="main-title">
          Referencia personal
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="personal_reference_relation">Tipo de relación:</label>
          <p-dropdown formControlName="personal_reference_relation" [panelStyle]="{'width': '100%' }" [options]="personalRelations" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="personal_reference_firstName">Nombre(s):</label>
          <input type="text" pInputText id="personal_reference_firstName" formControlName="personal_reference_firstName" placeholder="John" />
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="firstName">Apellido(s):</label>
          <input type="text" pInputText id="personal_reference_lastName" formControlName="personal_reference_lastName" placeholder="Doe" />
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="personal_reference_phone">Teléfono:</label>
          <p-inputMask mask="+99 99 9999 9999" id="personal_reference_phone" formControlName="personal_reference_phone" placeholder="+52 55 3424 3312"></p-inputMask>
        </div>
      </div>
      <div class="col-12">
        <div class="main-title">
          Referencia laboral
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="work_reference_relation">Tipo de relación:</label>
          <p-dropdown formControlName="work_reference_relation" [panelStyle]="{'width': '100%' }" [options]="workRelations" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="work_reference_firstName">Nombre(s):</label>
          <input type="text" pInputText id="work_reference_firstName" formControlName="work_reference_firstName" placeholder="John" />
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="work_reference_lastName">Apellido(s):</label>
          <input type="text" pInputText id="work_reference_lastName" formControlName="work_reference_lastName" placeholder="Doe" />
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="work_reference_phone">Teléfono:</label>
          <p-inputMask mask="+99 99 9999 9999" id="work_reference_phone" formControlName="work_reference_phone" placeholder="+52 55 3424 3312"></p-inputMask>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="aval_emailAddress">Correo:</label>
          <input type="text" pInputText id="aval_emailAddress" formControlName="aval_emailAddress" placeholder="correo@pulpi.mx" />
          <ng-container *ngIf="entity.get('aval_emailAddress')?.invalid && (entity.get('aval_emailAddress')?.dirty || entity.get('aval_emailAddress')?.touched)">
            <small class="p-error" *ngIf="entity.get('aval_emailAddress')?.errors?.['required']">El campo es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('aval_emailAddress')?.errors?.['pattern']">El valor no es un email válido.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="main-title">
          Aval
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="aval_reference_relation">Tipo de relación:</label>
          <p-dropdown formControlName="aval_reference_relation" [panelStyle]="{'width': '100%' }" [options]="avalRelations" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="aval_reference_firstName">Nombre(s):</label>
          <input type="text" pInputText id="aval_reference_firstName" formControlName="aval_reference_firstName" placeholder="John" />
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="aval_reference_lastName">Apellido(s):</label>
          <input type="text" pInputText id="aval_reference_lastName" formControlName="aval_reference_lastName" placeholder="Doe" />
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="aval_reference_phone">Teléfono:</label>
          <p-inputMask mask="+99 99 9999 9999" id="aval_reference_phone" formControlName="aval_reference_phone" placeholder="+52 55 3424 3312"></p-inputMask>
        </div>
      </div>
      <div class="col-12">
        <div class="main-title">
          Dirección del Aval
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="aval_address_cp">Código postal:</label>
          <input type="text" pInputText id="aval_address_cp" formControlName="aval_address_cp" placeholder="11000" />
          <ng-container *ngIf="entity.get('aval_address_cp')?.invalid && (entity.get('aval_address_cp')?.dirty || entity.get('aval_address_cp')?.touched)">
            <small class="p-error" *ngIf="entity.get('aval_address_cp')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="aval_address_country">País:</label>
          <input type="text" pInputText id="aval_address_country" formControlName="aval_address_country" placeholder="11000" />
          <ng-container *ngIf="entity.get('aval_address_country')?.invalid && (entity.get('aval_address_country')?.dirty || entity.get('aval_address_country')?.touched)">
            <small class="p-error" *ngIf="entity.get('aval_address_country')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="aval_address_state">Estado:</label>
          <p-dropdown formControlName="aval_address_state" [panelStyle]="{'width': '100%' }" [options]="states" placeholder="Selecciona un estado" optionLabel="label" optionValue="value"/>
          <ng-container *ngIf="entity.get('aval_address_state')?.invalid && (entity.get('aval_address_state')?.dirty || entity.get('aval_address_state')?.touched)">
            <small class="p-error" *ngIf="entity.get('aval_address_state')?.errors?.['required']">El periodo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="aval_address_city">Ciudad:</label>
          <input type="text" pInputText id="aval_address_city" formControlName="aval_address_city" placeholder="Nuevo León" />
          <ng-container *ngIf="entity.get('aval_address_city')?.invalid && (entity.get('aval_address_city')?.dirty || entity.get('aval_address_city')?.touched)">
            <small class="p-error" *ngIf="entity.get('aval_address_city')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="aval_address_delegation">Delegación:</label>
          <input type="text" pInputText id="aval_address_delegation" formControlName="aval_address_delegation" placeholder="Miguel Hidalgo"  />
          <ng-container *ngIf="entity.get('aval_address_delegation')?.invalid && (entity.get('aval_address_delegation')?.dirty || entity.get('aval_address_delegation')?.touched)">
            <small class="p-error" *ngIf="entity.get('aval_address_delegation')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="aval_address_neighborhood">Colonia:</label>
          <input type="text" pInputText id="aval_address_neighborhood" formControlName="aval_address_neighborhood" placeholder="Lomas de chapultepec"  />
          <ng-container *ngIf="entity.get('aval_address_neighborhood')?.invalid && (entity.get('aval_address_neighborhood')?.dirty || entity.get('aval_address_neighborhood')?.touched)">
            <small class="p-error" *ngIf="entity.get('firstName')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="aval_address_street">Calle:</label>
          <input type="text" pInputText id="aval_address_street" formControlName="aval_address_street" placeholder="Las palmas 23"  />
          <ng-container *ngIf="entity.get('aval_address_street')?.invalid && (entity.get('aval_address_street')?.dirty || entity.get('aval_address_street')?.touched)">
            <small class="p-error" *ngIf="entity.get('aval_address_street')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="aval_address_externalNumber">Número exterior:</label>
          <input type="text" pInputText id="aval_address_externalNumber" formControlName="aval_address_externalNumber" placeholder="23"  />
          <ng-container *ngIf="entity.get('aval_address_externalNumber')?.invalid && (entity.get('aval_address_externalNumber')?.dirty || entity.get('aval_address_externalNumber')?.touched)">
            <small class="p-error" *ngIf="entity.get('aval_address_externalNumber')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="aval_address_internalNumber">Número interior:</label>
          <input type="text" pInputText id="aval_address_internalNumber" formControlName="aval_address_internalNumber" placeholder="23" />
          <ng-container *ngIf="entity.get('aval_address_internalNumber')?.invalid && (entity.get('aval_address_internalNumber')?.dirty || entity.get('aval_address_internalNumber')?.touched)">
            <small class="p-error" *ngIf="entity.get('aval_address_internalNumber')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 text-right">
        <ng-container *ngIf="authService.userHasAuthorization('LOAN','UPDATE');">
          <button pButton pRipple label="Actualizar expediente" icon="pi pi-check" class="p-button-success" (click)="updateDataLoan()" [disabled]="entity.invalid || isUpdating"></button>
        </ng-container>
      </div>
    </div>
  </form>
</ng-container>
<p-toast></p-toast>
