import {Component, Input, OnInit} from '@angular/core';
import {ApiWorkerService} from "@modules/private/workers/data/services/api/api-worker.service";
import {ActivatedRoute} from "@angular/router";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {IWorker} from "@modules/private/workers/data/interfaces/iworker.interface";
import {IPosition} from "@modules/private/workers/data/interfaces/iposition.interface";
import {IPayroll} from "@modules/private/payroll/data/interfaces/ipayroll.interface";
import {LoaderService} from "@data/services/page/loader.service";
import {ISodPropertiesInterface} from "@modules/private/workers/data/interfaces/isod-properties.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "@data/services/modules/auth/auth.service";

@Component({
  selector: 'app-detail-worker',
  templateUrl: './detail-worker.component.html',
  styleUrls: ['./detail-worker.component.scss'],
  providers: [ MessageService, ConfirmationService ]
})
export class DetailWorkerComponent implements OnInit {
  workerId = ''
  items: MenuItem[] = []
  data!: {worker: IWorker, position: IPosition, payroll: IPayroll}
  properties!: ISodPropertiesInterface
  createEntity = false
  integrateAccounts = false
  entity!: FormGroup
  selectedWorkers = []
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private apiWorkerService: ApiWorkerService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    public authService: AuthService
  ) {
    this.workerId = this.route.snapshot.paramMap.get('id') as string
  }

  ngOnInit() {
    this.getData()
  }

  createForm() {
    console.log(this.data.worker.statusVerification, "aqui estoy")
    this.entity = this.fb.group({
      firstName: [this.data.worker.names.firstName ?? '', [Validators.required]],
      lastName: [this.data.worker.names.lastName ?? '', [Validators.required]],
      sex: [this.data.worker.sex ?? '', [Validators.required]],
      birthDate: [this.data.worker.birthDate ? new Date(this.data.worker.birthDate) : ''],
      curp: [this.data.worker.curp ?? '', [Validators.required, Validators.pattern(/^[A-Z]{4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z]{6}[0-9A-Z]{2}$/)]],
      emailAddress: [this.data.worker.emailAddress ?? '', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.+-]*[a-zA-Z][a-zA-Z0-9_.+-]*@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)]],
      phoneNumber: [this.data.worker.phoneNumber ?? '', [Validators.required, Validators.pattern(/^\+52[0-9]{10}$/)]],
      status: [this.data.worker.status, [Validators.required]],
      position: [this.data.position.name, [Validators.required]],
      // clabe: ['', [Validators.required, Validators.pattern(/^\d{18}$/)]],
      payrollId: [this.data.payroll._id, [Validators.required]],
      salary: [this.data.position.salary ?? 0, [Validators.required]],
      notifyUser: [false, [Validators.required]],
      civilStatus: [this.data.worker.civilStatus ?? ''],
      nationality: [this.data.worker.nationality ?? ''],
      startDate: [this.data.position.startDate ? new Date(this.data.position.startDate) : null, [Validators.required]],
      endDate: [this.data.position.endDate ? new Date(this.data.position.endDate) : null],
      city: [this.data.worker.address.city ?? ''],
      neighborhood: [this.data.worker.address.neighborhood ?? ''],
      postalCode: [this.data.worker.address.postalCode ?? ''],
      state: [this.data.worker.address.state ?? ''],
      streetNameAndNumber: [this.data.worker.address.streetNameAndNumber ?? ''],
      statusVerification: [this.data.worker.statusVerification, [Validators.required]]
    })
  }

  hideDialog() {
    this.createEntity = false;
    this.integrateAccounts = false
  }

  getData() {
    this.apiWorkerService.getById(this.workerId).subscribe(r => {
      console.log(r)
      this.data = r.data
      const items = []
      if (this.authService.userHasAuthorization('WORKER','UPDATE')) {
         items.push({
           label: 'Actualizar',
           icon: 'fa-solid fa-pen',
           command: () => {
             this.createForm()
             this.createEntity = true
           }
         })
        if (!r.error) {
          if (r.data.worker.status === 'ACTIVE') {
            items.push({
              label: 'Desactivar',
              icon: 'fa-solid fa-user-lock',
              command: () => {
                this.changeStatusUser('DEACTIVATED')
              }
            })
          }
          else if (r.data.worker.status === 'DEACTIVATED') items.push({
            label: 'Activar',
            icon: 'fa-solid fa-user-check',
            command: () => {
              this.changeStatusUser('ACTIVE')
            }
          })
          if (['ACTIVE','DEACTIVATED'].includes(r.data.worker.status)) {
            items.push({
              label: 'Restablecer contraseña',
              icon: 'fa-solid fa-key',
              command: () => {
                this.resetPassword()
              }
            })
          }
        }
      }
      items.push({
        label: 'Integrar cuentas',
        icon: 'fa-solid fa-arrows-to-circle',
        command: () => {
          this.integrateAccounts = true
        }
      })
      this.items = items
    })
    this.apiWorkerService.getUserProperties(this.workerId).subscribe(r=> {
      console.log(r.data)
      this.properties = r.data
    })
  }

  changeStatusUser(status: 'ACTIVE' | 'DEACTIVATED') {
    this.loaderService.showSpinner()
    this.apiWorkerService.changeStatus({data: {rfc: this.data.worker.rfc, status}}).subscribe(r=> {
      this.loaderService.hideSpinner()
      setTimeout(() => {
        this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 4000});
      }, 3100)
      if (!r.error) {
        this.getData()
      }

    })
  }

  update() {
    console.log(this.entity.value)
    this.loaderService.showSpinner()
    this.apiWorkerService.update({data: {rfc: this.data.worker.rfc, ...this.entity.value}}).subscribe(r => {
      this.loaderService.hideSpinner()
      setTimeout(() => {
        this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.data.errors[0]: r.msg, life: 4000});
      }, 3100)
      if (!r.error) {
        this.getData()
        this.hideDialog()
      }
    })
  }

  resetPassword() {
    this.loaderService.showSpinner()
    this.apiWorkerService.resetPassword({emailAddress: this.data.worker.emailAddress, userId: this.data.worker._id}).subscribe(r => {
      this.loaderService.hideSpinner()
      setTimeout(() => {
        this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail:  r.msg, life: 4000});
      }, 3100)
    })
  }

  integrateCollaborators() {
    console.log(this.selectedWorkers, this.workerId)
    this.loaderService.showSpinner()
    this.apiWorkerService.integrateWorkers({workers: this.selectedWorkers.map((i: {id: string}) => i.id), originalWorker: this.workerId}).subscribe(r => {
      this.loaderService.hideSpinner()
      if (!r.error) this.hideDialog()
      setTimeout(() => {
        this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail:  r.msg, life: 4000});
      }, 3100)
    })
  }
}
