<div class="row">
  <div class="col-12">
    <p-timeline [value]="stages"  align="alternate" styleClass="customized-timeline">
      <ng-template pTemplate="content" let-stage>
        <p-card [header]="stage.title" [subheader]="stage.subtitle">
          <div class="img" style="background-image: url('assets/media/images/pushing/{{stage.img}}')"></div>
          <p>Documento efectivo por: <strong>{{stage.daysToBeEffective}} dias</strong> </p>
          <p>Estatus actual: <strong>{{stage.status | selectEnum: $_pushing_status}}</strong></p>
          <p>Inicio de proceso: <strong>{{stage.creationDate | date: 'dd-MMMM-YYYY hh:mm:ss'}}</strong></p>
          <p>Última respuesta: <strong>{{stage.lastUpdated | date: 'dd-MMMM-YYYY hh:mm:ss'}}</strong></p>
          <div *ngIf="stage.comment && stage.comment !== ''">
            <p class="mt-2">Último motivo de rechazo: </p>
            <p class="text-color-danger"><strong>{{stage.comment}}</strong></p>
          </div>

          <div>
            <p class="mb-2" *ngIf="stage.dependsOn.length > 0"><strong>Esta etapa depende de:</strong></p>
            <ng-container *ngFor="let depend of stage.dependsOn">
              <p-chip [label]="depend" [style]="{fontSize: '12px', backgroundColor: '#4e488f', color: '#fff', margin: '0 4px 4px 0'}"></p-chip>
            </ng-container>
          </div>
        </p-card>
      </ng-template>
    </p-timeline>
  </div>
</div>
