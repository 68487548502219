import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {IResponseArray} from "@data/interfaces/iresponseArray.interface";
import {environment} from "@env/environment";
import {catchError, map, of} from "rxjs";
import {IApiPaymentList, IPayment} from "@modules/private/payment/data/interfaces/ipayment.interface";
import {IInstallment} from "@modules/private/installment/data/interfaces/iinstallment.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiPaymentService extends ApiClass {
  constructor() {
    super();
    this.path = 'payment'
  }

  getAll(page = 1, limit = 10, filters = {}) {
    const response: IApiPaymentList = {error: true, msg: '', data: [], totalItems: 0, status: 200, page: 1, itemsByPage: 10}
    return this.http.post<IResponseArray>(environment.apiUrl + this.path + '/all', {page, limit, filters}).pipe(
      map((r) => r as IApiPaymentList),
      catchError((e) => of(response))
    )
  }

  getByProductId(id: string) {
    return this.getCollection(id,  environment.apiUrl, 'payment/product').pipe(map((p) => p as {error: boolean; msg: string; data: {
      payment: IPayment,
      application: {
        amountApplied: number;
        admin: string;
        dateApplication: Date;
        status: string;
      },
      installment: IInstallment
      }[]}
    ))
  }

  cancel(data: any) {
    return this.postCollection(data, 'cancel').pipe(map((p) => p as {error: boolean; msg: string; data: IPayment}
    ))
  }
}
