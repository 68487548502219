import { Component } from '@angular/core';

@Component({
  selector: 'app-detail-payer',
  templateUrl: './detail-payer.component.html',
  styleUrls: ['./detail-payer.component.scss']
})
export class DetailPayerComponent {

}
