import { Injectable } from '@angular/core';
import {map, Observable,} from "rxjs";
import {IApiCompanyList, IApiCreateCompany} from "../../interfaces/icompany.interface";
import {ApiClass} from "@data/class/api-class";

@Injectable({
  providedIn: 'root'
})
export class ApiCompanyService extends ApiClass {
  constructor() {
    super();
    this.path = 'company'
  }

  getAll() {
    return this.getCollections().pipe(map((r) => r as IApiCompanyList))
  }

  create(data: any): Observable<any> {
    return this.postCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IApiCreateCompany }))
  }

  patch(data: any): Observable<any> {
    return this.patchCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IApiCreateCompany }))
  }

}
