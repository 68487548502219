import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-create-payer',
  templateUrl: './create-payer.component.html',
  styleUrls: ['./create-payer.component.scss']
})
export class CreatePayerComponent {
  @Input() entity!: FormGroup
  @Input() submitted!: boolean
}
