export const BANKS = [
  {
    "digito": "002",
    "banco": "BANAMEX"
  },
  {
    "digito": "012",
    "banco": "BANCOMER"
  },
  {
    "digito": "014",
    "banco": "BANCO SANTANDER MEXICANO"
  },
  {
    "digito": "021",
    "banco": "HSBC (MÉXICO)"
  },
  {
    "digito": "030",
    "banco": "BANCO DEL BAJIO"
  },
  {
    "digito": "032",
    "banco": "IXE BANCO"
  },
  {
    "digito": "036",
    "banco": "BANCO INBURSA"
  },
  {
    "digito": "037",
    "banco": "BANCO INTERACCIONES"
  },
  {
    "digito": "042",
    "banco": "BANCO MIFEL"
  },
  {
    "digito": "044",
    "banco": "BANCO SCOTIABANK INVERLAT"
  },
  {
    "digito": "058",
    "banco": "BANCO REGIONAL DE MONTERREY"
  },
  {
    "digito": "059",
    "banco": "BANCO INVEX"
  },
  {
    "digito": "060",
    "banco": "BANSI"
  },
  {
    "digito": "062",
    "banco": "BANCA AFIRME"
  },
  {
    "digito": "103",
    "banco": "AMERICAN EXPRESS BANK (MEXICO)"
  },
  {
    "digito": "106",
    "banco": "BANK OF AMERICA MEXICO"
  },
  {
    "digito": "127",
    "banco": "BANCO AZTECA"
  },
  {
    "digito": "072",
    "banco": "BANORTE"
  },
  {
    "digito": "137",
    "banco": "BANCOPPEL, S.A."
  }
]
