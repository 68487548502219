<p-card>
  <div class="grid">
    <div class="col-12 mb-3">
      <h1 class="mb-3 tooltip-title"> Carga masiva de colaboradores
        <span (click)="showDialog()" class="button-circle"><i class="fa-solid fa-question"></i></span></h1>
      <p class="text-color-secondary">El apartado de la carga masiva, permite realizar varias acciones, como cargar usuarios nuevos, actualizar sueldo, nombre de puesto, dar de baja, entre otras acciones.</p>
      <p-toast></p-toast>
    </div>
    <div class="col-12">
      <p-dialog header="Header" size="small" [(visible)]="visible" [modal]="true" [style]="{ width: '50rem' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
        <ng-template pTemplate="header">
          <div class="inline-flex align-items-center justify-content-center gap-2">
            <p-avatar image="assets/media/images/avatars/ia-support.jpg" shape="circle" />
            <span class="font-bold white-space-nowrap">Soporte técnico</span>
          </div>
        </ng-template>
        <div class="grid">
          <div class="col-12 p-4">
            <p class="mb-3">El sistema permite realizar distintas acciones masivas, donde el RFC es la llave para cada accion y el Tipo de accion permitira al sistema saber como realizarla, estas acciones se describen a continuacion.</p>
            <ul>
              <li><strong>CREATE:</strong> Esta accion permite cargar nuevos colaboradores, los cuales se pueden cargar con la informacion basica: RFC, Puesto, Salario Anualizado, Fecha de ingreso en el puesto, Id de Nomina a 24 digitos, asi como permitira agregar informacion adicional misma que el layout permite.</li>
              <li><strong>UPDATE:</strong> Esta accion permitira actualizar cada informacion del usuario, desde su nombre hasta su direccion a excepcion del RFC.</li>
              <li><strong>UPDATE_SALARY:</strong> Esta accion permite cambiar el salario del usuario, por lo que es necesario el Salario anualizado. </li>
              <li><strong>UPDATE_NAME_POSITION:</strong> Esta accion permite cambiar el nombre el puesto del usuario, por lo que es necesario el Puesto. </li>
              <li><strong>MOVE_PAYROLL:</strong> Esta accion permite cambiar a una nomina distinta, por loq ue es necesario el Id de la nomina.</li>
              <li><strong>DOWN_USER:</strong> Esta accion permite dar de baja a un usuario..</li>
              <li><strong>CHANGE_STATUS:</strong> Esta accion permite cambiar a un usuario de estatus.</li>
              <li><strong>UPDATE_CONTACT_DATA: </strong>Esta accion permite actualizar los datos de contacto por lo que es necesario el Email y el Telefono.</li>
            </ul>
            <p class="my-3">A continuación se enlistan los valores aceptados por el sistema para algunos campso</p>
            <ul>
              <li>
                <strong>Estatus de usuario:</strong> ACTIVE, DEACTIVATED, PENDING_ACTIVATION
              </li>
              <li>
                <strong>Genero:</strong> FEMALE_SEX, MALE_SEX
              </li>
              <li>
                <strong>Teléfono:</strong> Debe tener el prefijo +52 y los 10 digitos del teléfono, como ejemplo: +525544478923
              </li>
              <li>
                <strong>Id de nómina: </strong>Debe ser una cadena de 24 digitos
              </li>
              <li>
                <strong>Estatus marital: </strong> SINGLE, MARRIED, WIDOWER, DIVORCED
              </li>
              <li>
                <strong>Notificar al usuario: </strong> SEND, NOT_SEND
              </li>
            </ul>
            <div>
              <a class="link-download mt-1" href="https://storage.googleapis.com/pulpi-docs/public-documents/system-docs/carga_masiva.csv" target="_blank">Descargar Formato</a>
            </div>
          </div>
        </div>

        <ng-template pTemplate="footer">
          <p-button icon="pi pi-check" (click)="visible = false" label="Ok" pAutoFocus [autofocus]="true"></p-button>
        </ng-template>
      </p-dialog>
    </div>
    <div class="col-12">
      <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false"></p-messages>
      <p-fileUpload
        [maxFileSize]="1024"
        invalidFileSizeMessageSummary="Error en el tamaño del archivo."
        invalidFileSizeMessageDetail="El archivo no debe ser mayor a 1Mb"
        chooseLabel="Seleccionar documento"
        cancelLabel="Cancelar"
        uploadLabel="Subir"
        name="uploadedFiles[]"
        [multiple]="false"
        accept=".csv"
        [maxFileSize]="1000000"
        (uploadHandler)="onUpload($event)"
        [customUpload]="true"
      >
      </p-fileUpload>
    </div>
  </div>
</p-card>
