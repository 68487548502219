import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiWorkerService} from "@modules/private/workers/data/services/api/api-worker.service";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {MessageService} from "primeng/api";
import {Router} from "@angular/router";

@Component({
  selector: 'app-create-loan',
  templateUrl: './create-loan.component.html',
  styleUrls: ['./create-loan.component.scss']
})
export class CreateLoanComponent implements OnInit {
  entity: FormGroup
  submitted = false
  workers: {label: string; value: string;}[] = []
  constructor(
    private fb: FormBuilder,
    private apiWorkerService: ApiWorkerService,
    private apiLoanService: ApiLoanService,
    private messageService: MessageService,
    private router: Router
  ) {
    this.entity = this.fb.group({
      workerId: [ '', [Validators.required, Validators.minLength(24)]],
      amount: [ 0, [Validators.required, Validators.min(500)]],
      installmentCount: [ 0, [Validators.required, Validators.min(1)]]
    })
  }

  ngOnInit() {
   this.apiWorkerService.getAllNamesAndRfcs().subscribe(r => {
     const workers: {label: string; value: string;}[] = []
     r.data.forEach(u => {
       workers.push({label: `${u.rfc} | ${u.firstName} ${u.lastName}`, value: u._id})
     })
     this.workers = workers
   })
  }

  getUserName(id: string) {
    return this.workers.find(u =>  u.value === id)?.label
  }

  saveProduct() {
    this.submitted = true
    this.apiLoanService.create(this.entity.value).subscribe((r) => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 5000 });
      if (!r.error ) {
        console.log(r.data)
        setTimeout(() => {
          this.router.navigate([`/loan/${r.data._id}`])
          this.submitted = false
        }, 2000)
      } else this.submitted = false
    })
  }
}
