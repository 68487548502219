<ng-container *ngIf="data">
  <div class="grid">
    <div class="col-12">
      <div class="main-title">
        Términos y condiciones
      </div>
    </div>
    <div class="col-12 md:col-6 xl:col-4">
      <h3 class="mb-2">TyC:</h3>
      <p>{{data.positionId.workerId.agreementId}}</p>
    </div>
    <div class="col-12">
      <div class="main-title">
        OTROS DOCUMENTOS
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="field field-flex">
        <label for="ine_anverso">Identificación oficial anverso:</label>
        <p-fileUpload id="ine_anverso" mode="basic" chooseLabel="Selecciona un documento" styleClass="p-button-secondary" [multiple]="false"  name="uploadFiles[]" accept="image/*" [maxFileSize]="1000000" (onUpload)="onUpload($event)"></p-fileUpload>
      </div>
    </div>
  </div>
</ng-container>

