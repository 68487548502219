import {Component, ViewChild} from '@angular/core';
import {IRecordsRequestLoan} from "@modules/private/loan/data/interfaces/iloan.interface";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {LOAN_COLUMNS} from "@modules/private/loan/data/columns/loanColumns.constant";
import {Table} from "primeng/table";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {DataTableService} from "@data/services/utils/data-table.service";
import {SelectEnumPipe} from "@data/pipes/enum/select-enum.pipe";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {LoaderService} from "@data/services/page/loader.service";
import {PERIOD_TYPE_ENUM} from "@modules/private/payroll/data/enums/payroll.enum";
import {LOAN_STATUS_ENUM, TYPE_PRODUCT_ENUM, TYPE_SERVICE_ENUM} from "@modules/private/loan/data/enums/loan.enum";
import {WORKER_STATUS_ENUM} from "@modules/private/workers/data/enums/workerStatus.enum";
import {ApiApplicationService} from "@modules/private/application/data/services/api-application.service";
import {IRecordRequestApplication} from "@modules/private/application/data/interfaces/application.interface";
import {APPLICATION_COLUMNS} from "@modules/private/application/constants/applicationColumns.constant";
import {AuthService} from "@data/services/modules/auth/auth.service";

interface Column {
  field: string;
  header: string;
  type: string;
}

@Component({
  selector: 'app-list-application',
  templateUrl: './list-application.component.html',
  styleUrls: ['./list-application.component.scss'],
  providers: [MessageService, ConfirmationService, SelectEnumPipe, CurrencyPipe, DatePipe]
})
export class ListApplicationComponent {
  createEntity = false
  applications!: IRecordRequestApplication[];
  totalRecords = 0
  itemsByPage = 10
  loading: boolean = true;
  items: MenuItem[] = []
  _selectedColumns!: Column[]
  columns = APPLICATION_COLUMNS
  selectedApplications!: IRecordRequestApplication[]
  @ViewChild('dtApplication') dtApplication!: Table;
  constructor(
    private apiApplicationService: ApiApplicationService,
    public dataTableService: DataTableService,
    private messageService: MessageService,
    private selectEnumPipe: SelectEnumPipe,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private loaderService: LoaderService,
    private authService: AuthService
  ) {
    this.createMenu()
  }

  createMenu() {
    if (this.authService.userHasAuthorization('APPLICATION','EXPORT')) {
      this.items.push({
        label: 'Descargar',
        icon: 'fa-solid fa-cloud-arrow-down',
        items: [
          {
            label: 'Excel',
            command: () => {
              this.exportExcel()
            }
          },
          {
            label: 'PDF',
            command: () => {
              this.exportPdf()
            }
          }
        ]
      })
    }
    this.items = [
      ...this.items,
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find()
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtApplication.clear()
          this.find()
        }
      }
    ]
    this.selectedColumns = this.columns
  }

  ngOnInit() {
    this.find()
  }

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    this._selectedColumns = this.columns.filter((col) => val.includes(col));
  }

  find(page = 0, limit = this.itemsByPage, filters = {}) {
    this.loading = true;
    this.apiApplicationService.getAll(page, limit, filters).subscribe(r => {
      console.log(r)
      this.totalRecords = r.totalItems
      this.applications = r.data
      this.loading = false
    })
  }

  getValueObject(obj: any, keys: string) {
    const keysArray = keys.split('.')
    let value: any
    for (const key in keysArray) {
      if(key === '0') {
        value = obj[keysArray[key]]
      } else {
        value = value[keysArray[key]]
      }
    }
    return value
  }

  clear(table: Table) {
    table.clear();
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value
  }

  loadApplications(e: any) {
    console.log(e)
    const currentPage = e.first / this.itemsByPage
    this.itemsByPage = e.rows
    const finalFilters = {...e.filters}
    if (e.filters.global) finalFilters['folio'] = e.filters.global
    this.find(currentPage, this.itemsByPage, finalFilters)
  }

  convertData(d: IRecordRequestApplication[]) {
    return d.map(i => {
      return {
        ...i,
        period: this.selectEnumPipe.transform(i.period, PERIOD_TYPE_ENUM),
        amountApplied: this.currencyPipe.transform(i.amountApplied),
        mainAmountPayment: this.currencyPipe.transform(i.mainAmountPayment),
        installmentCapitalAmount: this.currencyPipe.transform(i.installmentCapitalAmount),
        installmentInterestAmount: this.currencyPipe.transform(i.installmentInterestAmount),
        installmentCollectedAmount: this.currencyPipe.transform(i.installmentCollectedAmount),
        dueDate: this.datePipe.transform(i.dueDate, 'dd-MM-YYYY'),
        applicationDate: this.datePipe.transform(i.applicationDate, 'dd-MM-YYYY hh:mm:ss'),
        requestProductDate: this.datePipe.transform(i.requestProductDate, 'dd-MM-YYYY hh:mm:ss'),
        requestProductDispersion: this.datePipe.transform(i.requestProductDispersion, 'dd-MM-YYYY hh:mm:ss'),
        paymentDate: this.datePipe.transform(i.paymentDate, 'dd-MM-YYYY'),
      }
    })
  }

  exportPdf() {
    this.loaderService.showSpinner()
    this.apiApplicationService.getAll(0, 100000000, this.dtApplication.filters).subscribe(r => {
      this.dataTableService.exportPdf([
        {dataKey: 'rfc', title: 'RFC'},
        {dataKey: 'folio', title: 'Folio'},
        ...this.selectedColumns.map(sc => {
          return {
            dataKey: sc.field,
            title: sc.header
          }
        })
      ], this.convertData(r.data))
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa', life: 5000 });
    })
  }

  exportExcel() {
    this.loaderService.showSpinner()
    this.apiApplicationService.getAll(0, 100000000, this.dtApplication.filters).subscribe(r => {
      const dataConverted = this.convertData(r.data)
      this.dataTableService.exportExcel(dataConverted.map(i => {
        const response: any = {RFC: i.rfcOwner, folio: i.folio}
        for (const column of this.selectedColumns) {
          const ii = i as any
          response[column.header] = ii[column.field]
        }
        return response
      }))
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa', life: 5000 });
    })
  }

  hideDialog() {
    this.createEntity = false;
  }
}
