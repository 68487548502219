import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {map} from "rxjs";
import {IApiRole, IApiRoleList} from "@modules/private/admin/data/interfaces/iapi-role.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiRoleService extends ApiClass{

  constructor() {
    super()
    this.path = 'role'
  }

  getAll() {
    return this.getCollection().pipe(map((r) => r as IApiRoleList))
  }

  create(data:any) {
    return this.postCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IApiRole}))
  }

  update(data:any) {
    return this.putCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IApiRole}))
  }
}
