import { Component } from '@angular/core';
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-reports-detail',
  templateUrl: './reports-detail.component.html',
  styleUrls: ['./reports-detail.component.scss'],
  providers: [MessageService]
})
export class ReportsDetailComponent {
  kindReport = ''
  reports = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Reporte de SoD (Historico)', value: 'SOD_REPORT'},
    {label: 'Migración PDL a CIB', value: 'CIB_REPORT'},
    {label: 'ATR', value: 'ATR_REPORT'},
    {label: 'ATR fondeador Percent', value: 'ATR_PERCENT_REPORT'},
    {label: 'Reporte para ciculo de crédito', value: 'CIRCLE_LOAN_REPORT'},
    {label: 'Incidencias de SoD', value: 'INCIDENTS_SOD'},
    {label: 'Exigibilidad Historica', value: 'ENFORCEABILITY_REPORT'},
    {label: 'Aplicaciones Historicas', value: 'APPLICATION_HISTORY_REPORT'},
    {label: 'Reporte de colaboradores para campañas', value: 'WORKERS_CAMPAIGNS'},
  ]
}
