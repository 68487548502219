import {Component, Input, OnInit} from '@angular/core';
import {ApiWithdrawalService} from "@modules/private/withdrawal/data/services/api/api-withdrawal.service";
import {IApiWithdrawalWithInstallment} from "@modules/private/withdrawal/data/interfaces/iwithdrawal.interface";

@Component({
  selector: 'app-worker-list-sod',
  templateUrl: './worker-list-sod.component.html',
  styleUrls: ['./worker-list-sod.component.scss']
})
export class WorkerListSodComponent implements OnInit{
  @Input() workerId!: string
  withdrawals:IApiWithdrawalWithInstallment[] = []

  constructor(
    private apiWithdrawalService: ApiWithdrawalService
  ) {}

  ngOnInit() {
    this.apiWithdrawalService.getByUserId(this.workerId).subscribe(r => {
      this.withdrawals = r.data
    })
  }

  getStatusPayment (withdrawal: IApiWithdrawalWithInstallment) {
    return +withdrawal.collectedAmount >= (+withdrawal.amount + +withdrawal.fee) ? 'Pagado' : 'Pendiente'
  }
  getSeverity(status: string) {
    switch (status) {
      case 'Pagado':
        return 'success'
      case 'Pendiente':
        return 'danger'
      default:
        return 'warning'
    }
  }
}
