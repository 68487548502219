import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {map} from "rxjs";
import {IOffer} from "@modules/private/pushing/data/interfaces/ioffer.interface";

@Injectable({
  providedIn: 'root'
})
export class OfferService extends ApiClass{

  constructor() {
    super()
    this.path = 'offer'
  }

  getBestOffers(data: any) {
    return this.postCollection(data, 'best').pipe(map((r) => r as {error: boolean; msg: string; data: IOffer}))
  }
}
