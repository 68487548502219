<section>
  <div class="menu">
    <i class="pi pi-bars" style="font-size: 2rem" (click)="onChangeMenu.emit()"></i>
    <img src="https://pulpi.mx/assets/media/logo.png" alt="">
   </div>
  <div class="actions">
    <div #avatarmenu class="avatar" [style]="'background-image: url('+avatar+')'"></div>
    <div class="info">
      <h5>{{authService.getUser?.user?.names?.firstName ?? ''}} {{authService.getUser?.user?.names?.lastName ?? ''}}</h5>
      <h6>{{authService.getUser?.user?.emailAddress ?? ''}}</h6>
    </div>
    <p-contextMenu [target]="avatarmenu" [model]="items" [triggerEvent]="'click'"></p-contextMenu>
  </div>
</section>
