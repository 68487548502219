<p-card>
  <div class="grid">
    <div class="col-12">
      <h1 class="mb-3 tooltip-title">Asignación masiva <span (click)="showDialog()" class="button-circle"><i class="fa-solid fa-question"></i></span></h1>
      <p class="mb-4">Este apartado permite la asignación masiva de recursos dentro de la plataforma, lo que limita el acceso a los recursos a nivel registro.</p>
      <p-toast></p-toast>
    </div>
    <div class="col-12 xl:col-4">
      <div class="field field-flex">
        <label for="adminId">Administrador(*):</label>
        <p-dropdown id="adminId" [(ngModel)]="adminId" [panelStyle]="{'width': '100%' }" [options]="admins" placeholder="Selecciona una opción" optionLabel="label" optionValue="value" />
      </div>
    </div>
    <div class="col-12 xl:col-4">
      <div class="field field-flex">
        <label for="typeProduct">Modulo(*):</label>
        <p-dropdown id="typeProduct" [(ngModel)]="typeModule" [panelStyle]="{'width': '100%' }" [options]="typeProductOptions" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
      </div>
    </div>
    <div class="col-12">
      <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false"></p-messages>
      <ng-container *ngIf="!isUploading; else elseLoading">
        <p-fileUpload
          [maxFileSize]="1024"
          invalidFileSizeMessageSummary="Error en el tamaño del archivo."
          invalidFileSizeMessageDetail="El archivo no debe ser mayor a 1Mb"
          chooseLabel="Seleccionar documento"
          cancelLabel="Cancelar"
          uploadLabel="Subir"
          name="uploadedFiles[]"
          [multiple]="false"
          accept=".csv"
          [maxFileSize]="1000000"
          (uploadHandler)="onUpload($event)"
          [customUpload]="true"
        >
        </p-fileUpload>
      </ng-container>
      <ng-template #elseLoading>
        <div class="loading-application-container w-100 text-center">
          <p class="mb-2">Realizando la asinación masiva...</p>
          <p-progressSpinner></p-progressSpinner>
        </div>
      </ng-template>
    </div>
  </div>
</p-card>

<p-dialog header="Header" size="small" [(visible)]="visible" [modal]="true" [style]="{ width: '50rem' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2">
      <p-avatar image="assets/media/images/avatars/ia-support.jpg" shape="circle" />
      <span class="font-bold white-space-nowrap">Soporte técnico</span>
    </div>
  </ng-template>
  <div class="grid">
    <div class="col-12 p-4">
      <p class="mb-3">El sistema permite realizar una asignación masiva de distintas entidades dentro de  la plataforma, lo que permite restringir el acceso a nivel de entidad.</p>
      <ul>
        <li><strong>Crédito: </strong>Al asignarle créditos a un administrador, este debe tener el permiso del modulo, y aun teniendo el permiso solo podra visualizar los elementos correspondientes que se le asignaron a travez del método, es importante mencionar que al realizar la asignación de elementos de forma masiva, se eliminan aquellos créditos que no esten en la nueva asignación, es decir si se desea asignarle créditos adicionales, lo ideal es agregar el listado completo de los folios a asignar, ya que las asignaciones pasadas quedan eliminados..</li>

      </ul>
      <div class="mt-2">
        <a class="link-download mt-1" href="assets/documents/asignacion_masiva.csv" target="_blank">Descargar Formato</a>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="visible = false" label="Ok" pAutoFocus [autofocus]="true"></p-button>
  </ng-template>
</p-dialog>
