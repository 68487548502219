import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {map, Observable} from "rxjs";
import {IApiPayrollList} from "@modules/private/payroll/data/interfaces/ipayroll.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiPayrollService extends ApiClass {
  constructor() {
    super();
    this.path = 'payroll'
  }

  getAll() {
    return this.getCollections().pipe(map((r) => r as IApiPayrollList))
  }

  create(data: any): Observable<any> {
    return this.postCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IApiPayrollList }))
  }

  patch(data: any): Observable<any> {
    return this.patchCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IApiPayrollList }))
  }

  getNames() {
    return this.getCollections().pipe(map((r) => r as {error: boolean; msg: string; data: {_id: string, name: string}[]}))
  }
}
