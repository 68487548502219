import { Component } from '@angular/core';
import {MenuItem} from "primeng/api";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {MENU_AUTHORIZATION} from "./constants/menu.constant";

@Component({
  selector: 'app-skeleton-leftnav',
  templateUrl: './skeleton-leftnav.component.html',
  styleUrls: ['./skeleton-leftnav.component.scss']
})
export class SkeletonLeftnavComponent {

  items: MenuItem[] = [];
  itemsAuthorization = MENU_AUTHORIZATION
  constructor(
    private authService: AuthService
  ) {
  }
  ngOnInit() {
    this.items = [
      {
        label: 'Home',
        expanded: true,
        items: [
          {
            label: 'Dashboard',
            icon: 'fa-solid fa-home',
            routerLink: '/'
          }
        ]
      }
    ]
    for (const item of this.itemsAuthorization) {
      const mainModule: {
        label: string;
        expanded: boolean;
        items: {
          label: string;
          icon: string;
          routerLink: string;
        }[]
      } = {
        label: item.title,
        expanded: true,
        items: []
      }
      for (const module of item.modules) {
        if (this.authService.userHasAuthorization(module.module, module.permission)) {
          mainModule.items.push({
            label: module.label,
            icon: module.icon,
            routerLink: module.routerLink
          })
        }
      }
      if (mainModule.items.length > 0) this.items.push(mainModule)
    }
    /*this.items = [
      {
        label: 'Empresas',
        expanded: true,
        items: [
          {
            label: 'Corporativos',
            icon: 'fa-solid fa-building',
            routerLink: '/company'
          },
          {
            label: 'Pagadoras',
            icon: 'fa-solid fa-money-bills',
            routerLink: '/payer'
          },
          {
            label: 'Convenios',
            icon: 'fa-solid fa-receipt',
            routerLink: '/agreement'
          },
          {
            label: 'Nominas',
            icon: 'fa-solid fa-users',
            routerLink: '/payroll'
          }
        ]
      },
      {
        label: 'Colaboradores',
        icon: 'pi pi-fw pi-pencil',
        items: [
          {
            label: 'Listado',
            icon: 'fa-solid fa-user-tie',
            routerLink: '/worker'
          },
          {
            label: 'Carga masiva',
            icon: 'fa-solid fa-users-gear',
            routerLink: '/worker/massive'
          }
        ]
      },
      {
        label: 'Productos',
        icon: 'pi pi-fw pi-user',
        items: [
          {
            label: 'SoD',
            icon: 'fa-solid fa-money-bill-transfer',
            routerLink: '/withdrawal'
          },
          {
            label: 'Créditos',
            icon: 'fa-solid fa-landmark',
            routerLink: '/loan'
          }
        ]
      },
      {
        label: 'Cobranza',
        items: [
          {
            label: 'Pagos',
            icon: 'fa-solid fa-file-invoice',
            routerLink: '/payment'
          },
          {
            label: 'Letras',
            icon: 'fa-solid fa-file-invoice-dollar',
            routerLink: '/installment'
          },
          {
            label: 'Aplicaciones',
            icon: 'fa-solid fa-layer-group',
            routerLink: '/application'
          },
          {
            label: 'Aplicacion masiva',
            icon: 'fa-solid fa-cash-register',
            routerLink: '/application/apply'
          },
        ]
      },
      {
        label: 'Miscelaneos',
        items: [
          {
            label: 'Reportes',
            icon: 'fa-solid fa-book',
            routerLink: '/reports'
          },
          {
            label: 'Omnicanal',
            icon: 'fa-solid fa-bullhorn',
            routerLink: '/omnicanal'
          }
        ]
      },
      {
        label: 'Configuración',
        icon: 'pi pi-fw pi-calendar',
        items: [
          {
            label: 'Administradores',
            icon: 'fa-solid fa-user-lock'
          },
          {
            label: 'Sistema',
            icon: 'fa-solid fa-gear',
            routerLink: 'platform-settings'
          }
        ]
      }
    ];*/
  }
}
