<ng-container *ngIf="entity">
  <p-card>
    <div class="grid">
      <div class="col-12 mb-3">
        <h1 class="mb-3">Configuraciones de la plataforma</h1>
        <p class="text-color-secondary">
         En este apartado podras modificar las configuraciones de la plataforma, con el objetivo de bloquear o desbloquear productos y/o servicios para los usuarios y/o administradores.
        </p>
      </div>
    </div>
    <form [formGroup]="entity">
      <div class="grid">
        <div class="col-12 md:col-6">
          <div class="field flex flex-column">
            <label for="sodIsActive">Activar retiros en la plataforma:</label>
            <p-inputSwitch id="sodIsActive" formControlName="sodIsActive"></p-inputSwitch>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field flex flex-column">
            <label for="loanIsActive">Activar créditos en la plataforma:</label>
            <p-inputSwitch id="loanIsActive" formControlName="loanIsActive"></p-inputSwitch>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field flex flex-column">
            <label for="smsIsActive">Activar envío de sms en la platafoma:</label>
            <p-inputSwitch id="smsIsActive" formControlName="smsIsActive"></p-inputSwitch>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field flex flex-column">
            <label for="whatsappIsActive">Activar envío de whatsapp en la platafoma:</label>
            <p-inputSwitch id="whatsappIsActive" formControlName="whatsappIsActive"></p-inputSwitch>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field flex flex-column">
            <label for="emailIsActive">Activar envío de email en la platafoma:</label>
            <p-inputSwitch id="emailIsActive" formControlName="emailIsActive"></p-inputSwitch>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field flex flex-column">
            <label for="pushNotificationsIsActive">Activar envío de push notifications:</label>
            <p-inputSwitch id="pushNotificationsIsActive" formControlName="pushNotificationsIsActive"></p-inputSwitch>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field flex flex-column">
            <label for="hubspotNotificationIsActive">Activar notificación a hubspot:</label>
            <p-inputSwitch id="hubspotNotificationIsActive" formControlName="hubspotNotificationIsActive"></p-inputSwitch>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field flex flex-column">
            <label for="requestCEPIsActive">Activar consulta de CEP de Movimientos:</label>
            <p-inputSwitch id="requestCEPIsActive" formControlName="requestCEPIsActive"></p-inputSwitch>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field flex flex-column">
            <label for="maintenanceIsActive">Activar mantenimiento del sistema:</label>
            <p-inputSwitch id="maintenanceIsActive" formControlName="maintenanceIsActive"></p-inputSwitch>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field flex flex-column">
            <label for="forceLastUpdateInApp">Forzar tener el app actualizada:</label>
            <p-inputSwitch id="forceLastUpdateInApp" formControlName="forceLastUpdateInApp"></p-inputSwitch>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field field-flex">
            <label for="androidLastVersion">Versión de Android:</label>
            <input type="text" pInputText id="androidLastVersion" formControlName="androidLastVersion" placeholder="4.3.1" required />
            <ng-container *ngIf="entity.get('androidLastVersion')?.invalid && (entity.get('androidLastVersion')?.dirty || entity.get('androidLastVersion')?.touched)">
              <small class="p-error" *ngIf="entity.get('androidLastVersion')?.errors?.['required']">La versión es obligatoria.</small>
              <small class="p-error" *ngIf="entity.get('androidLastVersion')?.errors?.['minlength'] || entity.get('androidLastVersion')?.errors?.['maxlength']">El campo debe tener 5 caracteres.</small>
            </ng-container>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field field-flex">
            <label for="iosLastVersion">Versión de IOS:</label>
            <input type="text" pInputText id="iosLastVersion" formControlName="iosLastVersion" placeholder="4.3.1" required />
            <ng-container *ngIf="entity.get('iosLastVersion')?.invalid && (entity.get('iosLastVersion')?.dirty || entity.get('iosLastVersion')?.touched)">
              <small class="p-error" *ngIf="entity.get('iosLastVersion')?.errors?.['required']">La versión es obligatoria.</small>
              <small class="p-error" *ngIf="entity.get('iosLastVersion')?.errors?.['minlength'] || entity.get('iosLastVersion')?.errors?.['maxlength']">El campo debe tener 5 caracteres.</small>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="authService.userHasAuthorization('SYSTEM','UPDATE');">
          <div class="col-12 text-right">
            <button pButton pRipple label="Actualizar configuración" icon="pi pi-check" class="p-button-success" (click)="updateSettings()" [disabled]="entity.invalid || isUpdating"></button>
          </div>
        </ng-container>
      </div>
    </form>
  </p-card>
</ng-container>
<p-toast></p-toast>
