import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {map, Observable} from "rxjs";
import {IPeriod} from "@modules/private/payroll/data/interfaces/iperiod.interface";
import {environment} from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiPeriodService extends ApiClass {

  constructor() {
    super()
    this.path = 'period'
  }

  getAll(id: string) {
    return this.getCollection(id,  environment.apiUrl, 'period/payroll', 'all').pipe(map((r) => r as {error: boolean; msg: string; data: IPeriod[] }))
  }

  create(data: any): Observable<any> {
    return this.postCollection({data}).pipe(map((r) => r as {error: boolean; msg: string; data: IPeriod }))
  }

  update(data: any): Observable<any> {
    return this.putCollection({data}).pipe(map((r) => r as {error: boolean; msg: string; data: IPeriod }))
  }

  delete(id: string): Observable<any> {
    return this.deleteCollection(id).pipe(map((r) => r as {error: boolean; msg: string; data: IPeriod }))
  }
}
