import {Component, OnInit, ViewChild} from '@angular/core';
import {IApiAdmin} from "@modules/private/admin/data/interfaces/iapi-admin.interface";
import {MenuItem, MessageService} from "primeng/api";
import {Table} from "primeng/table";
import {ApiAdminService} from "@modules/private/admin/data/services/api-admin.service";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "@data/services/page/loader.service";

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.scss']
})
export class AdminListComponent implements OnInit{
  admins!: IApiAdmin[]
  loading: boolean = true;
  cols: any[] = [
    {field: 'names'},
    {field: 'creationDate'}
  ]
  items: MenuItem[] = [];
  @ViewChild('dtAdmins') dtAdmins!: Table;
  createEntity = false
  formAction = 'CREATE'
  submitted = false
  entity!: FormGroup
  constructor(
    private apiAdminService: ApiAdminService,
    private messageService: MessageService,
    public authService: AuthService,
    private fb: FormBuilder,
    private loaderService: LoaderService
  ) {
    this.createMenu()
  }
  ngOnInit() {
    this.find()
  }

  createMenu() {
    this.items = [
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find()
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtAdmins.clear()
          this.find()
        }
      },
    ]
    if (this.authService.userHasAuthorization('ADMIN', 'CREATE')) {
      this.items = [
        ...this.items,
        { separator: true },
        {
          label: 'Nuevo',
          icon: 'fa-solid fa-square-plus',
          command: () => {
            this.newEntity('CREATE')
          }
        }
      ]
    }
  }

  find() {
    this.loading = true
    this.apiAdminService.getAll().subscribe(r => {
      console.log(r)
      if (!r.error) {
        this.admins = r.data
      }
      this.loading = false
    })
  }

  newEntity(formAction: 'CREATE' | 'UPDATE', data: IApiAdmin | null = null) {
    this.formAction = formAction
    if (formAction === 'CREATE') {
      this.entity = this.fb.group({
        emailAddress: ['', [Validators.required]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        role: ['', [Validators.required]],
      })
    } else {
      console.log(data?.role)
      this.entity = this.fb.group({
        id: [data?._id],
        emailAddress: [data?.emailAddress, [Validators.required]],
        firstName: [data?.names.firstName, [Validators.required]],
        lastName: [data?.names.lastName, [Validators.required]],
        role: [data?.role._id, [Validators.required]],
      })
    }
    this.entity.clearValidators()
    this.submitted = false
    this.createEntity = true
  }

  saveAdmin() {
    this.loaderService.showSpinner()
    if (this.formAction === 'CREATE') {
      this.apiAdminService.create(this.entity.value).subscribe(r => {
        this.loaderService.hideSpinner()
        this.messageService.add({
          severity: r.error ? 'error' : 'success',
          summary: r.error ? 'Error' : 'Success',
          detail: r.error ? r.msg : r.msg,
          life: 5000
        });
        if (!r.error) {
          this.hideDialog()
          this.find()
        }
      })
    } else {
      this.apiAdminService.update(this.entity.value).subscribe(r => {
        this.loaderService.hideSpinner()
        this.messageService.add({
          severity: r.error ? 'error' : 'success',
          summary: r.error ? 'Error' : 'Success',
          detail: r.error ? r.msg : r.msg,
          life: 5000
        });
        if (!r.error) {
          this.hideDialog()
          this.find()
        }
      })
    }
  }

  hideDialog() {
    this.createEntity = false;
    this.submitted = false;
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value
  }
}
