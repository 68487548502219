import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {SelectItemGroup} from "primeng/api";

interface City {
  name: string,
  code: string
}

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss']
})
export class RoleFormComponent {
  @Input() entity!: FormGroup
  @Input() submitted!: boolean
  @Input() permissions: SelectItemGroup[] = []

}
