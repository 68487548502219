<p-card>
  <div class="row">
    <div class="col-12">
      <h1 class="mb-3">Créditos</h1>
      <p class="mb-3 text-color-secondary">En este apartado se encuentran todos los créditos y adelantos pulpi, podras identificar los créditos aprobados, pendientes de aprobación y los rechazados. </p>
    </div>
    <p-toast></p-toast>
  </div>
  <p-table
    #dtLoans
    [scrollable]="true"
    scrollHeight="600px"
    [value]="loans"
    [lazy]="true"
    (onLazyLoad)="loadLoans($event)"
    [columns]="selectedColumns"
    dataKey="_id"
    [rows]="itemsByPage"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [loading]="loading"
    [paginator]="true"
    [tableStyle]="{'width': '100%'}"
    [(selection)]="selectedLoans"
    [rowHover]="true"
    [totalRecords]="totalRecords"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} créditos"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-row justify-content-between">
        <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
        <div>
          <p-multiSelect [options]="columns" [(ngModel)]="selectedColumns" optionLabel="header" selectedItemsLabel="{0} columnas seleccionadas" [style]="{'min-width': '200px'}" placeholder="Escoge las columnas"></p-multiSelect>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th pFrozenColumn></th>
        <th style="min-width: 15rem;" pFrozenColumn>
          <p-columnFilter type="text" field="rfc"></p-columnFilter>
        </th>
        <th style="min-width: 15rem;">
          <p-columnFilter type="text" field="folio"></p-columnFilter>
        </th>
        <ng-container *ngFor="let col of columns">
          <th  style="min-width: 18rem">
            <div class="flex align-items-center" >
            <ng-container *ngIf="col.showFilter">
              <ng-container [ngSwitch]="col.type">
                <ng-container *ngSwitchCase="'numeric'">
                  <p-columnFilter type="numeric" [field]="col.field" [currency]="'MXN'"></p-columnFilter>
                </ng-container>
                <ng-container *ngSwitchCase="'enum'">
                  <div class="flex align-items-center" pSortableColumn="periodType">
                    <p-columnFilter [field]="col.field" matchMode="equals" >
                      <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-dropdown [ngModel]="value" [options]="col.options" (onChange)="filter($event.value)" placeholder="Cualquiera">
                          <ng-template let-option pTemplate="item">
                            <p-tag [value]="option.label" [severity]="option.value | selectEnum: col.enumColor"></p-tag>
                          </ng-template>
                        </p-dropdown>
                      </ng-template>
                    </p-columnFilter>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'boolean'">
                  <p-columnFilter type="boolean" [field]="col.field"></p-columnFilter>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <p-columnFilter [type]="col.type" [field]="col.field"></p-columnFilter>
                </ng-container>
              </ng-container>
            </ng-container>
              <p-sortIcon [field]="col.field" [pSortableColumn]="col.field" [class]="'px-2 py-2'"></p-sortIcon>
            </div>
          </th>
        </ng-container>
      </tr>
      <tr>
        <th style="padding-left: 15px;" pFrozenColumn>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pFrozenColumn>
          RFC
        </th>
        <th>
          Folio
        </th>
        <ng-container *ngFor="let col of columns">
          <th  style="min-width: 15rem">
            {{col.header}}
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item let-columns="columns">
      <tr>
        <td pFrozenColumn>
          <p-tableCheckbox [value]="item"></p-tableCheckbox>
        </td>
        <td pFrozenColumn>
          <ng-container *ngIf="authService.userHasAuthorization('WORKER','READ_DETAIL'); else rfcDetail">
            <a class="link-subsection" routerLink="{{'/worker/'+item.workerId}}">{{item.rfc}}</a>
          </ng-container>
          <ng-template #rfcDetail>
            {{item.rfc}}
          </ng-template>
        </td>
        <td>
          <ng-container *ngIf="authService.userHasAuthorization('LOAN','READ_DETAIL'); else folioDetail">
            <a class="link-subsection" routerLink="{{'/loan/'+item._id}}">{{item.folio}}</a>
          </ng-container>
          <ng-template #folioDetail>
            {{item.folio}}
          </ng-template>
        </td>
        <td *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.dataType">
            <ng-container *ngSwitchCase="'currency'">
              {{getValueObject(item, col.field) | currency}}
            </ng-container>
            <ng-container *ngSwitchCase="'date'">
              {{getValueObject(item, col.field) | date: 'dd-MM-yyyy'}}
            </ng-container>
            <ng-container *ngSwitchCase="'percent'">
              {{getValueObject(item, col.field) | percent}}
            </ng-container>
            <ng-container *ngSwitchCase="'link'">
              <a class="link-subsection" routerLink="{{'/worker/'+getValueObject(item, col.linkField)}}">{{getValueObject(item, col.field)}}</a>
            </ng-container>
            <ng-container *ngSwitchCase="'enum'">
              <ng-container *ngIf="getValueObject(item, col.field) && getValueObject(item, col.field) !== ''; else elseEnum ">
                <p-tag [value]="getValueObject(item, col.field) | selectEnum:col.enum" [severity]="getValueObject(item, col.field) | selectEnum:col.enumColor"></p-tag>
              </ng-container>
              <ng-template #elseEnum>
                --
              </ng-template>
            </ng-container>
            <ng-container *ngSwitchCase="'boolean'">
              <i class="pi" [ngClass]="{ 'text-green-500 pi-check-circle': getValueObject(item, col.field), 'text-red-500 pi-times-circle': !getValueObject(item, col.field) }"></i>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{getValueObject(item, col.field)}}
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">No se encontraron créditos</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
<!------------------------CREATE LOAN ---------------------------->
<p-dialog [(visible)]="createEntity" [style]="{ width: '50vw' }" [header]="'Nuevo crédito'" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <app-create-loan #createLoan ></app-create-loan>
    <div class="row">
      <div class="col-12 flex justify-content-end flex-direction-row">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="createLoan.saveProduct()" [disabled]="!createLoan.entity.valid || createLoan.submitted"></button>
      </div>
    </div>
  </ng-template>
 <!-- <ng-template pTemplate="footer">

  </ng-template>-->
</p-dialog>
<p-dialog [(visible)]="assignLoans" [style]="{ width: '75vw' }" [header]="'Asignar créditos'" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <app-loan-assignment #loanAssignment></app-loan-assignment>
    <div class="row">
      <div class="col-12 flex justify-content-end flex-direction-row">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="loanAssignment.assign()" [disabled]="!loanAssignment.entity.valid"></button>
      </div>
    </div>
  </ng-template>
  <!-- <ng-template pTemplate="footer">

   </ng-template>-->
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
