import { Component } from '@angular/core';

@Component({
  selector: 'app-worker-list-payment',
  templateUrl: './worker-list-payment.component.html',
  styleUrls: ['./worker-list-payment.component.scss']
})
export class WorkerListPaymentComponent {

}
