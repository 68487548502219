import {ITableCol} from "@data/interfaces/itable.interface";
import {TYPE_PERIOD_ENUM, TYPE_PERIOD_ENUM_COLOR} from "@modules/private/company/data/enums/typePeriods.enum";
import {
  WORKER_STATUS_ENUM,
  WORKER_STATUS_ENUM_COLOR,
  WORKER_STATUS_VERIFICATION_ENUM, WORKER_STATUS_VERIFICATION_ENUM_COLOR
} from "@modules/private/workers/data/enums/workerStatus.enum";
import {TYPE_PRODUCT_ENUM, TYPE_PRODUCT_ENUM_COLOR} from "@modules/private/loan/data/enums/loan.enum";
import {
  TYPE_PRODUCT_INSTALLMENTS_ENUM,
  TYPE_PRODUCT_INSTALLMENTS_ENUM_COLOR
} from "@modules/private/installment/data/enum/typeProducts.enum";

export const INSTALLMENT_COLUMNS : ITableCol[] = [
  {type: 'text', field: 'firstName', header: 'Nombre(s)', showFilter: true},
  {type: 'text', field: 'lastName', header: 'Apellidos', showFilter: true},
  {type: 'text', field: 'fullName', header: 'Nombre completo', showFilter: true},
  {type: 'text', field: 'curp', header: 'CURP', showFilter: true},
  {type: 'text', field: 'emailAddress', header: 'Email', showFilter: true},
  {type: 'text', field: 'phoneNumber', header: 'Teléfono', showFilter: true},
  {type: 'date', field: 'dueDate', header: 'Fecha de exigibilidad', dataType: 'date', showFilter: true},
  {type: 'date', field: 'requestProductDate', header: 'Fecha de solicitud', dataType: 'date', showFilter: true},
  {type: 'date', field: 'dispersionProductDate', header: 'Fecha de dispersión', dataType: 'date', showFilter: true},
  {type: 'enum', field: 'productType', header: 'Tipo de producto', showFilter: true, dataType: 'enum', enum: TYPE_PRODUCT_INSTALLMENTS_ENUM, enumColor: TYPE_PRODUCT_INSTALLMENTS_ENUM_COLOR, options: [
      {value: 'WITHDRAWAL', label: 'Retiro'},
      {value: 'LOAN', label: 'Crédito'},
    ]},
  {type: 'numeric', field: 'index', header: 'Número de la letra', showFilter: true},
  {type: 'numeric', field: 'principalAmount', header: 'Monto de capital de la letra', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'revenueAmount', header: 'Monto de intéres de la letra', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'collectedInterestAmount', header: 'Monto cobrado de intéres', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'collectedPrincipalAmount', header: 'Monto cobrado de capital', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'collectedAmount', header: 'Monto total cobrado', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'percentInstallmentPaid', header: 'Porcentaje de letra pagado', dataType: 'percent', showFilter: true},
  {type: 'numeric', field: 'percentApplicationPaid', header: 'Porcentaje de aplicación pagada', dataType: 'percent', showFilter: true},
  {type: 'numeric', field: 'applicationAmount1', header: 'Monto de aplicación 1', dataType: 'currency', showFilter: true},
  {type: 'date', field: 'applicationDate1', header: 'Fecha de aplicación 1', dataType: 'date', showFilter: true},
  {type: 'date', field: 'applicationPaymentDate1', header: 'Fecha del pago de la aplicación 1', dataType: 'date', showFilter: true},
  {type: 'numeric', field: 'applicationPaymentOriginalAmount1', header: 'Monto original del pago asociado a la aplicación 1', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'applicationAmount2', header: 'Monto de aplicación 2', dataType: 'currency', showFilter: true},
  {type: 'date', field: 'applicationDate2', header: 'Fecha de aplicación 2', dataType: 'date', showFilter: true},
  {type: 'date', field: 'applicationPaymentDate2', header: 'Fecha del pago de la aplicación 2', dataType: 'date', showFilter: true},
  {type: 'numeric', field: 'applicationPaymentOriginalAmount2', header: 'Monto original del pago asociado a la aplicación 2', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'applicationAmount3', header: 'Monto de aplicación 3', dataType: 'currency', showFilter: true},
  {type: 'date', field: 'applicationDate3', header: 'Fecha de aplicación 3', dataType: 'date', showFilter: true},
  {type: 'date', field: 'applicationPaymentDate3', header: 'Fecha del pago de la aplicación 3', dataType: 'date', showFilter: true},
  {type: 'numeric', field: 'applicationPaymentOriginalAmount3', header: 'Monto original del pago asociado a la aplicación 3', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'applicationAmount4', header: 'Monto de aplicación 4', dataType: 'currency', showFilter: true},
  {type: 'date', field: 'applicationDate4', header: 'Fecha de aplicación 4', dataType: 'date', showFilter: true},
  {type: 'date', field: 'applicationPaymentDate4', header: 'Fecha del pago de la aplicación 4', dataType: 'date', showFilter: true},
  {type: 'numeric', field: 'applicationPaymentOriginalAmount4', header: 'Monto original del pago asociado a la aplicación 4', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'applicationAmount5', header: 'Monto de aplicación 5', dataType: 'currency', showFilter: true},
  {type: 'date', field: 'applicationDate5', header: 'Fecha de aplicación 5', dataType: 'date', showFilter: true},
  {type: 'date', field: 'applicationPaymentDate5', header: 'Fecha del pago de la aplicación 5', dataType: 'date', showFilter: true},
  {type: 'numeric', field: 'applicationPaymentOriginalAmount5', header: 'Monto original del pago asociado a la aplicación 5', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'applicationAmount6', header: 'Monto de aplicación 6', dataType: 'currency', showFilter: true},
  {type: 'date', field: 'applicationDate6', header: 'Fecha de aplicación 6', dataType: 'date', showFilter: true},
  {type: 'date', field: 'applicationPaymentDate6', header: 'Fecha del pago de la aplicación 6', dataType: 'date', showFilter: true},
  {type: 'numeric', field: 'applicationPaymentOriginalAmount6', header: 'Monto original del pago asociado a la aplicación 6', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'applicationAmount7', header: 'Monto de aplicación 7', dataType: 'currency', showFilter: true},
  {type: 'date', field: 'applicationDate7', header: 'Fecha de aplicación 7', dataType: 'date', showFilter: true},
  {type: 'date', field: 'applicationPaymentDate7', header: 'Fecha del pago de la aplicación 7', dataType: 'date', showFilter: true},
  {type: 'numeric', field: 'applicationPaymentOriginalAmount7', header: 'Monto original del pago asociado a la aplicación 7', dataType: 'currency', showFilter: true},
  {type: 'text', field: 'company', header: 'Corporativo', showFilter: true},
  {type: 'text', field: 'contract', header: 'Razón social', showFilter: true},
  {type: 'text', field: 'remunerator', header: 'Pagadora', showFilter: true},
  {type: 'text', field: 'payroll', header: 'Nómina', showFilter: true},
  {type: 'enum', field: 'periodType', header: 'Periodo',showFilter: true, dataType: 'enum', enum: TYPE_PERIOD_ENUM, enumColor: TYPE_PERIOD_ENUM_COLOR, options: [
  {value: 'WEEKLY_PERIOD', label: 'Semanal'},
  {value: 'SEMIMONTHLY_PERIOD', label: 'Quincenal'},
  {value: 'BIWEEKLY_PERIOD', label: 'Catorcenal'},
  {value: 'MONTHLY_PERIOD', label: 'Mensual'},
]},
  {type: 'enum', field: 'registerStatus', header: 'Estatus de registro', showFilter: true, dataType: 'enum', enum: WORKER_STATUS_ENUM, enumColor: WORKER_STATUS_ENUM_COLOR, options: [
      {value: 'ACTIVE', label: 'Activo'},
      {value: 'PENDING_ACTIVATION', label: 'Pendiente de activacio1n'},
      {value: 'DEACTIVATED', label: 'Desactivado',},
    ]},
  {type: 'enum', field: 'verificationStatus', header: 'Estatus de verificación', showFilter: true, dataType: 'enum', enum: WORKER_STATUS_VERIFICATION_ENUM, enumColor: WORKER_STATUS_VERIFICATION_ENUM_COLOR, options: [
      {value: 'PENDING_VERIFICATION', label: 'Pendiente de verificación'},
      {value: 'NO_REQUIRED_VERIFICATION', label: 'No requiere verificación'},
      {value: 'COMPLETED', label: 'Completado'}
    ]},
  {type: 'date', field: 'endDate', header: 'Fecha de baja', dataType: 'date', showFilter: true},
]
