import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {map} from "rxjs";
import {IApiPermissionList} from "@modules/private/admin/data/interfaces/iapi-permission.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiPermissionService extends ApiClass {

  constructor() {
    super()
    this.path = 'permission'
  }

  getAll() {
    return this.getCollection().pipe(map((r) => r as IApiPermissionList))
  }
}
