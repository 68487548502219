<p-table
  #dtAdmins
  [scrollable]="true"
  scrollHeight="600px"
  [value]="admins"
  [columns]="cols"
  dataKey="_id"
  [rows]="10"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50, 100]"
  [loading]="loading"
  [paginator]="true"
  [tableStyle]="{'width': '100%'}"
  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} administradores"
  [globalFilterFields]="['names.firstName', 'names.lastName', 'role.identifier']"
>
  <ng-template pTemplate="caption">
    <div class="flex flex-row justify-content-between">
      <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
      <div>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dtAdmins.filterGlobal(getValue($event), 'contains')" placeholder="Buscar..." />
          </span>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="min-width:15rem">
        <div class="flex align-items-center" pSortableColumn="names.firstName">
          Nombre comleto
          <p-columnFilter type="text" field="names.firstName" display="menu" class="mt-1"></p-columnFilter>
          <p-sortIcon field="names.firstName"></p-sortIcon>
        </div>
      </th>
      <th style="min-width:15rem">
        <div class="flex align-items-center" pSortableColumn="emailAddress">
          Email
          <p-columnFilter type="text" field="emailAddress" display="menu" class="mt-1"></p-columnFilter>
          <p-sortIcon field="emailAddress"></p-sortIcon>
        </div>
      </th>
      <th style="min-width:15rem">
        <div class="flex align-items-center" pSortableColumn="role.identifier">
          Role
          <p-columnFilter type="text" field="role.identifier" display="menu" class="mt-1"></p-columnFilter>
          <p-sortIcon field="role.identifier"></p-sortIcon>
        </div>
      </th>
      <th style="min-width:15rem">
        <div class="flex align-items-center" pSortableColumn="role.description">
          Descripción del role
          <p-columnFilter type="text" field="role.description" display="menu" class="mt-1"></p-columnFilter>
          <p-sortIcon field="role.description"></p-sortIcon>
        </div>
      </th>
      <th style="min-width:15rem">
        <div class="flex align-items-center" pSortableColumn="creationDate">
          Fecha de creación
          <p-columnFilter type="date" field="creationDate" display="menu" class="mt-1"></p-columnFilter>
          <p-sortIcon field="creationDate"></p-sortIcon>
        </div>
      </th>
      <ng-container *ngIf="authService.userHasAuthorization('ADMIN','UPDATE')">
        <th style="min-width:10rem">
          Acciones
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-admin>
    <tr>
      <td>
        {{ admin.names.firstName }} {{ admin.names.lastName }}
      </td>
      <td>
        {{ admin.emailAddress }}
      </td>
      <td>
        <span class="ml-1 vertical-align-middle">{{ admin.role.identifier ?? ''}}</span>
      </td>
      <td>
        <span class="ml-1 vertical-align-middle">{{ admin.role.description ?? '' }}</span>
      </td>
      <td>
        <span class="ml-1 vertical-align-middle">{{ admin.creationDate | date: 'MM/dd/yyyy' }}</span>
      </td>
      <ng-container *ngIf="authService.userHasAuthorization('ADMIN','UPDATE')">
        <td>
          <button pButton pRipple type="button" icon="pi pi-pencil" (click)="newEntity('UPDATE', admin)" class="p-button-rounded p-button-text"></button>
        </td>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="3">No se encontraron administradores</td>
    </tr>
  </ng-template>
</p-table>
<p-dialog [(visible)]="createEntity" [style]="{ width: '650px' }" [header]="formAction === 'CREATE' ? 'Nuevo Administrador' : 'Actualizar Administrador'" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <app-admin-form [entity]="entity" [submitted]="submitted"></app-admin-form>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveAdmin()" [disabled]="!entity.valid"></button>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
