<div class="grid">
  <div class="col-12">
    <p-messages [(value)]="responseMessage" [enableService]="visible" [closable]="true" [escape]="false"></p-messages>
  </div>
  <div class="col-12">
    <div class="card-report">
      <div class="card-report-info">
        <h3 class="mb-3">Reporte de ATR:</h3>
        <h6 class="mb-3">El reporte de ATR incluye el estado de la cartera al momento. </h6>
        <ng-container *ngIf="!isLoading; else loading">
          <button pButton pRipple label="Generar reporte" icon="pi pi-check" class="p-button-success" (click)="getReport()"></button>
        </ng-container>
        <ng-template #loading>
          <p-progressSpinner></p-progressSpinner>
        </ng-template>
      </div>
      <div class="card-report-image"></div>
    </div>
  </div>
</div>
