import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {IResponseArray} from "@data/interfaces/iresponseArray.interface";
import {environment} from "@env/environment";
import {catchError, map, of} from "rxjs";
import {IAccessory, IApiAccessoryList} from "@modules/private/accesory/data/interfaces/iaccessory.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiAccessoryService extends ApiClass {

  constructor() {
    super()
    this.path = 'accessory'
  }

  getAll(page = 1, limit = 10, filters = {}) {
    console.log("calling")
    const response: IApiAccessoryList = {error: true, msg: '', data: [], total: 0, status: 200, page: 1, itemsByPage: 10, totalItems: 0}
    return this.http.get<IResponseArray>(environment.apiUrl + this.path).pipe(
      map((r) => r as IApiAccessoryList),
      catchError((e) => of(response))
    )
  }

  create(data:any) {
    return this.postCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IAccessory}))
  }

  update(data:any) {
    return this.putCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IAccessory}))
  }

  delete(id: any) {
    return this.deleteCollection(id).pipe(map((r) => r as {error: boolean; msg: string; data: IAccessory}))
  }
}
