import { Component } from '@angular/core';
import {PrimeNGConfig} from "primeng/api";
import {LoaderService} from "@data/services/page/loader.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pulpi-v4-panel';
  constructor(
    private config: PrimeNGConfig,
    public loaderService: LoaderService
  ) {
    this.config.setTranslation({
      startsWith: 'Empieza con',
      contains: 'Contiene',
      notContains: 'No contiene',
      endsWith: 'Acaba en',
      equals: 'Igual',
      notEquals: 'No igual',
      noFilter: 'Sin filtro',
      lt: 'Menor que',
      matchAll: 'Coincide con todos',
      matchAny: 'Coincide con cualquiera',
      dateIs: 'La fecha es',
      dateIsNot: 'La fecha no es',
      dateAfter: 'La fecha es despues de',
      dateBefore: 'La fecha es antes de',
      addRule: 'Agregar regla',
      clear: 'Limpiar',
      apply: 'Aplicar',
      gt: 'Mayor que',
      gte: 'Mayor o igual que',
      lte: 'Menor o igual que'
    })
  }
}
