import { Component } from '@angular/core';

@Component({
  selector: 'app-detail-payroll',
  templateUrl: './detail-payroll.component.html',
  styleUrls: ['./detail-payroll.component.scss']
})
export class DetailPayrollComponent {

}
