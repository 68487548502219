<ng-container *ngIf="authService.getUser;else elseLogin">
  <section class="{{showLeftMenu ? '': 'hideMenu'}}">
    <header>
      <app-skeleton-header (onChangeMenu)="showLeftMenu = !showLeftMenu"></app-skeleton-header>
    </header>
    <nav *ngIf="showLeftMenu">
      <app-skeleton-leftnav></app-skeleton-leftnav>
    </nav>
    <main>
      <!--<div class="card flex mb-3">
        <p-breadcrumb class="max-w-full" [model]="items" [home]="home" [style]="{'background': 'none', 'border': 'none'}"></p-breadcrumb>--
      </div>-->
      <router-outlet></router-outlet>
      <app-skeleton-footer></app-skeleton-footer>
    </main>
    <!--<footer>
      <app-skeleton-footer></app-skeleton-footer>
    </footer>-->
  </section>
</ng-container>
<ng-template #elseLogin>
  <router-outlet></router-outlet>
</ng-template>
