import { Component } from '@angular/core';

@Component({
  selector: 'app-worker-documents',
  templateUrl: './worker-documents.component.html',
  styleUrls: ['./worker-documents.component.scss']
})
export class WorkerDocumentsComponent {

}
