import { Component } from '@angular/core';
import {Message, MessageService} from 'primeng/api';
import {NgxCsvParser, NgxCSVParserError} from "ngx-csv-parser";
import {IMassiveWorker} from "@modules/private/workers/data/interfaces/iworker.interface";
import {ApiWorkerService} from "@modules/private/workers/data/services/api/api-worker.service";
import {LoaderService} from "@data/services/page/loader.service";

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}
@Component({
  selector: 'app-massive-worker',
  templateUrl: './massive-worker.component.html',
  styleUrls: ['./massive-worker.component.scss'],
  providers: [MessageService]
})
export class MassiveWorkerComponent {
  visible: boolean = false;
  uploadData: IMassiveWorker[] = []
  isUploading = false
  responseMessage: Message[] = []
  showMessage = false
  constructor(
    private messageService: MessageService,
    private ngxCsvParser: NgxCsvParser,
    private apiWorkerService: ApiWorkerService,
    private loaderService: LoaderService
  ) {
  }

  showDialog() {
    this.visible = true;
  }

  onUpload(event: any) {
    const files = event.files
    this.isUploading = true
    this.loaderService.showSpinner()
     this.ngxCsvParser.parse(files[0], {header: false, delimiter: ',', encoding: 'utf8'})
       .pipe().subscribe({
       next: (result: any): void => {
         this.loaderService.hideSpinner()
         this.uploadData = []
         for (const key in result) {
           const object = result[key] as string[]
           if(+key > 0) {
             if(object[1] === undefined || object[2] === undefined) {
               this.messageService.add({severity: 'error', summary: 'Error de formato', detail: 'Hubo un error en el formato del archivo', life: 3000});
               break
             }
             this.uploadData.push({
               typeAction: object[0],
               rfc: object[1],
               clabe: object[2],
               position: object[3],
               salary: object[4],
               startDate: object[5],
               endDate: object[6],
               payrollId: object[7],
               firstName: object[8],
               lastName: object[9],
               curp: object[10],
               emailAddress: object[11],
               phoneNumber: object[12],
               birthDate: object[13],
               sex: object[14],
               civilStatus: object[15],
               nationality: object[16],
               agreementId: object[17],
               identityValidationId: object[18],
               city: object[19],
               neighborhood: object[20],
               postalCode: object[21],
               state: object[22],
               streetNameAndNumber: object[23],
               password: object[24],
               status: object[25],
               notifyUser: object[26]
             })
           }
         }
         if (this.uploadData.length > 0) {
           this.apiWorkerService.upload(this.uploadData).subscribe(r => {
             this.isUploading = false
             this.responseMessage = [
               {
                 summary: r.error ? 'Hubo un error al procesar el archivo':'El archivo se subio exitosamente',
                 severity: r.error ? 'error': 'success',
                 closable: true,
                 detail: r.error ? '' : `<a href="${r.data.url}" target="_blank">Descargar reporte</a>`
               }]
             this.showMessage = true
           })
         }
         console.log(this.uploadData)
       },
       error: (error: NgxCSVParserError): void => {
         console.log('Error', error)
         this.isUploading = false
       }
     })
    // this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
  }
}
