<p-card>
  <div class="row">
    <div class="col-12 mb-5 mb-3">
      <h1 class="mb-3">Accesos</h1>
      <p class="text-color-secondary">
        En este apartado se muestran a los administradores y los roles necesarios para limitar el acceso a la plataforma.
      </p>
      <p-toast></p-toast>
    </div>
  </div>
  <div class="col-12">
    <p-tabView>
      <ng-container *ngIf="authService.userHasAuthorization('ADMIN','READ_LIST')">
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-user"></i>
            <span class="ml-2">Administradores</span>
          </ng-template>
          <app-admin-list></app-admin-list>
        </p-tabPanel>
      </ng-container>
      <ng-container *ngIf="authService.userHasAuthorization('ROLE','READ_LIST')">
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-shield-halved"></i>
            <span class="ml-2">Roles</span>
          </ng-template>
          <app-role-list></app-role-list>
        </p-tabPanel>
      </ng-container>
    </p-tabView>
  </div>
</p-card>
