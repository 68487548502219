import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {ApiRoleService} from "@modules/private/admin/data/services/api-role.service";

@Component({
  selector: 'app-admin-form',
  templateUrl: './admin-form.component.html',
  styleUrls: ['./admin-form.component.scss']
})
export class AdminFormComponent {
  @Input() entity!: FormGroup
  @Input() submitted!: boolean
  roles: {label: string; value: string;}[] = []
  constructor(
    private apiRoleService: ApiRoleService
  ) {
    this.getAll()
  }

  getAll() {
    this.apiRoleService.getAll().subscribe(r => {
      this.roles = r.data.map(r => {return {label: `${r.identifier} | ${r.description}`, value: r._id}})
    })
  }
}
