<section>
  <div class="logo"></div>
  <div class="form-container">
    <form [formGroup]="entity">
      <div class="col-12">
        <div class="main-title">
          Iniciar sesión
        </div>
      </div>
      <div class="grid">
        <div class="col-12">
          <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false"></p-messages>
        </div>
        <div class="col-12">
          <div class="field field-flex">
            <label for="emailAddress">Email:*</label>
            <input type="text" pInputText id="emailAddress" formControlName="emailAddress" placeholder="user@pulpi.mx" required />
            <ng-container *ngIf="entity.get('emailAddress')?.invalid && (entity.get('emailAddress')?.dirty || entity.get('emailAddress')?.touched)">
              <small class="p-error" *ngIf="entity.get('emailAddress')?.errors?.['required']">El campo es obligatorio.</small>
              <small class="p-error" *ngIf="entity.get('emailAddress')?.errors?.['pattern']">El valor no es un email válido.</small>
            </ng-container>
          </div>
        </div>
        <div class="col-12">
          <div class="field field-flex">
            <label for="password">Contraseña:*</label>
            <p-password [feedback]="false" placeholder="************" formControlName="password" [toggleMask]="true"></p-password>
            <ng-container *ngIf="entity.get('password')?.invalid && (entity.get('password')?.dirty || entity.get('password')?.touched)">
              <small class="p-error" *ngIf="entity.get('password')?.errors?.['required']">El campo es obligatorio.</small>
            </ng-container>
          </div>
        </div>

        <div class="col-12 text-right">
          <button pButton pRipple label="Iniciar sesión" icon="fa-solid fa-lock" class="p-button-success" (click)="login()" [disabled]="entity.invalid"></button>
        </div>
      </div>
    </form>
  </div>
</section>
