<ng-container *ngIf="entity">
  <form [formGroup]="entity">
    <div class="grid">
      <div class="col-12">
        <div class="field">
          <label for="name">Nombre:(*)</label>
          <input type="text" pInputText id="name" formControlName="name" placeholder="Asistencia" required />
          <ng-container *ngIf="entity.get('name')?.invalid && (entity.get('name')?.dirty || entity.get('name')?.touched)">
            <small class="p-error" *ngIf="entity.get('name')?.errors?.['required']">El nombre es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('name')?.errors?.['minlength']">El nombre debe tener al menos {{entity.get('name')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="field">
          <label for="calculation">Tipo de calculo:(*)</label>
          <p-dropdown formControlName="calculation" [panelStyle]="{'width': '100%' }" [options]="calculations" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
          <ng-container *ngIf="entity.get('calculation')?.invalid && (entity.get('calculation')?.dirty || entity.get('calculation')?.touched)">
            <small class="p-error" *ngIf="entity.get('calculation')?.errors?.['required']">El tipo de accesorio es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="field flex flex-column">
          <label for="isFinanced">Accesorio es financiado:(*)</label>
          <p-inputSwitch id="isFinanced" formControlName="isFinanced"></p-inputSwitch>
        </div>
      </div>
      <ng-container *ngIf="entity.get('isFinanced')?.value === false;else elsePrices">
        <div class="col-12">
          <div class="field">
            <label for="creationPrice">Costo sin financiamiento:(*)</label>
            <p-inputNumber formControlName="creationPrice" inputId="creationPrice" [mode]="entity.get('calculation')?.value === 'FIXED' ? 'currency' : 'decimal'" currency="MXN" locale="es-MX" [suffix]="entity.get('calculation')?.value === 'FIXED' ? '':' %'"></p-inputNumber>
            <ng-container *ngIf="entity.get('creationPrice')?.invalid && (entity.get('creationPrice')?.dirty || entity.get('creationPrice')?.touched)">
              <small class="p-error" *ngIf="entity.get('creationPrice')?.errors?.['required']">El costo es obligatorio.</small>
              <small class="p-error" *ngIf="entity.get('creationPrice')?.errors?.['min']">El valor minimo es {{entity.get('creationPrice')?.errors?.['min']['min']}}.</small>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-template #elsePrices>
        <div class="col-12">
          <div class="field">
            <label for="weeklyPrice">Costo semanal:(*)</label>
            <p-inputNumber formControlName="weeklyPrice" inputId="weeklyPrice" [mode]="entity.get('calculation')?.value === 'FIXED' ? 'currency' : 'decimal'" currency="MXN" locale="es-MX" [suffix]="entity.get('calculation')?.value === 'FIXED' ? '':' %'"></p-inputNumber>
            <ng-container *ngIf="entity.get('weeklyPrice')?.invalid && (entity.get('weeklyPrice')?.dirty || entity.get('weeklyPrice')?.touched)">
              <small class="p-error" *ngIf="entity.get('weeklyPrice')?.errors?.['required']">El costo es obligatorio.</small>
              <small class="p-error" *ngIf="entity.get('weeklyPrice')?.errors?.['min']">El valor minimo es {{entity.get('weeklyPrice')?.errors?.['min']['min']}}.</small>
            </ng-container>
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <label for="biweeklyPrice">Costo catorcenal:(*)</label>
            <p-inputNumber formControlName="biweeklyPrice" inputId="biweeklyPrice" [mode]="entity.get('calculation')?.value === 'FIXED' ? 'currency' : 'decimal'" currency="MXN" locale="es-MX" [suffix]="entity.get('calculation')?.value === 'FIXED' ? '':' %'"></p-inputNumber>
            <ng-container *ngIf="entity.get('biweeklyPrice')?.invalid && (entity.get('biweeklyPrice')?.dirty || entity.get('biweeklyPrice')?.touched)">
              <small class="p-error" *ngIf="entity.get('biweeklyPrice')?.errors?.['required']">El costo es obligatorio.</small>
              <small class="p-error" *ngIf="entity.get('biweeklyPrice')?.errors?.['min']">El valor minimo es {{entity.get('biweeklyPrice')?.errors?.['min']['min']}}.</small>
            </ng-container>
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <label for="semimonthlyPrice">Costo quincenal:(*)</label>
            <p-inputNumber formControlName="semimonthlyPrice" inputId="semimonthlyPrice" [mode]="entity.get('calculation')?.value === 'FIXED' ? 'currency' : 'decimal'" currency="MXN" locale="es-MX" [suffix]="entity.get('calculation')?.value === 'FIXED' ? '':' %'"></p-inputNumber>
            <ng-container *ngIf="entity.get('semimonthlyPrice')?.invalid && (entity.get('semimonthlyPrice')?.dirty || entity.get('semimonthlyPrice')?.touched)">
              <small class="p-error" *ngIf="entity.get('semimonthlyPrice')?.errors?.['required']">El costo es obligatorio.</small>
              <small class="p-error" *ngIf="entity.get('semimonthlyPrice')?.errors?.['min']">El valor minimo es {{entity.get('semimonthlyPrice')?.errors?.['min']['min']}}.</small>
            </ng-container>
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <label for="monthlyPrice">Costo mensual:(*)</label>
            <p-inputNumber formControlName="monthlyPrice" inputId="monthlyPrice" [mode]="entity.get('calculation')?.value === 'FIXED' ? 'currency' : 'decimal'" currency="MXN" locale="es-MX" [suffix]="entity.get('calculation')?.value === 'FIXED' ? '':' %'"></p-inputNumber>
            <ng-container *ngIf="entity.get('monthlyPrice')?.invalid && (entity.get('monthlyPrice')?.dirty || entity.get('monthlyPrice')?.touched)">
              <small class="p-error" *ngIf="entity.get('monthlyPrice')?.errors?.['required']">El costo es obligatorio.</small>
              <small class="p-error" *ngIf="entity.get('monthlyPrice')?.errors?.['min']">El valor minimo es {{entity.get('monthlyPrice')?.errors?.['min']['min']}}.</small>
            </ng-container>
          </div>
        </div>
      </ng-template>
      <div class="col-12">
        <div class="field">
          <label for="type">Tipo de accesorio:(*)</label>
          <p-dropdown formControlName="type" [panelStyle]="{'width': '100%' }" [options]="typeAccessories" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
          <ng-container *ngIf="entity.get('type')?.invalid && (entity.get('type')?.dirty || entity.get('type')?.touched)">
            <small class="p-error" *ngIf="entity.get('type')?.errors?.['required']">El tipo de accesorio es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="field">
          <label for="description">Descripción:(*)</label>
          <textarea rows="5" cols="30" pInputTextarea formControlName="description" id="description"></textarea>
          <ng-container *ngIf="entity.get('description')?.invalid && (entity.get('description')?.dirty || entity.get('description')?.touched)">
            <small class="p-error" *ngIf="entity.get('description')?.errors?.['required']">La descripción es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="field">
          <label for="termsAndCondition">Terminos y condiciones:</label>
          <textarea rows="5" cols="30" pInputTextarea formControlName="termsAndCondition" id="termsAndCondition"></textarea>
        </div>
      </div>
    </div>
  </form>
</ng-container>
