import { Component } from '@angular/core';

@Component({
  selector: 'app-worker-detail-profile',
  templateUrl: './worker-detail-profile.component.html',
  styleUrls: ['./worker-detail-profile.component.scss']
})
export class WorkerDetailProfileComponent {

}
