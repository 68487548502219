import {Component, ViewChild} from '@angular/core';
import {MenuItem, MessageService} from "primeng/api";
import {ApiWriteOffService} from "@modules/private/write-off/data/services/api/api-write-off.service";
import {
  WRITE_OFF_COLUMNS
} from "@modules/private/write-off/data/columns/writeOffColumns.constant";
import {ITableCol} from "@data/interfaces/itable.interface";
import {Table} from "primeng/table";
import {IWriteOffList, IWriteOffRequestData} from "@modules/private/write-off/data/interfaces/iwriteOff.interface";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {LoaderService} from "@data/services/page/loader.service";
import {
  WRITE_OFF_CONCEPT,
  WRITE_OFF_STATUS_ENUM_ES
} from "@modules/private/write-off/data/enums/writeOff.enum";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-list-write-off',
  templateUrl: './list-write-off.component.html',
  styleUrls: ['./list-write-off.component.scss'],
  providers: [MessageService]
})
export class ListWriteOffComponent{
  totalRecords = 0
  totalPendingRecords = 0
  itemsByPage = 10
  first = 0
  loading: boolean = true
  items: MenuItem[] = []
  writeOffs:IWriteOffList[]=[]
  pendingWriteOffs:IWriteOffList[]=[]
  selectedWriteOffs!:IWriteOffList[]
  columns:ITableCol[] = WRITE_OFF_COLUMNS
  _selectedColumns!: ITableCol[]
  $_writeOffConcept = WRITE_OFF_CONCEPT
  @ViewChild('dtWriteOffPending') dtWriteOffPending!: Table
  @ViewChild('dtWriteOffHistory') dtWriteOffHistory!: Table

  constructor(
    private apiWriteOffService: ApiWriteOffService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private apiLoanService: ApiLoanService
  ) {
    this.createItems()
  }

  createItems() {
    this.createMenu()
    this.selectedColumns = this.columns
  }

  get selectedColumns(): any[] {
    return this._selectedColumns
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.columns.filter((col) => val.includes(col))
  }

  loadWriteOffs(e: any, type: 'pending' | 'history') {
    const currentPage = e.first / this.itemsByPage
    this.itemsByPage = e.rows
    const finalFilters = {...e.filters}
    if (type == 'pending')
      finalFilters.status = {'value': WRITE_OFF_STATUS_ENUM_ES.Pendiente, 'matchMode': 'equals'}
    this.find(currentPage, this.itemsByPage, finalFilters,type)

  }

  find(page = 0, limit = this.itemsByPage, filters = {}, type:string) {
    const findResult:{data: IWriteOffList[], totalItems: number} = {
      data: [],
      totalItems: 0
    }
    this.loading = true
    this.apiWriteOffService.getAll(page, limit, filters).subscribe(async r => {
      console.log(r)
      if (type == 'pending') {
        this.writeOffFormatted(r.data).then(response => this.pendingWriteOffs = response)
        this.totalPendingRecords = r.totalItems
      } else {
        this.writeOffFormatted(r.data).then(response => this.writeOffs = response)
        this.totalRecords = r.totalItems
      }
      findResult.totalItems = r.totalItems
      await this.writeOffFormatted(r.data).then( data => findResult.data = data)
      this.loading = false
    })
    return findResult
  }

  getValueObject(obj: any, keys: string) {
    const keysArray = keys.split('.')
    let value: any
    for (const key in keysArray) {
      if(key === '0') {
        value = obj[keysArray[key]]
      } else {
        value = value[keysArray[key]]
      }
    }
    return value
  }

  private async writeOffFormatted(data: IWriteOffRequestData[]): Promise<IWriteOffList[]> {
    const writeOffs: IWriteOffList[] = []
    let loan:{folio:string, id:string} = {folio: '', id: ''}
    for (const info of data) {
      if ( loan.id !== info.installmentBackup.productId){
        loan.id =info.installmentBackup.productId
        const response = await lastValueFrom(this.apiLoanService.getById(info.installmentBackup.productId))
        loan.folio = response.data.loan.folio
      }
      const writeOff = {
        _id: info._id,
        index: info.index + 1,
        loanFolio: info.loanFolio,
        loanId: info.installmentBackup.productId,
        debtRelief: info.debtRelief,
        reason: info.reason,
        status: info.status,
        date: info.date,
        adminFullName: info.adminInfo.names.firstName +' '+ info.adminInfo.names.lastName
      }
      writeOffs.push(writeOff)
    }
    return writeOffs
  }

  createMenu() {
    if (this.authService.userHasAuthorization('WRITEOFF','APPROVE')) {
      this.items.push({
        label: 'Autorizar',
        icon: 'fa-solid fa-thumbs-up',
        command: () => {
          this.approve(true)
        }
      },{
        label: 'Denegar',
        icon: 'fa-solid fa-thumbs-down',
        command: () => {
          this.approve(false)
        }
      })
    }
  }

  private approve(approve: boolean) {
    this.loaderService.showSpinner()
    this.apiWriteOffService.approve(this.selectedWriteOffs, approve).subscribe(r => {
      this.loaderService.hideSpinner()
      this.messageService.add({
        severity: r.error ? 'error' : 'success',
        summary: r.error ? 'Error' : 'Completado',
        detail: r.error ? r.msg : approve ? 'La(s) condonación(es) se aplicaron de forma correcta' : ' La(s) condonación(es) fueron canceladas' ,
        life: 5000
      })
      this.selectedWriteOffs=[]
      const filters = {first:0, rows:10,filters:{}}
      this.loadWriteOffs(filters,'pending')
      this.loadWriteOffs(filters,'history')
    })
  }
}
