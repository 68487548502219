import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {IResponseArray} from "@data/interfaces/iresponseArray.interface";
import {environment} from "@env/environment";
import {catchError, map, of} from "rxjs";
import {IApiInstallmentList, IInstallment} from "@modules/private/installment/data/interfaces/iinstallment.interface";
import {IInstallmentCalculated} from "@modules/private/loan/data/interfaces/installment.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiInstallmentService  extends ApiClass {
  constructor() {
    super();
    this.path = 'installment'
  }

  getAll(page = 1, limit = 10, filters = {}) {
    const response: IApiInstallmentList = {error: true, msg: '', data: [], totalItems: 0, status: 200, page: 1, itemsByPage: 10}
    return this.http.post<IResponseArray>(environment.apiUrl + this.path + '/all', {page, limit, filters}).pipe(
      map((r) => r as IApiInstallmentList),
      catchError((e) => of(response))
    )
  }

  getByLoanId(loanId: string) {
    return this.getCollection(loanId, environment.apiUrl, 'installment/loan').pipe(map((r) => r as {error: boolean; msg: string; data: IInstallment[]}))
  }
}
