import {ITableCol} from "@data/interfaces/itable.interface";
import {
  TYPE_PRODUCT_INSTALLMENTS_ENUM,
  TYPE_PRODUCT_INSTALLMENTS_ENUM_COLOR
} from "@modules/private/installment/data/enum/typeProducts.enum";
import {TYPE_PERIOD_ENUM, TYPE_PERIOD_ENUM_COLOR} from "@modules/private/company/data/enums/typePeriods.enum";

export const PAYMENT_COLUMNS: ITableCol[] = [
  {type: 'text', field: 'receivingKey', header: 'Clave de rastreo', showFilter: true},
  {type: 'text', field: 'accountNumber', header: 'Cuenta receptora', showFilter: true},
  {type: 'numeric', field: 'amount', header: 'Monto del pago', dataType: 'currency', showFilter: true},
  {type: 'date', field: 'date', header: 'Fecha de pago', dataType: 'date', showFilter: true},
  {type: 'date', field: 'creationDate', header: 'Fecha de creación', dataType: 'date', showFilter: true},
  {type: 'enum', field: 'productType', header: 'Tipo de producto', showFilter: true, dataType: 'enum', enum: TYPE_PRODUCT_INSTALLMENTS_ENUM, enumColor: TYPE_PRODUCT_INSTALLMENTS_ENUM_COLOR, options: [
      {value: 'WITHDRAWAL', label: 'Retiro'},
      {value: 'LOAN', label: 'Crédito'},
    ]},
  {type: 'text', field: 'adminFirstName', header: 'Nombre(s) del administrador', showFilter: true},
  {type: 'text', field: 'adminLastName', header: 'Apellidos del administrador', showFilter: true},
  {type: 'text', field: 'adminFullName', header: 'Nombre completo del administrador', showFilter: true},
  {type: 'text', field: 'workerFirstName', header: 'Nombre(s) del cliente', showFilter: true},
  {type: 'text', field: 'workerLastName', header: 'Apellido del cliente', showFilter: true},
  {type: 'text', field: 'workerFullName', header: 'Nombre completo del cliente', showFilter: true},
  {type: 'enum', field: 'period', header: 'Periodo',showFilter: true, dataType: 'enum', enum: TYPE_PERIOD_ENUM, enumColor: TYPE_PERIOD_ENUM_COLOR, options: [
      {value: 'WEEKLY_PERIOD', label: 'Semanal'},
      {value: 'SEMIMONTHLY_PERIOD', label: 'Quincenal'},
      {value: 'BIWEEKLY_PERIOD', label: 'Catorcenal'},
      {value: 'MONTHLY_PERIOD', label: 'Mensual'},
    ]},
  {type: 'text', field: 'company', header: 'Corporativo', showFilter: true},
  {type: 'text', field: 'contract', header: 'Razón social', showFilter: true},
  {type: 'text', field: 'remunerator', header: 'Pagadora', showFilter: true},
  {type: 'text', field: 'payroll', header: 'Nómina', showFilter: true}
]
