<p-card>
  <div class="row">
    <div class="col-12">
      <h1 class="mb-3">Accesorios Globales</h1>
      <p class="mb-3 text-color-secondary">En este apartado se encontraran los accesorios globales, estos accesorios permiten agregarse al contratar un crédito.  </p>
    </div>
    <p-toast></p-toast>
  </div>
  <div class="row">
    <div class="col-12">
      <app-main-table
        #tableAccessory
        [apiService]="apiAccessory"
        [columns]="columns"
        [searchInServer]="false"
        [concatRFCToExports]="false"
        [pluralEntityName]="'accesorios'"
        [additionalFilters]="{}"
        [actions]="actions"
        (onAction)="actionMethods($event)"
        (onMainAction)="mainActions($event)"
        [additionalMainActions]="additionalMainActions"
      ></app-main-table>
    </div>
  </div>
</p-card>

<p-dialog [(visible)]="createEntity" [style]="{ width: '450px' }" [header]="titleForm" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <app-create-accesory [entity]="entity" [submitted]="submitted"></app-create-accesory>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple [label]="actionSaveForm" icon="pi pi-check" class="p-button-text" (click)="saveAccessory()" [disabled]="!entity.valid"></button>
  </ng-template>
</p-dialog>
