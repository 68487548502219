import { Component } from '@angular/core';

@Component({
  selector: 'app-update-agreement',
  templateUrl: './update-agreement.component.html',
  styleUrls: ['./update-agreement.component.scss']
})
export class UpdateAgreementComponent {

}
