export enum TYPE_PRODUCT_INSTALLMENTS_ENUM {
  WITHDRAWAL = 'Retiro',
  LOAN = 'Crédito'
}

export enum TYPE_PRODUCT_INSTALLMENTS_ENUM_COLOR {
  WITHDRAWAL = 'success',
  LOAN = 'primary'
}


