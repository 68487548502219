export enum WRITE_OFF_STATUS_ENUM  {
  'PENDING' = 'Pendiente',
  'APPLIED'= 'Aplicado',
  'CANCELED' = 'Cancelado',
  'NOT_APPLIED' = 'No Aplicado'
}

export enum WRITE_OFF_STATUS_ENUM_ES {
  'Pendiente'= 'PENDING',
  'Aplicado' = 'APPLIED' ,
  'Cancelado' = 'CANCELED',
  'No Aplicado' = 'NOT_APPLIED'

}

export enum WRITE_OFF_STATUS_ENUM_COLOR {
  'PENDING'= 'primary',
  'APPLIED'= 'success',
  'NOT_APPLIED'= 'warning',
  'CANCELED'= 'danger',
}


export enum WRITE_OFF_CONCEPT {
  revenueAmount = 'interes',
  accessoryPrincipalAmount = 'capital de Accesorio',
  accessoryRevenueAmount = 'interes de Accesorio',
}
