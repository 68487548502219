<p-card>
  <div class="row">
    <div class="col-12">
      <h1 class="mb-3">Pagos</h1>
      <p class="mb-3 text-color-secondary">En el apartado de pagos podras visualizar cada entrada de dinero aplicada a los productos de crédito y SoD. </p>
      <p-toast></p-toast>
    </div>
  </div>
  <p-table
    #dtPayments
    [scrollable]="true"
    scrollHeight="600px"
    [value]="payments"
    [lazy]="true"
    (onLazyLoad)="loadPayments($event)"
    [columns]="selectedColumns"
    dataKey="_id"
    [rows]="+itemsByPage"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [loading]="loading"
    [paginator]="true"
    [tableStyle]="{'width': '100%'}"
    [(selection)]="selectedPayments"
    [rowHover]="true"
    [totalRecords]="totalRecords"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} pagos"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-row justify-content-between">
        <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
        <div>
          <p-multiSelect [options]="columns" [(ngModel)]="selectedColumns" optionLabel="header" selectedItemsLabel="{0} columnas seleccionadas" [style]="{'min-width': '200px'}" placeholder="Escoge Columnas"></p-multiSelect>
          <span class="p-input-icon-left ml-4">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dtPayments.filterGlobal(getValue($event), 'contains')" placeholder="Buscar..." />
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th pFrozenColumn></th>
        <ng-container *ngFor="let col of columns">
          <th  style="min-width: 15rem">
            <ng-container *ngIf="col.showFilter">
              <ng-container [ngSwitch]="col.type">
                <ng-container *ngSwitchCase="'numeric'">
                  <p-columnFilter type="numeric" [field]="col.field" [currency]="'MXN'"></p-columnFilter>
                </ng-container>
                <ng-container *ngSwitchCase="'enum'">
                  <div class="flex align-items-center" pSortableColumn="periodType">
                    <p-columnFilter [field]="col.field" matchMode="equals" >
                      <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-dropdown [ngModel]="value" [options]="col.options" (onChange)="filter($event.value)" placeholder="Cualquiera">
                          <ng-template let-option pTemplate="item">
                            <p-tag [value]="option.label" [severity]="option.value | selectEnum: col.enumColor"></p-tag>
                          </ng-template>
                        </p-dropdown>
                      </ng-template>
                    </p-columnFilter>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'boolean'">
                  <p-columnFilter type="boolean" [field]="col.field"></p-columnFilter>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <p-columnFilter [type]="col.type" [field]="col.field"></p-columnFilter>
                </ng-container>
              </ng-container>
            </ng-container>
          </th>
        </ng-container>
      </tr>
      <tr>
        <th style="padding-left: 15px;" pFrozenColumn>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <ng-container *ngFor="let col of columns">
          <th  style="min-width: 15rem">
            {{col.header}}
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-payment let-columns="columns">
      <tr>
        <td>
          <p-tableCheckbox [value]="payment"></p-tableCheckbox>
        </td>
        <td *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.dataType">
            <ng-container *ngSwitchCase="'currency'">
              {{getValueObject(payment, col.field) | currency}}
            </ng-container>
            <ng-container *ngSwitchCase="'date'">
              {{getValueObject(payment, col.field) | isoDateEnum}}
            </ng-container>
            <ng-container *ngSwitchCase="'enum'">
              <p-tag [value]="getValueObject(payment, col.field) | selectEnum:col.enum" [severity]="getValueObject(payment, col.field) | selectEnum:col.enumColor"></p-tag>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{getValueObject(payment, col.field)}}
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">No se encontraron pagos</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
