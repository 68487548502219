import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {catchError, map, of} from "rxjs";
import {
  IApiWorkerList,
  IMassiveWorker,
  IWorker,
  IWorkerRfcAndName
} from "@modules/private/workers/data/interfaces/iworker.interface";
import {IResponseArray} from "@data/interfaces/iresponseArray.interface";
import {environment} from "@env/environment";
import {IPosition} from "@modules/private/workers/data/interfaces/iposition.interface";
import {IPayroll} from "@modules/private/payroll/data/interfaces/ipayroll.interface";
import {ISodPropertiesInterface} from "@modules/private/workers/data/interfaces/isod-properties.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiWorkerService extends ApiClass {
  constructor() {
    super();
    this.path = 'worker'
  }

  getAll(page = 1, limit = 10, filters = {}) {
    const response: IApiWorkerList = {error: true, msg: '', data: [], total: 0, status: 200, page: 1, itemsByPage: 10, totalItems: 0}
    return this.http.post<IResponseArray>(environment.apiUrl + this.path, {page, limit, filters}).pipe(
      map((r) => r as IApiWorkerList),
      catchError((e) => of(response))
    )
  }

  getAllNamesAndRfcs() {
    return this.getCollections({}, environment.apiUrl, 'worker/namesAndRfcs').pipe(map((r) => r as {data: IWorkerRfcAndName[], error: boolean, msg: string}) )
  }

  getById(id: string) {
    return this.getCollection(id).pipe(map((r) => r as {data: { worker: IWorker, position: IPosition, payroll: IPayroll }, error: boolean, msg: string}))
  }

  getUserProperties(id: string) {
    return this.getCollection(id, environment.apiUrl,'sod/user').pipe(map((r) => r as {data: ISodPropertiesInterface, error: boolean, msg: string}))
  }

  upload(data: IMassiveWorker[]) {
    let response = {error: true, msg: '',data: {url: ''}}
    return this.http.post<{error: boolean, msg: string, data: {url: string} }>(environment.apiUrl + this.path + '/massive', {data}).pipe(
      map((r) => {
        response = r
        return response
      }),
      catchError((e) => of(response))
    )
  }

  changeStatus(data: any) {
    return this.putCollection(data, environment.apiUrl, 'worker/status').pipe(map((r) => r as {data: IWorker, error: boolean, msg: string}))
  }

  update(data: any) {
    return this.putCollection(data).pipe(map((r) => r as {data: {data: IWorker, errors: string[]}, error: boolean, msg: string}))
  }

  create(data: any) {
    return this.postCollection(data, 'new').pipe(map((r) => r as {data: IWorker & {errors: string[]}    , error: boolean, msg: string}))
  }

  resetPassword(data: any) {
    return this.postCollection(data, 'reset-password').pipe(map((r) => r as {data: IWorker, error: boolean, msg: string}))
  }

  integrateWorkers(data:any) {
    return this.postCollection(data, 'integrate').pipe(map((r) => r as {data: IWorker, error: boolean, msg: string}))
  }
}
