<p-card>
  <div class="row">
    <div class="col-12">
      <h1 class="mb-3">Colaboradores</h1>
      <p class="mb-3 text-color-secondary">
        En el apartado de colaboradores, podrás encontrar el estado del usuario dentro de la plataforma, así como los saldos del usuario, información general e información relacionada con su puesto.
      </p>
      <p-toast></p-toast>
    </div>
  </div>
  <p-table
    #dtWorkers
    [scrollable]="true"
    scrollHeight="600px"
    [value]="workers"
    [lazy]="true"
    (onLazyLoad)="loadWorkers($event)"
    [columns]="selectedColumns"
    dataKey="_id"
    [rows]="itemsByPage"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [loading]="loading"
    [paginator]="true"
    [tableStyle]="{'width': '100%'}"
    [(selection)]="selectedWorkers"
    [rowHover]="true"
    [totalRecords]="totalRecords"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} trabajadores"
    [styleClass]="'p-datatable-sm'"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-row justify-content-between">
        <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
        <div>
          <p-multiSelect [options]="columns" [(ngModel)]="selectedColumns" optionLabel="header" selectedItemsLabel="{0} columnas seleccionadas" [style]="{'min-width': '200px'}" placeholder="Escoge las columnas"></p-multiSelect>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th pFrozenColumn></th>
        <th style="min-width: 15rem;" pFrozenColumn>
          <p-columnFilter type="text" field="rfc"></p-columnFilter>
        </th>
        <ng-container *ngFor="let col of columns">
            <th  style="min-width: 15rem">
              <ng-container *ngIf="col.showFilter">
                <ng-container [ngSwitch]="col.type">
                  <ng-container *ngSwitchCase="'numeric'">
                    <p-columnFilter type="numeric" [field]="col.field" [currency]="'MXN'"></p-columnFilter>
                  </ng-container>
                  <ng-container *ngSwitchCase="'boolean'">
                    <p-columnFilter type="boolean" [field]="col.field"></p-columnFilter>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <p-columnFilter [type]="col.type" [field]="col.field"></p-columnFilter>
                  </ng-container>
                </ng-container>
              </ng-container>
            </th>
        </ng-container>
        <th style="width: 4rem"></th>
      </tr>
      <tr>
        <th style="padding-left: 15px;" pFrozenColumn>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pFrozenColumn>
          RFC
        </th>
        <ng-container *ngFor="let col of columns">
          <th  style="min-width: 15rem">
            {{col.header}}
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-columns="columns">
      <tr>
        <td pFrozenColumn>
          <p-tableCheckbox [value]="item"></p-tableCheckbox>
        </td>
        <td pFrozenColumn>
          <ng-container *ngIf="authService.userHasAuthorization('WORKER','READ_DETAIL'); else rfcDetail">
            <a class="link-subsection" routerLink="{{'/worker/'+item._id}}">{{item.rfc}}</a>
          </ng-container>
          <ng-template #rfcDetail>
            {{item.rfc}}
          </ng-template>
        </td>
        <td *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.dataType">
            <ng-container *ngSwitchCase="'currency'">
              {{getValueObject(item, col.field) | currency}}
            </ng-container>
            <ng-container *ngSwitchCase="'date'">
              {{getValueObject(item, col.field) | date: 'dd-MM-YYYY'}}
            </ng-container>
            <ng-container *ngSwitchCase="'link'">
              <a class="link-subsection" routerLink="{{'/worker/'+getValueObject(item, col.linkField)}}">{{getValueObject(item, col.field)}}</a>
            </ng-container>
            <ng-container *ngSwitchCase="'enum'">
              <ng-container *ngIf="getValueObject(item, col.field) && getValueObject(item, col.field) !== ''; else elseEnum ">
                <p-tag [value]="getValueObject(item, col.field) | selectEnum:col.enum" [severity]="getValueObject(item, col.field) | selectEnum:col.enumColor"></p-tag>
              </ng-container>
              <ng-template #elseEnum>
                --
              </ng-template>
            </ng-container>
            <ng-container *ngSwitchCase="'boolean'">
              <i class="pi" [ngClass]="{ 'text-green-500 pi-check-circle': getValueObject(item, col.field), 'text-red-500 pi-times-circle': !getValueObject(item, col.field) }"></i>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{getValueObject(item, col.field)}}
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">No se encontraron trabajadores</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>

<p-dialog [(visible)]="createEntity" [style]="{ width: '75vw' }" [header]="'Crear usuario'" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <app-worker-update [entity]="entity" [formAction]="'CREATE'"></app-worker-update>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="create()" [disabled]="!entity.valid"></button>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
