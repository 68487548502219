import { Injectable } from '@angular/core';
import {map, Observable} from "rxjs";
import {IApiCreatePayroll, IApiPayerList} from "@modules/private/payer/data/interfaces/iapi-payer.interface";
import {ApiClass} from "@data/class/api-class";

@Injectable({
  providedIn: 'root'
})
export class ApiPayerService extends ApiClass {

  constructor() {
    super();
    this.path = 'remunerator'
  }

  getAll() {
    return this.getCollections().pipe(map((r) => r as IApiPayerList))
  }

  create(data: any): Observable<any> {
    return this.postCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IApiCreatePayroll }))
  }

  patch(data: any): Observable<any> {
    return this.patchCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IApiCreatePayroll }))
  }
}
