<ng-container *ngIf="entity && allOptionsAvailable">
  <form [formGroup]="entity">
    <div class="grid">
    <ng-container *ngIf="type === 'CREATE'">
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="companyId">Corporativo</label>
          <div class="flex justify-content-center flex-column">
            <p-dropdown formControlName="companyId" [disabled]="type !== 'CREATE'" [panelStyle]="{'width': '100%' }" scrollHeight="150px" [options]="companies" optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true" placeholder="Selecciona un corporativo">
              <ng-template pTemplate="selectedItem">

                <div class="flex align-items-center" *ngIf="entity.value.companyId">
                  <div>{{getNameCompany(entity.value.companyId)}}</div>
                </div>
              </ng-template>
              <ng-template let-company pTemplate="item">
                <div class="flex align-items-center">
                  <div>{{ company.label }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <ng-container *ngIf="entity.get('companyId')?.invalid && (entity.get('companyId')?.dirty || entity.get('companyId')?.touched)">
              <small class="p-error" *ngIf="entity.get('companyId')?.errors?.['required']">El corporativo es obligatorio.</small>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="contractId">Convenio</label>
          <div class="flex justify-content-center flex-column">
            <p-dropdown formControlName="contractId" [disabled]="type !== 'CREATE'" [panelStyle]="{'width': '100%' }" scrollHeight="150px" [options]="agreements" optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true" placeholder="Selecciona un convenio">
              <ng-template pTemplate="selectedItem">

                <div class="flex align-items-center" *ngIf="entity.value.contractId">
                  <div>{{getNameAgreement(entity.value.contractId)}}</div>
                </div>
              </ng-template>
              <ng-template let-contract pTemplate="item">
                <div class="flex align-items-center">
                  <div>{{ contract.label }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <ng-container *ngIf="entity.get('contractId')?.invalid && (entity.get('contractId')?.dirty || entity.get('contractId')?.touched)">
              <small class="p-error" *ngIf="entity.get('contractId')?.errors?.['required']">El convenio es obligatorio.</small>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="col-12 md:col-6">
      <div class="field">
        <label for="name">Nombre</label>
        <input type="text" pInputText id="name" formControlName="name" placeholder="Nómina ABC" required />
        <ng-container *ngIf="entity.get('name')?.invalid && (entity.get('name')?.dirty || entity.get('name')?.touched)">
          <small class="p-error" *ngIf="entity.get('name')?.errors?.['required']">El nombre es obligatorio.</small>
          <small class="p-error" *ngIf="entity.get('name')?.errors?.['minlength']">El nombre debe tener al menos {{entity.get('name')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="type === 'CREATE'">
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="periodType">Periodo de pago</label>
          <p-dropdown formControlName="periodType" [panelStyle]="{'width': '100%' }" [options]="periods" placeholder="Selecciona un periodo" optionLabel="label" optionValue="value"/>
          <ng-container *ngIf="entity.get('periodType')?.invalid && (entity.get('periodType')?.dirty || entity.get('periodType')?.touched)">
            <small class="p-error" *ngIf="entity.get('periodType')?.errors?.['required']">El periodo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div class="col-12 md:col-6">
      <div class="field">
        <label for="firstDate">Primer fecha de pago</label>
        <input type="date" pInputText id="firstDate" formControlName="firstDate" required />
        <ng-container *ngIf="entity.get('firstDate')?.invalid && (entity.get('firstDate')?.dirty || entity.get('firstDate')?.touched)">
          <small class="p-error" *ngIf="entity.get('firstDate')?.errors?.['required']">La primer fecha es obligatoria.</small>
          <small class="p-error" *ngIf="entity.get('firstDate')?.errors?.['invalidDate']">La primer fecha debe ser menor a hoy.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="field">
        <label for="firstDate">Segunda fecha de pago</label>
        <input type="date" pInputText id="secondDate" formControlName="secondDate" />
        <ng-container *ngIf="entity.get('secondDate')?.invalid && (entity.get('secondDate')?.dirty || entity.get('secondDate')?.touched)">
          <small class="p-error" *ngIf="entity.get('secondDate')?.errors?.['required']">La segunda fecha es obligatoria.</small>
          <small class="p-error" *ngIf="entity.get('secondDate')?.errors?.['invalidDate']">La segunda fecha debe ser mayor a la primer fecha de pago y menor a la fecha actual</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="field flex flex-column">
        <label for="withdrawalFeeIsPercent">¿Comisión por retiro porcentual?:</label>
        <p-inputSwitch id="withdrawalFeeIsPercent" formControlName="withdrawalFeeIsPercent"></p-inputSwitch>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="field">
        <label for="withdrawalFee">Comisión por retiro:</label>
        <ng-container *ngIf="entity.value.withdrawalFeeIsPercent; else elseWithdrawalFee">
          <p-inputNumber formControlName="withdrawalFee" inputId="withdrawalFee" suffix=" %"> </p-inputNumber>
        </ng-container>
        <ng-template #elseWithdrawalFee>
          <p-inputNumber formControlName="withdrawalFee" inputId="withdrawalFee" mode="currency" currency="MXN" locale="es-MX"> </p-inputNumber>
        </ng-template>

        <ng-container *ngIf="entity.get('withdrawalFee')?.invalid && (entity.get('withdrawalFee')?.dirty || entity.get('withdrawalFee')?.touched)">
          <small class="p-error" *ngIf="entity.get('withdrawalFee')?.errors?.['required']">La comisión por retiro es obligatorio.</small>
          <small class="p-error" *ngIf="entity.get('withdrawalFee')?.errors?.['min']">El valor minimo es {{entity.get('withdrawalFee')?.errors?.['min']['min']}}.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="field">
        <label for="amountByWithdrawal">Monto por retiro:</label>
        <p-inputNumber formControlName="amountByWithdrawal" inputId="amountByWithdrawal" mode="currency" currency="MXN" locale="es-MX"> </p-inputNumber>
        <ng-container *ngIf="entity.get('amountByWithdrawal')?.invalid && (entity.get('amountByWithdrawal')?.dirty || entity.get('amountByWithdrawal')?.touched)">
          <small class="p-error" *ngIf="entity.get('amountByWithdrawal')?.errors?.['required']">El monto por retiro es obligatorio.</small>
          <small class="p-error" *ngIf="entity.get('amountByWithdrawal')?.errors?.['min']">El valor minimo es {{entity.get('amountByWithdrawal')?.errors?.['min']['min']}}.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="field">
        <label for="typeWithdrawalCalculation">Tipo de calculo:</label>
        <p-dropdown formControlName="typeWithdrawalCalculation" [panelStyle]="{'width': '100%' }" [options]="typeCalculations" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
        <ng-container *ngIf="entity.get('typeWithdrawalCalculation')?.invalid && (entity.get('typeWithdrawalCalculation')?.dirty || entity.get('typeWithdrawalCalculation')?.touched)">
          <small class="p-error" *ngIf="entity.get('typeWithdrawalCalculation')?.errors?.['required']">El tipo de calculo es obligatorio.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="field">
        <label for="withdrawableSalaryRatio">Porcentaje de salario disponible para retirar</label>
        <p-inputNumber formControlName="withdrawableSalaryRatio" inputId="withdrawableSalaryRatio" suffix=" %"> </p-inputNumber>
        <ng-container *ngIf="entity.get('withdrawableSalaryRatio')?.invalid && (entity.get('withdrawableSalaryRatio')?.dirty || entity.get('withdrawableSalaryRatio')?.touched)">
          <small class="p-error" *ngIf="entity.get('withdrawableSalaryRatio')?.errors?.['required']">El porcentaje de salario disponible es obligatorio.</small>
          <small class="p-error" *ngIf="entity.get('withdrawableSalaryRatio')?.errors?.['min']">El valor minimo es {{entity.get('withdrawableSalaryRatio')?.errors?.['min']['min']}}.</small>
          <small class="p-error" *ngIf="entity.get('withdrawableSalaryRatio')?.errors?.['max']">El valor máximo es {{entity.get('withdrawableSalaryRatio')?.errors?.['max']['max']}}.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="field">
        <label for="loanRate">Tasa de interes anual para créditos</label>
        <p-inputNumber formControlName="loanRate" inputId="loanRate" suffix=" %"> </p-inputNumber>
        <ng-container *ngIf="entity.get('loanRate')?.invalid && (entity.get('loanRate')?.dirty || entity.get('loanRate')?.touched)">
          <small class="p-error" *ngIf="entity.get('loanRate')?.errors?.['required']">La tasa de interès es obligatoria.</small>
          <small class="p-error" *ngIf="entity.get('loanRate')?.errors?.['min']">El valor minimo es {{entity.get('loanRate')?.errors?.['min']['min']}}.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="field">
        <label for="loanCreationFeeRate">Porcentaje de comisión por apertura para créditos</label>
        <p-inputNumber formControlName="loanCreationFeeRate" inputId="loanCreationFeeRate" suffix=" %"> </p-inputNumber>
        <ng-container *ngIf="entity.get('loanCreationFeeRate')?.invalid && (entity.get('loanCreationFeeRate')?.dirty || entity.get('loanCreationFeeRate')?.touched)">
          <small class="p-error" *ngIf="entity.get('loanCreationFeeRate')?.errors?.['required']">El porcentaje de comisión por apertura es obligatoria.</small>
          <small class="p-error" *ngIf="entity.get('loanCreationFeeRate')?.errors?.['min']">El valor minimo es {{entity.get('loanCreationFeeRate')?.errors?.['min']['min']}}.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12 md:col-3">
      <div class="field flex flex-column">
        <label for="withdrawalsEnabled">Retiros Activos</label>
        <p-inputSwitch id="withdrawalsEnabled" formControlName="withdrawalsEnabled"></p-inputSwitch>
      </div>
    </div>
    <div class="col-12 md:col-3">
      <div class="field flex flex-column">
        <label for="loansEnabled">Créditos Activos</label>
        <p-inputSwitch id="loansEnabled" formControlName="loansEnabled"></p-inputSwitch>
      </div>
    </div>
    </div>
  </form>
</ng-container>
