import {Component, Input, OnInit} from '@angular/core';
import {IDocument, IFlowData, IInputForm} from "@modules/private/pushing/data/interfaces/ipushing.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Message} from "primeng/api";

@Component({
  selector: 'app-pushing-record-offer',
  templateUrl: './pushing-record-offer.component.html',
  styleUrls: ['./pushing-record-offer.component.scss']
})
export class PushingRecordOfferComponent implements OnInit {
  responseMessage: Message[] = []
  showMessage = false
  isLoading = false
  @Input() data!: IDocument
  @Input() values!: IFlowData | null
  entity!: FormGroup
  inputs: {amount: number; installmentCount: number} = {amount: 0, installmentCount: 0}

  constructor(
    private fb: FormBuilder
  ) {
    this.entity = this.fb.group({})
  }

  ngOnInit() {
    if (this.values?.value) this.inputs = JSON.parse(this.values?.value)
    console.log(this.inputs)
    this.generateForm()
  }

  generateForm() {
    this.entity = this.fb.group({
      amount: [this.inputs.amount, [Validators.required]],
      installmentCount: [this.inputs.installmentCount, [Validators.required]],
    })
  }
  getEntity() {
    const data = new FormData()
    data.append('value', JSON.stringify(this.entity.value))
    data.append('documentId', this.data._id)
    return data
  }
}
