import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {IResponseArray} from "@data/interfaces/iresponseArray.interface";
import {environment} from "@env/environment";
import {catchError, map, Observable, of} from "rxjs";
import {
  IApiWriteOffRequest,
  IWriteOffId, IWriteOffList,
  IWriteOffRequestData
} from "@modules/private/write-off/data/interfaces/iwriteOff.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiWriteOffService extends ApiClass{

  constructor() {
    super();
    this.path='writeOff'
  }

  getAll(page = 0, limit = 10, filters = {}, sort = {}) {
    const response: IApiWriteOffRequest = {error: true, msg: '', data: [], totalItems: 0, status: 200, page: 1, itemsByPage: 10}
    return this.http.post<IResponseArray>(environment.apiUrl + this.path + '/all', {page, limit, filters, sort}).pipe(
      map((r) => r as IApiWriteOffRequest),
      catchError((e) => of(response))
    )
  }

  create(data: any, url: string = environment.apiUrl): Observable<any> {
    return this.postCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IWriteOffId}))
  }

  preWriteOff(data: any, url: string = environment.apiUrl): Observable<any> {
    return this.postCollection(data,'preWriteOff').pipe(map((r) => r as {error: boolean; msg: string; data: IWriteOffId}))
  }

  update(data:any) {
    return this.putCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IWriteOffRequestData}))
  }

  approve(selectedWriteOffs: IWriteOffList[], approve: boolean) {
    const data = {
      writeOffs: selectedWriteOffs,
      approve: approve
    }
    return this.postCollection(data,'approve', environment.apiUrl).pipe(map((r) => r as {error: boolean; msg: string; data: IWriteOffList[]}))
  }

  createAndPayroll(data: { writeOffs: any[]; payrolls: any }): Observable<any>  {
    return this.postCollection(data,'payroll').pipe(map((r) => r as {error: boolean; msg: string; data: IWriteOffId}))
  }
}
