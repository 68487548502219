import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromComponents from './components';
import {ButtonModule} from "primeng/button";
import {CardModule} from "primeng/card";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {DialogModule} from "primeng/dialog";
import {MultiSelectModule} from "primeng/multiselect";
import {RippleModule} from "primeng/ripple";
import {SplitButtonModule} from "primeng/splitbutton";
import {TableModule} from "primeng/table";
import {TagModule} from "primeng/tag";
import {ToastModule} from "primeng/toast";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MegaMenuModule} from "primeng/megamenu";
import {InputTextModule} from "primeng/inputtext";
import {InputMaskModule} from "primeng/inputmask";
import {ContextMenuModule} from "primeng/contextmenu";
import {PanelMenuModule} from "primeng/panelmenu";
import {MenuModule} from "primeng/menu";
import {MessageModule} from "primeng/message";
import {CalendarModule} from "primeng/calendar";
import {CalendarIcon} from "primeng/icons/calendar";
import {DropdownModule} from "primeng/dropdown";
import {SliderModule} from "primeng/slider";
import {ProgressBarModule} from "primeng/progressbar";
import {BreadcrumbModule} from "primeng/breadcrumb";
import {ToolbarModule} from "primeng/toolbar";
import {PasswordModule} from "primeng/password";
import {AvatarModule} from "primeng/avatar";
import {InputSwitchModule} from "primeng/inputswitch";
import {InputNumberModule} from "primeng/inputnumber";
import {FileUploadModule} from "primeng/fileupload";
import {FieldsetModule} from "primeng/fieldset";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NgxCsvParserModule} from "ngx-csv-parser";
import {TabViewModule} from "primeng/tabview";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {ChipModule} from "primeng/chip";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {TimelineModule} from "primeng/timeline";
import {RadioButtonModule} from "primeng/radiobutton";
import {SelectEnumPipe} from "@data/pipes/enum/select-enum.pipe";
import {HttpClientModule} from "@angular/common/http";
import {TimeElapsedPipe} from "@data/pipes/time-elapsed.pipe";
import {InputTextareaModule} from "primeng/inputtextarea";
import {CheckboxModule} from "primeng/checkbox";
import {KeyFilterModule} from "primeng/keyfilter";
import {PickListModule} from "primeng/picklist";
import {DragDropModule} from "primeng/dragdrop";
@NgModule({
  declarations: [
    ...fromComponents.components,
    SelectEnumPipe,
    TimeElapsedPipe
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ButtonModule,
    TableModule,
    TagModule,
    FormsModule,
    ReactiveFormsModule,
    MegaMenuModule,
    InputTextModule,
    InputMaskModule,
    ContextMenuModule,
    PanelMenuModule,
    MenuModule,
    MessageModule,
    MultiSelectModule,
    CalendarModule,
    CalendarIcon,
    DropdownModule,
    SliderModule,
    ProgressBarModule,
    CardModule,
    RippleModule,
    BreadcrumbModule,
    ToastModule,
    ToolbarModule,
    SplitButtonModule,
    DialogModule,
    PasswordModule,
    ConfirmDialogModule,
    AvatarModule,
    InputSwitchModule,
    InputNumberModule,
    FileUploadModule,
    FieldsetModule,
    FontAwesomeModule,
    NgxCsvParserModule,
    TabViewModule,
    ProgressSpinnerModule,
    ChipModule,
    ConfirmPopupModule,
    TimelineModule,
    RadioButtonModule,
    InputTextareaModule,
    CheckboxModule,
    KeyFilterModule,
    PickListModule,
    DragDropModule
  ],
  exports: [
    ...fromComponents.components,
    HttpClientModule,
    ButtonModule,
    TableModule,
    TagModule,
    FormsModule,
    ReactiveFormsModule,
    MegaMenuModule,
    InputTextModule,
    InputMaskModule,
    ContextMenuModule,
    PanelMenuModule,
    MenuModule,
    MessageModule,
    MultiSelectModule,
    CalendarModule,
    CalendarIcon,
    DropdownModule,
    SliderModule,
    ProgressBarModule,
    CardModule,
    RippleModule,
    BreadcrumbModule,
    ToastModule,
    ToolbarModule,
    SplitButtonModule,
    DialogModule,
    PasswordModule,
    ConfirmDialogModule,
    AvatarModule,
    InputSwitchModule,
    InputNumberModule,
    FileUploadModule,
    FieldsetModule,
    FontAwesomeModule,
    NgxCsvParserModule,
    TabViewModule,
    ProgressSpinnerModule,
    ChipModule,
    ConfirmPopupModule,
    TimelineModule,
    RadioButtonModule,
    SelectEnumPipe,
    TimeElapsedPipe,
    InputTextareaModule,
    CheckboxModule,
    KeyFilterModule,
    PickListModule,
    DragDropModule
  ]
})
export class SharedModule { }
