import {Component, Input, OnInit} from '@angular/core';
import {ILoanPopulated} from "@modules/private/loan/data/interfaces/iloan.interface";
import {ApiIncodeService} from "@data/services/api/api-incode.service";
import {MessageService} from "primeng/api";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LOAN_INCODE_ONBOARDING_STATUS} from "@modules/private/loan/data/enums/loan.enum";
import {AuthService} from "@data/services/modules/auth/auth.service";

@Component({
  selector: 'app-loan-identity-verification',
  templateUrl: './loan-identity-verification.component.html',
  styleUrls: ['./loan-identity-verification.component.scss'],
  providers: [MessageService]
})
export class LoanIdentityVerificationComponent implements  OnInit{
  @Input() data!: ILoanPopulated
  incodeSessionStatus = '-'
  urlIncodeSession = ''
  entity!: FormGroup
  documents = {
    addressStatement: '',
    document: '',
    fullFrameBackID: '',
    fullFrameFrontID: '',
    otherDocument1: '',
    otherDocument2: '',
    selfie: ''
  }
  isUpdating = false
  updateValidation = false
  $_loanOnboardingStatus = LOAN_INCODE_ONBOARDING_STATUS
  constructor(
    private fb: FormBuilder,
    private apiIncodeService: ApiIncodeService,
    private apiLoanService: ApiLoanService,
    private messageService: MessageService,
    public authService: AuthService
  ) {
    this.entity = this.fb.group({
      sessionId: ['', [Validators.required, Validators.minLength(24), Validators.maxLength(24)]]
    });
  }

  ngOnInit() {
    this.getSessionData()
  }

  getSessionData(sessionId = this.data.sessionId) {
    if (sessionId) {
      this.apiIncodeService.getSession(sessionId).subscribe(r => {
        if (r.token) {
          this.apiIncodeService.getOnboardingStatus(r.token, sessionId).subscribe(rr => {
            this.incodeSessionStatus = rr.onboardingStatus
            this.urlIncodeSession = `https://dashboard.incode.com/single-session/${sessionId}`
          })
          this.apiIncodeService.getDocuments(r.token).subscribe(rr => {
            this.documents = rr
          })
        }
        console.log(this.documents)
      })
    }
  }

  addValidationToLoan() {
    this.isUpdating = true
    this.apiLoanService.updateVerificationId({...this.entity.value, id: this.data._id}).subscribe((r) => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 5000 });
      this.isUpdating = false
      this.getSessionData(this.entity.value.sessionId)
      this.updateValidation = false
    })
  }
}
