<ng-container *ngIf="entity">
  <form [formGroup]="entity">
    <div class="grid px-4">
      <div class="col-12">
        <p>Asegurate de colocar el monto deseado y las letras deseadas.</p>
      </div>
      <div class="col-12 comment-rejected" *ngIf="values && values.comment !== ''">
        Último motivo de rechazo: <strong>{{values.comment}}</strong>
      </div>
      <div class="col-12">
        <div class="message">
          <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="amount">Monto solicitado:*</label>
          <input type="text" pInputText id="amount" formControlName="amount" placeholder="12000" required />
          <ng-container *ngIf="entity.get('amount')?.invalid && (entity.get('amount')?.dirty || entity.get('amount')?.touched)">
            <small class="p-error" *ngIf="entity.get('amount')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="field field-flex">
          <label for="installmentCount">Número de letras:*</label>
          <input type="number" pInputText id="installmentCount" formControlName="installmentCount" placeholder="12" required />
          <ng-container *ngIf="entity.get('installmentCount')?.invalid && (entity.get('installmentCount')?.dirty || entity.get('installmentCount')?.touched)">
            <small class="p-error" *ngIf="entity.get('installmentCount')?.errors?.['required']">El campo es obligatorio.</small>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</ng-container>
