<ng-container *ngIf="entity">
  <form [formGroup]="entity">
    <div class="field field-flex">
      <label for="role">Role:*</label>
      <p-dropdown formControlName="role" [panelStyle]="{'width': '100%' }" [options]="roles" placeholder="Selecciona un role" optionLabel="label" optionValue="value"/>
      <ng-container *ngIf="entity.get('role')?.invalid && (entity.get('role')?.dirty || entity.get('role')?.touched)">
        <small class="p-error" *ngIf="entity.get('role')?.errors?.['required']">El role es obligatorio.</small>
      </ng-container>
    </div>
    <div class="field">
      <label for="firstName">Nombre(s):*</label>
      <input type="text" pInputText id="firstName" formControlName="firstName" placeholder="Ortega Roldan" required autofocus />
      <ng-container *ngIf="entity.get('firstName')?.invalid && (entity.get('firstName')?.dirty || entity.get('firstName')?.touched)">
        <small class="p-error" *ngIf="entity.get('firstName')?.errors?.['required']">El nombre es obligatorio.</small>
        <small class="p-error" *ngIf="entity.get('firstName')?.errors?.['minlength']">El nombre debe tener al menos {{entity.get('firstName')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
      </ng-container>
    </div>
    <div class="field">
      <label for="lastName">Apellidos:*</label>
      <input type="text" pInputText id="lastName" formControlName="lastName" placeholder="John" required autofocus />
      <ng-container *ngIf="entity.get('lastName')?.invalid && (entity.get('lastName')?.dirty || entity.get('lastName')?.touched)">
        <small class="p-error" *ngIf="entity.get('lastName')?.errors?.['required']">Los apellidos son obligatorios.</small>
        <small class="p-error" *ngIf="entity.get('lastName')?.errors?.['minlength']">Los apellidos deben tener al menos {{entity.get('lastName')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
      </ng-container>
    </div>
    <div class="field field-flex">
      <label for="emailAddress">Email:*</label>
      <input type="text" pInputText id="emailAddress" formControlName="emailAddress" placeholder="user@pulpi.mx" required />
      <ng-container *ngIf="entity.get('emailAddress')?.invalid && (entity.get('emailAddress')?.dirty || entity.get('emailAddress')?.touched)">
        <small class="p-error" *ngIf="entity.get('emailAddress')?.errors?.['required']">El campo es obligatorio.</small>
        <small class="p-error" *ngIf="entity.get('emailAddress')?.errors?.['pattern']">El valor no es un email válido.</small>
      </ng-container>
    </div>
  </form>
</ng-container>
