import { Component } from '@angular/core';
import {Message, MessageService} from "primeng/api";
import {NgxCsvParser, NgxCSVParserError} from "ngx-csv-parser";
import {IApplicationMassive} from "@modules/private/application/data/interfaces/application.interface";
import {ApiApplicationService} from "@modules/private/application/data/services/api-application.service";

@Component({
  selector: 'app-massive-application',
  templateUrl: './massive-application.component.html',
  styleUrls: ['./massive-application.component.scss'],
  providers: [MessageService]
})
export class MassiveApplicationComponent {
  visible: boolean = false;
  uploadData: IApplicationMassive[] = []
  isUploading = false
  responseMessage: Message[] = []
  showMessage = false
  showSkipOverPaid = false
  showSkipPaymentsAlreadyApplied = false
  typeProductOptions = [
    {label: 'Creditos', value: 'LOAN'},
    {label: 'Retiros', value: 'WITHDRAWAL'}
  ]
  typeProduct = 'LOAN'
  skipOverpaidProduct = false
  skipPaymentAlreadyApplied = false
  constructor(
    private messageService: MessageService,
    private ngxCsvParser: NgxCsvParser,
    private apiApplicationService: ApiApplicationService
  ) {
  }

  showDialog() {
    this.visible = true;
  }

  onUpload(event: any) {
    const files = event.files
    this.isUploading = true
    this.showSkipOverPaid = false
    this.showSkipPaymentsAlreadyApplied = false
    this.showMessage = false
    this.ngxCsvParser.parse(files[0], {header: false, delimiter: ',', encoding: 'utf8'})
      .pipe().subscribe({
      next: (result: any): void => {
        console.log('Result', result)
        this.uploadData = []
        for (const key in result) {
          const object = result[key] as string[]
          if(+key > 0) {
            if(object[1] === undefined || object[2] === undefined) {
              this.messageService.add({severity: 'error', summary: 'Error de formato', detail: 'Hubo un error en el formato del archivo', life: 3000});
              break
            }
            this.uploadData.push({
              folio: object[0],
              amount: object[1],
              date: object[2],
              clabe: object[3],
              trackingId: object[4]

            })
          }
        }
        if (this.uploadData.length > 0) {
          this.apiApplicationService.upload(this.uploadData, this.typeProduct, this.skipPaymentAlreadyApplied, this.skipOverpaidProduct).subscribe(r => {
            console.log(r)
            this.isUploading = false
            if (r.data?.errorType === 'ERROR_PAYMENT') this.showSkipPaymentsAlreadyApplied = true

            if (r.data?.errorType === 'ERROR_OVERPAID') {
              this.showSkipOverPaid = true
              this.showSkipPaymentsAlreadyApplied = true
            }
            this.responseMessage = [
              {
                severity: r.error ? 'error': 'success',
                summary: r.error ? r.msg:'El archivo se subio exitosamente',
                closable: true,
                detail: (r.data.urlReport)  ? `<a href="${r.data?.urlReport}" target="_blank">Descargar reporte</a>`:''
              }]
            this.showMessage = true
            if (!r.error) {
              this.skipPaymentAlreadyApplied = false
              this.skipOverpaidProduct = false
            }
          })
        }
        console.log(this.uploadData)
      },
      error: (error: NgxCSVParserError): void => {
        console.log('Error', error)
        this.isUploading = false
      }
    })
    // this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
  }
}
