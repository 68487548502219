export enum TYPE_PERIOD_ENUM {
  'WEEKLY_PERIOD'= 'Semanal',
  'SEMIMONTHLY_PERIOD'= 'Quincenal',
  'BIWEEKLY_PERIOD'= 'Catorcenal',
  'MONTHLY_PERIOD'= 'Mensual',
}

export enum TYPE_PERIOD_ENUM_ES {
  'Semanal'= 'WEEKLY_PERIOD',
  'Catorcenal'= 'BIWEEKLY_PERIOD',
  'Quincenal'= 'SEMIMONTHLY_PERIOD',
  'Mensual'= 'MONTHLY_PERIOD',
}

export enum TYPE_PERIOD_ENUM_COLOR {
  'WEEKLY_PERIOD'= 'primary',
  'SEMIMONTHLY_PERIOD'= 'success',
  'BIWEEKLY_PERIOD'= 'warning',
  'MONTHLY_PERIOD'= 'danger',
}
