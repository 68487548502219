<ng-container *ngIf="entity">
  <form [formGroup]="entity">
    <div class="field field-flex">
      <label for="comment">Motivo de rechazo</label>
      <textarea rows="5" cols="30" pInputTextarea id="comment" formControlName="comment" placeholder="No cumple con los requerimientos" required autofocus ></textarea>
      <ng-container *ngIf="entity.get('comment')?.invalid && (entity.get('comment')?.dirty || entity.get('comment')?.touched)">
        <small class="p-error" *ngIf="entity.get('comment')?.errors?.['required']">El motivo de rechazo es obligatorio.</small>
        <small class="p-error" *ngIf="entity.get('comment')?.errors?.['minlength']">El motivo de rechazo debe tener al menos {{entity.get('comment')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
      </ng-container>
    </div>
  </form>
</ng-container>
