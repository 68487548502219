import { Injectable } from '@angular/core';
import {ObjectUtils} from "primeng/utils";
@Injectable({
  providedIn: 'root'
})
export class WriteOffService {
  private REVENUE = {
    ESP: 'interes',
    ENG: 'revenue'
  }
  private LATE_FEE = {
  ESP: 'mora',
  ENG: 'lateFee'
  }
  private ACCESSORY_PRINCIPAL_AMOUNT = {
    ESP: 'Capital de Accesorios',
    ENG: 'accessoryPrincipal'
  }
  private ACCESSORY_REVENUE_AMOUNT = {
    ESP: 'Interes de Accesorios',
    ENG: 'accessoryRevenue'
  }
  constructor(
  ) { }

  getTypes(lng: 'ESP' | 'ENG') {
    switch (lng) {
      case 'ESP': return this.getTypesEsp()
      case 'ENG': return this.getTypesEng()
      default : return this.getTypesEng()
    }
  }

  private getTypesEsp(): string[] {
    return ['interes', 'Capital de Accesorios', 'Interes de Accesorios']
  }

  private getTypesEng(): string[] {
    return ['revenue', 'accessoryPrincipal', 'accessoryRevenue']
  }

  private getTypesOrder() : any[] {
    return [
      {type:'revenue', order:1},
      {type:'accessoryPrincipal', order:2},
      {type:'accessoryRevenue', order:3}
    ]
  }

  traduceType(type: string, lng: 'ESP' | 'ENG') {
    switch (type) {
      case 'interes':
        return this.REVENUE[lng]
      case 'lateFee':
        return this.LATE_FEE[lng]
      case 'accessoryPrincipal' :
      case'accessoryPrincipalAmount':
        return this.ACCESSORY_PRINCIPAL_AMOUNT[lng]
      case 'accessoryRevenue':
      case 'accessoryRevenueAmount':
        return this.ACCESSORY_REVENUE_AMOUNT[lng]
      default:
        return this.REVENUE[lng]
    }
  }

  order(writeOffs:any[]) {
    const debtReliefsOrder:[] = []
    const order= this.getTypesOrder()
    writeOffs.forEach((writeOff: any) => {
      writeOff.debtRelief.forEach((debt:any) => {
        order.forEach(it => {
          const regexToEvaluate = new RegExp(`${it.type}`)
          if (regexToEvaluate.test(debt.concept)) debt['order'] = it.order
        })
      })
    })
    writeOffs.forEach(writeOff => {
      writeOff.debtRelief.sort((a:any, b:any) => a.order - b.order)
    })
    return debtReliefsOrder
  }
}


