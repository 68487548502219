<p-card>
  <div class="row">
    <div class="col-12 mb-5 mb-3">
      <h1 class="mb-3">Pagadoras</h1>
      <p class="text-color-secondary">
        Las pagadoras son entidades que controlan una o más razones sociales con las que emplean y pagan a los trabajadores. En algunos casos, la pagadora es el mismo corporativo.
      </p>
      <p-toast></p-toast>
    </div>
  </div>
  <p-table
    #dtPayers
    [scrollable]="true"
    scrollHeight="600px"
    [value]="payers"
    [columns]="cols"
    dataKey="_id"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [loading]="loading"
    [paginator]="true"
    [tableStyle]="{'width': '100%'}"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} pagadoras"
    [globalFilterFields]="['name', 'creationDate', 'hubspotLink']"
    (onFilter)="onFilter($event, dtPayers)"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-row justify-content-between">
        <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
        <div>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dtPayers.filterGlobal(getValue($event), 'contains')" placeholder="Buscar..." />
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="min-width:15rem">
          <div class="flex align-items-center" pSortableColumn="name">
            Nombre
            <p-columnFilter type="text" field="name" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="name"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:15rem">
          <div class="flex align-items-center" pSortableColumn="creationDate">
            Fecha de creación
            <p-columnFilter type="date" field="creationDate" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="creationDate"></p-sortIcon>
          </div>
        </th>
        <ng-container *ngIf="authService.userHasAuthorization('PAYER','UPDATE')">
          <th style="min-width:10rem">
            Acciones
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-payer>
      <tr>
        <td>
          {{ payer.name }}
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">{{ payer.creationDate | date: 'MM/dd/yyyy' }}</span>
        </td>
        <ng-container *ngIf="authService.userHasAuthorization('PAYER','UPDATE')">
          <td>
            <button pButton pRipple type="button" icon="pi pi-pencil" (click)="newEntity('UPDATE', payer)" class="p-button-rounded p-button-text"></button>
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="3">No se encontraron pagadoras</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>

<!------------------------CREATE COMPANY ---------------------------->
<p-dialog [(visible)]="createEntity" [style]="{ width: '450px' }" [header]="formAction === 'CREATE' ? 'Nueva pagadora' : 'Actualizar pagadora'" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <app-create-payer [entity]="entity" [submitted]="submitted"></app-create-payer>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="saveProduct()" [disabled]="!entity.valid"></button>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
