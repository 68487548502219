<p-card>
  <div class="grid">
    <div class="col-12 lg:col-6">
      <h3 class="mb-3">Perfil de usuario</h3>
    </div>
    <ng-container *ngIf="items.length > 0">
      <div class="col-12 lg:col-6 text-right">
        <p-splitButton label="Acciones de usuario" [menuStyle]="{width: '100%'}"  [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
        <p-toast></p-toast>
      </div>
    </ng-container>
  </div>
  <p-tabView styleClass="tabview-custom">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <i class="fa-regular fa-user"></i>
        <span class="ml-2">Resumen</span>
      </ng-template>
      <app-worker-overview [data]="data" [properties]="properties"></app-worker-overview>
    </p-tabPanel>
    <ng-container *ngIf="authService.userHasAuthorization('WORKER', 'READ_SOD_LIST')">
      <p-tabPanel header="Header II">
        <ng-template pTemplate="header">
          <i class="fa-solid fa-credit-card"></i>
          <span class="ml-2">SoD</span>
        </ng-template>
        <app-worker-list-sod [workerId]="workerId"></app-worker-list-sod>
      </p-tabPanel>
    </ng-container>
    <ng-container *ngIf="authService.userHasAuthorization('WORKER', 'READ_LOAN_LIST')">
      <p-tabPanel header="Header III">
        <ng-template pTemplate="header">
          <i class="fa-solid fa-receipt"></i>
          <span class="ml-2">Créditos</span>
        </ng-template>
        <app-worker-list-loan [workerId]="workerId"></app-worker-list-loan>
      </p-tabPanel>
    </ng-container>
    <!--<p-tabPanel header="Header III">
      <ng-template pTemplate="header">
        <i class="pi pi-search"></i>
        <span class="ml-2">Adelantos Pulpi </span>
      </ng-template>
      <app-worker-pulpi-advance></app-worker-pulpi-advance>
    </p-tabPanel>-->
    <!--<p-tabPanel header="Header III">
      <ng-template pTemplate="header">
        <i class="pi pi-search"></i>
        <span class="ml-2">Pagos </span>
      </ng-template>
      <app-worker-list-payment></app-worker-list-payment>
    </p-tabPanel>-->
    <!--<p-tabPanel header="Header III">
      <ng-template pTemplate="header">
        <i class="pi pi-search"></i>
        <span class="ml-2">Documentos</span>
      </ng-template>
      <app-worker-documents></app-worker-documents>
    </p-tabPanel>-->
    <!--<p-tabPanel header="Header III">
      <ng-template pTemplate="header">
        <i class="pi pi-search"></i>
        <span class="ml-2">Actividad</span>
      </ng-template>
      <app-worker-activity></app-worker-activity>
    </p-tabPanel>-->
  </p-tabView>
</p-card>
<p-dialog [(visible)]="createEntity" [style]="{ width: '75vw' }" [header]="'Actualizar'" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <app-worker-update [entity]="entity" [formAction]="'UPDATE'"></app-worker-update>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="update()" [disabled]="!entity.valid"></button>
  </ng-template>
</p-dialog>
<p-dialog [(visible)]="integrateAccounts" [style]="{ width: '75vw' }" [header]="'Integrar cuentas'" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <app-worker-integrate-accounts [workerId]="workerId" [selectedWorkers]="selectedWorkers" (onSelectedWorkerChange)="selectedWorkers = $event"></app-worker-integrate-accounts>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="integrateCollaborators()"></button>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
