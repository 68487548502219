<ng-container *ngIf="data && properties; else elseContent">
<div class="grid">
  <div class="col-12 xl:col-4">
    <div class="grid pulpi-work">
      <div class="pulpi-work-profile">
        <div style="background-image: url('assets/media/images/avatars/support.jpg')"></div>
        <h3 class="text-center">{{data.worker.names.firstName | uppercase}} {{data.worker.names.lastName | uppercase}}</h3>
        <h4>{{data.position.name | uppercase}}</h4>
      </div>
      <div class="pulpi-work-info">
        <ng-container *ngIf="authService.userHasAuthorization('WORKER', 'READ_CONTACT')">
          <div>
            <h5>Teléfono:</h5>
            <p>{{data.worker.phoneNumber || '-'}}</p>
          </div>
          <div>
            <h5>Email:</h5>
            <p>{{data.worker.emailAddress || '-'}}</p>
          </div>
        </ng-container>
        <div>
          <h5>Periodo:</h5>
          <p>{{data.payroll.periodType | selectEnum:$_periodType}}</p>
        </div>
        <ng-container *ngIf="authService.userHasAuthorization('WORKER', 'READ_SALARY_CALCULATION')">
          <div>
            <h5>Salario anual:</h5>
            <p>{{data.position.salary | currency}}</p>
          </div>
          <div>
            <h5>Salario mensual:</h5>
            <p>{{data.position.salary / 12 | currency}}</p>
          </div>
          <div>
            <h5>Restricción del salario:</h5>
            <p>{{properties.withdrawableSalaryRatio}}%</p>
          </div>
          <div>
            <h5>Salario mensual después de restricciones:</h5>
            <p>{{properties.monthlySalaryWithRestriction | currency}}</p>
          </div>
          <div>
            <h5>Salario del periodo:</h5>
            <p>{{properties.periodSalary | currency}}</p>
          </div>
          <div>
            <h5>Dias del periodo / Dias transcurridos:</h5>
            <p>{{properties.daysInPeriod}} / {{properties.accruedDays}}</p>
          </div>
          <div>
            <h5>Salario acumulado al dia {{properties.accruedDays}}:</h5>
            <p>{{properties.accruedSalary | currency}}</p>
          </div>
          <div>
            <h5>Monto disponible (considerando retenciones y retiros):</h5>
            <p>{{properties.availableSalary | currency}}</p>
          </div>
          <div>
            <h5>Salario retirado en el periodo:</h5>
            <p>{{properties.amountWithdrawn | currency}}</p>
          </div>
          <div>
            <h5>Comisión por retiro:</h5>
            <p>{{properties.fee | currency}}</p>
          </div>
          <div>
            <h5>Salario mostrado en la app:</h5>
            <p>{{properties.availableAppSalary | currency}}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="col-12 xl:col-8">
    <div class="grid">
      <div class="col-12">
        <div class="pulpi-info-container">
          <h4 class="pulpi-info-container-title">Dirección</h4>
          <div class="grid">
            <div class="col-6">
              <div class="pulpi-property-value">
                <p>Ciudad</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{data.worker.address.city || '-'}}
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="pulpi-property-value">
                <p>Colonia</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{data.worker.address.neighborhood || '-'}}
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="pulpi-property-value">
                <p>CP</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{data.worker.address.postalCode || '-'}}
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="pulpi-property-value">
                <p>Estado</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{data.worker.address.state || '-'}}
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="pulpi-property-value">
                <p>Calle y número</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{data.worker.address.streetNameAndNumber || '-'}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="pulpi-info-container">
          <h4 class="pulpi-info-container-title">Datos generales</h4>
          <div class="grid">
            <div class="col-6">
              <div class="pulpi-property-value">
                <p>RFC</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{data.worker.rfc}}
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="pulpi-property-value">
                <p>CURP</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{data.worker.curp || '-'}}
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="pulpi-property-value">
                <p>Genero</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{data.worker.sex | selectEnum:$_gendersEnum}}
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="pulpi-property-value">
                <p>Fecha de cumpleaños</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{(data.worker.birthDate | date: 'dd-MM-YYYY') || '-'}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="pulpi-info-container">
          <h4 class="pulpi-info-container-title">Estatus de productos y del usuario</h4>
          <div class="grid">
            <div class="col-6">
              <div class="pulpi-property-value">
                <p>Estatus de Retiros</p>
                <p>
                  <ng-container *ngIf="data.payroll.withdrawalsEnabled">
                    <ng-container *ngTemplateOutlet="active"></ng-container>
                  </ng-container>
                  <ng-container *ngIf="!data.payroll.withdrawalsEnabled">
                    <ng-container *ngTemplateOutlet="deactivated"></ng-container>
                  </ng-container>
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="pulpi-property-value">
                <p>Estatus de Crédito</p>
                <p>
                  <ng-container *ngIf="data.payroll.loansEnabled">
                    <ng-container *ngTemplateOutlet="active"></ng-container>
                  </ng-container>
                  <ng-container *ngIf="!data.payroll.loansEnabled">
                    <ng-container *ngTemplateOutlet="deactivated"></ng-container>
                  </ng-container>
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="pulpi-property-value">
                <p>Estatus de registro</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  <p-tag [severity]="data.worker.status | selectEnum:$_statusUserEnumColor" icon="fa-solid fa-check" [value]="data.worker.status | selectEnum:$_statusUserEnum"></p-tag>
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="pulpi-property-value">
                <p>Estatus de verificacion</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  <p-tag [severity]="data.worker.statusVerification | selectEnum:$_statusUserVerificationEnumColor" icon="fa-solid fa-check" [value]="data.worker.statusVerification | selectEnum:$_statusUserVerificationEnum"></p-tag>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="pulpi-info-container">
          <h4 class="pulpi-info-container-title">Registro</h4>
          <div class="grid">
            <div class="col-12">
              <div class="pulpi-property-value">
                <p>Id de Verificación</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{data.worker.identityValidationId || '-'}}
                </p>
              </div>
            </div>
            <div class="col-12">
              <div class="pulpi-property-value">
                <p>Id de contrato</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{data.worker.agreementId || '-'}}
                </p>
              </div>
            </div>
            <div class="col-12">
              <div class="pulpi-property-value">
                <p>Fecha de activación</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{data.worker.activationDate | date:'dd-MM-YYYY'}}
                </p>
              </div>
            </div>
            <div class="col-12">
              <div class="pulpi-property-value">
                <p>Fecha de ingreso:</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{data.position.startDate | date:'dd-MM-YYYY' }}
                </p>
              </div>
            </div>
            <div class="col-12">
              <div class="pulpi-property-value">
                <p>Fecha de egreso:</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{(data.position.endDate | date:'dd-MM-YYYY') || '-'}}
                </p>
              </div>
            </div>
            <div class="col-12">
              <div class="pulpi-property-value">
                <p>Fecha de aplicación de egreso:</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{(data.position.endDateApplied | date:'dd-MM-YYYY') || '-'}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="pulpi-info-container">
          <h4 class="pulpi-info-container-title">Datos de empresa</h4>
          <div class="grid">
            <div class="col-12">
              <div class="pulpi-property-value">
                <p>Corporativo</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{data.payroll.companyId.name || '-'}}
                </p>
              </div>
            </div>
            <div class="col-12">
              <div class="pulpi-property-value">
                <p>Pagadora</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{data.payroll.contractId.remuneratorId.name || '-'}}
                </p>
              </div>
            </div>
            <div class="col-12">
              <div class="pulpi-property-value">
                <p>Convenio</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  {{data.payroll.contractId.entity.name|| '-'}}
                </p>
              </div>
            </div>
            <div class="col-12">
              <div class="pulpi-property-value">
                <p>Nomina</p>
                <p>
                  <i class="fa-solid fa-arrow-right mr-3"></i>
                  <span>{{data.payroll.name ||'-'}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-container>
<ng-template #elseContent>
  <div class="row">
    <div class="col-12">
      <h5 class="text-center">No se encontro el recurso</h5>
    </div>
  </div>
</ng-template>

<ng-template #active>
  <i class="fa-solid fa-arrow-right mr-3"></i>
  <p-tag severity="success" icon="fa-solid fa-check" value="Activo"></p-tag>
</ng-template>

<ng-template #deactivated>
  <i class="fa-solid fa-arrow-right mr-3"></i>
  <p-tag severity="danger" icon="fa-solid fa-times" value="Desactivado"></p-tag>
</ng-template>
