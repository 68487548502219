import { Component } from '@angular/core';

@Component({
  selector: 'app-skeleton-footer',
  templateUrl: './skeleton-footer.component.html',
  styleUrls: ['./skeleton-footer.component.scss']
})
export class SkeletonFooterComponent {

}
