import {Component, Input} from '@angular/core';
import {PUSHING_STATUS} from "../../data/enums/pushing.enum";
import {IPushingFlowDetail, StageItem} from "@modules/private/pushing/data/interfaces/ipushing.interface";

@Component({
  selector: 'app-pushing-timeline',
  templateUrl: './pushing-timeline.component.html',
  styleUrls: ['./pushing-timeline.component.scss']
})
export class PushingTimelineComponent {
    @Input() data!: IPushingFlowDetail
    @Input() stages: StageItem[] = []
    $_pushing_status = PUSHING_STATUS


}
