import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {FlowService} from "@modules/private/pushing/data/services/flow.service";
import {ApiWorkerService} from "@modules/private/workers/data/services/api/api-worker.service";

@Component({
  selector: 'app-pushing-new-process',
  templateUrl: './pushing-new-process.component.html',
  styleUrls: ['./pushing-new-process.component.scss']
})
export class PushingNewProcessComponent implements OnInit{
  @Input() entity!: FormGroup
  @Input() submitted!: boolean
  flows:{label: string; value: string;}[]= []
  workers: {label: string; value: string;}[] = []
  constructor(
    private flowService: FlowService,
    private apiWorkerService: ApiWorkerService
  ) {
  }

  ngOnInit() {
    this.getFlows()
    this.getWorkers()
  }

  getUserName(id: string) {
    return this.workers.find(u =>  u.value === id)?.label
  }
  getFlows() {
    this.flowService.getAll().subscribe((r) => {
      if (!r.error) {
        this.flows = r.data.map(f => {
          return {
            label: f.name,
            value: f._id
          }
        })
      }
    })
  }

  getWorkers() {
    this.apiWorkerService.getAllNamesAndRfcs().subscribe(r => {
      const workers: {label: string; value: string;}[] = []
      r.data.forEach(u => {
        workers.push({label: `${u.rfc} | ${u.firstName} ${u.lastName}`, value: u._id})
      })
      this.workers = workers
    })
  }
}
