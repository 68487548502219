import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeElapsed'
})
export class TimeElapsedPipe implements PipeTransform {

  transform(value: Date): unknown {
    const time = (new Date().getTime()- (new Date(value).getTime() + (60 * 60  * 6 * 1000))) / (60 * 1000)
    if (['undefined', 'null', ''].includes(`${value}`)) return '--'
    if (time < 60) {
      return `${Math.round(time)} minutos`
    } else if (time < (60 * 24 * 2)) {
      return `${Math.round(time / (60))} horas`
    } else return `${Math.round(time / (60 * 24))} días`
  }

}
