import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {ApiAgreementService} from "@modules/private/agreement/data/services/api/api-agreement.service";
import {ApiCompanyService} from "@modules/private/company/data/services/api/api-company.service";

@Component({
  selector: 'app-create-payroll',
  templateUrl: './create-payroll.component.html',
  styleUrls: ['./create-payroll.component.scss']
})
export class CreatePayrollComponent  implements  OnInit {
  @Input() entity!: FormGroup
  @Input() submitted!: boolean
  @Input() type: 'CREATE' | 'UPDATE' | 'CALENDAR' = 'CREATE'
  agreements: {label: string; value: string;}[] = []
  companies: {label: string; value: string;}[] = []
  periods = [
    {label: 'Selecciona un periodo', value: ''},
    {label: 'SEMANAL', value: 'WEEKLY_PERIOD'},
    {label: 'QUINCENAL', value: 'SEMIMONTHLY_PERIOD'},
    {label: 'CATORCENAL', value: 'BIWEEKLY_PERIOD'},
    {label: 'MENSUAL', value: 'MONTHLY_PERIOD'}
  ]

  allOptionsAvailable = true
  typeCalculations = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Salario acumulado', value: 'ACCRUED'},
    {label: 'Salario desde el día uno', value: 'FIRST_DAY'}
  ]
  constructor(
    private apiAgreementService: ApiAgreementService,
    private apiCompanyService: ApiCompanyService
  ) {

  }


  ngOnInit() {
    this.apiCompanyService.getAll().subscribe(r => {
      r.data.forEach(a => {
        this.companies.push({label: a.name, value: a._id})
      })
    })

    this.apiAgreementService.getAll().subscribe(r => {
      this.allOptionsAvailable = true
      r.data.forEach(a => {
        this.agreements.push({label: a.entity.name, value: a._id})
      })
    })
  }

  getNameCompany(id: string) {
    return this.companies.find(p => p.value === id)?.label
  }

  getNameAgreement(id: string) {
    return this.agreements.find(p => p.value === id)?.label
  }
}
