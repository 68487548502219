import { Injectable } from '@angular/core';
import {environment} from "@env/environment";
import {catchError, map, Observable, of} from "rxjs";
import {ApiClass} from "@data/class/api-class";

@Injectable({
  providedIn: 'root'
})
export class ApiIncodeService extends ApiClass {
  constructor() {
    super();
    this.path = 'loan'
  }

  getOnboardingStatus(token: string, sessionId: string) {
    const response = {onboardingStatus: 'Desconocido'}
    return this.http.get<{onboardingStatus: string}>(
      `${environment.incodeUrl}get/onboarding/status?id=${sessionId}`,
      {
        headers: {
          'X-Incode-Hardware-Id': token,
          'api-version': '1.0',
          'x-api-key': environment.incodeApiKey
        }
      }).pipe(
        map((r) => r as {onboardingStatus: string}),
        catchError((e) => of(response))
    )
  }

  getSession(interviewId: string) {
    const response = { interviewId: null, token: null }
    return this.http.post<{ interviewId: string; token: string }>(
      `${environment.incodeUrl}start`,
      {
        configurationId: environment.incodeLoanConfigurationID,
        countryCode: 'MX',
        interviewId,
        language: 'es-ES'
      },
      {
        headers: {
          'api-version': '1.0',
          'x-api-key': environment.incodeApiKey
        }
      }
      ).pipe(
      map((r) => r as { interviewId: string; token: string }),
      catchError((e) => of(response))
    )
  }

  getDocuments(token: string) {
    const response = {
      addressStatement: '',
      document: '',
      fullFrameBackID: '',
      fullFrameFrontID: '',
      otherDocument1: '',
      otherDocument2: '',
      selfie: ''
    }
    return this.http.post<{
      addressStatement: string;
      document: string;
      fullFrameBackID: string;
      fullFrameFrontID: string;
      otherDocument1: string;
      otherDocument2: string;
      selfie: string;
    }>(
      `${environment.incodeUrl}get/images`,
      {
        images: [
          'addressStatement',
          'document',
          'fullFrameBackID',
          'fullFrameFrontID',
          'otherDocument1',
          'otherDocument2',
          'selfie'
        ]
      },
      {
        headers: {
          'X-Incode-Hardware-Id': token,
          'api-version': '1.0',
          'x-api-key': environment.incodeApiKey
        }
      }
    ).pipe(
      map((r) => r as {
        addressStatement: string;
        document: string;
        fullFrameBackID: string;
        fullFrameFrontID: string;
        otherDocument1: string;
        otherDocument2: string;
        selfie: string;
      }),
      catchError((e) => of(response))
    )
  }
}
