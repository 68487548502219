
  <form [formGroup]="entity">
    <div class="grid">
      <div class="col-12">
        <div class="field">
          <label for="workerId">Usuario</label>
          <div class="flex justify-content-center flex-column">
            <p-dropdown formControlName="workerId" [panelStyle]="{'width': '100%' }" scrollHeight="150px" [options]="workers" optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true" placeholder="Selecciona un usuario">
              <ng-template pTemplate="selectedItem">

                <div class="flex align-items-center" *ngIf="entity.value.workerId">
                  <div>{{getUserName(entity.value.workerId)}}</div>
                </div>
              </ng-template>
              <ng-template let-user pTemplate="item">
                <div class="flex align-items-center">
                  <div>{{ user.label }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <ng-container *ngIf="entity.get('workerId')?.invalid && (entity.get('workerId')?.dirty || entity.get('workerId')?.touched)">
              <small class="p-error" *ngIf="entity.get('workerId')?.errors?.['required']">El usuario es obligatorio.</small>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="amount">Monto del crédito</label>
          <p-inputNumber formControlName="amount" inputId="amount" mode="currency" currency="MXN" locale="es-MX"> </p-inputNumber>
          <ng-container *ngIf="entity.get('amount')?.invalid && (entity.get('amount')?.dirty || entity.get('amount')?.touched)">
            <small class="p-error" *ngIf="entity.get('amount')?.errors?.['required']">El monto del crédito es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('amount')?.errors?.['min']">El valor minimo es {{entity.get('amount')?.errors?.['min']['min']}}.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field">
          <label for="installmentCount">Número de letras</label>
          <p-inputNumber formControlName="installmentCount" inputId="installmentCount"> </p-inputNumber>
          <ng-container *ngIf="entity.get('installmentCount')?.invalid && (entity.get('installmentCount')?.dirty || entity.get('installmentCount')?.touched)">
            <small class="p-error" *ngIf="entity.get('installmentCount')?.errors?.['required']">El número de letras es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('installmentCount')?.errors?.['min']">El valor minimo de letras es {{entity.get('installmentCount')?.errors?.['min']['min']}}.</small>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
