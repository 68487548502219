import {ITableCol} from "@data/interfaces/itable.interface";
import {
  PUSHING_FLOW_STATUS, PUSHING_FLOW_STATUS_COLOR,
  PUSHING_STATUS,
  PUSHING_STATUS_COLOR
} from "@modules/private/pushing/data/enums/pushing.enum";
import {
  WORKER_STATUS_ENUM,
  WORKER_STATUS_ENUM_COLOR,
  WORKER_STATUS_VERIFICATION_ENUM, WORKER_STATUS_VERIFICATION_ENUM_COLOR
} from "@modules/private/workers/data/enums/workerStatus.enum";
import {LOAN_STATUS_ENUM, LOAN_STATUS_ENUM_COLOR} from "@modules/private/loan/data/enums/loan.enum";
import {PERIOD_TYPE_ENUM, PERIOD_TYPE_ENUM_COLOR} from "@modules/private/payroll/data/enums/payroll.enum";

export const PUSHING_MAS_NOMINA: ITableCol[] = [
  {type: 'text', field: '_id', header: 'Identificador', showFilter: false, dataType: 'link', linkField: '_id', rootLink: 'omnicanal'},
  {type: 'text', field: 'rfc', header: 'RFC', showFilter: true, dataType: 'link', linkField: 'workerId', rootLink: 'worker'},
  {type: 'text', field: 'folio', header: 'Producto asociado', showFilter: false, dataType: 'link', linkField: 'productId', rootLink: 'loan'},
  {type: 'text', field: 'firstName', header: 'Nombre(s)', showFilter: true},
  {type: 'text', field: 'lastName', header: 'Apellidos', showFilter: true},
  {type: 'text', field: 'fullName', header: 'Nombre completo', showFilter: true},
  {type: 'text', field: 'emailAddress', header: 'Email', showFilter: true},
  {type: 'text', field: 'phoneNumber', header: 'Teléfono', showFilter: true},
  {type: 'number', field: 'stages', header: 'Etapas totales', showFilter: true},
  {type: 'number', field: 'progress', header: 'Etapas completadas', showFilter: true},
  {type: 'number', field: 'progressAdvance', header: 'Progreso', showFilter: true, dataType: 'progress'},
  {type: 'text', field: 'flowName', header: 'Nombre del Flujo', showFilter: true},
  {type: 'text', field: 'flowIdentifier', header: 'Identificador del flujo', showFilter: true},
  {type: 'text', field: 'flowProvider', header: 'Proveedor del flujo', showFilter: true},
  {type: 'enum', field: 'status', header: 'Estatus', showFilter: true, dataType: 'enum', enum: PUSHING_FLOW_STATUS, enumColor: PUSHING_FLOW_STATUS_COLOR, options: [
      {value: 'IN_PROCESS', label: 'En proceso'},
      {value: 'COMPLETED', label: 'Completado'},
      {value: 'CANCELLED', label: 'Cancelado'},

    ]},
  {type: 'text', field: 'currentStage', header: 'Ultima etapa', showFilter: true},
  {type: 'text', field: 'origin', header: 'Origen', showFilter: true},
  {type: 'date', field: 'creationDate', header: 'Fecha de solicitud', showFilter: true, dataType: 'timeElapsed'},
  {type: 'date', field: 'lastUserResponse', header: 'Última respuesta', showFilter: true, dataType: 'timeElapsed'},
  {type: 'text', field: 'comment', header: 'Comentario', showFilter: true},
  {type: 'enum', field: 'workerStatus', header: 'Estatus de registro del usuario', showFilter: true, dataType: 'enum', enum: WORKER_STATUS_ENUM, enumColor: WORKER_STATUS_ENUM_COLOR, options: [
      {value: 'ACTIVE', label: 'Activo'},
      {value: 'PENDING_ACTIVATION', label: 'Pendiente de activación'},
      {value: 'DEACTIVATED', label: 'Desactivado'},
    ]},
  {type: 'enum', field: 'workerStatusVerification', header: 'Estatus de cuenta del usuario', showFilter: true, dataType: 'enum', enum: WORKER_STATUS_VERIFICATION_ENUM, enumColor: WORKER_STATUS_VERIFICATION_ENUM_COLOR, options: [
      {value: 'PENDING_VERIFICATION', label: 'Pendiente de verificación'},
      {value: 'NO_REQUIRED_VERIFICATION', label: 'No requiere verificación'},
      {value: 'COMPLETED', label: 'Completada'},
    ]},
  {type: 'text', field: 'workerProvider', header: 'Proveedor del usuario', showFilter: true},
  {type: 'numeric', field: 'salary', header: 'Salario', dataType: 'currency', showFilter: true},
  {type: 'text', field: 'namePosition', header: 'Nombre de la posición', showFilter: true},
  {type: 'date', field: 'startDate', header: 'Fecha de ingreso', showFilter: true, dataType: 'date'},
  {type: 'date', field: 'endDate', header: 'Fecha de egreso', showFilter: true, dataType: 'date'},
  {type: 'date', field: 'productAmount', header: 'Monto del crédito', dataType: 'currency', showFilter: true},
  {type: 'enum', field: 'productStatus', header: 'Estatus del crédito', showFilter: true, dataType: 'enum', enum: LOAN_STATUS_ENUM, enumColor: LOAN_STATUS_ENUM_COLOR, options: [
      {value: 'APPROVED', label: 'Aprobado'},
      {value: 'PENDING_APPROVAL', label: 'Pendiente de aprobación'},
      {value: 'DENIED', label: 'Denegado'}
    ]},
  {type: 'date', field: 'productRequest', header: 'Fecha de solicitud del crédito', showFilter: true, dataType: 'date' },
  {type: 'date', field: 'productDispersionDate', header: 'Fecha de entrega del crédito', showFilter: true, dataType: 'date'},
  {type: 'date', field: 'company', header: 'Empresa', showFilter: true},
  {type: 'date', field: 'contract', header: 'Razón social', showFilter: true},
  {type: 'date', field: 'remunerator', header: 'Pagadora', showFilter: true},
  {type: 'date', field: 'payroll', header: 'Nómina', showFilter: true},
  {type: 'enum', field: 'period', header: 'Periodo', showFilter: true, dataType: 'enum', enum: PERIOD_TYPE_ENUM, enumColor: PERIOD_TYPE_ENUM_COLOR, options: [
      {value: 'WEEKLY_PERIOD', label: 'Semanal'},
      {value: 'SEMIMONTHLY_PERIOD', label: 'Quincenal'},
      {value: 'BIWEEKLY_PERIOD', label: 'Catorcenal'},
      {value: 'MONTHLY_PERIOD', label: 'Mensual'},
    ]},
  {type: 'text', field: 'advisor', header: 'Asesor', showFilter: true},
]
