import {Component, Input} from '@angular/core';
import {ICreateCompany} from "@modules/private/company/data/interfaces/icompany.interface";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss']
})
export class CreateCompanyComponent {
  @Input() company!: FormGroup
  @Input() submitted!: boolean
}
