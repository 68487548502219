import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {IDocument, IFlowData, IInputForm} from "@modules/private/pushing/data/interfaces/ipushing.interface";
import {Message} from "primeng/api";

@Component({
  selector: 'app-pushing-record-form',
  templateUrl: './pushing-record-form.component.html',
  styleUrls: ['./pushing-record-form.component.scss']
})
export class PushingRecordFormComponent implements OnInit {
  responseMessage: Message[] = []
  showMessage = false
  isLoading = false
  entity!: FormGroup
  patterns: any =  {
    phone52: /^\+52[0-9]{10}$/,
    curp: /^[A-Z]{4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z]{6}[0-9A-Z]{2}$/,
    clabe: /^\d{18}$/,
    email: /^[a-zA-Z0-9_.+]*[a-zA-Z][a-zA-Z0-9_.+]*@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
  }
  @Input() data!: IDocument
  @Input() values!: IFlowData | null
  inputs: IInputForm[] = []
  constructor(
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.inputs = JSON.parse(this.data.settings)
    this.generateForm(this.inputs)
    console.log(this.values)
  }
  generateForm (inputs: IInputForm[]) {
    const fields:any = {}
    console.log(this.values, "aqui")
    const currentValues = (this.values) ? JSON.parse(this.values?.value) : {}
    console.log("currentValues", currentValues)
    for (const input of inputs) {
      if(input.typeField !== 'SEPARATOR') {
        const validations = []
        for (const validation of input.validations) {
          switch (validation.type) {
            case 'pattern':
              console.log('pattern', validation.value)
              if (this.patterns[validation.value as keyof typeof this.patterns]) {
                const pattern = this.patterns[validation.value as keyof typeof this.patterns] ?? new RegExp(validation.value.toString())
                validations.push(Validators.pattern(pattern))
              }
              break
            case 'maxlength':
            case 'minlength':
              validations.push(Validators.minLength(+validation.value))
              break
            default:
              validations.push(Validators.required)
              break
          }
        }
        if (input.typeField === 'INPUT_NUMBER') fields[input.id] = [currentValues[input.id] ??  0, validations]
        else if (input.typeField === 'INPUT_DATE') fields[input.id] = [currentValues[input.id] ? new Date(currentValues[input.id]):  '', validations]
        else fields[input.id] = [currentValues[input.id] ?? '', validations]
      }

    }
    this.entity = this.fb.group(fields)
    console.log(this.entity)
  }

  getEntity() {
    const data = new FormData()
    data.append('value', JSON.stringify(this.entity.value))
    data.append('documentId', this.data._id)
    return data
  }
}
