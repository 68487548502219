<div class="grid">
  <div class="col-12">
    <p-messages [(value)]="responseMessage" [enableService]="visible" [closable]="true" [escape]="false"></p-messages>
  </div>
  <div class="col-12">
    <div class="card-report">
      <div class="card-report-info">
        <h3 class="mb-3">Reporte de migración de PDL a CIB:</h3>
        <h6 class="mb-3">El reporte de migración de los créditos permite generar el formato necesario para migrar los créditos a la plataforma CIB. Las fechas solicitadas son para retornar los créditos generados en ese periodo de tiempo, la fecha máxima es opcional. </h6>
        <ng-container *ngIf="!isLoading; else loading">
          <button pButton pRipple label="Generar reporte" icon="pi pi-check" class="p-button-success" [disabled]="entity.invalid" (click)="getReport()"></button>
        </ng-container>
        <ng-template #loading>
          <p-progressSpinner></p-progressSpinner>
        </ng-template>
      </div>
      <div class="card-report-image"></div>
    </div>
  </div>
  <div class="col-12">
    <form [formGroup]="entity">
      <div class="grid">
        <div class="col-12 md:col-6">
          <div class="field field-flex">
            <label for="initDate">Fecha de inicio:*</label>
            <input type="date" pInputText id="initDate" formControlName="initDate" required />
            <ng-container *ngIf="entity.get('initDate')?.invalid && (entity.get('initDate')?.dirty || entity.get('initDate')?.touched)">
              <small class="p-error" *ngIf="entity.get('initDate')?.errors?.['required']">La primer fecha es obligatoria.</small>
            </ng-container>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field field-flex">
            <label for="endDate">Fecha máxima:</label>
            <input type="date" pInputText id="endDate" formControlName="endDate"/>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
