import {Component, EventEmitter, Output} from '@angular/core';
import {ApiReportsService} from "@modules/private/reports/data/services/api/api-reports.service";
import {Message} from "primeng/api";

@Component({
  selector: 'app-sod-report',
  templateUrl: './sod-report.component.html',
  styleUrls: ['./sod-report.component.scss']
})
export class SodReportComponent {
  @Output() onGenerated = new EventEmitter()
  visible = false
  isLoading = false
  responseMessage: Message[] = []
  constructor(
    private apiReportsService: ApiReportsService
  ) {
  }
  getReport() {
    this.visible = false
    this.isLoading = true
    this.responseMessage = []
    this.apiReportsService.getReport().subscribe((r) => {
      this.responseMessage = [
        {
          summary: r.error ? 'Hubo un error al obtener el reporte':'El reporte se genero correctamente',
          severity: r.error ? 'error': 'success',
          closable: true,
          detail: r.error ? '' : `<a href="${r.data.url}" target="_blank">Descargar reporte</a>`
        }]
      this.visible = true
      this.isLoading = false
      this.onGenerated.emit()
    })
  }
}
