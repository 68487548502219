import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  ApiPlatformSettingsService
} from "@modules/private/platform-settings/data/services/api/api-platform-settings.service";
import {MessageService} from "primeng/api";
import {AuthService} from "@data/services/modules/auth/auth.service";

@Component({
  selector: 'app-platform-settings',
  templateUrl: './platform-settings.component.html',
  styleUrls: ['./platform-settings.component.scss'],
  providers: [MessageService]
})
export class PlatformSettingsComponent {
  @Input() entity!: FormGroup
  isUpdating = false;

  constructor(
    private fb: FormBuilder,
    private apiPlatformSettingsService: ApiPlatformSettingsService,
    private messageService: MessageService,
    public authService: AuthService
  ) {
    this.getProperties()
  }

  getProperties() {
    this.apiPlatformSettingsService.get().subscribe(r => {
      if(!r.error) {
        this.entity = this.fb.group({
          sodIsActive: [r.data.sodIsActive ?? false, [Validators.required]],
          loanIsActive: [r.data.loanIsActive ?? false, [Validators.required]],
          emailIsActive: [r.data.emailIsActive ?? false, [Validators.required]],
          smsIsActive: [r.data.smsIsActive ?? false, [Validators.required]],
          whatsappIsActive: [r.data.whatsappIsActive ?? false, [Validators.required]],
          pushNotificationsIsActive: [r.data.pushNotificationsIsActive ?? false, [Validators.required]],
          hubspotNotificationIsActive: [r.data.hubspotNotificationIsActive ?? false, [Validators.required]],
          requestCEPIsActive: [r.data.requestCEPIsActive ?? false, [Validators.required]],
          maintenanceIsActive: [r.data.maintenanceIsActive ?? false, [Validators.required]],
          forceLastUpdateInApp: [r.data.forceLastUpdateInApp ?? false, [Validators.required]],
          androidLastVersion: [r.data.androidLastVersion ?? '', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
          iosLastVersion: [r.data.iosLastVersion ?? '', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
        })
      }
    })
  }

  updateSettings() {
    this.isUpdating = true
    this.apiPlatformSettingsService.update(this.entity.value).subscribe(r => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 5000 });
      this.isUpdating = false
    })
  }
}
