
<ng-container *ngIf="data;">
  <div class="grid background-secondary">
    <div class="col-12 comment-rejected" *ngIf="values && values.comment !== ''">
      Último motivo de rechazo: <strong>{{values.comment}}</strong>
    </div>
  </div>
</ng-container>
<div class="col-12">
    <div class="grid">
      <div class="col-12">
        <div class="main-title">
          Validacion Manual
        </div>
      </div>
      <div class="col-12">
        <p>Esta estapa es manual, necesitas validar los pasos anteriores, para que en caso que toda la información este correcta, puedas aprobar el proceso y el usuario pueda continuar, en caso contrario debes rechazar los procesos que no se encuentren bien y esperar a que el usuario los resuelva, o como asesor llenar la información y concluir el proceso.</p>
      </div>
    </div>
</div>
<p-toast></p-toast>
