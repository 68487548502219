import {Component, Input} from '@angular/core';
import {IDocument, IFlowData} from "@modules/private/pushing/data/interfaces/ipushing.interface";
import {Message} from "primeng/api";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {faCircle, faClipboard, faPaperclip, faTrashCan, faUpload} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-pushing-record-document',
  templateUrl: './pushing-record-document.component.html',
  styleUrls: ['./pushing-record-document.component.scss']
})
export class PushingRecordDocumentComponent {
  responseMessage: Message[] = []
  showMessage = false
  @Input() data!: IDocument
  @Input() values!: IFlowData | null
  entity: FormGroup
  hasFile = false
  file!: File
  allowUploadFile = false
  protected readonly faCircle = faCircle;
  protected readonly faUpload = faUpload;
  protected readonly faTrashCan = faTrashCan;
  constructor(
    private fb: FormBuilder
  ) {
    this.entity = this.fb.group({
      file: ['', [Validators.required]]
    })
  }

  select(event: any){
    this.hasFile = true
    console.log(event)
    this.file = event.files[0]
  }

  clear() {
    this.hasFile = false
  }

  getEntity() {
    const data = new FormData()
    data.append('document', this.file)
    data.append('documentId', this.data._id)
    return data
  }

  protected readonly faClipboard = faClipboard;
  protected readonly faPaperclip = faPaperclip;
}
