import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {IWeetrustDocument} from "@modules/private/loan/data/interfaces/weetrusth.interface";
import {map} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ApiWeetrusthService extends ApiClass{

  constructor() {
    super()
    this.path ='weetrust'
  }

  getDocumentById(documentId: string) {
    return this.getCollection(documentId).pipe(map((r) => r as {error: boolean; msg: string; data: IWeetrustDocument}))
  }
}
