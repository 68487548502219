import {Component, Input, OnInit} from '@angular/core';
import {IDocument, IFlowData} from "@modules/private/pushing/data/interfaces/ipushing.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiIncodeService} from "@data/services/api/api-incode.service";
import {LOAN_INCODE_ONBOARDING_STATUS} from "@modules/private/loan/data/enums/loan.enum";

@Component({
  selector: 'app-pushing-record-idetity',
  templateUrl: './pushing-record-idetity.component.html',
  styleUrls: ['./pushing-record-idetity.component.scss']
})
export class PushingRecordIdetityComponent implements OnInit {
  @Input() data!: IDocument
  @Input() values!: IFlowData | null
  incodeSessionStatus = '-'
  urlIncodeSession = ''
  documents = {
    addressStatement: '',
    document: '',
    fullFrameBackID: '',
    fullFrameFrontID: '',
    otherDocument1: '',
    otherDocument2: '',
    selfie: ''
  }
  entity!: FormGroup
  updateValidation = false
  $_loanOnboardingStatus = LOAN_INCODE_ONBOARDING_STATUS
  constructor(
    private fb: FormBuilder,
    private apiIncodeService: ApiIncodeService
  ) {
    this.entity = this.fb.group({
      sessionId: ['', [Validators.required, Validators.minLength(24), Validators.maxLength(24)]],
      requireUserAction: [false, [Validators.required]]
    });
  }

  ngOnInit() {
    this.getSessionData(this.values?.value ?? '')
  }

  getSessionData(sessionId: string) {
    if (sessionId !== '') {
      this.apiIncodeService.getSession(sessionId).subscribe(r => {
        if (r.token) {
          this.apiIncodeService.getOnboardingStatus(r.token, sessionId).subscribe(rr => {
            this.incodeSessionStatus = rr.onboardingStatus
            this.urlIncodeSession = `https://dashboard.incode.com/single-session/${sessionId}`
          })
          this.apiIncodeService.getDocuments(r.token).subscribe(rr => {
            this.documents = rr
          })
        }
        console.log(this.documents)
      })
    }
  }

  getEntity() {
    const data = new FormData()
    data.append('value', this.entity.value.sessionId)
    data.append('requireUserAction', this.entity.value.requireUserAction)
    data.append('documentId', this.data._id)
    return data
  }
}
