<ng-container *ngIf="loans">
  <div class="grid">
    <div class="col-12">
      <p-table
        [scrollable]="true"
        scrollHeight="600px"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 20, 50]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} creditos"
        [value]="loans"
        [loading]="isLoading"
        [tableStyle]="{ 'min-width': '100%' }">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="folio">Folio <p-sortIcon field="folio"></p-sortIcon></th>
            <th>Tipo de producto</th>
            <th pSortableColumn="accountNumber">Cuenta CLABE <p-sortIcon field="accountNumber"></p-sortIcon></th>
            <th pSortableColumn="date">Fecha de solicitud <p-sortIcon field="date"></p-sortIcon></th>
            <th pSortableColumn="installment.dueDate">Fecha de entrega <p-sortIcon field="installment.dueDate "></p-sortIcon></th>
            <th pSortableColumn="amount">Monto solicitado <p-sortIcon field="amount"></p-sortIcon></th>
            <th pSortableColumn="fee">Comision por apertura <p-sortIcon field="fee"></p-sortIcon></th>
            <th pSortableColumn="collectedAmount">Tasa de interes anual <p-sortIcon field="collectedAmount"></p-sortIcon></th>
            <th style="min-width: 25rem">Estatus</th>
            <th>Numero de letras</th>
            <th>Monto de la letra</th>
            <th>Monto cobrado de capital</th>
            <th>Monto cobrado de interes</th>
            <th>Monto por cobrar de capital</th>
            <th>Monto por cobrar de interes</th>
            <th>Fecha de exigibilidad de la primer letra</th>
            <th>Fecha de exigibilidad de la ultima letra</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-loan>
            <tr>
              <td>
                <a class="link-subsection" routerLink="{{'/loan/'+loan._id}}">{{ loan.folio}}</a>
              </td>
              <td>
                <p-tag [value]="getTypeProduct(loan)" [severity]="getSeverity(getTypeProduct(loan))"></p-tag>
              </td>
              <td>{{ loan.accountNumber}}</td>
              <td>{{ loan.creationDate | date:'dd-MM-YYYY' }}</td>
              <td>{{ loan.date | date: 'dd-MM-YYYY'}}</td>
              <td>{{ loan.amount | currency }}</td>
              <td>{{ loan.creationFee | currency}}</td>
              <td>{{ loan.rate | percent}}</td>
              <td>
                <p-tag [value]="loan.status | selectEnum:$_statusLoan" [severity]="loan.status | selectEnum:$_statusLoanColor"></p-tag>
              </td>
              <td>{{ loan.installmentCount}}</td>
              <td>{{ loan.installmentAmount | currency}}</td>
              <td>{{ loan.collectedPrincipalAmount | currency}}</td>
              <td>{{ loan.collectedInterestAmount | currency}}</td>
              <td>{{ loan.outstandingPrincipalAmount | currency}}</td>
              <td>{{ loan.outstandingInterestAmount | currency}}</td>
              <td>
                <ng-container *ngIf="loan.installments[loan.installments.length - 1]; else elseLastInstallment">
                  {{loan.installments[0].dueDate | date: 'dd-MM-YYYY'}}
                </ng-container>
                <ng-template #elseLastInstallment>
                  -
                </ng-template>
              </td>
              <td>
                <ng-container *ngIf="loan.installments[0]; else elseFirstInstallment">
                  {{ loan.installments[loan.installments.length - 1].dueDate | date: 'dd-MM-YYYY' }}
                </ng-container>
                <ng-template #elseFirstInstallment>
                  -
                </ng-template>
              </td>
            </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</ng-container>

<!--
<p-card>
  <div class="row">
    <div class="col-12 mb-5">
      <h1 class="mb-3">Retiros</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium aliquid blanditiis cum debitis eaque eligendi enim facere facilis harum, iure laudantium minima nostrum placeat repellat sint sit suscipit unde! Sint?    </p>
      <p-toast></p-toast>
    </div>
  </div>
  <p-table
    #dtPayers
    [value]="payers"
    [columns]="cols"
    dataKey="_id"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [loading]="loading"
    [paginator]="true"
    [tableStyle]="{'width': '100%'}"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} retiros"
    [globalFilterFields]="['name', 'creationDate', 'hubspotLink']"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-row justify-content-between">
        <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
        <div>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dtPayers.filterGlobal(getValue($event), 'contains')" placeholder="Buscar..." />
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="min-width:10rem">
          <div class="flex align-items-center" pSortableColumn="hubspotId">
            Folio
            <p-columnFilter type="text" field="hubspotId" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="hubspotId"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:10rem">
          <div class="flex align-items-center" pSortableColumn="hubspotId">
            Fecha del retiro
            <p-columnFilter type="text" field="hubspotId" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="hubspotId"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:15rem">
          <div class="flex align-items-center" pSortableColumn="name">
            Monto de retiro
            <p-columnFilter type="text" field="name" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="name"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:15rem">
          <div class="flex align-items-center" pSortableColumn="creationDate">
            Comisión por retiro
            <p-columnFilter type="date" field="creationDate" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="creationDate"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:10rem">
          <div class="flex align-items-center" pSortableColumn="hubspotId">
            Total del retiro
            <p-columnFilter type="text" field="hubspotId" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="hubspotId"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:10rem">
          <div class="flex align-items-center" pSortableColumn="hubspotId">
            Monto pagado
            <p-columnFilter type="text" field="hubspotId" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="hubspotId"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:10rem">
          <div class="flex align-items-center" pSortableColumn="hubspotId">
            Monto pendiente
            <p-columnFilter type="text" field="hubspotId" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="hubspotId"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:10rem">
          <div class="flex align-items-center" pSortableColumn="hubspotId">
            Estatus
            <p-columnFilter type="text" field="hubspotId" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="hubspotId"></p-sortIcon>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-sod>
      <tr>
        <td>
          {{ payer.name }}
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">{{ payer.creationDate | date: 'MM/dd/yyyy' }}</span>
        </td>
        <td>
          {{ payer.hubspotId }}
        </td>
        <td>
          <button pButton pRipple type="button" icon="pi pi-pencil" (click)="newEntity('UPDATE', payer)" class="p-button-rounded p-button-text"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="3">No se encontraron pagadoras</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
-->
