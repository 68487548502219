<p-card>
  <div class="row">
    <div class="col-12">
      <h1 class="mb-3">SoD</h1>
      <p class="mb-3 text-color-secondary">El producto de SoD (Salary on Demand), es un producto donde el usuario tiene la oportunidad de adelantar su salario de forma anticipada, el sistem permite que el usuario acumule su salario conforme avanzan los dias de su periodo de pago o que tenga un monto determinado desde el primer dia.</p>
      <p-toast></p-toast>
    </div>
  </div>
  <p-table
    #dtSoD
    [scrollable]="true"
    scrollHeight="600px"
    [value]="withdrawals"
    [lazy]="true"
    (onLazyLoad)="loadWithdrawals($event)"
    [columns]="selectedColumns"
    dataKey="_id"
    [rows]="itemsByPage"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [loading]="loading"
    [paginator]="true"
    [tableStyle]="{'width': '100%'}"
    [(selection)]="selectedWithdrawals"
    [rowHover]="true"
    [totalRecords]="totalRecords"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} retiros"
    [globalFilterFields]="[
      'names.firstName',
      'names.lastName',
      'rfc',
      'curp',
      'emailAddress',
      'phone',
      'sex',
      'status',
      'agreementId',
      'identityValidationId',
      'referralCode',
      'position.name',
      'position.salary',
      'company.name',
      'contract.name',
      'remunerator.name',
      'payroll.name',
      'position.name'
    ]"
    [styleClass]="'p-datatable-sm'"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-row justify-content-between">
        <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
        <div>
          <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header" selectedItemsLabel="{0} columnas seleccionadas" [style]="{'min-width': '200px'}" placeholder="Escoge las columnas"></p-multiSelect>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th pFrozenColumn></th>
        <th style="min-width: 15rem;" pFrozenColumn>
          <p-columnFilter type="text" field="RFC"></p-columnFilter>
        </th>
        <ng-container *ngFor="let col of columns">
          <th  style="min-width: 15rem">
            <ng-container *ngIf="col.showFilter">
              <ng-container [ngSwitch]="col.type">
                <ng-container *ngSwitchCase="'numeric'">
                  <p-columnFilter type="numeric" [field]="col.field" [currency]="'MXN'"></p-columnFilter>
                </ng-container>
                <ng-container *ngSwitchCase="'boolean'">
                  <p-columnFilter type="boolean" [field]="col.field"></p-columnFilter>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <p-columnFilter [type]="col.type" [field]="col.field"></p-columnFilter>
                </ng-container>
              </ng-container>
            </ng-container>
          </th>
        </ng-container>
        <th style="width: 4rem"></th>
      </tr>
      <tr>
        <th style="padding-left: 15px;" pFrozenColumn>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pFrozenColumn>
          RFC
        </th>
        <ng-container *ngFor="let col of columns">
          <th  style="min-width: 15rem">
            {{col.header}}
          </th>
        </ng-container>
        <th style="width: 4rem">Acciones</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-withdrawal let-columns="columns">
      <tr>
        <td pFrozenColumn>
          <p-tableCheckbox [value]="withdrawal"></p-tableCheckbox>
        </td>
        <td pFrozenColumn>
          <ng-container *ngIf="authService.userHasAuthorization('WORKER','READ_DETAIL'); else rfcDetail">
            <a class="link-subsection" routerLink="{{'/worker/'+withdrawal.workerId}}">{{withdrawal.RFC}}</a>
          </ng-container>
          <ng-template #rfcDetail>
            {{withdrawal.RFC}}
          </ng-template>
        </td>
        <td *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.dataType">
            <ng-container *ngSwitchCase="'currency'">
              {{getValueObject(withdrawal, col.field) | currency}}
            </ng-container>
            <ng-container *ngSwitchCase="'date'">
              {{getValueObject(withdrawal, col.field) | date: 'dd-MM-YYYY'}}
            </ng-container>
            <ng-container *ngSwitchCase="'link'">
              <a class="link-subsection" routerLink="{{'/worker/'+getValueObject(withdrawal, col.linkField)}}">{{getValueObject(withdrawal, col.field)}}</a>
            </ng-container>
            <ng-container *ngSwitchCase="'enum'">
              <p-tag [value]="getValueObject(withdrawal, col.field) | selectEnum:col.enum" [severity]="getValueObject(withdrawal, col.field) | selectEnum:col.enumColor"></p-tag>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{getValueObject(withdrawal, col.field)}}
            </ng-container>
          </ng-container>
        </td>
        <td>
          <button pButton pRipple type="button" icon="fa-solid fa-link-slash" class="p-button-rounded p-button-text p-button-danger" (click)="confirmStatusChange($event, withdrawal._id)"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">No se encontraron SoD</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
<p-confirmPopup></p-confirmPopup>
