import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-create-accesory',
  templateUrl: './create-accesory.component.html',
  styleUrls: ['./create-accesory.component.scss']
})
export class CreateAccesoryComponent {
  @Input() entity!: FormGroup
  @Input() submitted!: boolean
  typeAccessories = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Obligatorio', value: 'REQUIRED'},
    {label: 'Opcional', value: 'OPTIONAL'},
  ]

  calculations = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Porcentaje del monto solicitado', value: 'PERCENTAGE'},
    {label: 'Monto fijo', value: 'FIXED'},
  ]
  constructor(
  ) {
  }


}
