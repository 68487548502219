import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {IResponseArray} from "@data/interfaces/iresponseArray.interface";
import {environment} from "@env/environment";
import {catchError, map, of} from "rxjs";
import {IPushing, IPushingFlowDetail, IPushingList} from "@modules/private/pushing/data/interfaces/ipushing.interface";

@Injectable({
  providedIn: 'root'
})
export class PushingService  extends ApiClass {

  constructor() {
    super()
    this.path = 'pushing'
  }

  getAll(page = 1, limit = 10, filters = {}) {
    console.log("calling")
    const response: IPushingList = {error: true, msg: '', data: [], total: 0, status: 200, page: 1, itemsByPage: 10, totalItems: 0}
    return this.http.post<IResponseArray>(environment.apiUrl + this.path, {page, limit, filters}).pipe(
      map((r) => r as IPushingList),
      catchError((e) => of(response))
    )
  }

  updatePushing(data: any) {
    return this.patchCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IPushing}))
  }

  getById(id: string) {
    return this.getCollection(id).pipe(map((r) => r as { error: boolean; msg: string; data: IPushingFlowDetail}))
  }

  updateStatus(data: any) {
    return this.patchCollection(data, environment.apiUrl, 'pushing/status').pipe(map((r) => r as {error: boolean; msg: string; data: IPushing}))
  }

  createPushing(data: any) {
    return this.postCollection(data,'new').pipe(map((r) => r as {error: boolean; msg: string; data: IPushing}))
  }
}
