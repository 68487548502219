import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableService} from "@data/services/utils/data-table.service";
import {Table} from "primeng/table";
import {IAgreement} from "@modules/private/agreement/data/interfaces/iapi-agreement.interface";
import {ApiAgreementService} from "@modules/private/agreement/data/services/api/api-agreement.service";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "@data/services/modules/auth/auth.service";

@Component({
  selector: 'app-list-agreement',
  templateUrl: './list-agreement.component.html',
  styleUrls: ['./list-agreement.component.scss'],
  providers: [ MessageService, ConfirmationService ]
})
export class ListAgreementComponent implements OnInit{
  agreements!: IAgreement[];
  agreementsFiltered!: IAgreement[];
  cols: any[] = [
    {field: 'entity.name', header: 'Razon social'},
    {field: 'entity.rfc', header: 'RFC'},
    {field: 'remuneratorId.name',  header: 'Nombre de la pagadora'},
    {field: 'creationDate', header: 'Fecha de creacion'}
  ]
  colsPdf: any[] = [
    {dataKey: 'Nombre de razon social', title: 'Razón social'},
    {dataKey: 'RFC', title: 'RFC'},
    {dataKey: 'Nombre de pagadora', title: 'Nombre de la pagadora'},
    {dataKey: 'Fecha de creación', title: 'Fecha de creación'}
  ]
  loading: boolean = true;
  items: MenuItem[] = [];
  entity!: FormGroup
  submitted = false
  formAction : 'CREATE' | 'UPDATE' = 'CREATE'
  createEntity = false
  @ViewChild('dtAgreements') dtAgreements!: Table;
  constructor(
    private apiAgreementService: ApiAgreementService,
    public dataTableService: DataTableService,
    private messageService: MessageService,
    private fb: FormBuilder,
    public authService: AuthService
  ) {
    this.createMenu()
  }

  ngOnInit() {
    this.find()
  }

  find() {
    this.loading = true
    this.apiAgreementService.getAll().subscribe(r => {
      if(!r.error) {
        this.agreements = r.data.map(r => {
          r.creationDate = new Date(r.creationDate)
          return r
        })
        this.agreementsFiltered = this.agreements
      }
      this.loading = false
    })
  }

  onFilter(event: any, dt: any) {
    this.agreementsFiltered = event.filteredValue
  }


  hideDialog() {
    this.createEntity = false;
    this.submitted = false;
  }


  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value
  }

  transformData() {
    return this.agreementsFiltered.map((i) => {
      return {
        'Nombre de razon social': i.entity.name,
        'RFC': i.entity.rfc,
        'Nombre de pagadora': i.remuneratorId.name,
        'Fecha de creación': i.creationDate
      }
    })
  }

  newEntity(formAction: 'CREATE' | 'UPDATE', data: IAgreement | null = null) {
    this.formAction = formAction
    if(formAction === 'CREATE') {
      this.entity = this.fb.group({
        name: [ '', [Validators.required, Validators.minLength(3)]],
        rfc: [ '', [Validators.required, Validators.pattern(/^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$/)]],
        payerId: [ '', [Validators.required, Validators.minLength(24)]],
      })
    } else {
      this.entity = this.fb.group({
        name: [data?.entity.name, [Validators.required, Validators.minLength(3)]],
        rfc: [data?.entity.rfc, [Validators.required,  Validators.pattern(/^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$/)]],
        payerId: [data?.remuneratorId._id, [Validators.required, Validators.minLength(24)]],
        id: [data?._id]
      })
    }
    this.entity.clearValidators()
    this.submitted = false;
    this.createEntity = true;
  }

  saveProduct() {
    this.submitted = true
    const api = this.formAction === 'CREATE' ? this.apiAgreementService.create(this.entity.value) : this.apiAgreementService.patch(this.entity.value)
    api.subscribe((r) => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Exitoso', detail: r.error ? r.msg: r.msg, life: 3000 });
      if (!r.error ) {
        this.hideDialog()
        this.find()
      }
    })
  }

  createMenu() {
    if (this.authService.userHasAuthorization('AGREEMENT','EXPORT')) {
      this.items = [
        {
          label: 'Descargar',
          icon: 'fa-solid fa-cloud-arrow-down',
          items: [
            {
              label: 'CSV',
              command: () => {
                this.dtAgreements.exportCSV()
                this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa' });
              }
            },
            {
              label: 'Excel',
              command: () => {
                this.dataTableService.exportExcel(this.transformData())
                this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa' });
              }
            },
            {
              label: 'PDF',
              command: () => {
                this.dataTableService.exportPdf(this.colsPdf, this.transformData())
                this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa' });
              }
            }
          ]
        }
      ]
    }
    this.items = [
      ...this.items,
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find()
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtAgreements.clear()
          this.find()
        }
      }
    ]
    if (this.authService.userHasAuthorization('AGREEMENT', 'CREATE')) {
      this.items = [
        ...this.items,
        { separator: true },
        {
          label: 'Nuevo',
          icon: 'fa-solid fa-square-plus',
          command: () => {
            this.newEntity('CREATE')
          }
        }
      ]
    }
  }
}
