import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {map} from "rxjs";
import {IPlatformSettings} from "@modules/private/platform-settings/data/interfaces/iplatform-settings.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiPlatformSettingsService  extends ApiClass {
  constructor() {
    super();
    this.path = 'platform-settings'
  }

  get() {
    return this.getCollection().pipe(map((r) => r as {error: boolean; msg: string; data: IPlatformSettings}))
  }

  update(data: any) {
    return this.putCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IPlatformSettings}))
  }
}
