import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiAdminService} from "@modules/private/admin/data/services/api-admin.service";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {Table} from "primeng/table";
import {IApiAdmin} from "@modules/private/admin/data/interfaces/iapi-admin.interface";
import {AuthService} from "@data/services/modules/auth/auth.service";

@Component({
  selector: 'app-list-admin',
  templateUrl: './list-admin.component.html',
  styleUrls: ['./list-admin.component.scss'],
  providers: [ MessageService, ConfirmationService ]
})
export class ListAdminComponent implements OnInit{
  admins!: IApiAdmin[]
  loading: boolean = true;
  cols: any[] = [
    {field: 'names'},
    {field: 'creationDate'}
  ]
  items: MenuItem[] = [];
  @ViewChild('dtAdmins') dtAdmins!: Table;
  constructor(
    private apiAdminService: ApiAdminService,
    private messageService: MessageService,
    public authService: AuthService
  ) {
    this.createMenu()
  }
  ngOnInit() {
    this.find()
  }

  createMenu() {
    this.items = [
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find()
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtAdmins.clear()
          this.find()
        }
      },
    ]
  }

  find() {
    this.loading = true
    this.apiAdminService.getAll().subscribe(r => {
      console.log(r)
      if (!r.error) {
        this.admins = r.data
      }
      this.loading = false
    })
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value
  }
}
