<ng-container *ngIf="entity">
  <form [formGroup]="entity">
    <div class="field field-flex mb-5">
      <label for="flowId">Selecciona un flujo:*</label>
      <p-dropdown formControlName="flowId" [panelStyle]="{'width': '100%'}" [scrollHeight]="'150px'" [options]="flows" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
      <ng-container *ngIf="entity.get('flowId')?.invalid && (entity.get('flowId')?.dirty || entity.get('flowId')?.touched)">
        <small class="p-error" *ngIf="entity.get('flowId')?.errors?.['required']">Debes seleccionar una opción.</small>
      </ng-container>
    </div>
    <div>
      <p class="mb-7">Selecciona el flujo a donde quieres que el usuario retome su proceso, esto permitira a que el usuario no realice un doble proceso y lo complete de una forma más eficiente.</p>
    </div>
  </form>
</ng-container>
