<p-card>
  <div class="row">
    <div class="col-12 mb-3">
      <h1 class="mb-3">Plataforma Pulpi</h1>
      <p class="text-color-secondary">
        <h4 class="mb-4">
          Bienvenido {{(authService.getUser?.user?.names?.firstName ?? 'PulpiUser') | titlecase}},
        </h4>
        <p>
        La plataforma Pulpi te permitirá gestionar la cartera histórica, cada producto financiero, solicitudes de productos por los distintos canales, así como la reportería generada por toda la data generada.
      </p>
    </div>
  </div>
</p-card>
