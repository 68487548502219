import { Injectable } from '@angular/core';
import {map, Observable} from "rxjs";
import {ApiClass} from "@data/class/api-class";
import {
  IApiAgreementList,
  IApiCreateAgreement
} from "@modules/private/agreement/data/interfaces/iapi-agreement.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiAgreementService extends ApiClass {
  constructor() {
    super();
    this.path = 'contract'
  }

  getAll() {
    return this.getCollections().pipe(map((r) => r as IApiAgreementList))
  }

  create(data: any): Observable<any> {
    return this.postCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IApiCreateAgreement }))
  }

  patch(data: any): Observable<any> {
    return this.patchCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IApiCreateAgreement }))
  }
}
