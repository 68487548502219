import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {ILoanPopulated} from "@modules/private/loan/data/interfaces/iloan.interface";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {IPosition} from "@modules/private/workers/data/interfaces/iposition.interface";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-detail-loan',
  templateUrl: './detail-loan.component.html',
  styleUrls: ['./detail-loan.component.scss'],
  providers: [ConfirmationService]
})
export class DetailLoanComponent implements OnInit{
  loanId = ''
  data!: ILoanPopulated
  lastPosition!: IPosition
  constructor(
    private activeRoute: ActivatedRoute,
    private apiLoanService: ApiLoanService,
    public authService: AuthService
  ) {
    this.loanId = this.activeRoute.snapshot.paramMap.get('id') as string
  }

  ngOnInit() {
    this.getData()
  }
  getData() {
    this.apiLoanService.getById(this.loanId).subscribe(r => {
      console.log('aqui')
      this.data = r.data.loan
      this.lastPosition = r.data.lastPosition
    })
  }

}
