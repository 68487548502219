import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Message} from "primeng/api";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiReportsService} from "@modules/private/reports/data/services/api/api-reports.service";
import {ApiPayrollService} from "@modules/private/payroll/data/services/api/api-payroll.service";

@Component({
  selector: 'app-sod-incidents-report',
  templateUrl: './sod-incidents-report.component.html',
  styleUrls: ['./sod-incidents-report.component.scss']
})
export class SodIncidentsReportComponent implements OnInit {
  @Output() onGenerated = new EventEmitter()
  visible = false
  isLoading = false
  responseMessage: Message[] = []
  entity!: FormGroup
  payrolls: {label: string; value: string;}[]  = [
  ]
  constructor(
    private apiReportsService: ApiReportsService,
    private fb: FormBuilder,
    private apiPayrollService: ApiPayrollService
  ) {
    this.entity = this.fb.group({
      id: ['', [Validators.required]],
      cutOffDate: ['',  [Validators.required]]
    })
  }

  ngOnInit() {
    this.apiPayrollService.getNames().subscribe(r => {
      console.log(r.data)
      const payrolls: {label: string; value: string;}[] = []
      r.data.forEach(p => {
        payrolls.push({label: `${p.name}`, value: p._id})
      })
      this.payrolls = payrolls
    })
  }

  getPayrollName(id: string) {
    return this.payrolls.find(p =>  p.value === id)?.label
  }

  get getIds () {
    const labels = []
    for (const id of this.entity.value.id) {
      labels.push(id.label)
    }
    return labels
  }

  getReport() {
    this.visible = false
    this.isLoading = true
    this.responseMessage = []
    this.apiReportsService.getIncidentsSodReport({
      ids: this.entity.value.id.map((i: {label: string; value: string}) => i.value),
      cutOffDate: this.entity.value.cutOffDate
    }).subscribe((r) => {
      this.responseMessage = [
        {
          summary: r.msg,
          severity: r.error ? 'error': 'success',
          closable: true,
          detail: r.error ? '' : `<a href="${r.data.url}" target="_blank">Descargar reporte</a>`
        }]
      this.visible = true
      this.isLoading = false
      this.onGenerated.emit()
    })
  }
}
