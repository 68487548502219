import {Component, ViewChild} from '@angular/core';
import {ConfirmationService, MessageService} from "primeng/api";
import {PUSHING_MAS_NOMINA} from "@modules/private/pushing/data/constants/pushingColumns.constants";
import {ApiAccessoryService} from "@modules/private/accesory/data/services/api-accessory.service";
import {
  TYPE_REQUIRED_ACCESSORY_ENUM,
  TYPE_REQUIRED_ACCESSORY_ENUM_COLOR
} from "@modules/private/accesory/data/enums/accessory.enum";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Table} from "primeng/table";
import {MainTableComponent} from "@shared/components";
import {IAccessory} from "@modules/private/accesory/data/interfaces/iaccessory.interface";

@Component({
  selector: 'app-list-accesory',
  templateUrl: './list-accesory.component.html',
  styleUrls: ['./list-accesory.component.scss'],
  providers: [ MessageService, ConfirmationService ]
})
export class ListAccesoryComponent {
  columns = [
    {type: 'text', field: 'name', header: 'Nombre', showFilter: true},
    {type: 'text', field: 'description', header: 'Descripción', showFilter: true},
    {type: 'text', field: 'termsAndCondition', header: 'Términos y condiciones', showFilter: true},
    {type: 'boolean', field: 'isFinanced', header: 'Es Financiado', showFilter: true, dataType: 'boolean'},
    {type: 'enum', field: 'type', header: 'Tipo de accesorio', showFilter: true, dataType: 'enum', enum: TYPE_REQUIRED_ACCESSORY_ENUM, enumColor: TYPE_REQUIRED_ACCESSORY_ENUM_COLOR, options: [
        {value: 'REQUIRED', label: 'Requerido'},
        {value: 'OPTIONAL', label: 'Opcional'},
      ]},
  ]
  entity!: FormGroup
  createEntity = false
  submitted: boolean = false;
  titleForm = 'Crear Accesorio'
  actionSaveForm = 'Crear'
  mainAction = 'CREATE'
  @ViewChild('tableAccessory') tableAccessory!: MainTableComponent;
  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public apiAccessory: ApiAccessoryService,
    private fb: FormBuilder,
  ){}


  actions = [
    {icon: 'fa-solid fa-check', action: 'UPDATE', title: 'Actualizar', color: 'primary'},
    {icon: 'fa-solid fa-times', action: 'DELETE', showConfirm: true, confirmMessage: '¿Estas seguro de eliminar el accesorio?', title: 'Eliminar', color: 'danger'}
  ]

  additionalMainActions = [
    {label: 'Crear accesorio', icon: 'fa-solid fa-plus', action: 'CREATE'},
  ]

  mainActions(flow: string) {
    switch(flow) {
      case 'CREATE':
        this.createAccessory('CREATE')
        break;
    }
  }

  actionMethods(data: {event:any, action: string, data: any}) {
    console.log(data)
    switch (data.action) {
      case 'UPDATE':
        this.createAccessory('UPDATE', data.data as IAccessory)
        break;
      case 'DELETE':
        console.log(data.data._id)
        this.deleteAccessory(data.data._id)
        break;
    }
  }

  hideDialog() {
    this.createEntity = false;
    this.submitted = false;
  }

  createAccessory(action: 'CREATE' | 'UPDATE', data: IAccessory | null = null) {
    this.mainAction = action
    switch (action) {
      case 'CREATE':
        this.titleForm = 'Crear Accesorio'
        this.actionSaveForm = 'Crear'
        this.entity = this.fb.group({
          name: ['', [Validators.required]],
          description: ['', [Validators.required]],
          creationPrice: [0, [Validators.required]],
          weeklyPrice: [0, [Validators.required]],
          biweeklyPrice: [0, [Validators.required]],
          semimonthlyPrice: [0, [Validators.required]],
          monthlyPrice: [0, [Validators.required]],
          termsAndCondition: [''],
          calculation: [''],
          isFinanced: [false, [Validators.required]],
          type: ['', [Validators.required]],
        })
        break;
      case "UPDATE":
        this.titleForm = 'Actualizar Accesorio'
        this.actionSaveForm = 'Actualizar'
        this.entity = this.fb.group({
          id: [data?._id, [Validators.required]],
          name: [data?.name, [Validators.required]],
          description: [data?.description, [Validators.required]],
          creationPrice: [data?.price.creation, [Validators.required]],
          weeklyPrice: [data?.price.weekly, [Validators.required]],
          biweeklyPrice: [data?.price.biweekly, [Validators.required]],
          semimonthlyPrice: [data?.price.semimonthly, [Validators.required]],
          monthlyPrice: [data?.price.monthly, [Validators.required]],
          termsAndCondition: [data?.termsAndCondition],
          calculation: [data?.calculation],
          isFinanced: [data?.isFinanced, [Validators.required]],
          type: [data?.type, [Validators.required]],
        })
        break;
    }

    this.createEntity = true
    this.entity.clearValidators()
    this.submitted = false
  }

  deleteAccessory(id: string) {
    this.apiAccessory.delete(id).subscribe(r => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Éxito', detail: r.error ? r.msg: r.msg, life: 3000 });
      if (!r.error ) {
        this.hideDialog()
        this.tableAccessory.findRecords()
      }
    })
  }

  saveAccessory() {
   switch (this.mainAction) {
     case 'CREATE':
       this.apiAccessory.create(this.entity.value).subscribe(r => {
         this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Éxito', detail: r.error ? r.msg: r.msg, life: 3000 });
         if (!r.error ) {
           this.hideDialog()
           this.tableAccessory.findRecords()
         }
       })
       break;
     case 'UPDATE':
       this.apiAccessory.update(this.entity.value).subscribe(r => {
         this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Éxito', detail: r.error ? r.msg: r.msg, life: 3000 });
         if (!r.error ) {
           this.hideDialog()
           this.tableAccessory.findRecords()
         }
       })
       break;
   }
  }
}
