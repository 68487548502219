import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableService} from "@data/services/utils/data-table.service";
import {Table} from "primeng/table";
import {ApiPayerService} from "@modules/private/payer/data/services/api/api-payer.service";
import {IPayer} from "@modules/private/payer/data/interfaces/iapi-payer.interface";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "@data/services/modules/auth/auth.service";

@Component({
  selector: 'app-list-payer',
  templateUrl: './list-payer.component.html',
  styleUrls: ['./list-payer.component.scss'],
  providers: [ MessageService, ConfirmationService ]
})
export class ListPayerComponent implements OnInit{
  payers!: IPayer[];
  payersFiltered!: IPayer[]
  cols: any[] = [
    {field: 'name', header: 'Nombre'},
    {field: 'creationDate', header: 'Fecha de creacion'},
  ]
  colsPdf: any[] = [
    {dataKey: 'name', title: 'Nombre'},
    {dataKey: 'creationDate', title: 'Fecha de creacion'}
  ]
  loading: boolean = true;
  items: MenuItem[] = [];
  entity!: FormGroup
  submitted = false
  formAction : 'CREATE' | 'UPDATE' = 'CREATE'
  createEntity = false
  @ViewChild('dtPayers') dtPayers!: Table;
  constructor(
    private apiPayerService: ApiPayerService,
    public dataTableService: DataTableService,
    private messageService: MessageService,
    private fb: FormBuilder,
    public authService: AuthService
  ) {
    this.createMenu()
  }

  ngOnInit() {
   this.find()
  }

  find() {
    this.loading = true
    this.apiPayerService.getAll().subscribe(r => {
      if(!r.error) {
        this.payers = r.data.map(r => {
          r.creationDate = new Date(r.creationDate)
          return r
        })
        this.payersFiltered = this.payers
      }
      this.loading = false
    })
  }

  hideDialog() {
    this.createEntity = false;
    this.submitted = false;
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value
  }

  newEntity(formAction: 'CREATE' | 'UPDATE', data: IPayer | null = null) {
    this.formAction = formAction
    if(formAction === 'CREATE') {
      this.entity = this.fb.group({
        name: [ '', [Validators.required, Validators.minLength(3)]]
      })
    } else {
      this.entity = this.fb.group({
        name: [data?.name, [Validators.required, Validators.minLength(3)]],
        id: [data?._id]
      })
    }
    this.entity.clearValidators()
    this.submitted = false;
    this.createEntity = true;
  }

  onFilter(event: any, dt: any) {
    this.payersFiltered = event.filteredValue
  }

  saveProduct() {
    this.submitted = true
    const api = this.formAction === 'CREATE' ? this.apiPayerService.create(this.entity.value) : this.apiPayerService.patch(this.entity.value)
    api.subscribe((r) => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Exitoso', detail: r.error ? r.msg: r.msg, life: 3000 });
      if (!r.error ) {
        this.hideDialog()
        this.find()
      }
    })
  }

  createMenu() {
    if (this.authService.userHasAuthorization('PAYER', 'EXPORT')) {
      this.items = [
        {
          label: 'Descargar',
          icon: 'fa-solid fa-cloud-arrow-down',
          items: [
            {
              label: 'CSV',
              command: () => {
                this.dtPayers.exportCSV()
                this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa' });
              }
            },
            {
              label: 'Excel',
              command: () => {
                this.dataTableService.exportExcel(this.payersFiltered.map((c) => {
                  return {'Nombre': c.name, 'Fecha de creación': c.creationDate}
                }))
                this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa' });
              }
            },
            {
              label: 'PDF',
              command: () => {
                this.dataTableService.exportPdf(this.colsPdf, this.payersFiltered)
                this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa' });
              }
            }
          ]
        }
      ]
    }
    this.items = [
      ...this.items,
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find()
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtPayers.clear()
          this.find()
        }
      },
    ]
    if (this.authService.userHasAuthorization('PAYER', 'CREATE')) {
      this.items = [
        ...this.items,
        { separator: true },
        {
          label: 'Nuevo',
          icon: 'fa-solid fa-square-plus',
          command: () => {
            this.newEntity('CREATE')
          }
        }
      ]
    }
  }
}
