<ng-container *ngIf="data">
  <div class="grid">
    <ng-container *ngIf="urlIncodeSession !== '' && !updateValidation;">
      <div class="col-12">
        <div class="main-title">
          Verificación biometrica
        </div>
      </div>
      <div class="col-12 comment-rejected" *ngIf="values && values.comment !== ''">
        Último motivo de rechazo: <strong>{{values.comment}}</strong>
      </div>
      <div class="col-12 md:col-6">
        <h3 class="mb-2">Link a la verificación de identidad: <span><a [href]="urlIncodeSession" target="_blank"><i class="fa-solid fa-link"></i></a></span></h3>
      </div>
      <div class="col-4 md:col-6">
        <h3 class="mb-2">Estatus de validación: <span class="font-normal">{{incodeSessionStatus | selectEnum:$_loanOnboardingStatus}}</span></h3>
      </div>
      <ng-container *ngIf="documents.fullFrameFrontID && documents.fullFrameFrontID !== ''">
        <div class="col-12">
          <div class="main-title">
            Documentos
          </div>
        </div>
        <div class="col-12">
          <ng-container  *ngFor="let i of (documents | keyvalue)">
            <img class="img-validation" [src]="'data:image/jpg;base64,'+i.value">
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
    <div class="col-12">
      <form [formGroup]="entity">
        <div class="grid">
          <div class="col-12">
            <div class="main-title">
              Actualizar id de verificación
            </div>
          </div>
          <div class="col-12 md:col-6 xl:col-4">
            <div class="field field-flex">
              <label for="sessionId">Id de validación de identidad (incode):*</label>
              <input type="text" pInputText id="sessionId" formControlName="sessionId" placeholder="973cd2e1279517787c2b8c8c" required />
              <ng-container *ngIf="entity.get('sessionId')?.invalid && (entity.get('sessionId')?.dirty || entity.get('sessionId')?.touched)">
                <small class="p-error" *ngIf="entity.get('sessionId')?.errors?.['required']">El campo es obligatorio.</small>
                <small class="p-error" *ngIf="entity.get('sessionId')?.errors?.['minlength']">El campo debe tener {{entity.get('sessionId')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
              </ng-container>
            </div>
          </div>
          <div class="col-12 md:col-6 xl:col-4">
            <div class="field filed-flex flex flex-column">
              <label for="requireUserAction">¿Actualizar y solicitar acción por parte del usuario?</label>
              <p-inputSwitch formControlName="requireUserAction" class="ng-dirty ng-invalid"></p-inputSwitch>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
<p-toast></p-toast>
