import { Component } from '@angular/core';

@Component({
  selector: 'app-worker-activity',
  templateUrl: './worker-activity.component.html',
  styleUrls: ['./worker-activity.component.scss']
})
export class WorkerActivityComponent {

}
