import {Component, Input, ViewChild} from '@angular/core';
import {DataTableService} from "@data/services/utils/data-table.service";import {Table} from "primeng/table";
import {ApiWithdrawalService} from "@modules/private/withdrawal/data/services/api/api-withdrawal.service";
import {IPayment, IRecordsRequestPayment} from "@modules/private/payment/data/interfaces/ipayment.interface";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {ApiPaymentService} from "@modules/private/payment/data/services/api/api-payment.service";
import {
  PAYMENT_TYPE_PRODUCT_ENUM,
  PAYMENT_TYPE_PRODUCT_COLOR_ENUM, PAYMENT_STATUS_ENUM, PAYMENT_STATUS_COLOR_ENUM
} from "@modules/private/payment/data/enum/payment.enum";
import {LOAN_STATUS_ENUM, LOAN_STATUS_ENUM_COLOR} from "../../loan/data/enums/loan.enum";
import {SelectEnumPipe} from "@data/pipes/enum/select-enum.pipe";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {LoaderService} from "@data/services/page/loader.service";
import {LOAN_COLUMNS} from "../../loan/data/columns/loanColumns.constant";
import {PAYMENT_COLUMNS} from "@modules/private/payment/data/constants/paymentColumns.constant";
import {TYPE_PRODUCT_INSTALLMENTS_ENUM} from "@modules/private/installment/data/enum/typeProducts.enum";
import {TYPE_PERIOD_ENUM} from "@modules/private/company/data/enums/typePeriods.enum";
import {AuthService} from "@data/services/modules/auth/auth.service";

interface Column {
  field: string;
  header: string;
  type: string;
}

@Component({
  selector: 'app-list-payment',
  templateUrl: './list-payment.component.html',
  styleUrls: ['./list-payment.component.scss'],
  providers: [MessageService, ConfirmationService, SelectEnumPipe, CurrencyPipe, DatePipe]
})
export class ListPaymentComponent {
  items: MenuItem[] = []
  payments!: IRecordsRequestPayment[];
  totalRecords = 0
  itemsByPage = 10
  selectedPayments!: IRecordsRequestPayment[] | null;
  _selectedColumns!: Column[]
  loading: boolean = true;
  columns = PAYMENT_COLUMNS
  @ViewChild('dtPayments') dtPayments!: Table;
  constructor(
    private apiPaymentService: ApiPaymentService,
    public dataTableService: DataTableService,
    private messageService: MessageService,
    private selectEnumPipe: SelectEnumPipe,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private loaderService: LoaderService,
    private authService: AuthService
  ) {
    this.createMenu()
    this.selectedColumns = this.columns
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.columns.filter((col) => val.includes(col));
  }

  ngOnInit() {
    this.find()
  }

  find(page = 0, limit = this.itemsByPage, filters = {}) {
    this.loading = true;
    this.apiPaymentService.getAll(page, limit, filters).subscribe(r => {
      this.totalRecords = r.totalItems
      this.payments = r.data
      this.loading = false
    })
  }

  convertData(d: IRecordsRequestPayment[]) {
    return d.map(i => {
      return {
        ...i,
        amount: this.currencyPipe.transform(i.amount),
        date: this.datePipe.transform(i.date, 'dd-MM-YYYY'),
        creationDate: this.datePipe.transform(i.creationDate, 'dd-MM-YYYY'),
        productType: this.selectEnumPipe.transform(i.productType, TYPE_PRODUCT_INSTALLMENTS_ENUM),
        period: this.selectEnumPipe.transform(i.period, TYPE_PERIOD_ENUM)
      }
    })
  }

  clear(table: Table) {
    table.clear();
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value
  }

  loadPayments(e: any) {
    console.log(e)
    const currentPage = e.first / this.itemsByPage
    this.itemsByPage = e.rows
    const finalFilters = {...e.filters}
    if (e.filters.global) finalFilters['gatewayPaymentId'] = e.filters.global
    this.find(currentPage, this.itemsByPage, finalFilters)
  }

  exportPdf() {
    this.loaderService.showSpinner()
    this.apiPaymentService.getAll(0, 100000000, this.dtPayments.filters).subscribe(r => {
      this.dataTableService.exportPdf([
        ...this.selectedColumns.map(sc => {
          return {
            dataKey: sc.field,
            title: sc.header
          }
        })
      ], this.convertData(r.data)).then(r => {
        this.loaderService.hideSpinner()
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa', life: 5000 });
      }).catch(e => {
        this.loaderService.hideSpinner()
        this.messageService.add({ severity: 'danger', summary: 'Error', detail: 'Error al descargar el archivo', life: 5000 });
      })
    })
  }

  exportExcel() {
    this.loaderService.showSpinner()
    this.apiPaymentService.getAll(0, 100000000, this.dtPayments.filters).subscribe(r => {
      const dataConverted = this.convertData(r.data)
      this.dataTableService.exportExcel(dataConverted.map(i => {
        const response: any = {}
        for (const column of this.selectedColumns) {
          const ii = i as any
          response[column.header] = ii[column.field]
        }
        return response
      }))
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa', life: 5000 });
    })
  }

  getValueObject(obj: any, keys: string) {
    const keysArray = keys.split('.')
    let value: any
    for (const key in keysArray) {
      if(key === '0') {
        if(obj[keysArray[key]] !== undefined) value = obj[keysArray[key]]
        else return ''
      } else value = value[keysArray[key]]
    }
    return value
  }

  protected readonly LOAN_STATUS_ENUM = LOAN_STATUS_ENUM;
  protected readonly LOAN_STATUS_ENUM_COLOR = LOAN_STATUS_ENUM_COLOR;
  protected readonly LOAN_COLUMNS = LOAN_COLUMNS;

  createMenu() {
    if (this.authService.userHasAuthorization('PAYMENT','EXPORT')) {
      this.items.push({
        label: 'Descargar',
        icon: 'fa-solid fa-cloud-arrow-down',
        items: [
          {
            label: 'Excel',
            command: () => {
              this.exportExcel()
            }
          },
          {
            label: 'PDF',
            command: () => {
              this.exportPdf()
            }
          }
        ]
      })
    }
    this.items = [
      ...this.items,
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find()
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtPayments.clear()
          this.find()
        }
      }
    ]
  }
}
