<p-card>
  <div class="row">
    <div class="col-12 mb-3">
      <h1 class="mb-3">Corporativos</h1>
      <p class="text-color-secondary">
        Los corporativos son organizaciones que hacen negocios bajo un solo nombre conocido por el público (ej: TOTALPLAY, PULPI).
      </p>
      <p-toast></p-toast>
    </div>
  </div>
  <p-table
    #dt1
    [scrollable]="true"
    scrollHeight="600px"
    [value]="companies"
    [columns]="cols"
    dataKey="_id"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [loading]="loading"
    [paginator]="true"
    [tableStyle]="{'width': '100%'}"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} corporativos"
    [globalFilterFields]="['name', 'creationDate', 'hubspotLink']"
    editMode="row"
    (onFilter)="onFilter($event, dt1)"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-row justify-content-between">
        <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
        <div>
          <span class="p-input-icon-left mr-1">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt1.filterGlobal(getValue($event), 'contains')" placeholder="Buscar..." />
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="min-width:25rem">
          <div class="flex align-items-center" pSortableColumn="name">
              Nombre
              <p-columnFilter type="text" field="name" display="menu" class="mt-1"></p-columnFilter>
              <p-sortIcon field="name"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:15rem">
          <div class="flex align-items-center" pSortableColumn="creationDate">
            Fecha de creación
            <p-columnFilter type="date" field="creationDate" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="creationDate"></p-sortIcon>
          </div>
        </th>
        <ng-container *ngIf="authService.userHasAuthorization('COMPANY','UPDATE')">
          <th style="min-width:10rem"></th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-company let-editing="editing" let-ri="rowIndex">
      <tr [pEditableRow]="company">
        <td>
          {{company.name}}
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">{{ company.creationDate | date: 'MM/dd/yyyy' }}</span>
        </td>
        <ng-container *ngIf="authService.userHasAuthorization('COMPANY','UPDATE')">
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
              <button pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="newEntity('UPDATE', company)" class="p-button-rounded p-button-text"></button>
            </div>
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="3">No se encontraron corporativos</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>

<!------------------------CREATE COMPANY ---------------------------->
<p-dialog [(visible)]="createEntity" [style]="{ width: '450px' }" [header]="formAction === 'CREATE' ? 'Nuevo corporativo' : 'Actualizar corporativo'" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
      <app-create-company [company]="company" [submitted]="submitted"></app-create-company>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="saveProduct()" [disabled]="!company.valid"></button>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
