import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {map} from "rxjs";
import {IFlowData} from "@modules/private/pushing/data/interfaces/ipushing.interface";
import {environment} from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class FlowDataService extends ApiClass {

  constructor(
  ) {
    super()
    this.path = 'flowData'
  }

  reject(data: any) {
    return this.patchCollection(data, environment.apiUrl, 'flowData/reject').pipe(map((r) => r as {error: boolean; msg: string; data: IFlowData}))
  }

  update(data: any) {
    return this.patchCollection(data, environment.apiUrl, 'flowData/update').pipe(map((r) => r as {error: boolean; msg: string; data: IFlowData}))
  }

  approve(data: any) {
    return this.patchCollection(data, environment.apiUrl, 'flowData/approve').pipe(map((r) => r as {error: boolean; msg: string; data: IFlowData}))
  }
}
