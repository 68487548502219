import { Component } from '@angular/core';
import {IApplicationMassive} from "@modules/private/application/data/interfaces/application.interface";
import {Message, MessageService} from "primeng/api";
import {NgxCsvParser, NgxCSVParserError} from "ngx-csv-parser";
import {ApiApplicationService} from "@modules/private/application/data/services/api-application.service";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {ApiAdminService} from "@modules/private/admin/data/services/api-admin.service";

@Component({
  selector: 'app-massive-assignment',
  templateUrl: './massive-assignment.component.html',
  styleUrls: ['./massive-assignment.component.scss'],
  providers: [MessageService]
})
export class MassiveAssignmentComponent {
  visible: boolean = false;
  uploadData: string[] = []
  isUploading = false
  responseMessage: Message[] = []
  showMessage = false
  typeProductOptions = [
    {label: 'Creditos', value: 'LOAN'}
  ]
  typeModule = 'LOAN'
  admins: {label: string; value: string;}[] = []
  adminId = null
  constructor(
    private messageService: MessageService,
    private ngxCsvParser: NgxCsvParser,
    private apiApplicationService: ApiApplicationService,
    private apiLoanService: ApiLoanService,
    private apiAdminService: ApiAdminService
  ) {
    this.getAdmins()
  }

  showDialog() {
    this.visible = true;
  }

  getAdmins() {
    this.apiAdminService.getAll().subscribe(r => {
      const admins: {label: string; value: string;}[] = []
      r.data.forEach(a => {
        admins.push({
          label: `${a.names.firstName} ${a.names.lastName} | ${a.emailAddress}`,
          value: a._id
        })
      })
      this.admins = admins
    })
  }

  onUpload(event: any) {
    const files = event.files
    this.isUploading = true
    this.showMessage = false
    if (this.adminId === '') {
      this.messageService.add({severity: 'error', summary: 'Formulario incompleto', detail: 'El formulario se encuentra incompleto', life: 3000});
      return
    }
    this.ngxCsvParser.parse(files[0], {header: false, delimiter: ',', encoding: 'utf8'})
      .pipe().subscribe({
      next: (result: any): void => {
        this.uploadData = []
        for (const key in result) {
          const object = result[key] as string[]
          if(+key > 0) {
            if(object[0] === undefined || object[1] !== undefined) {
              this.messageService.add({severity: 'error', summary: 'Error de formato', detail: 'Hubo un error en el formato del archivo', life: 3000});
              break
            }
            this.uploadData.push(object[0])
          }
        }
        if (this.uploadData.length > 0) {
          this.apiLoanService.assignLoans({
            ids: this.uploadData,
            module: this.typeModule,
            adminId: this.adminId
          }).subscribe(r => {
            this.isUploading = false
            this.responseMessage = [
              {
                severity: r.error ? 'error': 'success',
                summary: r.error ? r.msg:'El archivo se subio exitosamente',
                closable: true,
              }]
            this.showMessage = true
          })
        }
        console.log(this.uploadData)
      },
      error: (error: NgxCSVParserError): void => {
        console.log('Error', error)
        this.isUploading = false
      }
    })
  }
}
