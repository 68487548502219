import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {ApiPayerService} from "@modules/private/payer/data/services/api/api-payer.service";

@Component({
  selector: 'app-create-agreement',
  templateUrl: './create-agreement.component.html',
  styleUrls: ['./create-agreement.component.scss']
})
export class CreateAgreementComponent implements  OnInit {
  @Input() entity!: FormGroup
  @Input() submitted!: boolean
  @Input() type: 'CREATE' | 'UPDATE' = 'CREATE'
  payers: {label: string; value: string;}[] = []
  allOptionsAvailable = false

  constructor(
    private apiPayerService: ApiPayerService
  ) {

  }


  ngOnInit() {
    this.apiPayerService.getAll().subscribe(r => {
      this.allOptionsAvailable = true
      r.data.forEach(a => {
        this.payers.push({label: a.name, value: a._id})
      })
    })
  }

  getName(id: string) {
    return this.payers.find(p => p.value === id)?.label
  }

}
