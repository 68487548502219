import { Injectable } from '@angular/core';
import * as FileSaver from "file-saver";

@Injectable({
  providedIn: 'root'
})
export class DataTableService {

  exportPdf(columns: any[], data: any) {
    return import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
        let large =  Object.keys(columns).length * 120
        large = large <= 1000 ? 1000 : large
        const doc = new jsPDF.default('l', 'px',  [1000, large]);
        (doc as any).autoTable(columns,  data);
        doc.save('products.pdf');
      });
    });
  }

  exportExcel(data: any) {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      console.log(data,  worksheet);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'products');
    }).catch(e => {
      console.log(e.message)
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
