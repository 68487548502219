<div class="card">
  <form [formGroup]="entity">
    <div class="grid">
      <div class="col-12 mb-8">
        <div class="field">
          <label for="adminId">Administrador:</label>
          <p-dropdown formControlName="adminId" [panelStyle]="{'width': '100%' }" [options]="admins" placeholder="Selecciona una opción" optionLabel="label" optionValue="value" (onChange)="getAssignments()"/>
          <ng-container *ngIf="entity.get('adminId')?.invalid && (entity.get('adminId')?.dirty || entity.get('adminId')?.touched)">
            <small class="p-error" *ngIf="entity.get('adminId')?.errors?.['required']">El administrador es obligatorio.</small>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
<ng-container *ngIf="entity.valid">
  <p-pickList
    [source]="sourceProducts"
    [target]="targetProducts"
    sourceHeader="Por asignar"
    targetHeader="Asignados"
    [dragdrop]="true"
    [responsive]="true"
    [sourceStyle]="{ height: '30rem' }"
    [targetStyle]="{ height: '30rem' }"
    filterBy="folio"
    sourceFilterPlaceholder="Buscar por folio"
    targetFilterPlaceholder="Buscar por folio"
    breakpoint="1400px">
    <ng-template let-loan pTemplate="item">
      <div class="flex flex-wrap p-2 align-items-center gap-3">
        <div class="flex-1 flex flex-column gap-2">
            <span class="font-bold">
                Folio: {{ loan.folio }}
            </span>
          <div class="flex align-products-center gap-2">
            <i class="pi pi-tag text-sm"></i>
            <span>
                {{ loan.fullName }}
            </span>
          </div>
        </div>
        <span class="font-bold text-900">
            {{ (loan.downDate | date: 'YYYY-MM-dd') ?? '--' }}
        </span>
      </div>
    </ng-template>
  </p-pickList>
</ng-container>
</div>
<p-toast></p-toast>
