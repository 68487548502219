import {ITableCol} from "@data/interfaces/itable.interface";
import {TYPE_PERIOD_ENUM, TYPE_PERIOD_ENUM_COLOR} from "@modules/private/company/data/enums/typePeriods.enum";
import {
  BUCKET_ENUM,
  BUCKET_ENUM_COLOR,
  LOAN_STATUS_ENUM,
  LOAN_STATUS_ENUM_COLOR, TYPE_PRODUCT_ENUM, TYPE_PRODUCT_ENUM_COLOR, TYPE_SERVICE_ENUM, TYPE_SERVICE_ENUM_COLOR
} from "@modules/private/loan/data/enums/loan.enum";
import {AFILIATION_ENUM, AFILIATION_ENUM_COLOR} from "@modules/private/workers/data/enums/workerStatus.enum";

export const LOAN_COLUMNS: ITableCol[] = [
  {type: 'text', field: 'firstName', header: 'Nombre(s)', showFilter: true},
  {type: 'text', field: 'lastName', header: 'Apellidos', showFilter: true},
  {type: 'text', field: 'fullName', header: 'Nombre Completo', showFilter: true},
  {type: 'text', field: 'emailAddress', header: 'Email', showFilter: true},
  {type: 'text', field: 'phoneNumber', header: 'Teléfono', showFilter: true},
  {type: 'text', field: 'company', header: 'Corporativo', showFilter: true},
  {type: 'text', field: 'contract', header: 'Razón social', showFilter: true},
  {type: 'text', field: 'remunerator', header: 'Pagadora', showFilter: true},
  {type: 'text', field: 'payroll', header: 'Nómina', showFilter: true},
  {type: 'enum', field: 'period', header: 'Periodo',showFilter: true, dataType: 'enum', enum: TYPE_PERIOD_ENUM, enumColor: TYPE_PERIOD_ENUM_COLOR, options: [
      {value: 'WEEKLY_PERIOD', label: 'Semanal'},
      {value: 'SEMIMONTHLY_PERIOD', label: 'Quincenal'},
      {value: 'BIWEEKLY_PERIOD', label: 'Catorcenal'},
      {value: 'MONTHLY_PERIOD', label: 'Mensual'},
    ]},
  {type: 'enum', field: 'product', header: 'Tipo de producto', showFilter: true, dataType: 'enum', enum: TYPE_PRODUCT_ENUM, enumColor: TYPE_PRODUCT_ENUM_COLOR, options: [
      {value: 'Crédito', label: 'Crédito'},
      {value: 'Adelanto Pulpi', label: 'Adelanto Pulpi'},
    ]},
  {type: 'numeric', field: 'amountFounded', header: 'Monto fondeado', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'capitalBorrowed', header: 'Capital Prestado', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'creationFee', header: 'Comisión por Apertura', dataType: 'currency', showFilter: true},
  {type: 'date', field: 'depositDate', header: 'Fecha de deposito', dataType: 'date', showFilter: true},
  {type: 'date', field: 'firstInstallment', header: 'Fecha de primer amortización', dataType: 'date', showFilter: true},
  {type: 'date', field: 'lastInstallment', header: 'Fecha de última amortización', dataType: 'date', showFilter: true},
  {type: 'date', field: 'nextInstallment', header: 'Fecha de siguiente amortización', dataType: 'date', showFilter: true},
  {type: 'date', field: 'firstInstallmentDefeated', header: 'Fecha en la que presento morosidad', dataType: 'date', showFilter: true},
  {type: 'date', field: 'downDate', header: 'Fecha de baja',  dataType: 'date', showFilter: true},
  {type: 'date', field: 'requestDate', header: 'Fecha de solicitud', dataType: 'date', showFilter: true},
  {type: 'numeric', field: 'rate', header: 'Tasa de intéres', dataType: 'percent', showFilter: true},
  {type: 'numeric', field: 'totalInstallments', header: 'Número de letras', showFilter: true},
  {type: 'numeric', field: 'installmentsPaid', header: 'Letras pagadas', showFilter: true},
  {type: 'numeric', field: 'installmentsToPay', header: 'Letras por pagar', showFilter: true},
  {type: 'numeric', field: 'interestInstallment', header: 'Intéreses de la letra', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'capitalInstallment', header: 'Capital de la letra', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'installmentAmount', header: 'Monto de la letra', dataType: 'currency', showFilter: true},
  {type: 'enum', field: 'status', header: 'Estatus de aprobación', showFilter: true, dataType: 'enum', enum: LOAN_STATUS_ENUM, enumColor: LOAN_STATUS_ENUM_COLOR, options: [
      {value: 'APPROVED', label: 'Aprobado'},
      {value: 'PENDING_APPROVAL', label: 'Pendiente de aprobación'},
      {value: 'DENIED', label: 'Denegado'}
    ]},
  {type: 'enum', field: 'bucket', header: 'Bucket', showFilter: true, dataType: 'enum', enum: BUCKET_ENUM, enumColor: BUCKET_ENUM_COLOR, options: [
      {value: 'BETWEEN_0_30', label: 'De 0 a 30 días'},
      {value: 'BETWEEN_31_60',label: 'De 31 a 60 días'},
      {value: 'BETWEEN_61_90',label: 'De 61 a 90 días'},
      {value: 'BETWEEN_91_120',label: 'De 91 A 120 días'},
      {value: 'BETWEEN_121_150',label: 'De 121 a 150 días'},
      {value: 'BETWEEN_151_180',label: 'De 151 a 180 días'}
    ]},
  {type: 'numeric', field: 'daysLate', header: 'Dias de atraso', showFilter: true},
  {type: 'numeric', field: 'totalDefeated', header: 'Total Vencido', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'totalPaid', header: 'Total Pagado ', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'totalToLiquidate', header: 'Total para liquidar', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'capitalPaid', header: 'Capital Pagado', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'capitalPending', header: 'Capital Pendiente', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'capitalDefeated', header: 'Capital Vencido', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'interestToPay', header: 'Intéres por pagar', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'interestPaid', header: 'Intéres pagado', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'interestPending', header: 'Intéres pendinete', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'interestDefeated', header: 'Intéres vencido', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'percentDePaid', header: 'Porcentaje pagado', showFilter: true},
  //{type: 'date', field: 'delayedDate', header: 'Fecha de atraso', dataType: 'date', showFilter: true},
  {type: 'text', field: 'personalReferenceName', header: 'Nombre de la referencia personal', showFilter: true},
  {type: 'text', field: 'personalReferencePhone', header: 'Teléfono de la referencia personal', showFilter: true},
  {type: 'text', field: 'personalReferenceRelation', header: 'Relación de la referencia personal', showFilter: true},
  {type: 'text', field: 'workReferenceName', header: 'Nombre de la referencia de trabajo', showFilter: true},
  {type: 'text', field: 'workReferencePhone', header: 'Teléfono de la referencia de trabajo', showFilter: true},
  {type: 'text', field: 'workReferenceRelation', header: 'Relación de la referencia de trabajo', showFilter: true},
  {type: 'text', field: 'avalReferenceName', header: 'Nombre del aval', showFilter: true},
  {type: 'text', field: 'avalReferencePhone', header: 'Teléfono del aval', showFilter: true},
  {type: 'text', field: 'avalReferenceRelation', header: 'Relación del aval', showFilter: true},
  {type: 'text', field: 'avalReferenceEmail', header: 'Email del aval', showFilter: true},
  {type: 'text', field: 'country', header: 'País', showFilter: true},
  {type: 'text', field: 'city', header: 'Ciudad', showFilter: true},
  {type: 'text', field: 'cp', header: 'Código Postal', showFilter: true},
  {type: 'enum', field: 'typeService', header: 'Tipo de servicio', showFilter: true,  dataType: 'enum', enum: TYPE_SERVICE_ENUM, enumColor: TYPE_SERVICE_ENUM_COLOR, options: [
      {value: 'NEW', label: 'Nuevo'},
      {value: 'REPEAT', label: 'Repetido'},
    ]},
  {type: 'numeric', field: 'timeProcess', header: 'Tiempo de procesamiento', showFilter: true},
  {type: 'enum', field: 'userStatus', header: 'Estatus de baja', showFilter: true, dataType: 'enum', enum: AFILIATION_ENUM, enumColor: AFILIATION_ENUM_COLOR, options: [
      {value: 'AFILIADO', label: 'Activo'},
      {value: 'DESAFILIADO', label: 'Baja'},
    ]}
]
