
<ng-container *ngIf="data;">
  <div class="grid background-secondary">
    <div class="col-12 comment-rejected" *ngIf="values && values.comment !== ''">
      Último motivo de rechazo: <strong>{{values.comment}}</strong>
    </div>
    <ng-container  *ngIf="values && values.value !== ''">
      <div class="col-12">
        <div class="main-title">
          Estado del documento de firma
        </div>
      </div>
      <div class="col-12">
        <h3 class="mb-2">URL asignada al usuario: <span class="font-normal h6 ml-2">{{values.value}}</span></h3>
      </div>
    </ng-container>
  </div>
</ng-container>
<div class="col-12">
  <form [formGroup]="entity">
    <div class="grid">
      <div class="col-12">
        <div class="main-title">
          Actualizar la liga:
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field field-flex">
          <label for="url">Url de la liga externa:*</label>
          <input type="text" pInputText id="url" formControlName="url" placeholder="https://pulpi.mx/liga" required />
          <ng-container *ngIf="entity.get('url')?.invalid && (entity.get('url')?.dirty || entity.get('url')?.touched)">
            <small class="p-error" *ngIf="entity.get('url')?.errors?.['required']">El campo es obligatorio.</small>
            <small class="p-error" *ngIf="entity.get('url')?.errors?.['minlength']">El campo debe tener {{entity.get('url')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
          </ng-container>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="field filed-flex flex flex-column">
          <label for="requireUserAction">¿Actualizar y solicitar acción por parte del usuario?</label>
          <p-inputSwitch formControlName="requireUserAction" class="ng-dirty ng-invalid"></p-inputSwitch>
        </div>
      </div>
    </div>
  </form>
</div>
<p-toast></p-toast>
