import {Component, EventEmitter, Output} from '@angular/core';
import {Message} from "primeng/api";
import {ApiReportsService} from "@modules/private/reports/data/services/api/api-reports.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-reports-pdl-to-cib',
  templateUrl: './reports-pdl-to-cib.component.html',
  styleUrls: ['./reports-pdl-to-cib.component.scss']
})
export class ReportsPdlToCibComponent {
  @Output() onGenerated = new EventEmitter()
  visible = false
  isLoading = false
  responseMessage: Message[] = []
  entity!: FormGroup
  constructor(
    private apiReportsService: ApiReportsService,
    private fb: FormBuilder
  ) {
    this.entity = this.fb.group({
      initDate: ['', [Validators.required]],
      endDate: ['']
    })
  }

  getReport() {
    this.visible = false
    this.isLoading = true
    this.responseMessage = []
    this.apiReportsService.getPDLtoCIBReport(this.entity.value).subscribe((r) => {
      this.responseMessage = [
        {
          summary: r.error ? 'Hubo un error al obtener el reporte':'El reporte se genero correctamente',
          severity: r.error ? 'error': 'success',
          closable: true,
          detail: r.error ? '' : `<a href="${r.data.url}" target="_blank">Descargar reporte</a>`
        }]
      this.visible = true
      this.isLoading = false
      this.onGenerated.emit()
    })
  }
}
