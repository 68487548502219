<form [formGroup]="entity">
  <div class="grid" formArrayName="dates">
    <div class="col-12">
      <div class="grid" *ngFor="let date of dates.controls; let i = index">
        <div class="col-12" [formGroupName]="i">
          <div class="grid">
            <div class="col-12 md:col-6 xl:col-3">
              <div class="field">
                <label for="cutoffDate">Fecha de corte:</label>
                <p-calendar
                  id="cutoffDate"
                  formControlName="cutoffDate"
                  [showIcon]="true"
                  [showOnFocus]="false"
                  [numberOfMonths]="1"
                  inputId="buttondisplay"
                />
                <ng-container *ngIf="entity.get('cutoffDate')?.invalid && (entity.get('cutoffDate')?.dirty || entity.get('cutoffDate')?.touched)">
                  <small class="p-error" *ngIf="entity.get('cutoffDate')?.errors?.['required']">La fecha de corte es obligatoria.</small>
                  <small class="p-error" *ngIf="entity.get('cutoffDate')?.errors?.['invalidDate']">La primer fecha debe ser menor a hoy.</small>
                </ng-container>
              </div>
            </div>
            <div class="col-12 md:col-6 xl:col-3">
              <div class="field">
                <label for="incidentDate">Fecha de envio de incidencias:</label>
                <p-calendar
                  [disabled]="true"
                  id="incidentDate" formControlName="incidentDate"
                  [showIcon]="true"
                  [showOnFocus]="false"
                  [numberOfMonths]="1"
                  inputId="buttondisplay" />
                <ng-container *ngIf="entity.get('incidentDate')?.invalid && (entity.get('incidentDate')?.dirty || entity.get('incidentDate')?.touched)">
                  <small class="p-error" *ngIf="entity.get('incidentDate')?.errors?.['required']">La fecha de envió de incidencias es obligatoria.</small>
                  <small class="p-error" *ngIf="entity.get('incidentDate')?.errors?.['invalidDate']">La primer fecha debe ser menor a hoy.</small>
                </ng-container>
              </div>
            </div>
            <div class="col-12 md:col-6 xl:col-3">
              <div class="field">
                <label for="paymentDate">Fecha de pago:</label>
                <p-calendar
                  id="paymentDate" formControlName="paymentDate"
                  [showIcon]="true"
                  [showOnFocus]="false"
                  [numberOfMonths]="1"
                  inputId="buttondisplay" />
                <ng-container *ngIf="entity.get('paymentDate')?.invalid && (entity.get('paymentDate')?.dirty || entity.get('paymentDate')?.touched)">
                  <small class="p-error" *ngIf="entity.get('paymentDate')?.errors?.['required']">La fecha de pago es obligatoria.</small>
                  <small class="p-error" *ngIf="entity.get('paymentDate')?.errors?.['invalidDate']">La primer fecha debe ser menor a hoy.</small>
                </ng-container>
              </div>
            </div>

            <ng-container *ngIf="dates.controls[i].value.isNextDate">
              <div class="col-3 flex flex-row justify-content-end align-items-center">
                <button pButton label="Eliminar" icon="fa-solid fa-times" class="p-button-danger p-button-sm button-small" (click)="removeDate(i, currentDate(i)._id )"></button>
                <ng-container *ngIf="currentDate(i)._id === null; else endUpdate">
                  <button pButton label="Guardar" icon="pi pi-check" class="p-button-success p-button-sm button-small ml-3" (click)="createPeriod(date)" ></button>
                </ng-container>
                <ng-template #endUpdate>
                  <button pButton label="Actualizar" icon="fa-solid fa-pencil" class="p-button-primary p-button-sm button-small ml-3" (click)="updatePeriod(date)" ></button>
                </ng-template>
              </div>
            </ng-container>
            <div class="col-12 border-bottom-1 mb-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid">
    <div class="col-2 col-offset-10 text-right">
      <button pButton label="Agregar" icon="fa-solid fa-plus" class="p-button-success p-button-sm" (click)="addDate()"></button>
    </div>
    <div class="col-12">
      <p-chip styleClass="pl-0 pr-3 mr-2">
          <span class="bg-cutoff-date border-circle w-2rem h-2rem flex align-items-center justify-content-center">
          </span>
              <span class="ml-2 font-medium">
              Fecha de corte
          </span>
      </p-chip>
      <p-chip styleClass="pl-0 pr-3">
          <span class="bg-incident-date border-circle w-2rem h-2rem flex align-items-center justify-content-center">
          </span>
        <span class="ml-2 font-medium">
              Fecha de envio de incidencias
          </span>
      </p-chip>
      <p-chip styleClass="pl-0 pr-3 mr-2">
          <span class="bg-payment-date border-circle w-2rem h-2rem flex align-items-center justify-content-center">
          </span>
        <span class="ml-2 font-medium">
              Fecha de pago
          </span>
      </p-chip>
    </div>
  </div>
</form>
<p-calendar
  class="max-w-full"
  [(ngModel)]="date"
  [inline]="true"
  [showWeek]="true"
  [numberOfMonths]="2"
  (ngModelChange)="onChange()"
  [selectionMode]="'multiple'"
>
  <ng-template pTemplate="date" let-date>
    <ng-container *ngIf="isPaymentDate(date.day, date.month, date.year, 'ALL')">
      <span style="border-radius:50%; padding: .25em; width: 2em; height: 2em; display:flex; justify-content: center; align-items: center;color: black;">
        {{date.day}}
    </span>
    </ng-container>
    <ng-container *ngIf="isPaymentDate(date.day, date.month, date.year, 'INCIDENT')">
      <span [ngStyle]="{backgroundColor: '#27396a'}" style="border-radius:50%; padding: .25em; width: 2em; height: 2em; display:flex; justify-content: center; align-items: center;color: white;">
        {{date.day}}
    </span>
    </ng-container>
    <ng-container *ngIf="isPaymentDate(date.day, date.month, date.year, 'CUTOFF')">
        <span [ngStyle]="{backgroundColor: '#1a81ae'}" style="border-radius:50%; padding: .25em; width: 2em; height: 2em; display:flex; justify-content: center; align-items: center;color: white;">
          {{date.day}}
      </span>
    </ng-container>
    <ng-container *ngIf="isPaymentDate(date.day, date.month, date.year, 'PAYMENT')">
        <span [ngStyle]="{backgroundColor: '#1cd16b'}" style="border-radius:50%; padding: .25em; width: 2em; height: 2em; display:flex; justify-content: center; align-items: center;color: white;">
          {{date.day}}
      </span>
    </ng-container>
    <ng-container *ngIf="isPaymentDate(date.day, date.month, date.year, 'DEFAULT')">
      <span style="color: black !important;">
        {{date.day}}
    </span>
    </ng-container>
  </ng-template>
</p-calendar>
<p-toast></p-toast>
