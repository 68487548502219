export enum PUSHING_STATUS {
  '' = 'No ha iniciado el proceso',
  NOT_STARTED = 'No ha iniciado el proceso',
  VALIDATED = 'Validado',
  MANUAL_VALIDATION = 'Require validación manual',
  REJECTED = 'Rechazado',
  IN_PROGRESS = 'En proceso',
  EXPIRED = 'Expirado',
  NEED_AGENT_INTERACTION = 'Necesita atencion del asesor'
}

export enum PUSHING_FLOW_STATUS {
  COMPLETED = 'Completado',
  IN_PROCESS = 'En proceso',
  CANCELLED = 'Cancelado'
}
export enum PUSHING_STATUS_COLOR {
  '' = 'secondary',
  NOT_STARTED = 'secondary',
  VALIDATED = 'success',
  MANUAL_VALIDATION = 'primary',
  REJECTED = 'danger',
  NEED_AGENT_INTERACTION = 'danger',
  IN_PROGRESS = 'info',
  EXPIRED = 'warning'
}

export enum PUSHING_FLOW_STATUS_COLOR {
  COMPLETED = 'success',
  IN_PROCESS = 'primary',
  CANCELLED = 'danger'
}
