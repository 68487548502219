<ng-container>
  <form [formGroup]="entity">
    <div class="grid px-4">
      <div class="col-12">
        <p class="p mb-4">
          Asegurate de subir un documento valido para el proceso.
        </p>
      </div>
      <div class="col-12 comment-rejected" *ngIf="values && values.comment !== ''">
        Último motivo de rechazo: <strong>{{values.comment}}</strong>
      </div>
      <div class="col-12">
        <div class="message">
          <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
        </div>
      </div>
      <div class="col-12" *ngIf="values && values.status === 'VALIDATED' && values.value !== '' && !allowUploadFile">
        <div class="document-uploaded">
          <a [href]="values.value" target="_blank">Abrir documento <fa-icon [icon]="faPaperclip"></fa-icon></a>
          <div class="document-uploaded-delete" (click)="allowUploadFile = !allowUploadFile">
            <fa-icon [icon]="faTrashCan"></fa-icon>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="(allowUploadFile || !values) || (values && values.status !== 'VALIDATED')">
        <div class="input-container">
          <div class="icon {{hasFile ? 'icon-filled' : 'icon-empty'}}">
            <fa-icon [icon]="faCircle"></fa-icon>
          </div>
          <div class="input-info">
            <h4>Ultimo comprobante de nomina</h4>
            <p>Documento legible</p>
          </div>
          <div class="action">
            <ng-container *ngIf="hasFile; else elseFile">
              <fa-icon [icon]="faTrashCan" (click)="file.clear();clear()"></fa-icon>
            </ng-container>
            <ng-template #elseFile>
              <fa-icon [icon]="faUpload"></fa-icon>
            </ng-template>
            <div class="fileUpload {{hasFile ? 'hide-all': ''}}">
              <p-fileUpload #file mode="basic" chooseLabel="Choose" name="file[]" accept="image/*,application/pdf" [maxFileSize]="1000000" (onSelect)="select($event)" [customUpload]="true" ></p-fileUpload>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>
