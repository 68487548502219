import {Component, OnInit} from '@angular/core';
import {IReport} from "@modules/private/reports/data/interfaces/ireport.interface";
import {ApiReportsService} from "@modules/private/reports/data/services/api/api-reports.service";
import {TYPE_REPORT_ENUM} from "@modules/private/reports/data/enums/type-report.enum";
@Component({
  selector: 'app-reports-table',
  templateUrl: './reports-table.component.html',
  styleUrls: ['./reports-table.component.scss']
})
export class ReportsTableComponent implements OnInit {
  reports: IReport[] = []
  types = [
    {label: 'Reporte de SoD', value: 'SOD_REPORT', color: 'help'},
    {label: 'Reporte de migracion de PDL a CIB', value: 'CIB_REPORT', color: 'success'},
    {label: 'Reporte de ATR', value: 'ATR_REPORT', color: 'success'},
    {label: 'Reporte de ATR para fondeador percent', value: 'ATR_PERCENT_REPORT', color: 'success'},
    {label: 'Reporte para circulo de crédito', value: 'CIRCLE_LOAN_REPORT', color: 'success'},
    {label: 'Reporte de incidencias de SOD', value: 'INCIDENTS_SOD', color: 'info'},
    {label: 'Reporte de incidencias de PDL', value: 'INCIDENTS_LOAN', color: 'warning'},
    {label: 'Reporte de exigibilidad Historica', value: 'ENFORCEABILITY_REPORT', color: 'warning'},
    {label: 'Reporte de aplicaciones Historica', value: 'APPLICATION_HISTORY_REPORT', color: 'warning'},
    {label: 'Reporte de colaboradores para campañas', value: 'WORKERS_CAMPAIGNS', color: 'warning'},
    {label: 'Reporte de migración de SOD deprecated', value: 'MIGRATION_SOD', color: 'danger'},
    {label: 'Reporte de migración de PDL deprecated', value: 'MIGRATION_LOAN', color: 'secondary'},
  ]
  $_typeEnum = TYPE_REPORT_ENUM
  constructor(
    private apiReportsService: ApiReportsService
  ) {
  }

  ngOnInit() {
    this.getReports()
  }

  getReports() {
    this.apiReportsService.getAll().subscribe((r) => {
      console.log(r.data)
      if(!r.error) this.reports = r.data.map((r) => {
        r.creationDate = new Date(r.creationDate)
        return r
      })
    })
  }
}
