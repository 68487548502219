<div class="grid">
  <div class="col-12">
    <p-messages [(value)]="responseMessage" [enableService]="visible" [closable]="true" [escape]="false"></p-messages>
  </div>
  <div class="col-12">
    <div class="card-report">
      <div class="card-report-info">
        <h3 class="mb-3">Reporte de incidencias de SOD:</h3>
        <h6 class="mb-3">El reporte de incidencias de SOD, se genera proporcionando una fecha de corte y una nómina, la fecha de corte que se seleccione se tomara como el último que el sistema tomara para regresar las incidencias de SoD. </h6>
        <ng-container *ngIf="!isLoading; else loading">
          <button pButton pRipple label="Generar reporte" icon="pi pi-check" class="p-button-success" [disabled]="entity.invalid" (click)="getReport()"></button>
        </ng-container>
        <ng-template #loading>
          <p-progressSpinner></p-progressSpinner>
        </ng-template>
      </div>
      <div class="card-report-image"></div>
    </div>
  </div>
  <div class="col-12">
    <form [formGroup]="entity">
      <div class="grid">
        <ng-container *ngIf="getIds.length > 0">
          <div class="col-12 container-tag">
            <h4>Nóminas seleccionadas:</h4>
            <div class="container-tag_tags">
              <ng-container *ngFor="let id of getIds">
                <div class="tag">
                  <p-tag [value]="id" [rounded]="true"></p-tag>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <div class="col-12 md:col-4">
          <div class="field field-flex">
            <label for="cutOffDate">Fecha de corte:*</label>
            <input type="date" pInputText id="cutOffDate" formControlName="cutOffDate" required />
            <ng-container *ngIf="entity.get('cutOffDate')?.invalid && (entity.get('cutOffDate')?.dirty || entity.get('cutOffDate')?.touched)">
              <small class="p-error" *ngIf="entity.get('cutOffDate')?.errors?.['required']">La fecha de corte es obligatoria.</small>
            </ng-container>
          </div>
        </div>
        <div class="col-12 md:col-8">
          <div class="field field-flex">
            <label for="id">Nómina:</label>
            <div class="flex justify-content-center flex-column">
              <p-multiSelect [options]="payrolls" formControlName="id" optionLabel="label" placeholder="Selecciona al menos una nómina"></p-multiSelect>
              <ng-container *ngIf="entity.get('id')?.invalid && (entity.get('id')?.dirty || entity.get('id')?.touched)">
                <small class="p-error" *ngIf="entity.get('id')?.errors?.['required']">La nómina es obligatoria.</small>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
