import { Component } from '@angular/core';

@Component({
  selector: 'app-loan-historial',
  templateUrl: './loan-historial.component.html',
  styleUrls: ['./loan-historial.component.scss']
})
export class LoanHistorialComponent {

}
