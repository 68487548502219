<ng-container *ngIf="entity">
  <form [formGroup]="entity">
    <div class="field">
      <label for="permissions">Permisos:</label>
      <p-multiSelect [options]="permissions" [group]="true" formControlName="permissions" placeholder="Selecciona los permisos" scrollHeight="200px" display="chip">
        <ng-template let-group pTemplate="group">
          <div class="flex align-items-center">
            <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'mr-2 flag flag-' + group.value" style="width: 20px" />
            <span>{{ group.label }}</span>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
    <div class="field">
      <label for="identifier">Nombre del Role:</label>
      <input type="text" pInputText id="identifier" formControlName="identifier" placeholder="ROLE_ONLY_READ" required autofocus />
      <ng-container *ngIf="entity.get('identifier')?.invalid && (entity.get('identifier')?.dirty || entity.get('identifier')?.touched)">
        <small class="p-error" *ngIf="entity.get('identifier')?.errors?.['required']">El nombre es obligatorio.</small>
        <small class="p-error" *ngIf="entity.get('identifier')?.errors?.['minlength']">El nombre debe tener al menos {{entity.get('identifier')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
      </ng-container>
    </div>
    <div class="field">
      <label for="description">Alcance del Role:</label>
      <textarea rows="5" cols="30" pInputTextarea id="description" formControlName="description" placeholder="Role que solo tiene acceso a leer" required autofocus></textarea>
      <ng-container *ngIf="entity.get('description')?.invalid && (entity.get('description')?.dirty || entity.get('description')?.touched)">
        <small class="p-error" *ngIf="entity.get('description')?.errors?.['required']">El alcance es obligatorio.</small>
      </ng-container>
    </div>
  </form>
</ng-container>
