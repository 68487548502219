import {Component, Input, OnInit} from '@angular/core';
import {ILoanPopulated} from "@modules/private/loan/data/interfaces/iloan.interface";
import {GENDER_WORKER} from "@modules/private/workers/data/enums/gender.enum";
import {PERIOD_TYPE_ENUM} from "@modules/private/payroll/data/enums/payroll.enum";
import {IPosition} from "@modules/private/workers/data/interfaces/iposition.interface";

@Component({
  selector: 'app-worker-loan-detail',
  templateUrl: './worker-loan-detail.component.html',
  styleUrls: ['./worker-loan-detail.component.scss']
})
export class WorkerLoanDetailComponent implements OnInit {
  @Input() data!: ILoanPopulated
  @Input() lastPosition!: IPosition
  $_gendersEnum = GENDER_WORKER
  $_periodTypeEnum = PERIOD_TYPE_ENUM
  constructor(
  ) {
  }

  ngOnInit() {
  }
}
