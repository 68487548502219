<p-table
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 20, 50]"
  currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} reportes"
  [value]="reports" [tableStyle]="{ 'min-width': '100%' }">
  <ng-template pTemplate="header">
    <tr>
      <th style="min-width:30rem">
        <p-columnFilter field="type" matchMode="equals">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-dropdown [ngModel]="value" [options]="types" (onChange)="filter($event.value)" placeholder="Tipo de reporte">
              <ng-template let-option pTemplate="item">
                {{option.label}}
              </ng-template>
            </p-dropdown>
          </ng-template>
        </p-columnFilter>
      </th>
      <th style="min-width:15rem">
        <div class="flex align-items-center" pSortableColumn="companyId.name">
          Nombre del corporativo
          <p-columnFilter type="text" field="companyId.name" display="menu" class="mt-1"></p-columnFilter>
          <p-sortIcon field="companyId.name"></p-sortIcon>
        </div>
      </th>
      <th style="min-width:15rem">
        <div class="flex align-items-center" pSortableColumn="payrollId.name">
          Nombre de la nómina
          <p-columnFilter type="text" field="payrollId.name" display="menu" class="mt-1"></p-columnFilter>
          <p-sortIcon field="payrollId.name"></p-sortIcon>
        </div>
      </th>
      <th style="min-width:15rem">
        <div class="flex align-items-center" pSortableColumn="adminId.names.firstName">
          Exportador
          <p-columnFilter type="text" field="adminId.names.firstName" display="menu" class="mt-1"></p-columnFilter>
          <p-sortIcon field="adminId.names.firstName"></p-sortIcon>
        </div>
      </th>
      <th style="min-width:15rem">
        <div class="flex align-items-center" pSortableColumn="creationDate">
          Fecha de creación
          <p-columnFilter type="date" field="creationDate" display="menu" class="mt-1"></p-columnFilter>
          <p-sortIcon field="creationDate"></p-sortIcon>
        </div>
      </th>
      <th style="min-width:10rem">URL de reporte</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-report>
    <tr>
      <td>
        {{ report.type | selectEnum: $_typeEnum }}</td>
      <td>{{ report.companyId ? report.companyId.name : '-'}}</td>
      <td>{{ report.payrollId ? report.payrollId.name : '-'}}</td>
      <td>{{ report.adminId ? (report.adminId.names.firstName + ' ' + report.adminId.names.lastName): '-'}}</td>
      <td>{{ report.creationDate | date: 'dd-MM-YYYY hh:mm:ss' }}</td>
      <td><a [href]="report.url" target="_blank">Abrir Url</a></td>
    </tr>
  </ng-template>
</p-table>
