import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IFlowData, IPushingFlowDetail} from "@modules/private/pushing/data/interfaces/ipushing.interface";
import {ConfirmationService, MessageService} from "primeng/api";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FlowDataService} from "@modules/private/pushing/data/services/flow-data.service";
import {PUSHING_STATUS, PUSHING_STATUS_COLOR} from "@modules/private/pushing/data/enums/pushing.enum";

@Component({
  selector: 'app-pushing-record-request',
  templateUrl: './pushing-record-request.component.html',
  styleUrls: ['./pushing-record-request.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class PushingRecordRequestComponent {
  @Output() onCompleteUpdate = new EventEmitter()
  @Input() data!: IPushingFlowDetail
  createEntity = false;
  submitted: boolean = false;
  entity!: FormGroup
  $_statusPushingEnum = PUSHING_STATUS
  $_statusPushingColorEnum = PUSHING_STATUS_COLOR
  constructor(
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private flowDataService: FlowDataService,
    private messageService: MessageService,
  ) {
  }

  confirmUpdate(event: Event, stageId: string, data: FormData) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: '¿Estas seguro que deseas actualizar la información?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Continuar',
      rejectLabel: 'Cancelar',
      rejectButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.save(stageId, data)
      }
    });
  }

  confirmApprobation(event: Event, stageId: string, documentId: string) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: '¿Estas seguro que deseas aprobar la información?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Aprobar',
      rejectLabel: 'Cancelar',
      rejectButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.approve(stageId, documentId)
      }
    });
  }

  getValue(documentId: string): IFlowData | null {
    return (documentId) ?  this.data.flowData.find(fd => fd.documentId === documentId) ?? null : null
  }

  getStageId(documentId: string): string{
    let stageId = ''
    if (documentId) {
      stageId = this.data.flowData.find(fd => fd.documentId === documentId)?._id ?? ''
    }
    return stageId
  }

  getStageStatus(documentId: string): string{
    let stageId = 'IN_PROGRESS'
    if (documentId) {
      stageId = this.data.flowData.find(fd => fd.documentId === documentId)?.status ?? ''
    }
    return stageId
  }
  hideDialog() {
    this.createEntity = false;
    this.submitted = false;
  }

  reject(stageId: string = '') {
    this.entity = this.fb.group({
      comment: ['', [Validators.required, Validators.minLength(15)]],
      stageId: [stageId, [Validators.required]]
    })
    this.entity.clearValidators()
    this.submitted = false;
    this.createEntity = true;
  }

  continueWithReject() {
    console.log(this.entity.value)
    this.flowDataService.reject(this.entity.value).subscribe((r) => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 3000 });
      this.onCompleteUpdate.emit()
      if (!r.error) {
        this.hideDialog()
      }
    })
  }

  save(stageId: string, data: FormData) {
    data.append('stageId', stageId )
    data.append('workerId', this.data.pushing.workerId._id)
    this.flowDataService.update(data).subscribe((r) => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 3000 });
      this.onCompleteUpdate.emit()
      if (!r.error) {
        this.hideDialog()
      }
    })
  }

  approve(stageId: string, documentId: string) {
    this.flowDataService.approve({stageId, workerId:  this.data.pushing.workerId._id, documentId}).subscribe((r) => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 3000 });
      this.onCompleteUpdate.emit()
      if (!r.error) {
        this.hideDialog()
      }
    })
  }
}
