import { Component } from '@angular/core';
import {MenuItem} from "primeng/api";
import {AuthService} from "@data/services/modules/auth/auth.service";

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent {
  items: MenuItem[] | undefined;
  home: MenuItem | undefined;
  showLeftMenu = true
  constructor(
    public authService: AuthService
  ) {
    this.items = [{ label: 'Computer' }, { label: 'Notebook' }, { label: 'Accessories' }, { label: 'Backpacks' }, { label: 'Item' }];
    this.home = { icon: 'pi pi-home', routerLink: '/' };
  }
}
