import {Component, Input, OnInit} from '@angular/core';
import {IApiLoanWithInstallments} from "@modules/private/loan/data/interfaces/iloan.interface";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {LOAN_STATUS_ENUM, LOAN_STATUS_ENUM_COLOR} from "@modules/private/loan/data/enums/loan.enum";
import {PERIOD_TYPE_ENUM, PERIOD_TYPE_ENUM_COLOR} from "../../../payroll/data/enums/payroll.enum";

@Component({
  selector: 'app-worker-list-loan',
  templateUrl: './worker-list-loan.component.html',
  styleUrls: ['./worker-list-loan.component.scss']
})
export class WorkerListLoanComponent implements OnInit {
  @Input() workerId!: string
  loans: IApiLoanWithInstallments[] = []
  isLoading = true
  $_statusLoan = LOAN_STATUS_ENUM
  $_statusLoanColor = LOAN_STATUS_ENUM_COLOR
  constructor(
    private apiLoanService: ApiLoanService
  ) {
  }

  ngOnInit() {
    this.apiLoanService.getByUserId(this.workerId).subscribe(r => {
      this.loans = r.data
      this.isLoading = false
    })
  }

  getTypeProduct(loan: IApiLoanWithInstallments) {
    return (loan.rate > 0.001)  ? 'Credito' : 'Adelanto Pulpi'
  }

  getSeverity(typeProduct: string) {
    switch (typeProduct) {
      case 'Credito':
        return 'success'
      default:
        return 'primary'
    }
  }

  protected readonly PERIOD_TYPE_ENUM = PERIOD_TYPE_ENUM;
  protected readonly PERIOD_TYPE_ENUM_COLOR = PERIOD_TYPE_ENUM_COLOR;
}
